import React, { useEffect, useRef, useState } from 'react';
import { Button, CMSLayout, Col, Grid, Input, Row, Text, UploadFile, useUIState, showPopupMessage } from 'components';
import { COLOR } from 'const';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { notification } from 'antd';
import { omitBy, isNil } from 'lodash';

const PackingSettings = () => {
  const { route } = useNavFunc();
  // @ts-ignore
  const { id } = route.params || {};
  const [{ loading: imageLoading }, setImageUI] = useUIState();
  const [loading, setLoading] = useState(false);
  const uploadRef = useRef<{ showDialog: Function }>(null);
  const [packingSlip, updatePackingSlip] = useState<any>({});

  const onChangeField = (key) => (value) => {
    updatePackingSlip(s => ({
      ...s,
      [key]: value,
    }))
  }

  const getPackingSlips = async () => {
    if (id) {
      const res = await Store.Api.PackingSlip.getPackingSlip({ storeId: id });
      const data = res?.data?.data || {};
      updatePackingSlip({
        id: data.id,
        // storeId: data.storeId,
        companyName: data.companyName,
        phone: data.phone,
        email: data.email,
        address: data.address,
        companyLogo: data.companyLogo,
      });
    }
  }

  useEffect(() => {
    getPackingSlips();
  }, []);

  const onImageUploaded = urls => {
    if (urls.length === 0) return setImageUI({ loading: false });
    updatePackingSlip(s => ({
      ...s,
      companyLogo: urls?.[0],
    }))
    setImageUI({ loading: false });
  }

  const submit = async () => {
    setLoading(true);
    try {
      const res = await Store.Api.PackingSlip.upsertPackingSlip(omitBy({
        ...packingSlip,
        storeId: id,
      }, isNil));
      if (res.data.error) {
        showPopupMessage({
          title: '',
          content: String(res.data.error),
          buttonOkText: 'OK',
          
          typeHighlight: 'danger',
          contentHighlight: 'Error'
    
        });
        // alert(res.data.error);
      } else if (res.data.data) {
        notification.success({ message: "Packing slips successfully updated" })
      }
    } catch (error) {
      showPopupMessage({
        title: '',
        content: String(error?.message || JSON.stringify(error)),
        buttonOkText: 'OK',
        
        typeHighlight: 'danger',
        contentHighlight: 'Error'
  
      });
      // alert(error?.message || JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  }

  return (
    <CMSLayout requireAuthen>
      <Row m2 marginBottom={0} justifyContent={'space-between'}>
        <Text h3>Packing slip</Text>
        <Row>
          <Button
            isLoading={loading}
            text={"Save"}
            width={100} height={40} borderRadius={20}
            onPress={submit}
          />
        </Row>
      </Row>
      <Col flex1 m2 mv1 p1 round1 bgWhite overflow={"scroll"}>
        <Grid xs="100%" md="50%" alignItems={"flex-start"} mb2>
          <Col m1>
            <Text mb2 caption>
              The information below is what we will display within your packing slip
            </Text>
            <Input
              placeholder="Company Name"
              value={packingSlip.companyName}
              mb1
              onChange={onChangeField("companyName")}
            />
            <Input
              placeholder="Phone"
              value={packingSlip.phone}
              mb1
              onChange={onChangeField("phone")}
            />
            <Input
              placeholder="Email"
              value={packingSlip.email}
              mb1
              onChange={onChangeField("email")}
            />
            <Input
              multiline
              height={120}
              placeholder="Address"
              value={packingSlip.address}
              mb1
              onChange={onChangeField("address")}
            />
            <Input
              placeholder="Company logo"
              value={packingSlip.companyLogo}
              mb1
              flexDirection={'row'} alignItems={'center'}
              onChange={onChangeField("companyLogo")}
              buttonRight={
                <Button
                  isLoading={imageLoading}
                  mr1
                  text="Upload"
                  height={30}
                  borderRadius={15}
                  width={100}
                  onPress={() => {
                    setImageUI({ loading: true });
                    uploadRef.current.showDialog();
                  }}
                />
              }
            />
          </Col>
        </Grid>
        <UploadFile
          ref={uploadRef}
          onUploaded={onImageUploaded}
        />
      </Col>
    </CMSLayout>
  );
};

PackingSettings.routeInfo = {
  title: 'Packing Slip Detail',
  path: '/packing-slips/:id'
};

export default PackingSettings;
