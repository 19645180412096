
import { Col, Row } from "components";
import React from "react";
import ImageItem from "./ImageItem";
import { ScrollView, useWindowDimensions } from "react-native";


const ProductImages = ({ images, mainImage, onPressRemove }: {
  images: string[], mainImage?: string, onPressRemove?: (idx: number) => void,
}) => {
  const { height } = useWindowDimensions();

  const mainImgMaxHeight = height - 280;
  return (
    <Col>
      <ImageItem
        uri={mainImage}
        style={{ width: "100%", maxWidth: mainImgMaxHeight, borderRadius: 8, alignSelf: "center" }}
      />
      <ScrollView
        style={{ width: "100%" }}
        contentContainerStyle={{ padding: 4, justifyContent: "center", flexGrow: 1 }}
        horizontal
        showsHorizontalScrollIndicator={false}
      >
        {images?.map((i, idx) => (
          <ImageItem
            uri={i}
            key={`${i}${idx}`}
            marginRight={16}
            marginTop={16}
            canRemove={typeof onPressRemove === "function"}
            style={{ borderRadius: 3 }}
          />
        ))}
      </ScrollView>
    </Col>
  )
}

export default ProductImages;
