import React, { useState, useRef, useEffect, useId } from 'react';
import { Col, Text, TouchField, Tooltip } from 'components';
import { useNavFunc } from 'navigation';
import { COLOR } from 'const';

const SideNav = ({ title, screen, highlights, params, tooltip, icon, url, badge }) => {
  const { navigate, route, navigation } = useNavFunc();
  const [isHover, setIsHover] = useState(false);
  const [id] = useState('item-'+Math.random().toString(36).substring(7));
  const isActive = route.name === screen || (!!highlights && highlights.includes(route.name));
  const bgColor = isActive ? COLOR.MAIN : (
    isHover ? COLOR.GREY_LIGHT : 'transparent'
  );
  const textColor = isActive ? 'white' : 'black';
  useEffect(() => {
    const navItem = document.getElementById(id);
    navItem.onclick = (e) => {
      e.preventDefault();
      navigate(screen, params);
    };
  }, [id, screen, params]);

  const renderNavItem = () => {
    // @ts-ignore
    return (<nav-item
      active={isActive ? 'active' : ''}
      icon={icon}
      title={title}
      url={url}
      id={id}
      badge={badge}
    />)
  }

  return (
    <Col mv0 mh1 mh0
      // onPress={(e) => {
      //   e.stopPropagation();
      //   navigate(screen, params);
      // }}
    >
      {tooltip ? (
        <Tooltip
          title={tooltip}
          trigger="mouseenter"
          position="top-end"
        >
          {renderNavItem()}
        </Tooltip>
      ) : (
        renderNavItem()
      )}
    </Col>
  );
};

export default SideNav;
