import React, { useEffect, useState } from 'react';
import { TouchField, Col, Row, Text, SwapIconEffect, modal } from 'components';
import { TPrintJob, TUser } from 'type';
import { SVG } from 'assets';
import { SimpleLineIcons, Feather, MaterialIcons, AntDesign, EvilIcons, Ionicons } from '@expo/vector-icons';
import { TimeHelper } from 'helpers';
import { Image } from 'react-native';
import {
  ActivityIndicator
} from 'react-native';
import { COLOR } from 'const';
import Store from 'store';
import * as Animatable from 'react-native-animatable';

interface IPrintJobItemProps {
  data: TPrintJob,
  onPay: any,
  onDataUpdate(newData: TPrintJob): void,
  onDelete: any,
}

const ICON = {
  DOWNLOAD: 0,
  LOADING: 1,
  DONE: 2
}

const PrintJobItem = ({ data, onPay, onDataUpdate, onDelete }: IPrintJobItemProps) => {
  const val = data;
  const [actionIcon, setActionIcon] = useState<[number, 'rotate' | 'fade']>([ICON.DOWNLOAD, 'rotate']);
  const [key, setKey] = useState(Math.random());
  const [anim, setAnim] = useState({});
  const [showItem, setShowItem] = useState(true);

  const showPreview = () => {
    const image = (
      <Image
        style={{
          width: 300,
          height: 300,
        }}
        resizeMode='contain'
        source={{ uri: val.previewUrl }}
      />
    )
    modal.show(image);
  }

  const showInfo = (e) => {
    const nativeEvent = e.nativeEvent;
    modal.show(
      <Col shadow bgWhite p1 width={150} height={70} transform={[{ translateX: -75 }]}>
        <Text subtitle1 mb0>Artboard Size</Text>
        <Text>{(data.data?.product?.physicalWidth || 0).toFixed(2)} / {(data.data?.product?.physicalHeight || 0).toFixed(2)}</Text>
        <Col
          width={0}
          height={0}
          borderLeftWidth={11 / 2}
          borderLeftColor={'transparent'}
          borderRightWidth={11 / 2}
          borderRightColor={'transparent'}
          borderBottomWidth={11}
          borderBottomColor={'white'}
          absolute
          top={-11}
          left={75-11/2}
        />
      </Col>
      , {
        contentStyle: {
          position: 'absolute',
          top: nativeEvent.pageY + 15,
          left: nativeEvent.pageX,
        }
      })
  }

  return showItem ? (
    <Animatable.View {...anim} style={{ flexDirection: 'row', alignItems: 'center' }} key={val.id}>
      
      <Col flex1 m1>
        <Text>{val.productName} <TouchField middle width={20} height={20} transform={[{ translateY: 3 }]} onPress={showInfo}>
            <Ionicons name="information-circle-outline" size={16} color={COLOR.FONT} onPress={showInfo} />
          </TouchField>
        </Text>
        <Text caption color={COLOR.GREY}>{val.productVariantionName}</Text>
      </Col>
      <Col width={50} m1>
        <Text>{val.quantity}</Text>
      </Col>
      <Col flex1 m1>
        <Text>Created: {TimeHelper.formatDay(val.createdAt)}</Text>
      </Col>
      <Col flex1 m1>
        <Text bold={!val.isPaid ? true : false} color={!val.isPaid ? 'red' : undefined}>{!val.isPaid ? 'UNPAID' : 'PAID'}</Text>
      </Col>
      <Row width={150} m1>
        <TouchField cirle middle onPress={showPreview} mr0>
          <SVG.Eye />
        </TouchField>
        {!val.isPaid && (
          <TouchField onPress={onPay}>
            <Row ph2 pv0>
              <Text color='red' mr0>Pay</Text>
              <MaterialIcons name="payment" size={24} color={'red'} />
            </Row>
          </TouchField>
        )}
      </Row>
    </Animatable.View>
  ) : null;
};

export default PrintJobItem;
