export const swapAnim = {
  rotate: {
    appear: {
      0: {
        rotate: '0deg',
        opacity: 0,
      },
      0.25: {
        rotate: '90deg',
      },
      0.5: {
        rotate: '180deg',
        opacity: 0.5,
      },
      0.75: {
        rotate: '270deg',
        opacity: 0.75,
      },
      1: {
        rotate: '360deg',
        opacity: 1,
      },
    },
    disappear: {
      0: {
        rotate: '0deg',
      },
      0.25: {
        rotate: '90deg',
      },
      0.5: {
        rotate: '180deg',
        opacity: 0.6,
      },
      0.75: {
        rotate: '270deg',
        opacity: 0.4,
      },
      1: {
        rotate: '360deg',
        opacity: 0,
      },
    }
  },
  

  fade: {
    appear: 'fadeIn',
    disappear: 'fadeOut',
  },

  'rotate-backward': {
    appear: {
      0: {
        rotate: '0deg',
        opacity: 0,
      },
      0.25: {
        rotate: '-90deg',
      },
      0.5: {
        rotate: '-180deg',
        opacity: 0.5,
      },
      0.75: {
        rotate: '-270deg',
        opacity: 0.75,
      },
      1: {
        rotate: '-360deg',
        opacity: 1,
      },
    },
    disappear: {
      0: {
        rotate: '0deg',
      },
      0.25: {
        rotate: '-90deg',
      },
      0.5: {
        rotate: '-180deg',
        opacity: 0.6,
      },
      0.75: {
        rotate: '-270deg',
        opacity: 0.4,
      },
      1: {
        rotate: '-360deg',
        opacity: 0,
      },
    }
  },
};