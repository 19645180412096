import create from 'zustand';
import { useEffect, useState, Dispatch, useMemo, useRef } from 'react';
import { useUIState, IStateUI } from 'components';
import { TProduct } from 'type';
import Client from './api-client';

type TProductTypeQuery = {
  wholeSale?: boolean,
  printOnDemand?: boolean,
  customProduct?: boolean,
}

interface IProductStore {
  products: {
    [productId: string]: TProduct,
  },
  getList(p: number, resellerId?: string, productType?: TProductTypeQuery ): Promise<{ hasNext: boolean, list: Array<TProduct>, total: number, error?: string, }>,
  useProduct(id: string, useCache?: boolean) : {
    product: TProduct | undefined,
    uiState: IStateUI,
    setProduct: Dispatch<TProduct>,
    hasDoneSomeEditing: boolean,
  },
  [otherKey: string]: any,
}

export const useProductStore = create<IProductStore>((set, get) => ({
  products: {},
  getList: async (page = 1, resellerId, productType = {}) => {
    // const productTypeQuery = (() => {
    //   const q : any = {};
    //   if (productType.wholeSale !== undefined) q.wholeSale = !productType.wholeSale ? 0 : 1;
    //   if (productType.printOnDemand !== undefined) q.printOnDemand = !productType.printOnDemand ? 0 : 1;
    //   if (productType.customProduct !== undefined) q.customProduct = !productType.customProduct ? 0 : 1;
    //   return q;
    // })();
    const res = await Client.Api.Product.list({ page, resellerId });

    if (!res.data.success) {
      return {
        hasNext: false,
        list: [],
        total: 0,
        error: res.data.error,
      }
    }

    if (res.data.data.list && Array.isArray(res.data.data.list)) {
      const obj : IProductStore['products'] = {}
      res.data.data.list.forEach((j : TProduct) => {
        obj[j.id] = j;
      });
      set({ products: { ...get().jobs, ...obj } });
    }
    return res.data.data;
  },
  useProduct: (id : string, useCache = true) => {
    
    const [uiState, setUI]= useUIState();
    const [product, setProduct] = useState<TProduct>();

    const countRef = useRef(0);
    const hasDoneSomeEditing = useMemo(() => {
      if (id === 'new') return true;
      if (!product) return true;
      countRef.current++;
      return countRef.current > 1 ? true : false;
    }, [id, product]);

    useEffect(() => {
      if (id === 'new') return;
      if (useCache && !!get().products[id]) {
        setProduct(get().products[id]);
        return;
      }
      (async () => {
        setUI({ fetching: true });
        try {
          const res = await Client.Api.Product.detail({ id });
          if (res.data.error) {
            setUI({ fetching: false, errorMes: res.data.error });
            return;
          }
          if (res.data.data) {
            setProduct(res.data.data);
            setUI({ fetching: false, errorMes: '' });
          }
        } catch(err) {
          setUI({ fetching: false, errorMes: String(err) });
        }
      })();
    }, [id, useCache]);

    return { product, setProduct, hasDoneSomeEditing, uiState };
  }
}));
