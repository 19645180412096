import React, { useState } from 'react';
import { Modal, notification } from 'antd';
import { Button, Col, Text, TextArea } from 'components';
import { ValHelper } from 'helpers';
import Store from 'store';

const InviteUsers = (props) => {
  const [show, setShow] = useState(false);
  const [emails, setEmails] = useState('');

  const onPress = () => {
    setShow(true);
  }

  const onSubmit = async () => {
    const cleanEmails = emails?.split(/,|\n/)?.map(i => i?.trim()).filter(Boolean);
    if (cleanEmails.length && cleanEmails.some(i => !ValHelper.validateEmail(i))) {
      notification.error({ message: "Invalid email" });
      return;
    }
    if (!cleanEmails?.length) return;
    const res = await Store.Api.User.inviteUser({ emails: cleanEmails });
    if (res.data.errors?.[0]) {
      notification.error({ message: res.data.errors[0] });
      return;
    }
    notification.success({ message: "Emails have been sent" });
    setShow(false);
    setEmails('');
  }

  return (
    <>
      <Button
        text="Invite users"
        borderRadius={20}
        paddingVertical={6}
        paddingHorizontal={10}
        onPress={onPress}
      />
      <Modal
        open={show}
        title="Invite user via email"
        onOk={onSubmit}
        onCancel={() => setShow(false)}
        okText="Send"
      >
        <Col height={12} />
        <TextArea
          height={80}
          placeholder='Enter emails'
          value={emails}
          onChangeText={setEmails}
        />
        <Text caption mt1>* Separate by comma or line break</Text>
      </Modal>
    </>
  )
};

export default InviteUsers;
