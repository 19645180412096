import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { Button, Modal, notification } from 'antd';
import { BgIcon, Col, InputField, Row, SelectField, Text, UploadField } from 'components';
import { COLORS, COUNTRIES } from 'const';
import { TCandleStickerInfo, TDesign, TProduct } from 'type';
import { useUserStore } from 'store/User.Store';
import Store from 'store';
import { ValHelper } from 'helpers';
import { TPackingSlip } from 'store/api-client/type/TPackingSlip';

interface Props {
  setDesignData: any,
  product: TProduct,
  design: TDesign,
}

const CandleStickerModal = (props: Props, ref) => {
  const { product, setDesignData, design } = props;
  const [show, setShow] = useState(false);
  const [data, setData] = useState<TCandleStickerInfo>();
  const { user } = useUserStore();
  const [loadingTemplate, setLoadingTemplate] = useState(false);
  const [packingSlip, setPackingSlip] = useState<TPackingSlip>();
  const brands = design?.brands;

  const onSaveCandleInfo = () => {
    setDesignData({
      candle: data,
    })
    setData(null);
    setShow(false);
  }

  const storeId = useMemo(() => {
    if (!brands?.length || brands.length > 1) return;
    return brands?.[0]?.storeId;
  }, [brands]);

  useEffect(() => {
    if (storeId) {
      Store.Api.PackingSlip.getPackingSlip({ storeId }).then(res => {
        setPackingSlip(res.data.data);
      });
    } else {
      setPackingSlip(null);
    }
  }, [storeId])

  const previewCandleTemplate = async () => {
    if (loadingTemplate) return;
    setLoadingTemplate(true);

    const {
      scentName, defaultScentName, companyName, logo,
      address, country, address2, state, postCode,
    } = data || {};
    const combineAddress = [country, address, address2, state, postCode].filter(Boolean).join("\n");
    try {
      const res2 = await Store.Api.Pdf.generateCandleTemplate({
        address: combineAddress,
        scentName: scentName || defaultScentName || scentVariantOpts?.[0]?.value,
        templateUrl: product?.data?.candleTemplate,
        companyName,
        companyLogo: logo || packingSlip?.companyLogo,
      });
      if (res2.data.data) {
        window.open(res2.data.data, "_blank");
      }
    } catch (error) {
      notification.error({ message: error.message })
    } finally {
      setLoadingTemplate(false);
    }
  }

  useImperativeHandle(ref, () => ({
    show: (data) => {
      setData(ValHelper.getDefaultCandleStickerInfo({
        candleInfo: data,
        product,
        user,
        packingSlip,
      }));
      setShow(true);
    },
  }));

  const scentVariantOpts = useMemo(() => {
    return product?.data?.scentVariants?.split(/,|\n/).filter(i => i.trim()).map(i => ({
      label: i,
      value: i,
    }))
  }, [product]);

  const countryOpts = useMemo(() => {
    return COUNTRIES.map(i => ({
      label: i.name,
      value: i.name,
    }))
  }, []);

  return (
    <Modal
      open={show}
      title="Candle sticker information"
      closable
      onCancel={() => setShow(false)}
      width={640}
      footer={[
        <Button
          type="text"
          size="large"
          key="preview-candle"
          loading={loadingTemplate}
          disabled={!product?.data?.candleTemplate}
          onClick={previewCandleTemplate}
        >
          <Text fontSize={16} fontWeight="600" color={COLORS.BLUE_LIGHT}>
            Preview
          </Text>
        </Button>,
        <Button
          size="large"
          type="primary"
          key="save-candle"
          onClick={onSaveCandleInfo}
          style={{ backgroundColor: COLORS.HEADLINES }}
        >
          <Text fontSize={16} fontWeight="600" colorWhite>Save</Text>
        </Button>
      ]}
    >
      <Col>
        <Col height={1} backgroundColor={COLORS.BORDER} marginVertical={12} />
        <Row marginBottom={12} round0 borderThin borderColor='#FFB020' pv1 ph2 backgroundColor={'#FFF6DC'}>
          <BgIcon name="warning" size={16} color="#FFB020" />
          <Text ml2 bold color="#FFB020" lineHeight={17}>This is a legally required sticker on the bottom of the candle. You can customise this with your brand name and logo.</Text>
        </Row>
        <Row marginBottom={12}>
          <Col flex1>
            <SelectField
              title="Scent name"
              subTitle="Our recommend names for this scent - Let us name it for you!"
              value={data?.defaultScentName}
              options={scentVariantOpts}
              selectProps={{
                placeholder: "Select a name",
              }}
              onChange={(value: string) => {
                setData(s => ({
                  ...s,
                  defaultScentName: value,
                }))
              }}
            />
          </Col>
          <Col width={16} />
          <Col flex1>
            <InputField
              title="Custom name"
              value={data?.scentName}
              onChangeText={(v) => {
                setData(s => ({
                  ...s,
                  scentName: v,
                }))
              }}
              inputProps={{ placeholder: "Type your bespoke candle name" }}
            />
          </Col>
        </Row>
        <InputField
          title="Brand/Company name"
          value={data?.companyName}
          onChangeText={(v) => {
            setData(s => ({
              ...s,
              companyName: v,
            }))
          }}
          marginBottom={12}
        />
        <UploadField
          title="Brand logo (optional)"
          value={data?.logo}
          onChange={(v) => {
            setData(s => ({
              ...s,
              logo: v,
            }))
          }}
          marginBottom={12}
        />
        <SelectField
          title="Country"
          value={data?.country}
          options={countryOpts}
          selectProps={{ showSearch: true }}
          onChange={(value: string) => {
            setData(s => ({
              ...s,
              country: value,
            }))
          }}
          marginBottom={12}
        />
        <InputField
          title="Address line 1"
          value={data?.address}
          onChangeText={(v) => {
            setData(s => ({
              ...s,
              address: v,
            }))
          }}
          marginBottom={12}
        />
        <InputField
          title="Address line 2 (optional)"
          value={data?.address2}
          onChangeText={(v) => {
            setData(s => ({
              ...s,
              address2: v,
            }))
          }}
          marginBottom={12}
        />
        <InputField
          title="County / State"
          value={data?.state}
          onChangeText={(v) => {
            setData(s => ({
              ...s,
              state: v,
            }))
          }}
          marginBottom={12}
        />
        <InputField
          title="Post Code / Zip Code"
          value={data?.postCode}
          onChangeText={(v) => {
            setData(s => ({
              ...s,
              postCode: v,
            }))
          }}
        />
      </Col>
    </Modal>
  );
};

export default forwardRef(CandleStickerModal);
