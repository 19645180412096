import React, { useEffect, useMemo, useState } from 'react';
import { TUser } from 'type';


interface IProps {
  user: TUser;
  onClickSettings?: Function;
  onClickLogout?: Function;
  onClickResellerInspect?: Function;
}

const MENUS = {
  Settings: 'Settings',
  LogOut: 'Log out',
  ResellerInspect: 'Reseller Inspect',
}

const UserTopRightInfo = ({ onClickLogout, onClickSettings, onClickResellerInspect, user }: IProps) => {
  const [id] = useState(Math.random().toString(36).substring(7));
  const role = user?.role;
  // @ts-ignore
  const isAdminInspect = !!user?.adminInspect;
  const userName = user?.firstName ? `${user?.firstName} ${user?.lastName}` : "";

  useEffect(() => {
    const el = document.getElementById(id);
    if (!el) return;
    // @ts-ignore
    el.onMenuClick = (menuItem) => {
      switch (menuItem) {
        case MENUS.Settings:
          !!onClickSettings && onClickSettings();
          break;
        case MENUS.LogOut:
          !!onClickLogout && onClickLogout();
          break;
        case MENUS.ResellerInspect:
          !!onClickSettings && onClickResellerInspect();
          break;
        default:
      }
    }
  }, [id]);

  const displayMenus = useMemo(() => {
    const arr = [];
    if (role === 'admin' || isAdminInspect) {
      arr.push(MENUS.ResellerInspect);
    }
    if (role !== 'admin') {
      arr.push(MENUS.Settings);
    }
    arr.push(MENUS.LogOut);
    return arr;
  }, [role, isAdminInspect]);

  // @ts-ignore
  return (<user-dropdown
    user={userName}
    role={role}
    content={displayMenus.join(',')}
    id={id}
  />);
}

export default UserTopRightInfo;
