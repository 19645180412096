import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Text,
  TouchField,
  useRefState,
  useUIState,
} from "components";
import { COLOR, SCREEN } from "const";
import { Entypo } from "@expo/vector-icons";
import { TimeHelper, modalConfirm } from "helpers";
import Store from "store";

import { useNavFunc } from "navigation";
import { TOnlineStore } from "../../../store/api-client";
import { useDynamicResponsiveValue } from "quickly-react";

const BrandsSettingsItem = ({ val, navigate, onPressDelete }) => {
  return (
    <Row>
      <Col flex1 m1>
        <Text>{val.name}</Text>
      </Col>
      <Col flex1 m1>
        <Text>{val.url}</Text>
      </Col>
      <Col flex1 m1>
        <Text>{val.type}</Text>
      </Col>
      <Col flex1 m1>
        <TouchField
          onPress={() => navigate(SCREEN.PackingSlipDetail, { id: val.id })}
          width={30}
          height={30}
          borderRadius={15}
          middle
        >
          <Entypo name="edit" size={17} color="black" />
        </TouchField>
      </Col>
      <Row flex1 m1>
        <TouchField
          onPress={() => navigate(SCREEN.UpsertStore, { id: val.id })}
          width={30}
          height={30}
          borderRadius={15}
          middle
        >
          <Entypo name="edit" size={17} color="black" />
        </TouchField>

        <TouchField
          onPress={onPressDelete}
          width={30}
          height={30}
          borderRadius={15}
          middle
          ml2
        >
          <Entypo name="trash" size={17} color="black" />
        </TouchField>
      </Row>
    </Row>
  );
};

const BrandsSettings = () => {
  const { navigate, navigation } = useNavFunc();
  const UserStore = Store.useUserStore();
  const ShopStore = Store.useShopStore();
  const [{ fetching, errorMes, loading: btnLoading }, setUI] = useUIState({
    fetching: false,
  });
  const [pagination, setPagination] = useState({ hasNext: false, total: 1 });
  const [shops, getShops, setShops] = useRefState<Array<TOnlineStore>>([]);
  const rV = useDynamicResponsiveValue(['xs', 'lg']);
  const isMobile = rV({ xs: true, lg: false });

  const getData = async (p) => {
    try {
      const u = await UserStore.onReady();
      const resellerId = UserStore.getResellerId(u);
      if (getShops().length === 0) {
        setUI({ fetching: true, errorMes: "" });
      }
      await TimeHelper.wait(500);
      const { list, hasNext, total, error } = await ShopStore.getList(
        p,
        resellerId
      );
      console.log({ list, hasNext, total, error });
      if (error) return setUI({ fetching: false, errorMes: error });
      setPagination({ hasNext, total });
      setShops(list);
      setUI({ fetching: false, errorMes: "" });
    } catch (err) {
      setUI({ fetching: false, errorMes: String(err) });
    }
  };

  const onPressDelete = (val) => {
    modalConfirm({
      title: 'Delete',
      content: 'Once deleted this store cannot be recovered. Do you wish to continue?',
      onOk: async () => {
        const res = await ShopStore.deleteStore(val.id);
        if (res) setShops(s => s.filter(i => i.id !== val.id));
      },
    })
  }

  useEffect(() => {
    getData(1);
  }, []);

  if (isMobile) {
    return (
      <Col flex1>
        {shops.map((val, i) => {
          return (
            <Row shadow mv0 round0 flexWrap={'wrap'} alignItems={'flex-start'}>
              <Col p0 m0 width={'100%'}>
                <Text fontSize={10} mb0 color={'rgba(0,0,0,0.2)'}>Store Name</Text>
                <Text h3 mb1>{val.name || '(untitled)'}</Text>
                <Text numberOfLines={2}
                  onPress={() => window.open(val.url)}
                >{val.url}</Text>
              </Col>
              <Col p0 m0 width={'calc( 50% - 10px)'}>
                <Text fontSize={10} color={'rgba(0,0,0,0.2)'}>Type</Text>
                <Text>{val.type}</Text>
              </Col>
              <Col p0 m0 width={'calc( 50% - 10px)'}>
                <Text fontSize={10} color={'rgba(0,0,0,0.2)'}>Actions</Text>
                <TouchField
                  onPress={() => navigate(SCREEN.PackingSlipDetail, { id: val.id })}
                  height={24}
                  borderRadius={4}
                  justifyContent={'flex-start'}
                  flexDirection={'row'}
                  middle
                  mb0
                >
                  <Entypo name="edit" size={14} color="black" />
                  <Text ml0>Packing Slip</Text>
                </TouchField>
                <TouchField
                  onPress={() => navigate(SCREEN.UpsertStore, { id: val.id })}
                  borderRadius={4}
                  flexDirection={'row'}
                  justifyContent={'flex-start'}
                  middle
                  mb0
                >
                  <Entypo name="edit" size={14} color="black" />
                  <Text ml0>Edit</Text>
                </TouchField>
                <TouchField
                  onPress={onPressDelete}
                  height={24}
                  borderRadius={4}
                  justifyContent={'flex-start'}
                  flexDirection={'row'}
                  middle
                >
                  <Entypo name="trash" size={14} color="black" />
                  <Text ml0>Delete</Text>
                </TouchField>
              </Col>
            </Row>
          );
        })}
      </Col>
    )
  }

  return (
    <Col flex1>
      <Row
        height={50}
        stretch
        borderBottomColor={COLOR.GREY_BG}
        borderBottomWidth={1}
      >
        <Col flex1 m1>
          <Text color={COLOR.GREY}>Store Name</Text>
        </Col>
        <Col flex1 m1>
          <Text color={COLOR.GREY}>URL</Text>
        </Col>
        <Col flex1 m1>
          <Text color={COLOR.GREY}>Type</Text>
        </Col>
        <Col flex1 m1>
          <Text color={COLOR.GREY}>Packing Slip</Text>
        </Col>
        <Col flex1 m1>
          <Text color={COLOR.GREY}>Edit</Text>
        </Col>
      </Row>
      <Col>
        {shops.map((val, i) => {
          return <BrandsSettingsItem
            key={i} val={val} navigate={navigate}
            onPressDelete={() => onPressDelete(val)}
          />;
        })}
      </Col>
    </Col>
  );
};

export default BrandsSettings;
