import React from "react";
import { Button as AntButton } from "antd";
import BgIcon from "../BgIcon";
import { Text } from "components/base";
import { COLORS } from "const";

interface Props {
  icon?: string;
  title?: string;
  onPress?: () => void;
  style?: any;
}

const ButtonOutline = (props: Props) => {
  const { icon, style, title, onPress } = props;
  return (
    <AntButton
      size="large"
      icon={icon ? (
        <BgIcon name={icon} size={16} />
      ) : undefined}
      style={{ display: "flex", alignItems: "center", ...style }}
      onClick={onPress}
    >
      <Text fontSize={14} color={COLORS.TEXT}>{title}</Text>
    </AntButton>
  )
}

export default ButtonOutline;
