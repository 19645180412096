import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from "react";
import { Col, Row, Text, TouchField, useUIState } from "components";
import { COLOR, SCREEN } from "const";
import { TimeHelper } from "../../../helpers";
import { Entypo } from "@expo/vector-icons";
import { useNavFunc } from "navigation";
import Store from "store";
import { TUser } from "type";

import UpsertUsers from "screens/users/UpsertUsers";
import { useSettingsForm } from "./FormContext";

const UserSettingsItem = ({ val }) => {
  const { navigate, navigation } = useNavFunc();
  const fullName = [val.firstName, val.lastName].filter(Boolean).join(" ");
  return (
    <Row>
      <Col flex1 m1>
        <Text>
          {fullName || val.email}
        </Text>
      </Col>
      <Col flex1 m1>
        <Text>{val.onlineStore?.name ?? ""}</Text>
      </Col>
      <Col flex1 m1>
        <Text>{TimeHelper.formatDay(val.createdAt)}</Text>
      </Col>
      <Col flex1 m1>
        <TouchField
          onPress={() => navigate(SCREEN.UpsertUsers, { id: val.id })}
          width={30}
          height={30}
          borderRadius={15}
          middle
        >
          <Entypo name="edit" size={17} color="black" />
        </TouchField>
      </Col>
    </Row>
  );
};

interface IProps { }

const UsersSettings = (props: IProps, ref) => {
  useImperativeHandle(ref, () => ({
    getData: () => {
      getData(1);
    },
  }));
  const UserStore = Store.useUserStore();
  const [{ fetching, errorMes, loading: btnLoading }, setUI] = useUIState({
    fetching: false,
  });
  const [pagination, setPagination] = useState({ hasNext: false, total: 1 });
  const [users, setUsers] = useState<Array<TUser>>([]);

  const getData = async (p) => {
    try {
      setUI({ fetching: true, errorMes: "" });
      await TimeHelper.wait(300);
      const { list, hasNext, total, error } =
        await UserStore.getListResellerUsers(p);
      if (error) return setUI({ fetching: true, errorMes: error });
      setPagination({ hasNext, total });
      setUsers(list);
      setUI({ fetching: false, errorMes: "" });
    } catch (err) {
      setUI({ fetching: true, errorMes: String(err) });
    }
  };

  useEffect(() => {
    getData(1);
  }, []);

  const { showUpsertUserUI, setShowUpsertUserUI } = useSettingsForm();
  if (showUpsertUserUI) {
    return (
      <UpsertUsers
        noLayout noBGService forceId='new'
        customSuccessHandler={(userData) => {
          getData(1);
          setShowUpsertUserUI(false);
        }}
      />
    )
  }

  if (users.length === 0) {
    return (
      <Col pl2 pr2>
        <Text subtitle1 mb2>No user found</Text>
      </Col>
    );
  }

  return (
    <Col flex1>
      <Row
        height={50}
        stretch
        borderBottomColor={COLOR.GREY_BG}
        borderBottomWidth={1}
      >
        <Col flex1 m1>
          <Text color={COLOR.GREY}>Name</Text>
        </Col>
        <Col flex1 m1>
          <Text color={COLOR.GREY}>Brand</Text>
        </Col>
        <Col flex1 m1>
          <Text color={COLOR.GREY}>Create At</Text>
        </Col>
        <Col flex1 m1 />
      </Row>
      <Col>
        {users.map((val, i) => {
          return <UserSettingsItem key={i} val={val} />;
        })}
      </Col>
    </Col>
  );
};

export default forwardRef(UsersSettings);
