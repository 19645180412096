import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Button, Modal } from 'antd';
import { BgIcon, Col, InputField, Row, showPopupMessage, Text, TouchField } from 'components';
import { COLOR, COLORS, SCREEN } from 'const';
import { TUser } from 'type';
import { useUserStore } from 'store/User.Store';
import { ActivityIndicator, ScrollView } from 'react-native';
import Store from 'store';
import { useNavFunc } from 'navigation';

interface Props {
}

let searchTimeOut = setTimeout(() => { }, 0);
const ResellerInspectModal = (props: Props, ref) => {
  const [show, setShow] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState<Array<TUser>>([]);
  const [canLoadMore, setCanLoadMore] = useState(false);
  const [selectedReseller, setSelectedReseller] = useState<TUser | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { navigation } = useNavFunc();
  const UserStore = useUserStore();
  // @ts-ignore
  const adminToken = UserStore.user?.adminInspect;

  useImperativeHandle(ref, () => ({
    show: () => {
      setShow(true);
      getData(1);
    },
  }));

  const getData = async (p, search = undefined) => {
    try {
      if (isLoading) return;
      setIsLoading(true);
      const { list, hasNext, total, error } = await UserStore.getListResellers(p, 20, search);
      if (!error) {
        setCanLoadMore(hasNext);
        setUsers(list);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onConfirm = async () => {
    try {
      setIsSubmitting(true);
      const res = await UserStore.loginAsReseller(selectedReseller);
      if (res.success) {
        setShow(false);
        navigation.reset({
          index: 0,
          routes: [{ name: SCREEN.HomeGetStarted }],
        });
        setTimeout(() => {
          window.location.reload();
        }, 100);
      }
    } catch (error) {
      showPopupMessage({
        title: '',
        content: error.message || JSON.stringify(error),
        buttonOkText: 'OK',
        // 
        typeHighlight: 'danger',
        contentHighlight: 'Error'
      });
    } finally {
      setIsSubmitting(false);
    }
  }

  const onPressBackAdmin = async () => {
    Store.Client.setToken(adminToken);
    setShow(false);
    navigation.reset({
      index: 0,
      routes: [{ name: SCREEN.Home }],
    });
    setTimeout(() => {
      window.location.reload();
    }, 100);
  }

  const setSearchKeyword = (keyword) => {
    setKeyword(keyword);
    clearTimeout(searchTimeOut);
    searchTimeOut = setTimeout(() => {
      getData(1, keyword);
    }, 500);
  }

  return (
    <Modal
      open={show}
      title="Select Reseller to Inspect"
      closable
      onCancel={() => setShow(false)}
      width={500}
      footer={[
        <Button
          type="text"
          size="large"
          key="close-modal"
          onClick={() => setShow(false)}
        >
          <Text fontSize={16} fontWeight="600" color={COLORS.BLUE_LIGHT}>
            Cancel
          </Text>
        </Button>,
        !!adminToken && <Button
          size="large"
          type="text"
          onClick={onPressBackAdmin}
          style={{ backgroundColor: COLORS.BORDER }}
        >
          <Text fontSize={16} fontWeight="600">Back to Admin</Text>
        </Button>,
        <Button
          size="large"
          type="primary"
          key="confirm-modal"
          onClick={onConfirm}
          loading={isSubmitting}
          disabled={!selectedReseller}
          style={{ backgroundColor: COLORS.HEADLINES }}
        >
          <Text fontSize={16} fontWeight="600" colorWhite>Inspect</Text>
        </Button>
      ].filter(Boolean)}
    >
      <Col height={400}>
        <InputField
          value={keyword}
          onChangeText={setSearchKeyword}
          icon='search'
          inputProps={{ placeholder: "Search by name" }}
          marginBottom={4}
        />
        <ScrollView style={{ flex: 1 }}>
          {users.map((user) => (
            <TouchField
              paddingVertical={12}
              paddingRight={4}
              nativeID="client-item"
              key={`reseller${user.id}`}
              onPress={() => setSelectedReseller(user)}
              // @ts-ignore
              style={{ outline: "none" }}
            >
              <Row alignItems={"center"}>
                <Col flex1>
                  <Text marginLeft={5}>{[user.firstName, user.lastName].filter(Boolean).join(" ")}</Text>
                </Col>
                {selectedReseller?.id === user.id && (
                  <BgIcon name="tick" size={16} color={COLOR.MAIN} />
                )}
              </Row>
            </TouchField>
          ))}
          {isLoading && <Col middle p1><ActivityIndicator color={COLOR.MAIN} /></Col>}
        </ScrollView>
      </Col>
    </Modal>
  );
};

export default forwardRef(ResellerInspectModal);
