import React, { useEffect, useMemo, useState } from "react";
import { Select } from "antd";
import Store from 'store';
import { BgIcon } from "components";
import { COLORS } from "const";

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};
export const OrderSearchSelect: React.FC<{ placeholder: string; style: React.CSSProperties, onSelectOrder: (order) => void, isReset: boolean }> = (props) => {
  const [data, setData] = useState(null);
  const [listData, setListData] = useState([]);
  const [value, setValue] = useState<string>();
  const [isLoadingData, setIsLoadingData] = useState(false);

  const OrderStore = Store.useOrderStore();
  const UserStore = Store.useUserStore();
  const { user } = UserStore;

  const resellerId = useMemo(() => {
    return user?.role === 'admin' ? 'all' : user?.role === 'reseller' ? user?.id
      : user?.resellerId;
  }, [user]);

  const getDataByOrderId = async (orderId: string, callBack: (data: { value: string; text: string }[]) => void) => {
    if (!orderId) {
      callBack([]);
      return;
    }
    setIsLoadingData(true);
    const query = orderId;
    const { list } = await OrderStore.search(1, query, resellerId);
    if (list.length === 0) {
      callBack([]);
      setIsLoadingData(false);
      return;
    }
    setListData(list);
    const data = list.map((item) => {

      const sourceId = item?.['Order Source ID'];

      const lastPipeline = item.Pipelines.length === 0 ? undefined : item.Pipelines[item.Pipelines.length - 1];
      const { isSampleRequest } = lastPipeline?.SharedData || {};
      const isSample = item['Raw Data']?.is_sample_request || isSampleRequest || item.OrderType === "Sample";
      const customerName = isSample ? item['Raw Data'].shipping_address.name : item['Customer Name'];
      return ({
      value: item['Order ID'].toString(),
      text: `#${sourceId ? item['Order ID'] : item?.['Order Number']} - ${customerName} - ${item['All Product Names']}`,
    })});
    callBack(data);
    setIsLoadingData(false);
  };

  const handleSearch = debounce((newValue: string) => {
    getDataByOrderId(newValue, setData);
  }, 500);

  useEffect(() => {
    if (props.isReset) {
      setValue(undefined);
      setData(null);
    }
  }, [props.isReset]);

  const handleChange = async (newValue: string) => {
    props.onSelectOrder(listData.find((item) => item['Order ID'].toString() === newValue));
    setValue(newValue);
  };

  return (
    <Select
      showSearch
      value={value}
      placeholder={props.placeholder}
      style={props.style}
      defaultActiveFirstOption={false}
      suffixIcon={<BgIcon name="search" size={18} color={COLORS.ICON_MUTED} />}
      filterOption={false}
      onSearch={handleSearch}
      onChange={handleChange}
      notFoundContent={<div style={{textAlign: 'center', padding: 20}}>{isLoadingData ? 'Loading...' : 'No data'}</div>}
      options={(data || []).map((d) => ({
        value: d.value,
        label: d.text,
      }))}
    />
  );
};