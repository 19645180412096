interface IShowPopupMessage {
  title?: string;
  content?: string;
  buttonOkText: string;
  onButtonOkClick?: Function;
  typeHighlight: string;
  contentHighlight: string;
}
type TResult = {
  buttonOKClicked: boolean,
};
export const showPopupMessage = ({
  title,
  content,
  buttonOkText,
  onButtonOkClick,
  typeHighlight,
  contentHighlight,
}: IShowPopupMessage): Promise<TResult> => {
  console.log("showPopupMessage");
  // @ts-ignore
  return window.showMessagePopup({
    title: title,
    content: content,
    buttonOkText: buttonOkText,
    onButtonOkClick: onButtonOkClick,
    highlightMessage: {
      type: typeHighlight, // 'success', 'warning', 'danger',
      content: contentHighlight,
    },
  });
};
