import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Button, Modal, Image } from 'antd';
import { Col, RatioCol, Text } from 'components';
import { COLORS } from 'const';
import { ASSETS } from 'assets';

interface Props {
}

const PersonalisedBlockTutorModal = (props: Props, ref) => {
  const [show, setShow] = useState(false);

  useImperativeHandle(ref, () => ({
    show: () => {
      setShow(true);
    },
  }));


  return (
    <Modal
      open={show}
      title="Setup Personalised Block on Shopify"
      closable
      onCancel={() => setShow(false)}
      width={640}
      footer={[
        <Button
          size="large"
          type="primary"
          onClick={() => setShow(false)}
          style={{ backgroundColor: COLORS.HEADLINES }}
        >
          <Text fontSize={16} fontWeight="600" colorWhite>Close</Text>
        </Button>
      ]}
    >
      <Col>
        <Text mb0>Add Personalised Button block to Product detail page using Customize</Text>
        <RatioCol width={'100%'} ratio={1976 / 950} mb1>
          <Image src={ASSETS.CUSTOMIZE_ADD_BLOCKS_1} style={{ width: '100%', height: '100%' }} />
        </RatioCol>
        <RatioCol width={'100%'} ratio={1382 / 950} mb1>
          <Image src={ASSETS.CUSTOMIZE_ADD_BLOCKS_2} style={{ width: '100%', height: '100%' }} />
        </RatioCol>
      </Col>
    </Modal>
  );
};

export default forwardRef(PersonalisedBlockTutorModal);
