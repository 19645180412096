import React, { useEffect, useState } from 'react';
import { TouchField, Col, Row, Text, SwapIconEffect, modal, Grid } from 'components';
import { TPrintJob, TUser } from 'type';
import { ASSETS, SVG } from 'assets';
import { SimpleLineIcons, Feather, MaterialIcons, AntDesign, EvilIcons, Ionicons, FontAwesome5 } from '@expo/vector-icons';
import { TimeHelper, ValHelper } from 'helpers';
import { Image } from 'react-native';
import {
  ActivityIndicator
} from 'react-native';
import { COLOR } from 'const';
import Store from 'store';
import * as Animatable from 'react-native-animatable';
import NoteModal from './NoteModal';
import CustomerAddressModal from './CustomerAddressModal';
import DispatchRoyal from './DispatchRoyal';

interface IPrintJobItemProps {
  data: TPrintJob,
  onDownload: any,
  reseller?: TUser,
  onDataUpdate(newData: TPrintJob): void,
  onDelete: any,
  isSelected?: boolean,
  onSelected?(): void,
  isSearchJob?: boolean,
}

const ICON = {
  DOWNLOAD: 0,
  LOADING: 1,
  DONE: 2
}

const PrintJobItem = ({ reseller, data, onDownload, onDataUpdate, onDelete, isSelected, onSelected, isSearchJob }: IPrintJobItemProps) => {
  const val = data;
  const [actionIcon, setActionIcon] = useState<[number, 'rotate' | 'fade']>([ICON.DOWNLOAD, 'rotate']);
  const [key, setKey] = useState(Math.random());
  const [anim, setAnim] = useState({});
  const [showItem, setShowItem] = useState(true);
  const [isGreatHarbour, setIsGreatHarbour] = useState(false);

  useEffect(() => {
    setIsGreatHarbour(reseller?.id === '578953627077');
  }, [reseller]);

  useEffect(() => {
    if (data.isPrinted) {
      setAnim({
        animation: 'fadeOut',
        duration: 500,
        onAnimationEnd: () => {
          setShowItem(false);
        }
      })
    }
  }, [data.isPrinted]);

  const handleDownload = async () => {
    setActionIcon([ICON.LOADING, 'rotate']);
    if (typeof onDownload === 'function') {
      try {
        await TimeHelper.wait(300);
        await onDownload();
      } catch (err) {
        setActionIcon([ICON.DOWNLOAD, 'rotate']);
      }
    }
    setActionIcon([ICON.DONE, 'rotate']);
    await TimeHelper.wait(2000);
    setActionIcon([ICON.DOWNLOAD, 'fade']);
    onDataUpdate({
      ...val,
      isPDFDownloaded: true,
    });
  };

  const confirmPrinted = async () => {
    await Store.Api.PrintJob.upsert({
      id: val.id,
      isPrinted: true,
    })
    onDataUpdate({
      ...val,
      isPrinted: true,
    })
  }
  const cancelPrinted = async () => {
    await Store.Api.PrintJob.upsert({
      id: val.id,
      isPDFDownloaded: false,
      isPrinted: false,
    })
    onDataUpdate({
      ...val,
      isPDFDownloaded: false,
      isPrinted: false,
    })
  }

  const showPreview = () => {
    const image = (
      <Image
        style={{
          width: 300,
          height: 300,
        }}
        resizeMode='contain'
        source={{ uri: val.previewUrl }}
      />
    )
    modal.show(image);
  }

  const showNotes = () => {
    modal.show(<NoteModal printJobId={val.id} />);
  }

  const showCustomerInfo = () => {
    modal.show(<CustomerAddressModal printJob={val} shipping_address={val.data?.shipping_address} customer={val.data?.customer} />);
  }

  const showRoyalMailModal = () => {
    modal.show(
      <DispatchRoyal
        printJob={val}
        orderId={String(val.data?.orderId || '')}
      />
    );
  }

  const showInfo = (e) => {
    const nativeEvent = e.nativeEvent;
    modal.show(
      <Col shadow bgWhite p1 width={150} height={70} transform={[{ translateX: -75 }]}>
        <Text subtitle1 mb0>Artboard Size</Text>
        <Text>{(data.data?.product?.physicalWidth || 0).toFixed(2)} / {(data.data?.product?.physicalHeight || 0).toFixed(2)}</Text>
        <Col
          width={0}
          height={0}
          borderLeftWidth={11 / 2}
          borderLeftColor={'transparent'}
          borderRightWidth={11 / 2}
          borderRightColor={'transparent'}
          borderBottomWidth={11}
          borderBottomColor={'white'}
          absolute
          top={-11}
          left={75 - 11 / 2}
        />
      </Col>
      , {
        contentStyle: {
          position: 'absolute',
          top: nativeEvent.pageY + 15,
          left: nativeEvent.pageX,
        }
      })
  }

  const clientName = !reseller?.firstName && !reseller?.lastName ? '' : `${reseller?.firstName} ${reseller.lastName}`;
  const notSupportPDF = [
    'Great Harbour Gifts'
  ]

  return (showItem || isSearchJob) ? (
    <Grid xs='100%' md='100%' lg='1:any'>
      <Animatable.View {...(isSearchJob ? {} : anim)} style={{ flexDirection: 'row', alignItems: 'center' }} key={val.id}>
        <Col width={50} m1>
          <TouchField cirle middle onPress={onSelected}>
            {isSelected ? (
              <FontAwesome5 name="check-circle" size={18} color="black" />
            ) : (
              <FontAwesome5 name="circle" size={18} color="black" />
            )}
          </TouchField>
        </Col>
        <Col flex1 m1>
          <Text>{clientName}</Text>
        </Col>
        <Col flex1 m1>
          <Text>{val.productName} <TouchField middle width={20} height={20} transform={[{ translateY: 3 }]} onPress={showInfo}>
            <Ionicons name="information-circle-outline" size={16} color={COLOR.FONT} onPress={showInfo} />
          </TouchField>
          </Text>
          <Text caption color={COLOR.GREY}>{val.productVariantionName}</Text>
        </Col>
        {/* <Col flex1 m1>
        <TouchField cirle middle onPress={showPreview}>
          <SVG.Eye />
        </TouchField>
      </Col> */}
        <Col width={50} m1>
          <Text>{val.quantity}</Text>
        </Col>
        <Col flex1 m1>
          <Text>Created: {TimeHelper.formatDay(val.createdAt)}</Text>
        </Col>
        {/* <Col flex1 m1>
        <Text bold={!val.isPaid ? true : false} color={!val.isPaid ? 'red' : undefined}>{!val.isPaid ? 'UNPAID' : 'PAID'}</Text>
      </Col> */}
        {/* <Col flex1 m1>
        <TouchField cirle middle onPress={showNotes}>
          <SimpleLineIcons name="notebook" size={20} color="black" />
        </TouchField>
      </Col> */}
        {notSupportPDF.includes(clientName) ? (
          <Col flex1 m1 >
            <Col margin={5}>
              <Row>
                <Text caption color={COLOR.GREY} mr1>Printed?</Text>
                <TouchField width={20} height={20} borderRadius={10} middle onPress={confirmPrinted}>
                  <MaterialIcons name="done" size={15} color={'black'} />
                </TouchField>
              </Row>
            </Col>
          </Col>
        ) : (
          <Col flex1 m1>

            {val.isPDFDownloaded ? (
              <Col margin={5}>
                <Text caption color={COLOR.GREY}>Have you printed?</Text>
                <Row>
                  <TouchField width={20} height={20} borderRadius={10} middle onPress={confirmPrinted}>
                    <MaterialIcons name="done" size={15} color={'black'} />
                  </TouchField>
                  <TouchField width={20} height={20} borderRadius={10} middle ml1 marginTop={3} onPress={cancelPrinted}>
                    <AntDesign name="close" size={14} color="black" />
                  </TouchField>
                </Row>
              </Col>

            ) : (
              <SwapIconEffect
                width={40}
                height={40}
                displayIndex={actionIcon[0]}
                key={key}
                effect={actionIcon[1] || 'rotate'}
              >
                <TouchField cirle middle onPress={handleDownload}>
                  <Feather name="download" size={20} color="black" />
                </TouchField>
                <Col flex1 middle>
                  <ActivityIndicator size="small" color="black" />
                </Col>
                <Col flex1 middle>
                  <MaterialIcons name="done" size={20} color={COLOR.GREEN} />
                </Col>
              </SwapIconEffect>
            )}
          </Col>
        )}

        <Col flex1 m1>
          {!!val.data.orderId && (
            <a href={`https://bg-production.personify.tech/api/online-stores/order-packing-slips/${val.data.orderId}?clientId=${ValHelper.parseClientId(reseller?.id)}`} target="_blank" style={{ textDecoration: 'none' }}>
              <TouchField cirle middle>
                <Feather name="external-link" size={24} color="black" />
              </TouchField>
            </a>
          )}
        </Col>
        {/* <Grid width={220} m1 xs='50%' md='33%' lg='20%'>
          <TouchField cirle middle onPress={showRoyalMailModal} mr0>
            <FontAwesome5 name="shipping-fast" size={24} color={COLOR.FONT} />
          </TouchField>
          <TouchField cirle middle onPress={showCustomerInfo} mr0>
            <Image source={ASSETS.CUSTOMER_ADDRESS_ICON} style={{ width: 20, height: 40 }} resizeMode='contain' />
          </TouchField>
          <TouchField cirle middle onPress={showNotes} mr0>
            <SimpleLineIcons name="notebook" size={20} color="black" />
          </TouchField>
          <TouchField cirle middle onPress={isGreatHarbour ? undefined : showPreview} mr0 opacity={isGreatHarbour ? 0.3 : 1}>
            <SVG.Eye />
          </TouchField>
          <TouchField cirle middle onPress={onDelete}>
            <EvilIcons name="trash" size={24} color={COLOR.FONT} />
          </TouchField>
        </Grid> */}
        {/* <Row width={220} m1>
        <TouchField cirle middle onPress={showRoyalMailModal} mr0>
          <FontAwesome5 name="shipping-fast" size={24} color={COLOR.FONT} />
        </TouchField>
        <TouchField cirle middle onPress={showCustomerInfo} mr0>
          <Image source={ASSETS.CUSTOMER_ADDRESS_ICON} style={{ width: 20, height: 40 }} resizeMode='contain' />
        </TouchField>
        <TouchField cirle middle onPress={showNotes} mr0>
          <SimpleLineIcons name="notebook" size={20} color="black" />
        </TouchField>
        <TouchField cirle middle onPress={isGreatHarbour ? undefined : showPreview} mr0 opacity={isGreatHarbour ? 0.3 : 1}>
          <SVG.Eye />
        </TouchField>
        <TouchField cirle middle onPress={onDelete}>
          <EvilIcons name="trash" size={24} color={COLOR.FONT} />
        </TouchField>
      </Row> */}
      </Animatable.View>
      <Row width={220} m1>
        <TouchField cirle middle onPress={showRoyalMailModal} mr0>
          <FontAwesome5 name="shipping-fast" size={24} color={COLOR.FONT} />
        </TouchField>
        <TouchField cirle middle onPress={showCustomerInfo} mr0>
          <Image source={ASSETS.CUSTOMER_ADDRESS_ICON} style={{ width: 20, height: 40 }} resizeMode='contain' />
        </TouchField>
        <TouchField cirle middle onPress={showNotes} mr0>
          <SimpleLineIcons name="notebook" size={20} color="black" />
        </TouchField>
        <TouchField cirle middle onPress={isGreatHarbour ? undefined : showPreview} mr0 opacity={isGreatHarbour ? 0.3 : 1}>
          <SVG.Eye />
        </TouchField>
        <TouchField cirle middle onPress={onDelete}>
          <EvilIcons name="trash" size={24} color={COLOR.FONT} />
        </TouchField>
      </Row>
    </Grid>

  ) : null;
};

export default PrintJobItem;
