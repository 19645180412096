import { useNavigation } from '@react-navigation/native';
import { Tooltip } from 'antd';
import { Col, Grid, Row } from 'components';
import { COLOR } from 'const';
import { ValHelper } from 'helpers';
import React, { useState } from 'react';
import { Image, Pressable, StyleSheet, Text, LayoutChangeEvent } from 'react-native';

interface IProps {
  products: any[];
  onProductClick?: (product: any) => void;
}

const ListProductContainer
  : React.FC<IProps> = ({ onProductClick, products }) => {
    const [width, setWidth] = useState(180);
    const [hoveredItem, setHoveredItem] = useState<string | null>(null);
    const navigation = useNavigation();

    const onLayoutItem = (e: LayoutChangeEvent) => {
      setWidth(e.nativeEvent.layout.width);
    }
    return (
      <Grid xs='50%' sm="33.3%" md="25%" xl='20%' alignItems='stretch' flexGrow={1}>
        {products.map((product, i) => (
          <Col key={i} padding={10} flexGrow={1}>
            <Pressable
              onLayout={i === 0 ? onLayoutItem : undefined}
              onPress={() => onProductClick(product.id)}
              style={[styles.productContainer, {
                backgroundColor: hoveredItem === product.id ? '#dfdfdf' : '#f3f1f1',
              }]}
            >
              <Tooltip
                trigger='hover'
                //@ts-ignore
                onMouseEnter={() => setHoveredItem(product.id)}
                onMouseLeave={() => setHoveredItem(null)}
              >
                <Image
                  source={{ uri: product.image }}
                  style={{
                    width: width,
                    height: width * 1.064,
                    borderRadius: 8,
                  }}
                />
                <Row style={product.label !== '' && styles.label}>
                  <Text style={{ fontSize: 12, color: 'white' }}>{product.label}</Text>
                </Row>
                <Col alignItems='flex-start' paddingHorizontal={10}>
                  <Text style={styles.productName}>{product.name}</Text>
                  <Text style={styles.priceText}>Price: £{ValHelper.formatMoney(product.price)}</Text>
                </Col>
              </Tooltip>
            </Pressable>
          </Col>
        ))}
      </Grid>
    );
  }

const styles = StyleSheet.create({
  productContainer: {
    borderRadius: 8,
    shadowColor: '#000',
    shadowOffset: {
      width: 2,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 4,
    elevation: 8,
    flex: 1,
  },
  productName: {
    fontSize: 16,
    fontWeight: 'bold',
    marginTop: 10,
  },
  priceText: {
    marginBottom: 10,
    fontSize: 14,
  },
  label: {
    position: "absolute",
    top: 0,
    right: 0,
    backgroundColor: COLOR.MAIN,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 7,
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 0,
    paddingVertical: 3,
    paddingHorizontal: 10
  }
});

export default ListProductContainer;
;
