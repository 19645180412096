import { Col, Text, CMSLayout, Table, BgIcon } from 'components';
import React, { useState, useEffect, useCallback } from 'react';
import { IScreen, TOrderLog } from 'type';
import Store, { Api } from 'store';
import { useNavFunc } from 'navigation';
import { COLORS, LOG_TYPES, SCREEN } from 'const';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { Input, Pagination } from 'antd';
import { ValHelper } from 'helpers';
import { startCase } from 'lodash';

const OrderLogs: IScreen = () => {
  const { navigation } = useNavFunc();
  const UserStore = Store.useUserStore();
  const { user } = UserStore;
  const userId = user?.id;
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState('');

  const getData = useCallback(async () => {
    try {
      if (isLoading) return;
      setIsLoading(true);
      const res = await Api.Log.listOrderLog({
        page,
        search: searchKeyword,
      })
      const newData = res?.data?.data?.list || [];
      setData(s => {
        if (page === 1) return newData;
        return [...s, ...newData];
      })
    } catch (error) {
      alert(error?.message || JSON.stringify(error))
    } finally {
      setIsLoading(false);
    }
  }, [isLoading, searchKeyword, page])

  useEffect(() => {
    if (!!userId) {
      getData()
      const unsubscribe = navigation.addListener('focus', () => {
        getData()
      });
      return unsubscribe;
    }
  }, [userId, page])

  const onChangeFilter = (pagination, filters, sorter) => {
  }


  const columns: ColumnsType<TOrderLog> = [
    {
      title: 'Order Id',
      key: 'orderId',
      render: (record) => (
        <Text>#{record?.body?.orderId}</Text>
      )
    },
    {
      title: 'Author',
      key: 'author',
      render: (record) => (
        <Col>
          <Text>{record.otherData?.user?.email}</Text>
          <Text>{record.otherData?.user?.name}</Text>
        </Col>
      )
    },
    {
      title: 'Changes',
      key: 'changes',
      render: (record) => (
        <Col>
          <Text semiBold mb1>{ValHelper.convertTypeToTitle(record.type)}</Text>
          <Text>{Object.keys(record?.body?.changes || {})
            .filter(key => !!record?.body?.changes?.[key]?.from || !!record?.body?.changes?.[key]?.to)
            .map(key => {
              if (key === "status") return "";
              return `${startCase(key)}: ${record?.body?.changes?.[key]?.from} => ${record?.body?.changes?.[key]?.to}\n`
            })}
          </Text>
        </Col>
      )
    },
    {
      title: 'Created at',
      key: 'createdAt',
      render: (record) => (
        <Text>{moment(record.createdAt).format('DD/MM/YYYY HH:mm')}</Text>
      )
    },
  ].filter(Boolean);

  return user?.role !== 'admin' ? (
    <CMSLayout requireAuthen>
      <Col flex1 m2 mt1 p2 round1 bgWhite>
        <Col flex1 middle>
          <Text>Permission denied</Text>
        </Col>
      </Col>
    </CMSLayout>
  ) : (
    <CMSLayout requireAuthen>
      <Col m2 mb0>
        <Input
          value={searchKeyword}
          size="large"
          placeholder="Search order, changes"
          prefix={(
            <Col marginBottom={-4}>
              <BgIcon name="search" size={18} color={COLORS.ICON_MUTED} />
            </Col>
          )}
          onChange={(e) => setSearchKeyword(e.target.value)}
          onPressEnter={() => {
            setPage(1);
            getData();
          }}
        />
      </Col>
      <Col flex1 m2 mt1>
        <Table
          data={data}
          columns={columns}
          rowKey={val => `${val.Id}_${val['Order ID']}`}
          loading={isLoading}
          onChange={onChangeFilter}
        />
        <Pagination
          style={{ marginTop: 12 }}
          pageSize={20}
          current={page}
          onChange={setPage}
        />
      </Col>
    </CMSLayout>
  )
};

OrderLogs.routeInfo = {
  title: 'Bottled Goose - Print Manager',
  path: '/system/order-logs',
};

export default OrderLogs;
