import React, { useEffect, useState, useRef } from 'react';
import { IScreen } from 'type';
import { Button, CMSLayout, Col, Row, ShimmerLoading, Text, useUIState } from 'components';
import { useNavFunc } from 'navigation';
import Store from 'store';
import { SCREEN } from 'const';

const ManualInvoice: IScreen = () => {

  const { navigate, navigation } = useNavFunc();
  const UserStore = Store.useUserStore();
  const [{ fetching, errorMes, loading: btnLoading }, setUI] = useUIState({
    fetching: false,
  });
  const [pagination, setPagination] = useState({ hasNext: false, total: 1 });
  const [manualInvoices, setManualInvoices] = useState<Array<any>>([]);
  const renderManualInvoicesList = () => {
    if (manualInvoices.length === 0) {
      return (
        <Col flex1>
          <Text>No invoices found</Text>
        </Col>
      );
    }
    return manualInvoices.map((invoice, index) => {

      return (
        <Row key={index} stretch height={50} middle>
          <Col flex={1}>
            <Text>{invoice.id}</Text>
          </Col>
          <Col flex={1}>
            <Text>{invoice.clientName}</Text>
          </Col>
          <Col flex={1}>
            <Text>{invoice.total}</Text>
          </Col>
          <Col flex={1}>
            <Text>{invoice.status}</Text>
          </Col>
          <Col flex={1}>
            <Button
              text="View"
              width={100}
              height={30}
              borderRadius={15}
              onPress={() => {
                navigation.reset({
                  index: 0,
                  routes: [{ name: SCREEN.ManualCreateInvoiceCreate, params: { id: invoice.id } }],
                });
              }}
            />
          </Col>
        </Row>
      );
    })
  }
  return (
    <CMSLayout requireAuthen>

      <Row m2 marginBottom={0} justifyContent={"space-between"}>
        <Text h3>Manual Invoices</Text>
        <Button
          isLoading={btnLoading}
          text="Create new invoice"
          width={200}
          height={40}
          borderRadius={20}
          onPress={async () => {
            navigation.reset({
              index: 0,
              routes: [{ name: SCREEN.ManualCreateInvoiceCreate, params: { id: "new" } }],
            });
          }}
        />
      </Row>
      <Col flex1 m2 mv1 p2 round1 bgWhite>
        {errorMes ? (
          <Col flex1 middle>
            <Text color="red" subtitle1>
              {errorMes}
            </Text>
          </Col>
        ) : fetching ? (
          <Col>
            <Row height={50} stretch mt2>
              <ShimmerLoading round1 flex={1} m1 />
              <ShimmerLoading round1 flex={1} m1 />
              <ShimmerLoading round1 flex={1} m1 />
              <ShimmerLoading round1 flex={1} m1 />
            </Row>
            <Row height={50} stretch mt2>
              <ShimmerLoading round1 flex={1} m1 />
              <ShimmerLoading round1 flex={1} m1 />
              <ShimmerLoading round1 flex={1} m1 />
              <ShimmerLoading round1 flex={1} m1 />
            </Row>
          </Col>
        ) : (
          renderManualInvoicesList()
        )}
      </Col>
    </CMSLayout>
  );
};

ManualInvoice.routeInfo = {
  title: 'Manual Invoice',
  path: '/manual-invoice',
};

export default ManualInvoice;
