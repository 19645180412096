import React from 'react';
import * as Sentry from "@sentry/react";
import { ValHelper } from '../helpers';

export const withSentryProfiler = (Component: React.ComponentType<any>, componentName?: string): any => {
  if (ValHelper.isDev) {
    return Component;
  }
  
  return function WithSentryProfilerWrapper(props: any) {
    return (
      <Sentry.Profiler name={componentName || Component.displayName || Component.name}>
        <Component {...props} />
      </Sentry.Profiler>
    );
  };
};
