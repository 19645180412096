import React, { useEffect, useState } from 'react';
import { Col, SelectField, TableForm, Text } from 'components';
import { TProduct } from 'type';

interface IProps {
  product?: TProduct;
  updateProduct?: any;
  updateProductData?: any;
}

const DISCOUNT_TYPES = [
  { label: 'Percentage', value: 'percentage' },
  { label: 'Price', value: 'price' },
]

const UpsertProductPricing = ({ product, updateProduct, updateProductData }: IProps) => {
  const [discountType, setDiscountType] = useState(product?.data?.discountType || 'price')
  const [packPrices, setPackPrices] = useState(product?.packPrices?.length ? product.packPrices : [{
    size: "1",
    price: String(product?.price),
    discount: 0,
  }])

  const onChangePackPrices = (data) => {
    setPackPrices(data)
    updateProduct({
      packPrices: data
    })
    if (!product?.data?.discountType) {
      updateProductData({
        discountType: 'price'
      })
    }
  }

  const onChangeDiscountType = (val: string) => {
    setDiscountType(val)
    updateProductData({
      discountType: val
    })
  }

  return (
    <Col flex1 bgWhite>
      <Text caption mb2>* Discounts for different pack sizes</Text>
      <SelectField
        title="Discount type"
        options={DISCOUNT_TYPES}
        value={discountType}
        onChange={(val: string) => onChangeDiscountType(val)}
        isMultiple={false}
      />
      <TableForm
        addBtnTitle="Add row"
        data={packPrices}
        columns={[
          { key: 'size', label: 'Min Qty' },
          discountType === 'price'
            ? { key: 'price', label: 'Unit Price' }
            : { key: 'discount', label: 'Discount %' },
        ]}
        editable
        onChange={onChangePackPrices}
        isBottomBtn
        marginBottom={24}
      />
    </Col>
  )
};

export default UpsertProductPricing;
