import { Modal, ModalFuncProps } from "antd";
import { COLOR } from "const";

export const modalConfirm = (props: ModalFuncProps) => {
  Modal.confirm({
    okButtonProps: {
      style: { backgroundColor: COLOR.MAIN },
    },
    cancelButtonProps: {
      style: { color: COLOR.FONT },
    },
    ...props,
  })
}
