
import Store from 'store';
import { COLOR, SCREEN } from 'const';
////////////////////////////////////////////////
import React, { useState, useRef, useEffect } from 'react';
import { BgIcon, useUIState, Col, Row, Text } from 'components';
import {
  ScrollView, View, TouchableOpacity, StyleSheet,
  useWindowDimensions, TextInput, Image, ActivityIndicator,
} from 'react-native';
import { useNavFunc } from 'navigation';
import { ValHelper } from 'helpers';
import { dataFacts } from 'const/facts';
import { SVG } from 'assets';
import { FlatList } from 'react-native-gesture-handler';
import { ASSETS } from 'assets';
import GeeseFactsRandom from 'components/widgets/facts/GeeseFactsRandom';
import { IScreen } from 'type';
////////////////////////////////////////////////

const ForgotPassword: IScreen = () => {
  const { navigate, reset, route } = useNavFunc();
  const { width } = useWindowDimensions();

  const minWidth = 900;
  const isResizeLayout = width > minWidth;
  const isMobile = width <= minWidth;
  const resizeFont = (width: number, minWidth: number) => {
    if (width > 1000) return 0;
    if (minWidth <= width) return 2;
    if (width < minWidth && width >= 550) return 0;
    if (width < 550) return 2;
  }
  /* Logic */
  const [email, setEmail] = useState('');

  const isLoginEnabled =
    email.length > 0
  const [openEyePassWord, setOpenEyePassword] = useState(true);

  /* Validate */
  const [errorMessageEmail, setErrorMessageEmail] = useState('');
  const validateEmail = () => {
    const emailChecker = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailChecker.test(email)) {
      setErrorMessageEmail('Please provide a valid email.');
      return false;
    }
    setErrorMessageEmail('');
    return true;
  }
  /* Forgot password */
  const [loading, setLoading] = useState(false);
  const [errorMes, setErrorMes] = useState('');
  const [showMessage, setShowMessage] = useState(false);

  const onSubmit = async () => {
    try {
      if (loading) return;
      setLoading(true);
      const res = await Store.Api.User.forgotPassword({
        email,
      });
      if (!res.data.success) {
        setErrorMes(res.data.error);
      } else {
        setShowMessage(true);
      }
    } catch (error) {
      setErrorMes(error.message || JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  }
  /* Show screen */
  const renderFact = () => {
    return (
      <Col
        width={isResizeLayout ? '50%' : '100%'}
        // justifyContent={isResizeLayout ? 'center' : ''}
        justifyContent={'center'}
        alignItems={isResizeLayout ? 'flex-end' : 'center'}
        paddingLeft={isResizeLayout ? 30 : 30}
        paddingRight={isResizeLayout ? 0 : 30}
        height={isResizeLayout ? '100%' : ''}
        // backgroundColor={'red'}
        flex1
      >
        <Col
          width={'100%'}
          height={'100%'}
          backgroundColor={'#FFFFFFE5'}
          opacity={0.9}
          middle
          borderRadius={16}
          padding={isResizeLayout ? 16 : 5}
        >
          <GeeseFactsRandom />
        </Col>
      </Col>
    )
  }

  const renderForm = () => {
    return (
      <Col
        width={isResizeLayout ? '50%' : '100%'}
        middle
        height={isResizeLayout ? '100%' : ''}
      // backgroundColor={'yellow'}   
      // flex1

      >
        <Image
          source={ASSETS.BG_SMALL_BLUE}
          style={{
            width: 100, height: 120, display: isResizeLayout ? '' : 'none',
          }}
          resizeMode="contain"
        />

        <Col
          maxWidth={750}
          justifyContent={'center'}
          paddingHorizontal={30}
          width={'100%'}
          paddingBottom={30}
        >
          <Col
            marginTop={20}
            marginBottom={isResizeLayout ? 50 : 16}
          >
            <Row
              flexWrap={'wrap'}
              justifyContent={'center'}
            >
              <Text
                fontFamily={'Inter, sans-serif'}
                fontWeight={600}
                fontSize={32 - resizeFont(width, minWidth)}
                lineHeight={40}
                marginBottom={16}
                color={'#101840'}
                textAlign={'center'}
              >Welcome to </Text>
              <Image
                source={ASSETS.BG_SMALL_BLUE}
                style={{
                  width: 40, height: 52, borderRadius: 10,
                  display: isResizeLayout ? 'none' : '',
                  alignSelf: 'center', marginRight: 10,
                }}
                resizeMode="contain"
              />
              <Text
                fontFamily={'Inter, sans-serif'}
                fontWeight={600}
                fontSize={32 - resizeFont(width, minWidth)}
                lineHeight={40}
                marginBottom={16}
                color={'#101840'}
                textAlign={'center'}
              >Bottled Goose</Text>
            </Row>
            <Text
              fontFamily={'Inter, sans-serif'}
              fontWeight={600}
              fontSize={18 - resizeFont(width, minWidth)}
              lineHeight={24}
              color={'#8692A6'}
              display={showMessage ? 'none' : ''}
            >Please input your email to change password</Text>
          </Col>
          {showMessage ?
            <Col flex1 middle padding={20} maxWidth={750} height={200}
            >
              <Text
                fontFamily={'Inter, sans-serif'}
                fontWeight={600}
                fontSize={20 - resizeFont(width, minWidth)}
                lineHeight={isResizeLayout ? 40 : 30}
                color={'#101840'}
                textAlign={'center'}
              > Check your email and reset your password
              </Text>
            </Col>
            :
            <Col flex1>
              <Col
                marginBottom={16}
              >
                <Text
                  fontFamily={'Inter, sans-serif'}
                  fontWeight={600}
                  fontSize={14 - resizeFont(width, minWidth)}
                  lineHeight={21}
                  color={'#101840'}
                  marginBottom={8}
                >Email address</Text>
                <TextInput
                  style={{
                    fontFamily: 'Inter, sans-serif',
                    fontWeight: 400,
                    fontSize: 16 - resizeFont(width, minWidth),
                    lineHeight: 24,
                    height: 52,
                    borderWidth: 1,
                    borderRadius: 5,
                    borderColor: '#696F8C',
                    paddingVertical: 14,
                    paddingHorizontal: 16,
                    outline: 'none',
                    flex: 1,
                  }}
                  placeholder='Enter your email address'
                  placeholderTextColor={'#696F8C'}
                  onChangeText={(text) => setEmail(text)}
                  value={email}
                  nativeID='enter-email'
                ></TextInput>
                <Text
                  fontFamily={'Inter, sans-serif'}
                  fontWeight={400}
                  fontSize={16 - resizeFont(width, minWidth)}
                  lineHeight={24}
                  color={'red'}
                  paddingBottom={8}
                >{errorMes}</Text>
              </Col>
              <Col marginBottom={16}>
                <Col
                  middle
                  paddingVertical={8}
                  paddingHorizontal={16}
                  marginBottom={20}
                  borderRadius={4}
                  backgroundColor={'#223363'}
                  opacity={isLoginEnabled ? 1 : 0.7}
                  style={{ outline: 'none', }}
                  onPress={() => {
                    validateEmail();
                    onSubmit();
                  }}
                  disabled={!isLoginEnabled}
                >
                  <Row>
                    {loading
                      ? <ActivityIndicator
                        size={"small"}
                        color={'white'}
                        style={{ position: 'absolute', marginLeft: -25, }}
                      />
                      : ''
                    }
                    <Text
                      fontFamily={'Inter, sans-serif'}
                      fontWeight={600}
                      fontSize={16 - resizeFont(width, minWidth)}
                      lineHeight={16}
                      color={'#FFF'}
                    > Submit
                    </Text>
                  </Row>
                </Col>
              </Col>
            </Col>}

          <Row
            marginBottom={20}
          >
            <Col
              flex6
              height={1}
              backgroundColor={'#686677'}
            ></Col>
            <Text
              fontFamily={'Inter, sans-serif'}
              fontWeight={600}
              fontSize={12 - resizeFont(width, minWidth)}
              lineHeight={16}
              color={'#101840'}
              flex={1}
              textAlign={'center'}
            >
              Or
            </Text>
            <Col
              flex6
              height={1}
              backgroundColor={'#686677'}
            ></Col>
          </Row>
          <Col
          >
            <Text
              fontFamily={'Inter, sans-serif'}
              fontWeight={400}
              fontSize={16 - resizeFont(width, minWidth)}
              lineHeight={24}
              color={'#101840'}
            >
              Do you return to the
              <Text
                fontFamily={'Inter, sans-serif'}
                fontWeight={500}
                fontSize={14 - resizeFont(width, minWidth)}
                lineHeight={24}
                color={'#3366FF'}
                marginLeft={8}
                onPress={() => navigate('Login')}
              >
                Login ?
              </Text>
            </Text>
          </Col>

        </Col>
      </Col>
    )
  }

  const renderMobile = () => {
    return (
      <ScrollView style={{ height: 500, backgroundColor: COLOR.EXTENDED_N300 }}>
        {renderForm()}
        {renderFact()}
        <Col height={50} />
      </ScrollView>
    )
  };

  return isMobile ? renderMobile() : (
    <Col
      flex1
      backgroundColor={COLOR.EXTENDED_N300}
    >
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{
          flex: 1,
        }}
      >
        <Col
          flex1
          middle
          paddingBottom={isResizeLayout ? 0 : 30}
        >
          <Row
            flexWrap={'wrap-reverse'}
            // flex1={isResizeLayout}
            style={{
              flex: isResizeLayout ? 1 : ''
            }}
            marginVertical={isResizeLayout ? 30 : 0}
            width={'100%'}
          >
            {renderFact()}
            {renderForm()}
          </Row>
        </Col>
      </ScrollView>
    </Col>
  )
}

ForgotPassword.routeInfo = {
  title: 'Forgot Password',
  path: '/forgot-password',
}

export default ForgotPassword;
