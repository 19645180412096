import React, { useMemo, useState } from 'react';
import { Col, Row, Text, TouchField } from 'components';
import { FontAwesome } from '@expo/vector-icons';
import { COLOR } from 'const';
import { TPrintJob, TUser } from 'type';

interface TToday extends TPrintJob {

}

interface Props {
  data: Array<TToday>,
  listReseller: Array<TUser>,
}

const TodayData = ({ data, listReseller } : Props) => {
  const [showDetail, setShowDetail] = useState(false);
  return data.length === 0 ? (
    <Col mb2>
      <Text caption fontStyle={'italic'}>You dont have any print job created today</Text>
    </Col>
  ) : (
    <Col mb2 backgroundColor={'rgba(0,0,0,0.05)'} p1>
      <Row mb2={showDetail}>
        <Text>You have <Text bold>{data.length} print jobs</Text> created today</Text>
        <TouchField ml1 p0 onPress={() => setShowDetail(v => !v)}><Text caption>{showDetail ? 'Close' : 'Show'} me</Text></TouchField>
      </Row>
      {showDetail && (
        <Col>
          <Row p0 borderBottomColor={COLOR.GREY} borderBottomWidth={0.5}>
            <Col flex1>
              <Text bold>Client</Text>
            </Col>
            <Col flex={2}>
              <Text bold>Product Name</Text>
            </Col>
            <Col width={50}>
              <Text ml2 bold>QTY</Text>
            </Col>
          </Row>
          {data.map((v, vI) => {
            let reseller = !v.clientId ? undefined : listReseller.find(u => u.id === v.clientId);
            if (v.clientId === 'partnet-in-wine') {
              reseller = {
                id: 'partnet-in-wine',
                firstName: 'Partner',
                lastName: 'In Wine',
                role: 'reseller',
                email: '',
                password: '',
              }
            }
            return (
              <Row p0 borderBottomColor={COLOR.GREY} borderBottomWidth={0.5}>
                <Col flex1>
                  <Text>{!reseller?.firstName && !reseller?.lastName ? '' : `${reseller?.firstName} ${reseller.lastName}`}</Text>
                </Col>
                <Col flex={2}>
                  <Text>{v.productName}</Text>
                </Col>
                <Col width={50}>
                  <Text ml2>{v.quantity}</Text>
                </Col>
              </Row>
            )
          })}
        </Col>
      )}
    </Col>
  );
};

export default TodayData;