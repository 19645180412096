
import React from 'react';
import { Input } from 'antd';
import { TextAreaProps } from 'antd/es/input';
import { FONT } from 'const';

const { TextArea: AntTextArea } = Input;

interface Props extends TextAreaProps {
  height?: number,
  onChangeText?: (value) => void
}

const TextArea = (props: Props) => {
  const { onChangeText, height, ...restProps } = props;

  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChangeText?.(e.target.value);
  };

  return (
    <AntTextArea
      onChange={onChange}
      style={{ height, outline: "none", color: "#383838", fontFamily: FONT.defaultFont }}
      {...restProps}
    />
  )
}

export default TextArea;
