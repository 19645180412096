import React from 'react';
import { Tooltip as TooltipComponent } from 'react-tippy';

interface IProps {
  children: any,
  title: string,
  trigger?: string,
  position: string,
  [other: string]: any,
}

const Tooltip = ({ children, title, trigger, position } : IProps) => {
  // @ts-ignore
  return (<TooltipComponent
      title={title}
      trigger={trigger}
      position={position}
    >
      {children}
    </TooltipComponent>
  )
}

export default Tooltip;