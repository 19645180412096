import RequestUtils from "store/api-client/Request.utils";
import { MICRO_SERVICES_HOST, ENV } from "store/Order.Store";

export const requestMicroApi = async (url: string, data?: { method?: string, body?: any, headers?: any }) => {
  return fetch(`${MICRO_SERVICES_HOST}${url}`, {
    method: data?.method || "get",
    headers: {
      ...(data?.headers || {}),
      "Authorization": RequestUtils.getToken(),
      "Content-Type": "application/json",
      "env": ENV,
    },
    body: data?.body,
  });
}
