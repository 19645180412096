import React, { useEffect, useRef, useState } from 'react';
import {
  Col,
  Row,
  TouchField,
  Input,
  Text,
  SwapIconEffect,
} from "components";
import { SVG, ASSETS } from "assets";
import { StyleSheet, ActivityIndicator, Animated, Image } from "react-native";
import Store from "store";
import UserTopRightInfo from "./UserTopRightInfo";
import { COLOR, SCREEN } from "const";
import { useDynamicResponsiveValue } from "quickly-react";
import { MaterialCommunityIcons, Feather, Ionicons, AntDesign } from "@expo/vector-icons";
import { useNavFunc } from "navigation";
import ResellerInspectModal from '../reseller-inspect/ResellerInspectModal';

const HeaderBar = Row;

export const HEADER_LOGIN_WIDTH = 269 * 0.75;
export const HEADER_LOGIN_HEIGHT = 79 * 0.7;

const Header = ({
  headerAnimated, mobileLeftIconIndex, openMobileDrawer, closeMobileDrawer, onPressLogout,
  onSearchInput, searchInitialValue, searchPlaceholder, onClearSearchInput,
  breadcrumbs, description, searchAutoClear,
}) => {
  const { navigate } = useNavFunc();
  const resellerInspectRef = useRef<any>(null);
  const rV = useDynamicResponsiveValue(["xs", "md"]);
  const breakpoint = rV({ xs: "xs", md: "md" });
  const UserStore = Store.useUserStore();
  const [searchKeyword, setSearchKeyword] = useState(searchInitialValue || "");

  const renderSearch = () => {
    return (
      <Row
        ml2
        width={300}
        borderRadius={15}
        backgroundColor={COLOR.GREY_LIGHT}
      >
        <Input
          height={30}
          flex1
          borderColor="transparent"
          value={searchKeyword}
          onChange={setSearchKeyword}
          inputProps={{
            style: {
              // @ts-ignore
              outline: "none",
              fontSize: 12,
            },
            placeholderTextColor: COLOR.GREY,
            onSubmitEditing: () => {
              if (searchAutoClear) setSearchKeyword('');
              onSearchInput(searchKeyword);
            },
          }}
          placeholder={searchPlaceholder || "Search"}
        />
        {searchKeyword?.length > 0 && onClearSearchInput && (
          <Col
            absolute
            top={3}
            right={30}
            onPress={() => {
              setSearchKeyword('');
              onClearSearchInput();
            }}
          >
            <AntDesign name="close" size={20} color={COLOR.GREY} />
          </Col>
        )}
        <Col
          absolute
          top={3}
          right={10}
          onPress={() => onSearchInput(searchKeyword)}
        >
          <Feather name="search" size={20} color={COLOR.GREY} />
        </Col>
      </Row>
    )
  }

  const renderBreadcrumb = () => {
    return (
      <Col pl2>
        {breadcrumbs && breadcrumbs.length > 0 && (
          <Row>
            {breadcrumbs.map(({ title, screen, params }, index) => {
              const isLast = index === breadcrumbs.length - 1;
              const isFirst = index === 0;
              const color = isLast ? COLOR.MAIN : '#667085';
              return (
                <Row key={index} mr1>
                  {!!screen ? (
                    <TouchField
                      onPress={() => navigate(screen, params)}
                      marginLeft={isFirst ? -5 : 0}
                    >
                      <Text p0 fontSize={14} color={color} semiBold>{title}</Text>
                    </TouchField>
                  ) : (
                    <Text p0 marginLeft={isFirst ? -5 : 0} fontSize={14} color={color} semiBold>{title}</Text>
                  )}
                  {!isLast && (
                    <AntDesign name="right" size={12} color="#667085" style={{ marginLeft: 5, marginRight: 0 }} />
                  )}
                </Row>
              )
            })}
          </Row>
        )}
        {description && (
          <Text caption color='#667085'>{description}</Text>
        )}
      </Col>
    )
  }

  return (
    <>
      <Col height={HEADER_LOGIN_HEIGHT} />
      <Animated.View
        // @ts-ignore
        style={{
          backgroundColor: "white",
          flexDirection: "row",
          alignItems: "stretch",
          height: HEADER_LOGIN_HEIGHT,
          transform: [{ translateY: headerAnimated.containerY }],
          position: "fixed",
          top: 0,
          left: 0, right: 0,
          zIndex: 10000,
          borderBottomColor: COLOR.GREY_LIGHT,
          borderBottomWidth: 1,
        }}
      >
        {breakpoint === "xs" ? (
          <Row
            stretch
            width100p
            height={HEADER_LOGIN_HEIGHT}
            backgroundColor={COLOR.MAIN}
          >
            <SwapIconEffect
              width={50}
              height={55}
              displayIndex={mobileLeftIconIndex}
              effect={mobileLeftIconIndex === 0 ? "rotate" : "rotate-backward"}
            >
              <TouchField
                width={50}
                height={55}
                middle
                onPress={openMobileDrawer}
                activeOpacity={1}
              >
                <Ionicons name="menu-sharp" size={24} color="white" />
              </TouchField>
              <TouchField
                width={50}
                height={55}
                middle
                activeOpacity={1}
                onPress={closeMobileDrawer}
              >
                <Ionicons name="close" size={24} color="white" />
              </TouchField>
            </SwapIconEffect>
            <Col flex1 middle>
              <SVG.BottleGoose fill="white" width={"25%"} />
            </Col>
            <TouchField width={50} middle onPress={onPressLogout}>
              <MaterialCommunityIcons name="logout" size={24} color="white" />
            </TouchField>
          </Row>
        ) : (
          <>
            <Animated.View
              style={{
                transform: [
                  { translateX: headerAnimated.logoXY.x },
                  { translateY: headerAnimated.logoXY.y },
                ],
              }}
            >
              <Col
                onPress={() => navigate(SCREEN.Home)}
                middle
                width={HEADER_LOGIN_WIDTH}
                height={HEADER_LOGIN_HEIGHT}
                backgroundColor={COLOR.MAIN}
              >
                <Image
                  source={ASSETS.LOGO}
                  style={{ width: "65%", height: "80%" }}
                  resizeMode="contain"
                />
              </Col>
            </Animated.View>

            <HeaderBar flex1 justifyContent={"space-between"}>
              {onSearchInput ? (
                renderSearch()
              ) : (
                renderBreadcrumb()
              )}

              <Row mr1>
                <UserTopRightInfo
                  user={UserStore.user}
                  onClickLogout={onPressLogout}
                  onClickSettings={() => {
                    navigate(SCREEN.Settings);
                  }}
                  onClickResellerInspect={() => {
                    resellerInspectRef.current?.show();
                  }}
                />
              </Row>
            </HeaderBar>
          </>
        )}
      </Animated.View>
      <ResellerInspectModal ref={resellerInspectRef} />
    </>
  );
};
export default Header;
