import { Col, Text, Button, Input, useUIState } from 'components';
import { IScreen } from 'type';
import React, { useState, useRef, useEffect } from 'react';
// import './LoginMobile.css';
import { COLOR, SCREEN } from 'const';
import { SVG } from 'assets';
import Store, { Api } from 'store';
import { useNavFunc } from 'navigation';
import {
  Animated,
  useWindowDimensions,
} from 'react-native';
import { ASSETS } from 'assets';

const MessageMobile: IScreen = () => {
  const UserStore = Store.useUserStore();
  const { user } = UserStore;
  const userName = [user?.firstName, user?.lastName].filter(Boolean).join(" ");

  useEffect(() => {
    if (!user) return;
    Api.User.sendEmailWithDesktopLink();
  }, [user]);

  return (
    <div className="message-container">
    <div className="logo">
      <img src={ASSETS.LOGO_MOBILE} />
    </div>
    <div className="wellcome-image">
      <img src={ASSETS.MESS_MOBILE}/>
    </div>
    <div className="message-content">
      <p className="message_dear">
        <strong>Dear {userName},</strong>
      </p>
      <p>
        Many thanks for creating a Bottled Goose account. Our platform is best
        viewed on a laptop or desktop rather than a mobile device.
        <br />
        An email has been sent to your inbox with a link to view your account when
        you’re next at your computer.
      </p>
      <p>Have a great day!</p>
      <p>Bottled Goose team.</p>
    </div>
    {/* <button type="submit" className="form_submit">
      <span className="form_submit-text">Go back to log in</span>
    </button> */}
  </div>
  
  )
}

MessageMobile.routeInfo = {
  title: 'MessageMobile',
  path: '/mobile/message',
}

export default MessageMobile;