import { Animated } from 'react-native';

class AnimHelper {
  animate = (
    value : Animated.Value | Animated.ValueXY,
    toValue: Animated.TimingAnimationConfig['toValue'],
    duration: Animated.TimingAnimationConfig['duration'],
    delay : Animated.TimingAnimationConfig['delay'] = 0,
  ) => new Promise((resolve) => {
    Animated.timing(value, {
      toValue,
      duration,
      delay,
      useNativeDriver: false,
    }).start(({ finished }) => {
      resolve(finished);
    });
  });
}

export default new AnimHelper();