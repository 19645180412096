import { TouchField } from './index';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import React from 'react';
import { COLOR } from '../../const';

interface IProps {
  isOn: boolean;
  toggleSwitch: any;
  translateY?: number;
  size?: number;
  height?: number;
}

const Switch = ({ isOn, toggleSwitch, size, translateY = 0, height }: IProps) => {
  const props = size <= 38 ? { circle: true } : { width: size+5, height: height || size+5 };
  return (
    // @ts-ignore
    <TouchField {...props} middle onPress={toggleSwitch} transform={[{ translateY: translateY }]} style={{ outline: 'none' }}>
      {isOn ? (
        <MaterialCommunityIcons name="toggle-switch" size={size || 38} color={COLOR.MAIN} />
      ) : (
        <MaterialCommunityIcons name="toggle-switch-off" size={size || 38} color={COLOR.GREY} />
      )}
    </TouchField>
  );
}

export default Switch;
