import React, { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import { Col, Text, Row, showPopupMessage, InputField, PhoneField, SelectField, InputNumberField, BgIcon } from 'components';
import { Modal, Popover } from 'antd';
import { COLOR, COLORS, COUNTRIES, SCREEN } from 'const';
import Store, { Api } from 'store';
import { TCMSOrder, TProduct } from 'type';
import { usePaymentStore } from 'store/Payment.Store';
import { ValHelper, modalConfirm } from 'helpers';
import { useNavFunc } from 'navigation';
import './ordermodal.css'
import PayBuyNowOrder from './BuyNowModal.Pay';


const FIELDS = [
  { id: "first_name", label: "First name", require: true },
  { id: "last_name", label: "Last name", require: true },
  { id: "address1", label: "Street address", placeholder: "House number and street name", require: true },
  { id: "address2", placeholder: "Apartment, suite, unit etc. (optional)", label: "" },
  { id: "town", label: "Town / City", require: true },
  { id: "zip", label: "Post Code / ZIP", require: true },
  { id: "country", label: "Country", require: true },
  { id: "email", label: "Email", require: true },
];

interface Props {
  product: TProduct;
  sampleDesignData: {
    designId: string,
    image: string,
    galleries: any[],
    style: string,
  };
}

const BuyNowModal = (props: Props, ref) => {
  const { navigation } = useNavFunc();
  const { balance, refreshBalance } = usePaymentStore();
  const [show, setShow] = useState(false);
  const [isOrdering, setIsOrdering] = useState(false);
  const UserStore = Store.useUserStore();
  const { user } = UserStore;
  const [draftOrder, setDraftOrder] = useState<any>();
  const [formErrors, setFormErrors] = useState({});
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [quantity, setQuantity] = useState(1);
  const [customAddress, setCustomAddress] = useState({
    first_name: user?.firstName,
    address1: user?.addressLine1,
    county: "",
    country: user?.country,
    town: user?.town,
    zip: user?.postCode,
    last_name: user?.lastName,
    address2: user?.addressLine2,
    email: user?.email,
    phone: '',
  })

  useImperativeHandle(ref, () => ({
    show: () => {
      refreshBalance();
      setShow(true);
    },
  }));

  const preCheckBalance = async () => {
    const res = await Api.Payment.getChargeAmountWithoutInvoice({
      address: customAddress,
      products: [{
        productId: props.product?.id,
        designId: props.sampleDesignData?.designId,
      }]
    })
    if (res.data.data) {
      const { amount } = res.data.data;
      if (amount * 100 <= (balance?.balance * -1)) {
        return true;
      }
      modalConfirm({
        title: "Insufficient balance",
        content: `Please add more funds to request this order (£${ValHelper.formatMoney(amount)})`,
        onOk: () => {
          setShow(false);
          navigation.navigate(SCREEN.Settings, { tabId: 'billing' });
        },
        onCancel: () => { },
      })
    }
  }

  const validateFields = () => {
    const requireFields = FIELDS.filter(i => i.require).map(i => i.id);
    const errors = {};
    requireFields.map(key => {
      if (!customAddress[key]) {
        errors[key] = "This field is required";
      }
    })
    if (customAddress.phone) {
      if (!isValidPhone) errors["phone"] = "Invalid phone number";
    }
    if (customAddress.email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(customAddress.email)) errors["email"] = "Invalid email";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  }

  const onSubmit = async () => {
    const isValid = validateFields();
    if (!isValid) return;
    setIsOrdering(true);
    try {
      const enoughBalance = await preCheckBalance();
      if (!enoughBalance) return;

      const res = await Api.Order.createDraftInvoice({
        productId: props.product?.id,
        customAddress,
        designId: props.sampleDesignData.designId,
        style: props.sampleDesignData.style,
        quantity,
        type: 'Wholesale'
      })
      if (res.data.success) {
        await new Promise(resolve => setTimeout(resolve, 1000));
        setDraftOrder(res?.data?.data as any);
      } else {
        showPopupMessage({
          title: '',
          content: String(res.data.error),
          buttonOkText: 'OK',
          typeHighlight: 'danger',
          contentHighlight: 'Error'
        });
        // alert(res.data.error);
      }
    } catch (err) {
      showPopupMessage({
        title: '',
        content: String(err),
        buttonOkText: 'OK',
        typeHighlight: 'danger',
        contentHighlight: 'Error'

      });
      // alert(String(err));
    } finally {
      setIsOrdering(false);
    }
  }

  const okText = "Buy now";

  const countryOpts = useMemo(() => {
    return COUNTRIES.map(i => ({
      label: i.name,
      value: i.name,
    }))
  }, []);

  const discountedPrice = ValHelper.calculateDiscountedPrice({
    price: props.product?.price,
    quantity,
    discountType: props.product?.data?.discountType,
  }, props.product?.packPrices);

  console.log("draftOrderdraftOrder", draftOrder)
  return (
    <Modal
      open={show}
      title={""}
      onOk={onSubmit}
      onCancel={() => setShow(false)}
      okText={isOrdering ? "Ordering..." : okText}
      closeIcon={false}
      className={!!draftOrder ? "modalStyle" : ""}
      footer={!!draftOrder ? false : undefined}
    >
      {!!draftOrder ? (
        <PayBuyNowOrder
          stripeInvoice={draftOrder?.data?.invoice}
          draftInvoiceId={draftOrder?.id}
          onClose={() => {
            setDraftOrder(null);
            setQuantity(1);
            setShow(false);
          }}
        />
      ) : (
        <Col>
          {props.sampleDesignData?.image && (
            <Row>
              <Col>
                <img src={props.sampleDesignData.image} alt="Sample Design" style={{ width: 200, height: 200 }} />
              </Col>
              <Col flex1 ml1>
                <Text bold mb1 fontSize={24}>{props.product?.name}</Text>
                <Text caption>{props.sampleDesignData.style}</Text>
              </Col>
            </Row>
          )}
          <Row mb2 alignItems="flex-start">
            <InputNumberField
              flex1
              title="Quantity"
              value={quantity}
              onChangeText={setQuantity}
              inputProps={{ defaultValue: 1, min: 1 }}
            />
            <Col flex1>
              <Row marginBottom={8}>
                <Text fontWeight="500" fontSize={14} marginRight={4}>Total price</Text>
                <Popover
                  content={(
                    <Col padding={4}>
                      <Row marginBottom={8}>
                        <Col width={80}>
                          <Text>Min Qty</Text>
                        </Col>
                        {props.product?.data?.discountType === 'price' ? (
                          <Col width={80}>
                            <Text>Unit Price</Text>
                          </Col>
                        ) : (
                          <Col width={80}>
                            <Text>Discount</Text>
                          </Col>
                        )}
                      </Row>
                      {props.product?.packPrices?.map((i, idx) => (
                        <Row key={idx} marginBottom={4}>
                          <Col width={80}>
                            <Text fontWeight="500">{`>=`}{i.size}</Text>
                          </Col>
                          <Col width={120}>
                            <Text fontWeight="500">£{ValHelper.formatMoney(
                              ValHelper.calculateDiscountedPrice({
                                price: props.product?.price,
                                quantity: i.size,
                                discountType: props.product?.data?.discountType,
                              }, props.product.packPrices)
                            )}</Text>
                          </Col>
                        </Row>
                      ))}
                    </Col>
                  )}
                  trigger="hover"
                >
                  <BgIcon name='info' size={14} />
                </Popover>
              </Row>
              <Row>
                <Text fontSize={18} bold marginRight={4}>
                  £{ValHelper.formatMoney(discountedPrice * quantity)}
                </Text>
                <Text caption fontSize={13}> -£{ValHelper.formatMoney((Math.abs(props.product?.price - discountedPrice)) * quantity)} ({Math.round((discountedPrice - props.product?.price) / props.product?.price * 100)}%)</Text>
              </Row>
            </Col>
          </Row>
          <Col>
            <Text mb2 subtitle1>Please enter Shipping address</Text>
            {FIELDS.map(field => {
              if (field.id === "country") {
                return (
                  <Col marginBottom={8}>
                    <SelectField
                      title="Country"
                      value={customAddress.country}
                      options={countryOpts}
                      selectProps={{ showSearch: true }}
                      onChange={(value: string) => {
                        setCustomAddress(s => ({
                          ...s,
                          [field.id]: value
                        }));
                        setFormErrors(s => ({
                          ...s,
                          [field.id]: null,
                        }))
                      }}
                    />
                  </Col>
                )
              }
              return (
                <Col key={field.id} marginBottom={8}>
                  <InputField
                    title={field.label}
                    value={customAddress[field.id] || ""}
                    onChangeText={(val) => {
                      setCustomAddress(s => ({
                        ...s,
                        [field.id]: val
                      }));
                      setFormErrors(s => ({
                        ...s,
                        [field.id]: null,
                      }))
                    }}
                    isRequired={field.require}
                    error={formErrors[field.id]}
                    inputProps={{
                      placeholder: field.placeholder || field.label,
                    }}
                  />
                </Col>
              )
            })}
            <PhoneField
              size="large"
              title="Phone number"
              value={customAddress.phone}
              error={formErrors["phone"]}
              onValidPhone={setIsValidPhone}
              onChangeText={(val) => {
                setCustomAddress(s => ({
                  ...s,
                  phone: val
                }));
                setFormErrors(s => ({
                  ...s,
                  phone: null,
                }))
              }}
            />
          </Col>
        </Col>
      )}
    </Modal>
  );
};

export default forwardRef(BuyNowModal);
