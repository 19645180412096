import React from 'react';
import { ProgressBar, Intent, OverlayToaster, Position, ToastProps } from '@blueprintjs/core';

export const AppToaster = OverlayToaster.create({
  position: Position.TOP,
  usePortal: true,
});

class ToastHelper {
  currentToast;

  showProgress = (progress) => {
    this.currentToast = AppToaster.show({
      icon: "download",
      message: (
        <ProgressBar
          intent={progress < 1 ? Intent.PRIMARY : Intent.SUCCESS}
          value={progress}
        />
      ),
      onDismiss: () => {
        this.currentToast = undefined;
      },
      timeout: progress < 1 ? 0 : 2000,
    }, this.currentToast);
  }

  show = (props: ToastProps) => {
    AppToaster.show(props);
  }
}

export default new ToastHelper;
