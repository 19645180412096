import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import axios from 'axios';
import Store from 'store';
import { showPopupMessage } from 'components';


const moment = require('moment')

export const downloadPDFsAsZip = async (urls, updateProgress) => {
  if (!urls || !urls.length) return;
  const totalProgress = urls.length * 100;
  let progressByIdx = {};

  const intervalUpdateProgress = setInterval(() => {
    const totalCurrentProgress = Object.keys(progressByIdx).reduce((total, idx) => {
      return total + Number(progressByIdx[idx] || 0);
    }, 0);
    updateProgress(totalCurrentProgress / totalProgress);
  }, 60);

  const zip = new JSZip();

  await Promise.all(
    urls.map(async (url, idx) => {
      try {
        const response = await axios.get(url, {
          responseType: 'blob',
          onDownloadProgress: (progressEvent) => {
            const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            progressByIdx[idx] = progress;
          },
        });
        const urlParts = url.split('/');
        const fileName = urlParts[urlParts.length - 1];
        zip.file(fileName, response.data);
      } catch (error) {
        console.error(`Error downloading from ${url}: ${error}`);
      }
    })
  );

  zip.generateAsync({ type: 'blob' }).then((content) => {
    updateProgress(1);
    clearInterval(intervalUpdateProgress);
    saveAs(content, `${new Date().getTime()}.zip`);
  });
}

export const downloadArtworkAndPackingSlip = async ({ artworks = [], slips = [], candles = [] }, updateProgress) => {
  const zip = new JSZip();
  const folderArtwork = zip.folder("artworks");
  const folderCandle = zip.folder("candles");
  const folderSlip = zip.folder("packing_slips");

  console.log('artworks', artworks);

  // artworks is array of { url, name, quantity }
  // if name is the same, check the url if same then combine the quantity, else change name
  const mergeArtworks = (() => {
    const map = {};
    artworks.forEach(({ name, url, quantity, lineId, ...rest }) => {
      if (map[name]) {
        if (map[name].url === url) {
          const oldNameMatch = `-${map[url].quantity}.pdf`;
          map[url].quantity += quantity;
          map[url].name = map[url].name.replace(oldNameMatch, `-${map[url].quantity}.pdf`);
        } else {
          // edit the existing
          const newName = `${map[name].lineId}-${name}`
          map[newName] = {
            ...map[name],
            name: newName,
          };
          delete map[name];

          // add new
          map[`${lineId}-${name}`] = { name: `${lineId}-${name}`, url, quantity, lineId, ...rest };
        }
      } else {
        map[name] = { name, url, quantity, lineId, ...rest };
      }
    });
    return Object.values(map);
  })();

  const totalProgress = (mergeArtworks.length + slips.length + candles.length) * 100;
  let progressByIdx = {};

  const intervalUpdateProgress = setInterval(() => {
    const totalCurrentProgress = Object.keys(progressByIdx).reduce((total, idx) => {
      return total + Number(progressByIdx[idx] || 0);
    }, 0);
    updateProgress(totalCurrentProgress / totalProgress);
  }, 60);

  let tasks = mergeArtworks.map(async ({ url, name }, idx) => {
    try {
      const response = await axios.get(url, {
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          progressByIdx[idx] = progress;
        },
      });
      folderArtwork.file(name, response.data);
    } catch (error) {
      console.error(`Error downloading from ${url}: ${error}`);
    }
  });

  tasks = tasks.concat(slips.map(async ({ url, name, pdfUrl }, i) => {
    try {
      const idx = i + mergeArtworks.length;
      if (pdfUrl) {
        const response = await axios.get(pdfUrl, {
          responseType: 'blob',
          onDownloadProgress: (progressEvent) => {
            const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            progressByIdx[idx] = progress;
          },
        });
        folderSlip.file(name, response.data);
        return;
      }
      const res = await axios.get(url);
      const response = await axios.request({
        url: 'https://puppeteer.personify.tech/api/html2pdf',
        headers: { 'Content-Type': 'application/json' },
        method: 'post',
        data: JSON.stringify({
          html: res.data,
          viewport: { width: 595, height: 842 },
        }),
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          progressByIdx[idx] = progress;
        },
      });
      folderSlip.file(name, response.data);
    } catch (error) {
      console.error(`Error downloading from ${url}: ${error}`);
    }
  })).concat(candles.map(async ({ url, name }, i) => {
    const idx = i + mergeArtworks.length + slips.length;
    const response = await axios.get(url, {
      responseType: 'blob',
      onDownloadProgress: (progressEvent) => {
        const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
        progressByIdx[idx] = progress;
      },
    });
    folderCandle.file(name, response.data);
  }));

  await Promise.all(tasks);

  zip.generateAsync({ type: 'blob' }).then((content) => {
    updateProgress(1);
    clearInterval(intervalUpdateProgress);
    saveAs(content, `${moment().format('DD.MM.YYYY')}_Orders.zip`);
  }).catch(e => {
    console.log("download err", e);
    // alert(e?.message || JSON.stringify(e));
    showPopupMessage({
      title: '',
      content: String(e?.message || JSON.stringify(e)),
      buttonOkText: 'OK',
      // 
      typeHighlight: 'danger',
      contentHighlight: 'Error'

    });
  });
}
