import React from 'react';
import { Col, Row, Text, CMSLayout, useUIState, ShimmerLoading, TouchField, Button, PrintJobItem, SwapIconEffect, Grid, RangeDatePicker } from 'components';
import { IScreen } from 'type';
import ListOrder from './ListOrders';
import Store from 'store';

const ListOrdersForReseller: IScreen = () => {
  const UserStore = Store.useUserStore();
  const { user } = UserStore;
  console.log('user', user);
  const resellerId = UserStore.getResellerId(user);
  console.log('resellerId', resellerId);
  if (!resellerId) return null;
  return (
    <ListOrder
      resellerId={resellerId}
      breadcrumbs={[
        {  title: 'My Orders' },
      ]}
    />
  );
};

ListOrdersForReseller.routeInfo = {
  title: 'My Orders - Bottled Goose',
  path: '/my-list-orders/:stage',
};

export default ListOrdersForReseller;
