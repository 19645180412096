import Request from '../Request.utils'
export interface IAddLogRequestBody {
  data?: any;
  newData?: any;
  oldData?: any;
  type?: string;
}
export interface IListOrderLogRequestQuery {
  page?: number;
  search?: string;
}


class LogAPI {
  addLog = async (body: IAddLogRequestBody) => {
    const res = await Request.call('/api/log', 'POST', undefined, undefined, body, );
    return res;
  }
  listOrderLog = async (query: IListOrderLogRequestQuery) => {
    const res = await Request.call('/api/orders-log', 'GET', undefined, query, undefined, );
    return res;
  }
}
export default new LogAPI()