import { Col, IColProps, Row, Text } from "components/base";
import React, { ReactElement } from "react";
import BgIcon from "../BgIcon";
import { Select, SelectProps } from "antd";
import { COLORS } from "const";

interface Props extends IColProps {
  title?: string,
  icon?: string,
  value?: string | string[],
  isMultiple?: boolean,
  onChange?: (value: string | string[], opts: any[]) => void,
  selectProps?: SelectProps,
  right?: ReactElement,
  error?: string,
  isRequired?: boolean,
  options?: { value: string, label: string }[],
}

const SelectField = (props: Props) => {
  const { title, icon, value, onChange, selectProps, isMultiple, right, options, isRequired, error, ...restProps } = props;
  return (
    <Col {...restProps}>
      {!!title && (
        <Row marginBottom={8} alignItems="center">
          <Text fontWeight="500" fontSize={14}>{title}</Text>
          {!!icon && (
            <Col marginLeft={8}>
              <BgIcon name={icon} size={14} />
            </Col>
          )}
          {isRequired && (
            <Text color={COLORS.RED} fontSize={18} marginLeft={2}>*</Text>
          )}
        </Row>
      )}
      <Row>
        <Select
          size="large"
          mode={isMultiple ? "multiple" : undefined}
          value={value}
          style={{ flex: 1 }}
          onChange={onChange}
          options={options || []}
          status={!!error ? "error" : undefined}
          {...selectProps}
        />
        {right}
      </Row>
      {!!error && (
        <Text fontSize={12} marginTop={2} color={COLORS.RED}>{error}</Text>
      )}
    </Col>
  )
}

export default SelectField;
