import React, { useContext, useEffect, useRef, useState } from "react";
import { createContext, memo, useMemo } from "react";
import { useUserStore } from "store/User.Store";

interface SettingsFormValues {
  profile: {
    firstName?: string,
    lastName?: string,
    
    // password?: string,
  },
  packingSlip: {
    companyName?: string,
    phone?: string,
    email?: string,
    address?: string,
    companyLogo?: string,
  },
  billingInformation: {
    firstName?: string,
    lastName?: string,
    cardNumber?: string,
    cardHolder?: string,
    cvc?: string,
    expiredDate?: string,
    addressLine1?: string,
    addressLine2?: string,
    city?: string,
    postcode?: string,
    currentBalance?: number
    country?: string,
    currency?: string,
    accountName?: string,
  },
  showUpsertUserUI?: boolean,
  setShowUpsertUserUI?: (value: boolean) => void,
  updateProfile?: (data: any, isInit?: boolean) => void,
  updatePackingSlip?: (data: any, isInit?: boolean) => void,
  updateBillingInformation?: (data: any, isInit?: boolean) => void,
  checkIsChanged?: (type?: "packing" | "profile" | "billing") => boolean,
  onSaved?: (type?: "packing" | "profile" | "billing") => void,
}

export const SettingsFormContext = createContext<SettingsFormValues>({
  profile: {
    
  },
  packingSlip: {
  },
  billingInformation: {
    currentBalance: 0,
    currency: '',
  },
});

export const SettingsFormProvider = memo((props: any) => {
  const { children } = props;
  const { user } = useUserStore();
  const profileChanged = useRef(false);
  const packingSlipChanged = useRef(false);
  const billingChanged = useRef(false);
  const [profile, setProfile] = useState({});
  const [packingSlip, setPackingSlip] = useState({});
  const [billingInformation, setBillingInformation] = useState({});
  const [showUpsertUserUI, setShowUpsertUserUI] = useState(false);

  useEffect(() => {
    if (user) {
      setProfile(s => ({
        ...s,
        firstName: user.firstName,
        lastName: user.lastName,
      }))
      profileChanged.current = false;
    }
  }, [user]);

  const updateProfile = (data: any, isInit?: boolean) => {
    if (!isInit) profileChanged.current = true;
    setProfile(data);
  }

  const updateBillingInformation = (data: any, isInit?: boolean) => {
    if (!isInit) billingChanged.current = true;
    setBillingInformation(data);
  }

  const updatePackingSlip = (data: any, isInit?: boolean) => {
    if (!isInit) packingSlipChanged.current = true;
    setPackingSlip(data);
  }

  const checkIsChanged = (type?: string) => {
    if (type === "packing") return packingSlipChanged.current;
    if (type === "profile") return profileChanged.current;
    if (type === "billing") return billingChanged.current;
    return packingSlipChanged.current || profileChanged.current || billingChanged.current;
  }

  const onSaved = (type: string) => {
    if (type === "packing") return packingSlipChanged.current = false;
    if (type === "profile") return profileChanged.current = false;
    if (type === "billing") return billingChanged.current = false;
  }

  const contextValue = useMemo(() => ({
    profile,
    updateProfile,
    packingSlip,
    updatePackingSlip,
    billingInformation,
    updateBillingInformation,
    showUpsertUserUI,
    setShowUpsertUserUI,
    checkIsChanged,
    onSaved,
  }), [
    profile,
    packingSlip,
    billingInformation,
    showUpsertUserUI,
  ]);

  return (
    <SettingsFormContext.Provider value={contextValue}>
      {children}
    </SettingsFormContext.Provider>
  );
});

export const useSettingsForm = () => {
  return useContext(SettingsFormContext);
}
