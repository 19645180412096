import React, { ReactElement, useMemo, useState } from 'react';
import { Row, Col, BgIcon, useMobileScreen } from 'components';
import { COLORS } from 'const';
import { Input, Tabs, Badge } from 'antd';
import { ORDER_STAGE, useOrderStore } from 'store/Order.Store';
import { ValHelper } from 'helpers';
import { useUserStore } from 'store/User.Store';

export const TABS = [
  { title: 'Pending Jobs', key: 'Pending', tooltip: 'These jobs have come through and need your attention' },
  { title: 'In Production Jobs', key: 'Accepted', tooltip: 'These jobs have been accepted and are being printed' },
  { title: 'Fulfilled Jobs', key: 'Fulfilled', tooltip: 'These jobs have all been dispatched' },
  { title: 'Rejected Jobs', key: 'Rejected', tooltip: 'If you job falls outside of the bottled goose T&C, BG will reject the job' },
];

export const useOrderTabs = () => {
  const [tab, setTab] = useState<'Pending' | 'Accepted' | 'Fulfilled'>('Pending');
  return [tab, setTab];
}

export const ListOrdersTabs = ({ activeTab, onChangeTab, searchPlaceholder, onSearchInput, renderAction, ...props }) => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const activeKey = ValHelper.toDisplayStage(activeTab);
  const UserStore = useUserStore();
  const OrderStore = useOrderStore();
  const isMobile = useMobileScreen();
  const unpaidOrder = OrderStore.reportData?.unpaidOrderThisYear;

  const items = useMemo(() => {
    const arr: { key: string, label: string | ReactElement }[] = [{ key: "All", label: "All" }];
    ORDER_STAGE.forEach(i => {
      let badge = 0;
      if (i === "Pre Production") {
        badge = unpaidOrder;
      }
      arr.push({
        key: i,
        label: badge ? (
          <Badge offset={[0, -5]} count={badge}>{i}</Badge>
        ) : i,
      })
    })
    if (UserStore?.user?.role === "admin") {
      arr.push({ key: "Recycling Bin", label: "Recycling Bin" });
    }
    return arr;
  }, [UserStore, unpaidOrder]);

  if (isMobile) {
    return (
      <Col marginHorizontal={-4} paddingBottom={4} {...props}>
        <Tabs
          defaultActiveKey={items[1].key}
          activeKey={activeKey}
          items={items}
          onChange={onChangeTab}
          tabBarGutter={16}
          style={{ marginBottom: -4 }}
        />
        <Input
          value={searchKeyword}
          size="large"
          placeholder={searchPlaceholder}
          prefix={(
            <Col marginBottom={-4}>
              <BgIcon name="search" size={18} color={COLORS.ICON_MUTED} />
            </Col>
          )}
          onChange={(e) => setSearchKeyword(e.target.value)}
          onPressEnter={() => {
            onSearchInput(searchKeyword);
          }}
        />
        {renderAction}
      </Col>
    )
  }
  return (
    <Row flexWrap="wrap" alignItems="center" justifyContent="space-between" mr1 {...props}>
      <Tabs
        defaultActiveKey={items[1].key}
        activeKey={activeKey}
        items={items}
        onChange={onChangeTab}
      />
      {renderAction}
      <Col width={282} marginBottom={8}>
        <Input
          value={searchKeyword}
          size="middle"
          placeholder={searchPlaceholder}
          prefix={(
            <Col marginBottom={-4}>
              <BgIcon name="search" size={18} color={COLORS.ICON_MUTED} />
            </Col>
          )}
          onChange={(e) => setSearchKeyword(e.target.value)}
          onPressEnter={() => {
            onSearchInput(searchKeyword);
          }}
        />
      </Col>
    </Row>
  )
};
