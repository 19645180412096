import {
  Col,
  Row,
  Text,
  CMSLayout,
  useUIState,
  ShimmerLoading,
  Button,
  showPopupMessage,
} from "components";
import React, { useState, useEffect } from "react";
import { IScreen } from "type";
import Store from "store";
import { useNavFunc } from "navigation";
import { COLOR } from "const";
import { ActivityIndicator } from "react-native";
import { TimeHelper, ValHelper, modalConfirm } from "helpers";
import { AntDesign } from '@expo/vector-icons';
import { notification } from "antd";

const ListWithdrawRequest: IScreen = () => {
  const UserStore = Store.useUserStore();
  const [list, setList] = useState([]);
  const [listLoading, setListLoading] = useState<any>({});
  const [{ fetching, errorMes, loading: btnLoading }, setUI] = useUIState({
    fetching: false,
  });

  const getData = async (p) => {
    try {
      setUI({ fetching: true, errorMes: "" });
      await TimeHelper.wait(300);
      const res = await Store.Api.Data.list({
        type: 'request-withdraw',
      }, {
        userId: 'all',
        orderBy: "createdAt",
      });
      if (res.data.success) {
        setList(res.data.data);
      }
      setUI({ fetching: false, errorMes: "" });
    } catch (err) {
      setUI({ fetching: true, errorMes: String(err) });
    }
  };

  useEffect(() => {
    getData(1);
  }, []);

  const resolveRequest = (id, index, isApprove) => {
    modalConfirm({
      title: 'Refund',
      content: `Are you sure you want to ${isApprove ? "approve" : "reject"} this request?`,
      onOk: () => onConfirmRequest(id, index, isApprove),
    })
  }

  const onConfirmRequest = async (id, index, isApprove) => {
    if (UserStore.user?.role !== 'admin') return showPopupMessage({
      title: '',
      content: 'Permission denied',
      buttonOkText: 'OK',
      
      typeHighlight: 'danger',
      contentHighlight: 'Error'

    });
    //  alert('Permission denied');
    if (listLoading[index]?.loading) return;
    setListLoading(s => ({
      ...s,
      [index]: { loading: true, success: false },
    }));
    try {
      let res;
      if (!isApprove) {
        res = await Store.Api.Data.upsert({
          type: 'request-withdraw',
        }, {
          id,
          field2: "Rejected",
        });
      } else {
        res = await Store.Api.Payment.approveRefund({
          id,
        });
      }
      if (res.data.success) {
        setListLoading({
          ...listLoading,
          [index]: { loading: false, success: true },
        });
      } else if (res.data.error) {
        setListLoading({
          ...listLoading,
          [index]: { loading: false, success: false },
        });
      }
    } catch (err) {
      notification.error({ message: err.message || JSON.stringify(err) })
      setListLoading({
        ...listLoading,
        [index]: { loading: false, success: false },
      });
    } finally {
      getData(1);
    }
  }

  const renderList = () => {
    if (UserStore.user?.role !== 'admin') {
      return (
        <Col flex1>
          <Text>Permission denied</Text>
        </Col>
      )
    }
    if (list.length === 0) {
      return (
        <Col flex1>
          <Text>No request found</Text>
        </Col>
      );
    }
    return (
      <Col flex1>
        <Row
          height={50}
          stretch
          borderBottomColor={COLOR.GREY_BG}
          borderBottomWidth={1}
        >
          <Col flex1 m1>
            <Text color={COLOR.GREY}>Client</Text>
          </Col>
          <Col flex1 m1>
            <Text color={COLOR.GREY}>Withdraw Amount</Text>
          </Col>
          <Col flex1 m1>
            <Text color={COLOR.GREY}>Created At</Text>
          </Col>
          <Col flex1 m1>Status</Col>
        </Row>
        <Col>
          {list.map((val, i) => {
            return (
              <Row
                height={50}
                stretch
                borderBottomColor={COLOR.GREY_BG}
                borderBottomWidth={1}
                key={val.id}
              >
                <Col flex1 m1 justifyContent={"center"}>
                  <Text>{val.name}</Text>
                </Col>
                <Col flex1 m1 justifyContent={"center"}>
                  <Text>{ValHelper.formatMoney(val.field1)} {val.data?.currency || 'GBP'}</Text>
                </Col>
                <Col flex1 m1 justifyContent={"center"}>
                  <Text>{TimeHelper.formatDay(val.createdAt)}</Text>
                </Col>
                <Col flex1 m1 justifyContent={"center"}>
                  {["Approved", "Rejected"].includes(val.field2) ?
                    <Text color={val.field2 === "Approved" ? COLOR.GREEN : COLOR.RED}>
                      {val.field2}
                    </Text>
                    :
                    (listLoading[i]?.loading ?
                      <ActivityIndicator color={COLOR.MAIN} size='small' />
                      :
                      <Row>
                        <Button
                          mr1
                          text='Reject'
                          outline
                          height={36}
                          borderRadius={18}
                          width={80}
                          onPress={() => resolveRequest(val.id, i, false)}
                        />
                        <Button
                          text='Approve'
                          solid
                          height={36}
                          borderRadius={18}
                          width={80}
                          onPress={() => resolveRequest(val.id, i, true)}
                        />
                      </Row>
                    )
                  }
                </Col>
              </Row>
            );
          })}
        </Col>
      </Col>
    );
  };

  return (
    <CMSLayout requireAuthen>
      <Row m2 marginBottom={0} justifyContent={"space-between"}>
        <Text h3>List withdrawal request</Text>
      </Row>
      <Col flex1 m2 mv1 p2 round1 bgWhite>
        {errorMes ? (
          <Col flex1 middle>
            <Text color="red" subtitle1>
              {errorMes}
            </Text>
          </Col>
        ) : fetching ? (
          <Col>
            <Row height={50} stretch mt2>
              <ShimmerLoading round1 flex={1} m1 />
              <ShimmerLoading round1 flex={1} m1 />
              <ShimmerLoading round1 flex={1} m1 />
              <ShimmerLoading round1 flex={1} m1 />
            </Row>
            <Row height={50} stretch mt2>
              <ShimmerLoading round1 flex={1} m1 />
              <ShimmerLoading round1 flex={1} m1 />
              <ShimmerLoading round1 flex={1} m1 />
              <ShimmerLoading round1 flex={1} m1 />
            </Row>
          </Col>
        ) : (
          renderList()
        )}
      </Col>
    </CMSLayout>
  );
};

ListWithdrawRequest.routeInfo = {
  title: "List Withdraw Fund request - Bottled Goose",
  path: "/products/list-withdraw-request",
};

export default ListWithdrawRequest;
