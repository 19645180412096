import { modalConfirm } from "helpers";
import { IModalData, useModalConfirm } from "navigation/ModalConfirmContext";
import { TCMSOrder, TCMSPipeline } from "type";

export const getPDFDataFromPipelines = (pipelines: Array<TCMSPipeline>) => {
  const pdfStages = (() => {
    return pipelines.map(v => {
      const p = v;
      const stages = v.Stages;
      const jobs = v.Jobs;
      const listStages = Object.keys(stages).map(key => ({ ...stages[key], StageId: key }));
      const listJobs = Object.keys(jobs).map(key => ({ ...jobs[key], jobId: key }));
      const findStage = listStages.find(v => v.Jobs?.includes('Generate PDF'));
      // @ts-ignore
      const listJobsOfStages = !findStage ? [] : listJobs.filter(v => v.StageId === findStage.StageId && v.Title === 'Generate PDF');
      // console.log('listJobsOfStages', listJobsOfStages);
      const listStatus = listJobsOfStages.map(v => v.Status);
      const findCandleStage = listStages.find(v => v.Jobs?.includes('Generate Candle Sticker'));
      const listCandleJobsOfStages = !findCandleStage ? [] : listJobs.filter(v => v.StageId === findCandleStage.StageId && v.Title === 'Generate Candle Sticker');

      return {
        hasPDFStage: listJobsOfStages.length > 0,
        hasCandleStage: listCandleJobsOfStages.length > 0,
        status: listStatus,
        isSuccess: listStatus?.includes('Success'),
        lastStatus: listStatus[listStatus.length - 1],
        lastJob: listJobsOfStages[listJobsOfStages.length - 1],
        lastCandleJob: listCandleJobsOfStages[listCandleJobsOfStages.length - 1],
        startTime: v.StartTime,
        endTime: v.EndTime,
        pipelineId: v.Id,
        deploymentAddress: v.DeploymentAddress,
        packingSlipUrl: v.SharedData?.packingSlipUrl,
        candleStickers: (v.SharedData.canBeProcessedItems || []).map(v => !v.candleSticker ? null : ({
          lineId: v.id,
          name: v.name,
          candleSticker: v.candleSticker,
          orderId: p.OrderId,
          product_id: v.product_id,
          quantity: v.quantity,
        })).filter(Boolean),
        pdfs: (v.SharedData.canBeProcessedItems || []).map(v => !v.pdf ? null : ({
          lineId: v.id,
          name: v.name,
          pdf: v.pdf,
          orderId: p.OrderId,
          product_id: v.product_id,
          quantity: v.quantity,
          designId: (v.properties || []).find(v => v.name === 'BG Product Number')?.value,
        })).filter(Boolean),
      }
    })
  })();
  const displayValue = (() => {
    if (pdfStages.length === 0) return { status: 'Not started', pdfs: [] };
    const last = pdfStages[pdfStages.length - 1];
    const status = last.lastStatus;
    const pdfs = last.pdfs;
    const hasPDFStage = last.hasPDFStage;
    const isStuck = last.startTime && !last.endTime && (new Date().getTime() - Number(last.startTime) > 10 * 60 * 1000);
    const isStuckCandle = last.lastCandleJob?.Status === 'Pending' && isStuck;

    return {
      hasPDFStage,
      status,
      pdfs,
      isStuck,
      isStuckCandle,
      log: last?.lastJob?.Log,
      logCandle: last?.lastCandleJob?.Log,
      candleStickers: last.candleStickers,
      packingSlipUrl: last.packingSlipUrl,
    }
  })();
  return { pdfStages, displayValue };
}

export const useConfirmCancelOrder = () => {
  const { show: showConfirm } = useModalConfirm();

  const show = ({
    message,
    orders,
  }) => new Promise<{ confirmed?: boolean, data?: any }>((resolve) => {
    showConfirm({
      title: '',
      content: message,
      typeHighlight: 'danger',
      contentHighlight: 'Warning',
      type: orders.some(order => !order['OrderType'] || order['OrderType'] === 'Shopify') ? 'cancel-order' : 'normal',
      onButtonOkClick: (data: any) => resolve({ confirmed: true, data }),
      onClose: () => resolve({ confirmed: false }),
    })
  });

  return {
    show
  }
}
