import { Col, Row, Table, Text, useMobileScreen } from 'components';
import React from 'react';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { TInvoice } from 'type';
import { ValHelper } from 'helpers';
import { useNavFunc } from 'navigation';
import { SCREEN } from 'const';
import { OrderLineItemsRaw } from 'screens/list-orders/ListOrders.Comps';

const ReportOrders = ({ data, isLoading }) => {
  const { navigate } = useNavFunc();
  const isMobile = useMobileScreen();

  const columns: ColumnsType<TInvoice> = [
    {
      title: 'Job Number',
      key: 'jobNumber',
      render: (order: TInvoice) => (
        <Col onPress={() => {
          console.log('order', order);
          navigate(SCREEN.OrderDetail, { orderId: order.orderId });
        }}>
          <Text textDecorationLine={'underline'}>#{order.orderNumber}</Text>
        </Col>
      )
    },
    {
      title: 'Store / Brand',
      key: 'productName',
      render: (order: TInvoice) => (
        <Text>{order.store}</Text>
      )
    },
    {
      title: 'Product SKU',
      key: 'productSKU',
      render: (order: TInvoice) => {
        const str = order.lineItems?.map(i => i.name).join('\n\n');
        return (
          <Text>{str}</Text>
        )
      }
    },
    {
      title: 'Quantity',
      key: 'quantity',
      render: (order: TInvoice) => {
        const str = order.lineItems?.map(i => i.quantity).join('\n\n');
        return (
          <Text>{str}</Text>
        )
      }
    },
    {
      title: 'Our price ex vat',
      key: 'priceExVAT',
      render: (order: TInvoice) => {
        const str = order.lineItems?.map(i => {
          const printJobID = i.properties.find(i => i.name === "Print Job")?.value;
          return printJobID;
        }).filter(Boolean)
          .map(i => `£${ValHelper.formatMoney(order.prices?.[i])}`)
          .join('\n\n');
        return (
          <Text>{str}</Text>
        )
      }
    },
    {
      title: 'Postage cost',
      key: 'postageCost',
      render: (order: TInvoice) => (
        <Text>{order.data?.shippingFee ? `£${ValHelper.formatMoney(order.data?.shippingFee)}` : ''}</Text>
      )
    },
    {
      title: 'VAT',
      key: 'vat',
      render: (order: TInvoice) => (
        <Text>£{ValHelper.formatMoney(order.taxes)}</Text>
      )
    },
    {
      title: 'Total',
      key: 'total',
      render: (order: TInvoice) => (
        <Text>£{ValHelper.formatMoney(order.total)}</Text>
      )
    },
    {
      title: 'Paid',
      key: 'paid',
      width: 130,
      render: (order: TInvoice) => (
        <Text>{order.paidAt ? moment(order.paidAt).format("DD-MM-YYYY HH:mm") : ""}</Text>
      )
    },
    {
      title: 'Fulfilled',
      key: 'forfilled',
      width: 130,
      render: (order: TInvoice) => (
        <Text>{order.fulfilledAt ? moment(order.fulfilledAt).format("DD-MM-YYYY HH:mm") : ""}</Text>
      )
    },
    {
      title: 'Customer Name',
      key: 'customerName',
      render: (order: TInvoice) => (
        <Text>{[order.customerInfo?.first_name, order.customerInfo?.last_name].filter(Boolean).join(" ")}</Text>
      )
    },
    {
      title: 'Customer Email',
      key: 'customerEmail',
      render: (order: TInvoice) => (
        <Text>{order.customerInfo?.email}</Text>
      )
    },
  ];

  if (isMobile) {
    return (
      <Col paddingHorizontal={8} marginTop={8}>
        {data.map((order: TInvoice, idx) => {
          return (
            <Col bgWhite borderRadius={8} padding={10} marginBottom={10} key={`order-${idx}`}>
              <Col flex1>
                <Row>
                  <Text>#{order.orderNumber}</Text>
                </Row>
                <Col height={24} marginTop={2}>
                  <OrderLineItemsRaw items={order.lineItems} />
                </Col>
                <Text>{[order.customerInfo?.first_name, order.customerInfo?.last_name].filter(Boolean).join(" ")}</Text>
              </Col>
              <Row marginTop={12} alignItems="flex-start">
                <Row flex1 alignItems="flex-start">
                  <Text>Store / Brand</Text>
                  <Col marginLeft={10} flex1>
                    <Text>{order.store}</Text>
                  </Col>
                </Row>
                <Row flex1 alignItems="flex-start">
                  <Text>Total</Text>
                  <Col marginLeft={10} flex1>
                    <Text>£{ValHelper.formatMoney(order.total)}</Text>
                  </Col>
                </Row>
              </Row>
              <Row marginTop={10} alignItems="flex-start">
                <Row flex1 alignItems="flex-start">
                  <Text>Paid</Text>
                  <Col marginLeft={10} flex1>
                    <Text>{order.paidAt ? moment(order.paidAt).format("DD-MM-YYYY HH:mm") : ""}</Text>
                  </Col>
                </Row>
                <Row flex1 alignItems="flex-start">
                  <Text>Fulfilled</Text>
                  <Col marginLeft={10} flex1>
                    <Text>{order.fulfilledAt ? moment(order.fulfilledAt).format("DD-MM-YYYY HH:mm") : ""}</Text>
                  </Col>
                </Row>
              </Row>
            </Col>
          )
        })}
      </Col>
    )
  }

  return (
    <Table
      data={data}
      columns={columns}
      rowKey={val => `${val.id}`}
      minWidth={1200}
      loading={isLoading}
    />
  )
};

export default ReportOrders;
