import * as React from "react";
import Svg, { Path } from "react-native-svg";
const SVGComponent = (props) => (
  <Svg
    width={13}
    height={12}
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.50187 5.25201C6.09002 5.25201 5.75304 5.59039 5.75304 6.00396C5.75304 6.41753 6.09002 6.7559 6.50187 6.7559C6.91373 6.7559 7.2507 6.41753 7.2507 6.00396C7.2507 5.59039 6.91373 5.25201 6.50187 5.25201ZM12.4925 5.9814C12.4925 5.97388 12.4925 5.97388 12.4925 5.96636V5.95884C12.4925 5.95132 12.4925 5.95132 12.4925 5.9438C12.4925 5.93628 12.4925 5.93628 12.4925 5.92876C12.4775 5.82349 12.4326 5.73326 12.3652 5.65806C11.9833 5.15426 11.5265 4.71061 11.0622 4.30456C10.0663 3.42478 8.92808 2.68787 7.67005 2.39461C6.93619 2.21415 6.20983 2.20663 5.47598 2.3495C4.80952 2.48485 4.17301 2.75555 3.56646 3.09392C2.63042 3.62781 1.76927 4.35719 1.02044 5.17682C0.885647 5.33472 0.750858 5.48511 0.623557 5.65054C0.458814 5.86861 0.458814 6.13179 0.623557 6.34985C1.00546 6.85366 1.46225 7.2973 1.92652 7.70336C2.92246 8.58313 4.06069 9.32004 5.31872 9.6133C6.04509 9.78625 6.77894 9.79377 7.51279 9.64338C8.17925 9.50803 8.81576 9.23733 9.42231 8.89895C10.3583 8.36507 11.2195 7.63568 11.9683 6.81606C12.1031 6.66567 12.2454 6.50776 12.3727 6.34233C12.4401 6.26714 12.485 6.16939 12.5 6.07163C12.5 6.06411 12.5 6.06411 12.5 6.05659C12.5 6.04907 12.5 6.04907 12.5 6.04155V6.03403C12.5 6.02652 12.5 6.02652 12.5 6.019C12.5 6.01148 12.5 6.00396 12.5 5.99644C12.5 5.98892 12.4925 5.98892 12.4925 5.9814ZM6.50187 8.2598C5.25881 8.2598 4.25538 7.25219 4.25538 6.00396C4.25538 4.75573 5.25881 3.74812 6.50187 3.74812C7.74493 3.74812 8.74836 4.75573 8.74836 6.00396C8.74836 7.25219 7.74493 8.2598 6.50187 8.2598Z"
      fill="#8F95B2"
    />
  </Svg>
);
export default SVGComponent;
