import {
  ScrollView, View, TouchableOpacity, StyleSheet,
  useWindowDimensions, TextInput, Image,
} from 'react-native';
import React from 'react';
import { dataFacts } from 'const/facts';
import { BgIcon, useUIState, Col, Row, Text } from 'components';

const dataFactsImage = dataFacts.map((item, index) => {
  return {
    id: item.id,
    title: item.title,
    content: item.content,
    // image: require(`../../assets/img/facts/${item.id}`),
    src: require(`../../../assets/img/facts/${index + 1}.jpeg`),
  }
});
const getRandomFactOfTheDay = () => {
  const today = new Date().toISOString().slice(0, 10); // Get the current date in YYYY-MM-DD format
  const hash = Array.from(today).reduce((acc, char) => acc + char.charCodeAt(0), 0); // Simple hash function
  const randomIndex = hash % dataFactsImage.length; // Use the hash to get a random index

  return dataFactsImage[randomIndex];
};
const dataFactOfTheDay = getRandomFactOfTheDay();

const GeeseFactsRandom: React.FC = () => {
  const minSizeText = 800;
  const { width, height } = useWindowDimensions();  

  const maxWidth = 1440;
  const minWidth = 900;
  const isResizeLayout = width > minWidth;
  const widthImage = (width: number, minWidth: number) => {
    if (isResizeLayout) { return (width / 2) * 0.7; }
    else { return width * 0.7; }
  }
  const heightImage = widthImage(width, minWidth) * 0.64;
  const resizeFont = (width: number, minWidth: number) => {
    if (width > 1000) return 0;
    if (minWidth <= width) return 2;
    if (width < minWidth && width >= 550) return 0;
    if (width < 550) return 2;
  }
  return (
    <Col 
    middle
    paddingVertical={isResizeLayout ? 0 : 30}
    >
      <Text
        fontFamily={'Inter, sans-serif'}
        fontWeight={600}
        fontSize={width > 500 ? 32 - resizeFont(width, minWidth) : 28}
        lineHeight={40}
        marginBottom={isResizeLayout ? 25 : 10}
        color={'#101840'}
        textAlign={'center'}
      >Goose fact of the day</Text>
      <Text
        fontFamily={'Inter, sans-serif'}
        fontWeight={600}
        fontSize={25 - resizeFont(width, minWidth)}
        lineHeight={isResizeLayout ? 40 : 10}
        marginBottom={isResizeLayout ? 35 : 20}
        color={'#101840'}
      >{dataFactOfTheDay.title}</Text>
      <Col
        width={widthImage(width, minWidth)}
        height={heightImage}
        resizeMode='contain'
        borderRadius={16}
        borderColor='#E6E8F0'
        borderWidth={1}
        padding={16}
        marginBottom={25}
      >
        <Image style={{ width: '100%', height: '100%', borderRadius: 16 }}
          source={dataFactOfTheDay.src} />
      </Col>
      <Text
        fontFamily={'Inter, sans-serif'}
        fontWeight={500}
        fontSize={18 - resizeFont(width, minWidth)}
        lineHeight={24}
        color={'black'}
        textAlign={'justify'}
        paddingHorizontal={16}
      >
        {dataFactOfTheDay.content}</Text>
    </Col>
  )
};

export default GeeseFactsRandom;
