import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Col, IColProps } from 'components';
import { Animated } from 'react-native';

interface ITouchFieldProps extends IColProps {
  cirle?: boolean;
  bgHovered?: string;
  withPropRender?: boolean;
  children: any;
}

const AnimationBackground = ({ isHovered, bgNormal, bgHovered }) => {
  const [height, setHeight] = useState(0);
  const translateY = useRef(new Animated.Value(0)).current;
  useEffect(() => {
    if (!height) return;
    Animated.timing(translateY, {
      toValue: isHovered ? 0 : height,
      duration: 300,
      useNativeDriver: true,
    }).start();
  }, [isHovered, height]);

  const bgHoveredComputed = (() => {
    if (bgHovered) return bgHovered;
    return 'rgba(0,0,0,0.05)';
  })();

  const onLayout = (e) => {
    setHeight(e.nativeEvent.layout.height);
  };

  return (
    <Col flex1 style={{ overflow: 'hidden' }} onLayout={onLayout}>
      <Col flex1 backgroundColor={bgNormal} />
      <Animated.View
        style={{
          position: 'absolute',
          backgroundColor: bgHoveredComputed,
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          transform: [{ translateY: translateY }],
        }}
      />
    </Col>
  );
};

const TouchField = ({ bgHovered, children, increaseWidth, withPropRender, ...props }: ITouchFieldProps) => {
  const [size, setSize] = useState({
    width: props.cirle ? 40 : props.width,
    height: props.cirle ? 40 : props.height,
  });
  const [isHovered, setIsHovered] = useState(false);
  const borderRadius = props.cirle ? 20 : props.borderRadius || 8;

  return (
    <Col
      overflow="hidden"
      borderRadius={borderRadius}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      {...props}
      height={size.height}
      width={size.width}
    >
      <Col absoluteFill>
        <AnimationBackground isHovered={isHovered} bgNormal={'transparent'} bgHovered={bgHovered} />
      </Col>
      {withPropRender ? children({ isHovered }) : children}
    </Col>
  );
};

export default TouchField;
