import * as React from "react";
import Svg, { Path } from "react-native-svg";
const SVGComponent = (props) => (
  <Svg
    width={props.width || 630}
    height={props.width || 630}
    viewBox="0 0 630 630"
    // minWidth={630}
    // width={330}
    // height={330}
    viewBox="0 0 630 630"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M310.174 83.1222C271.845 59.346 184.451 181.982 253.575 252.869C268.947 268.632 273.508 258.993 293.618 262.307C304.945 264.172 309.557 240.269 360.499 255.238C393.989 265.079 389.088 219.127 436.59 229.849C540.301 253.247 400.655 76.608 310.162 83.1222H310.174Z"
      fill="#101840"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M301.77 82.8198C307.201 92.232 321.514 94.311 334.568 90.2538C348.554 85.9068 355.811 70.6986 350.078 57.2292C346.601 49.0518 340.175 42.0084 330.574 38.2536C320.972 34.4988 310.275 38.5182 305.437 47.5272C299.3 58.9554 297.108 74.7684 301.757 82.8324L301.77 82.8198Z"
      fill="#101840"
    />
    <Path
      d="M287.104 183.758C285.97 178.076 283.576 173.59 280.539 170.654C276.104 166.37 270.233 165.236 264.827 168.197C261.652 169.936 258.716 173.162 256.36 178.051C258.426 180.268 260.732 181.138 263.214 181.251C268.658 171.184 277.011 174.208 279.519 184.338C282.24 185.044 284.76 184.64 287.104 183.746V183.758Z"
      fill="#FFF8F5"
    />
    <Path
      d="M285.693 178.807C284.408 175.43 282.631 172.683 280.54 170.654C276.105 166.37 270.233 165.236 264.828 168.197C262.938 169.231 261.136 170.793 259.498 172.91C261.993 174.17 264.676 175.279 267.511 176.198C270.8 174.056 274.303 175.052 276.81 178.34C279.872 178.769 282.871 178.92 285.706 178.794L285.693 178.807Z"
      fill="white"
      stroke="#101840"
      strokeWidth={2.52}
      strokeMiterlimit={10}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M301.191 173.426C305.513 167.744 326.076 161.482 329.642 164.279C330.612 183.834 334.493 209.614 338.55 219.618C327.575 223.121 305.802 221.861 296.289 219.379C301.355 209.903 302.174 180.671 301.191 173.414V173.426Z"
      fill="white"
      stroke="#313039"
      strokeWidth={2.52}
      strokeMiterlimit={10}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M280.212 136.546C274.794 135.047 269.099 138.575 267.486 144.409C265.873 150.255 268.947 156.202 274.365 157.689C279.783 159.188 285.479 155.66 287.091 149.827C288.704 143.993 285.63 138.033 280.212 136.546Z"
      fill="white"
      stroke="#313039"
      strokeWidth={2.52}
      strokeMiterlimit={10}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M329.654 164.279C350.709 175.342 358.055 133.988 331.418 144.963C324.74 134.618 312.531 132.665 311.397 113.929C306.823 113.614 302.35 113.69 297.99 114.269C287.948 115.592 279.494 122.333 275.764 131.758C265.911 156.656 277.213 182.158 293.253 187.551C304.467 191.331 316.853 181.755 329.667 164.291L329.654 164.279Z"
      fill="white"
      stroke="#313039"
      strokeWidth={2.52}
      strokeMiterlimit={10}
    />
    <Path
      d="M342.594 152.8C343.262 153.191 344.106 152.977 344.509 152.309C344.9 151.641 344.686 150.797 344.018 150.394C343.766 150.242 343.501 150.104 343.237 149.978C341.372 149.071 339.331 148.768 337.378 149.31C335.437 149.839 333.636 151.187 332.237 153.544C332.061 153.833 331.897 154.148 331.733 154.476C331.506 154.892 331.481 155.408 331.733 155.849C332.111 156.517 332.968 156.744 333.636 156.366C334.467 155.887 335.248 155.572 335.979 155.383C336.71 155.194 337.403 155.156 338.02 155.219C338.789 155.308 339.469 154.753 339.558 153.985C339.646 153.216 339.091 152.536 338.323 152.447C337.907 152.397 337.491 152.384 337.05 152.41C337.39 152.221 337.743 152.069 338.109 151.969C339.369 151.616 340.729 151.843 342.015 152.46C342.204 152.548 342.393 152.649 342.594 152.775V152.8Z"
      fill="#101840"
    />
    <Path
      d="M330.826 165.022C331.242 164.367 331.04 163.51 330.385 163.107C329.73 162.691 328.873 162.893 328.47 163.548C326.302 166.988 323.682 170.528 320.519 173.905C317.356 177.269 313.665 180.47 309.33 183.217C308.688 183.632 308.486 184.489 308.902 185.132C309.318 185.774 310.174 185.976 310.817 185.56C315.366 182.662 319.234 179.323 322.535 175.808C325.836 172.292 328.558 168.613 330.813 165.035L330.826 165.022Z"
      fill="#101840"
    />
    <Path
      d="M363.51 183.317C359.692 177.962 355.118 174.422 350.62 172.809C344.03 170.428 337.428 171.99 333.207 177.584C330.725 180.86 329.188 185.573 329.024 191.734C332.199 193.057 334.996 192.893 337.617 191.86C338.562 178.907 348.629 178.151 355.937 187.475C359.087 186.934 361.507 185.359 363.523 183.33L363.51 183.317Z"
      fill="#FFF8F5"
    />
    <Path
      d="M359.743 178.844C356.845 175.946 353.707 173.918 350.608 172.809C344.018 170.428 337.415 171.99 333.194 177.584C331.72 179.537 330.586 181.982 329.868 184.943C333.031 185.094 336.332 184.993 339.696 184.615C342.103 180.86 346.198 180.256 350.318 182.511C353.695 181.528 356.857 180.293 359.743 178.844Z"
      fill="white"
      stroke="#101840"
      strokeWidth={2.52}
      strokeMiterlimit={10}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M308.687 508.271L337.138 507.641L339.129 587.349C314.03 597.227 164.858 600.692 151.25 573.275C257.695 547.558 246.62 461.639 308.687 508.271Z"
      fill="white"
      stroke="#19181F"
      strokeWidth={2.52}
      strokeMiterlimit={10}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M191.419 590.008C170.781 586.656 155.22 581.288 151.251 573.275C167.996 569.23 181.843 563.686 193.511 557.411C194.771 561.645 195.451 566.181 195.451 570.893C195.451 577.76 194.002 584.249 191.419 590.008Z"
      fill="white"
      stroke="#19181F"
      strokeWidth={2.52}
      strokeMiterlimit={10}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M285.327 586.013L287.318 506.306C278.939 501.593 175.883 466.326 173.111 459.724C181.012 443.482 183.128 422.024 235.91 408.429C-27.7578 314.156 -15.2083 569.243 285.327 586.001V586.013Z"
      fill="#223363"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M287.078 515.932L287.318 506.318C278.939 501.606 175.883 466.339 173.111 459.736C181.011 443.495 183.128 422.037 235.91 408.442C142.14 374.913 83.3362 385.598 59.9632 413.998C91.2616 467.347 179.197 507.73 287.078 515.92V515.932Z"
      fill="#223363"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M333.371 581.83C316.714 561.884 217.111 511.119 223.285 480.186C232.432 434.284 358.407 410.508 393.737 421.583C591.633 350.847 656.876 521.048 473.218 571.927C436.162 582.196 391.948 583.846 333.383 581.83H333.371Z"
      fill="#223363"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M239.084 509.972C228.223 499.225 221.533 488.918 223.271 480.186C232.419 434.284 358.394 410.508 393.724 421.583C485.44 388.798 548.641 407.786 571.8 442.638C523.832 487.28 432.469 517.457 327.625 517.457C296.679 517.457 266.918 514.823 239.084 509.972Z"
      fill="#223363"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M315.768 506.936L287.317 506.306L285.327 586.013C310.426 595.892 459.597 599.357 473.205 571.939C366.76 546.223 377.836 460.303 315.768 506.936Z"
      fill="white"
      stroke="#19181F"
      strokeWidth={2.52}
      strokeMiterlimit={10}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M433.037 588.672C453.675 585.32 469.236 579.953 473.205 571.939C456.46 567.895 442.613 562.351 430.945 556.076C429.685 560.309 429.005 564.845 429.005 569.558C429.005 576.425 430.454 582.914 433.037 588.672Z"
      fill="white"
      stroke="#19181F"
      strokeWidth={2.52}
      strokeMiterlimit={10}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M384.438 519.158C362.25 496.15 350.38 480.929 315.768 506.936C323.794 518.755 339.078 526.743 356.617 526.743C367.038 526.743 376.651 523.921 384.438 519.158Z"
      fill="white"
      stroke="#19181F"
      strokeWidth={2.52}
      strokeMiterlimit={10}
    />
    <Path
      d="M284.268 505.084C284.986 505.373 285.793 505.033 286.083 504.315C286.372 503.597 286.032 502.79 285.314 502.501L157.235 450.261C156.517 449.971 155.71 450.311 155.421 451.03C155.131 451.748 155.471 452.554 156.189 452.844L284.268 505.084Z"
      fill="white"
    />
    <Path
      d="M473.319 459.131C474.037 458.854 474.39 458.048 474.113 457.33C473.836 456.611 473.029 456.259 472.311 456.536L366.61 497.536C365.891 497.813 365.539 498.62 365.816 499.338C366.093 500.056 366.899 500.409 367.617 500.132L473.319 459.131Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M270.9 243.155C264.108 233.541 267.825 218.547 270.459 212.638C302.337 211.113 332.363 209.702 357.802 206.212C362.905 212.965 365.979 234.335 360.209 245.965C419.794 255.793 604.056 418.03 425.955 458.552C110.88 555.534 29.7736 345.278 270.912 243.142L270.9 243.155Z"
      fill="white"
      stroke="#19181F"
      strokeWidth={2.52}
      strokeMiterlimit={10}
    />
    <Path
      d="M353.871 247.955C354.564 247.628 354.867 246.796 354.539 246.103C354.212 245.41 353.38 245.108 352.687 245.435C347.924 247.666 337.529 249.593 326.844 250.715C315.971 251.849 304.87 252.139 299.162 251.03C298.406 250.891 297.675 251.383 297.537 252.139C297.398 252.895 297.89 253.625 298.646 253.764C304.618 254.923 316.034 254.646 327.147 253.487C338.096 252.34 348.831 250.324 353.884 247.955H353.871Z"
      fill="#19181F"
    />
    <Path
      d="M394.645 297.574C394.531 296.818 393.813 296.289 393.057 296.402C392.301 296.516 391.772 297.234 391.885 297.99L407.66 409.487C407.774 410.243 408.492 410.773 409.248 410.659C409.853 410.571 410.306 410.092 410.407 409.525C410.722 408.379 412.196 405.052 412.637 403.931C416.871 393.158 426.233 385.157 437.22 380.57C448.27 375.971 460.921 374.825 471.694 377.786C473.054 378.164 474.403 378.605 475.738 379.134C476.456 379.411 477.263 379.058 477.54 378.34C477.817 377.622 477.464 376.816 476.746 376.538C475.36 375.997 473.911 375.518 472.412 375.102C461.047 371.977 447.728 373.174 436.136 378C424.998 382.637 415.422 390.65 410.647 401.436L394.619 297.574H394.645Z"
      fill="#19181F"
    />
    <Path
      d="M245.36 306.823C245.511 306.067 245.033 305.336 244.277 305.172C243.521 305.021 242.79 305.5 242.626 306.256L224.898 391.696C224.747 392.452 225.225 393.183 225.981 393.347C226.737 393.511 227.468 393.019 227.632 392.263L245.36 306.823Z"
      fill="#19181F"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M425.943 458.564L408.605 410.684L361.632 421.659L305.436 456.095C308.536 461.462 310.716 465.998 318.629 466.402C314.546 470.32 307.314 471.933 304.567 474.592C308.372 483.714 324.336 481.118 326.592 479.972C340.918 495.243 366.231 477.25 375.87 473.936C381.704 474.516 415.069 471.089 425.93 458.577L425.943 458.564Z"
      fill="white"
      stroke="#19181F"
      strokeWidth={2.52}
      strokeMiterlimit={10}
    />
    <Path
      d="M366.508 456.107C367.151 455.692 367.353 454.835 366.937 454.192C366.521 453.55 365.664 453.348 365.022 453.764L325.861 478.8C325.218 479.216 325.017 480.073 325.432 480.715C325.848 481.358 326.705 481.559 327.348 481.144L366.508 456.107Z"
      fill="#19181F"
    />
    <Path
      d="M360.612 441.013C361.229 440.546 361.355 439.677 360.902 439.06C360.435 438.442 359.566 438.316 358.949 438.77C354.387 442.184 343.236 449.417 332.111 456.41C319.284 464.474 306.533 472.185 304.013 473.319C303.307 473.634 302.992 474.453 303.307 475.159C303.622 475.864 304.441 476.179 305.147 475.864C307.793 474.68 320.682 466.88 333.597 458.779C344.799 451.735 356.025 444.452 360.612 441.025V441.013Z"
      fill="#19181F"
    />
    <Path
      d="M353.278 428.413C353.934 428.009 354.148 427.153 353.745 426.497C353.341 425.842 352.485 425.628 351.829 426.031L304.705 454.898C304.05 455.301 303.836 456.158 304.239 456.813C304.642 457.468 305.499 457.682 306.154 457.279L353.278 428.413Z"
      fill="#19181F"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M227.304 245.36C221.974 248.333 219.605 254.23 222.012 258.527C224.406 262.823 230.681 263.894 235.998 260.921C241.328 257.947 243.696 252.05 241.29 247.754C238.883 243.457 232.621 242.386 227.304 245.36Z"
      fill="white"
      stroke="#19181F"
      strokeWidth={2.52}
      strokeMiterlimit={10}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M199.067 254.974C207.421 250.916 230.101 249.026 242.688 251.206C249.681 252.416 246.179 266.087 242.159 267.259C245.158 271.82 245.284 279.644 241.214 285.503C243.495 289.787 242.424 298.507 238.329 302.274C234.057 317.092 226.686 317.671 218.446 318.276L218.874 454.066C138.789 452.239 156.391 359.415 182.511 295.47L199.055 254.961L199.067 254.974Z"
      fill="white"
      stroke="#19181F"
      strokeWidth={2.52}
      strokeMiterlimit={10}
    />
    <Path
      d="M242.033 268.645C242.802 268.708 243.469 268.141 243.532 267.372C243.595 266.603 243.028 265.936 242.26 265.873C237.938 265.507 232.482 265.721 227.203 266.2C220.399 266.83 213.859 267.901 210.344 268.796C209.601 268.985 209.147 269.741 209.323 270.484C209.5 271.228 210.268 271.681 211.012 271.492C214.452 270.623 220.815 269.564 227.455 268.96C232.57 268.493 237.85 268.292 242.033 268.645Z"
      fill="#19181F"
    />
    <Path
      d="M241.1 286.852C241.869 286.915 242.537 286.348 242.6 285.579C242.663 284.81 242.096 284.143 241.327 284.08C237.081 283.714 232.041 283.928 227.165 284.42C220.663 285.062 214.477 286.209 210.898 287.116C210.155 287.305 209.701 288.061 209.878 288.805C210.067 289.548 210.823 290.002 211.566 289.825C215.056 288.943 221.104 287.834 227.429 287.204C232.129 286.738 237.005 286.524 241.088 286.877L241.1 286.852Z"
      fill="#19181F"
    />
    <Path
      d="M238.341 303.66C239.11 303.66 239.727 303.03 239.727 302.261C239.727 301.493 239.097 300.875 238.329 300.875C233.641 300.901 228.664 301.253 224.065 301.77C218.975 302.35 214.313 303.156 211.024 303.988C210.281 304.177 209.827 304.933 210.004 305.676C210.193 306.419 210.949 306.873 211.692 306.697C214.88 305.89 219.403 305.109 224.355 304.555C228.904 304.038 233.793 303.698 238.329 303.673L238.341 303.66Z"
      fill="#19181F"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M257.065 335.727H57.0275L127.26 484.835H327.285L257.065 335.727Z"
      fill="white"
    />
    <Path
      d="M256.826 334.328H54.5957L126.138 486.234H329.25C305.411 435.595 281.559 384.955 257.708 334.328H256.826ZM58.9931 337.126H255.944L324.853 483.449H127.903L58.9931 337.126Z"
      fill="#19181F"
    />
    <Path
      d="M384.263 486.234C385.031 486.234 385.661 485.604 385.661 484.835C385.661 484.067 385.031 483.437 384.263 483.437H127.248C126.479 483.437 125.849 484.067 125.849 484.835C125.849 485.604 126.479 486.234 127.248 486.234H384.263Z"
      fill="#19181F"
    />
    <Path
      d="M191.924 422.15C185.369 422.15 180.055 416.836 180.055 410.281C180.055 403.726 185.369 398.412 191.924 398.412C198.479 398.412 203.793 403.726 203.793 410.281C203.793 416.836 198.479 422.15 191.924 422.15Z"
      fill="#19181F"
    />
  </Svg>
);
export default SVGComponent;
