import React, { useState } from "react";
import { Col, Row, Text } from "components/base";
import { IColProps } from "components/base/Col";
import { COLOR, COLORS } from "const";
import { get, set } from "lodash";
import { Button as AntButton } from "antd";
import { Input02, InputField } from "../form";
import ButtonOutline from "../button/ButtonOutline";
import TouchField from "../button/TouchField";
import { EvilIcons } from "@expo/vector-icons";
import BgIcon from "../BgIcon";

export interface ITableFormProps extends IColProps {
  columns: { label: string, key: string, isMultiline?: boolean }[],
  onChange?: (data: any[]) => void,
  addBtnTitle?: string,
  data?: any[],
}

const TableForm = (props: ITableFormProps) => {
  const [data, setData] = useState<any[]>(props.data || [{}]);
  const { columns, onChange, addBtnTitle, ...restProps } = props;

  const onAddRow = () => {
    setData(s => [...s, {}]);
  }

  const onRemove = (i) => {
    const arr = [...data];
    arr.splice(i, 1);
    setData(arr);
    onChange?.(arr);
  }

  const onChangeRow = (value, key, idx) => {
    const arr = [...data];
    set(arr, `[${idx}].${key}`, value);
    setData(arr);
    onChange?.(arr);
  }

  return (
    <Col {...restProps}>
      <Row marginTop={16}>
        <Row flex1>
          {columns.map(col => (
            <Col flex1 key={`h${col.label}`}>
              <Text fontWeight="500" fontSize={14}>{col.label}</Text>
            </Col>
          ))}
        </Row>
        <Col width={81}>
        </Col>
      </Row>
      {data.map((i, idx) => (
        <Row marginTop={8} key={`row${idx}`}>
          <Row flex1 alignItems="flex-start">
            {columns.map((col, cIdx) => (
              <Col flex1 paddingRight={8} key={`c${cIdx}${idx}`}>
                <InputField
                  value={get(i, col.key)}
                  onChangeText={(v) => onChangeRow(v, col.key, idx)}
                />
              </Col>
            ))}
          </Row>
          <AntButton
            type="text" size="large"
            onClick={() => onRemove(idx)}
          >
            <Text fontSize={16} fontWeight="600" color={COLORS.RED}>
              Delete
            </Text>
          </AntButton>
        </Row>
      ))}
      <Col mt2 alignItems="flex-start">
        <ButtonOutline
          icon="add"
          title={addBtnTitle || "Add row"}
          onPress={onAddRow}
        />
      </Col>
    </Col>
  );
};

export default TableForm;
