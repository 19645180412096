import React from 'react';
import Navigation from './src/navigation/Navigation';
import { ModalContainer } from './src/components';
import './App.css';
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import 'react-tippy/dist/tippy.css';
import { ConfigProvider } from 'antd';
import { COLOR, COLORS } from "./src/const";
import { initalCheckShopifyEmbedAppFlow } from './src/components';
import { NavigationMiddleWareProvider } from "./src/navigation/NavigationContext";
import { ModalConfirmProvider } from "./src/navigation/ModalConfirmContext"
import * as Sentry from "@sentry/react";

if (!window.location.hostname.includes('localhost')) {
  Sentry.init({
    dsn: "https://95156b4ea1a01c3e72cce02d9c86e6a1@o109340.ingest.us.sentry.io/4508233419980800",
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 1.0,
  });
}

initalCheckShopifyEmbedAppFlow();

export default function AppWeb() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBorder: COLORS.SEPARATORS,
          colorSplit: COLORS.SEPARATORS,
          colorPrimary: COLORS.BLUE_LIGHT,
          // borderRadius: 4,
          // colorBgContainer: COLOR.GREY_LIGHT,
          padding: 10,
        },
        components: {
          Button: {
            defaultBorderColor: COLORS.BORDER,
            defaultColor: COLORS.BORDER,
            borderRadius: 8,
          },
          Input: {
            colorBorder: COLORS.SEPARATORS,
            hoverBorderColor: COLORS.BLUE_LIGHT,
            lineWidthFocus: 0,
            activeBorderColor: COLORS.BLUE_LIGHT,
            colorPrimaryBorder: COLORS.BLUE_LIGHT,
            colorTextDisabled: COLORS.TEXT_MUTED,
            borderRadius: 8,
            activeBg: COLORS.BG_LIGHTER,
            colorIcon: COLORS.ICON_MUTED,
            colorTextPlaceholder: COLORS.ICON_MUTED,
            colorHighlight: COLORS.BLUE_LIGHT,
            colorBgContainer: COLORS.BG_LIGHTER,
          },
          Table: {
            rowSelectedBg: COLORS.BG_LIGHTER,
            rowSelectedHoverBg: COLORS.BG_LIGHTER,
            headerBg: COLORS.BG_WHITE,
            headerColor: COLORS.HEADLINES,
          },
          Select: {
            colorBgContainer: COLORS.BG_LIGHTER,
          },
          Descriptions: {
            labelBg: COLORS.WHITE,
          },
          Tabs: {
            lineWidth: 0,
            colorPrimary: COLORS.BLUE_LIGHT,
          },
          DatePicker: {
            colorBorder: COLORS.BORDER,
            hoverBorderColor: COLORS.BLUE_LIGHT,
            lineWidthFocus: 0,
            activeBorderColor: COLORS.BLUE_LIGHT,
            colorPrimaryBorder: COLORS.BLUE_LIGHT,
            colorTextDisabled: COLORS.TEXT_MUTED,
            borderRadius: 8,
            activeBg: COLORS.BG_LIGHTER,
            colorIcon: COLORS.ICON_MUTED,
            colorTextPlaceholder: COLORS.ICON_MUTED,
            cellActiveWithRangeBg: '#ECF8FF',
            colorBgContainer: COLORS.BG_LIGHTER,
          },
          Upload: {
            colorBgContainer: COLORS.BG_LIGHTER,
            colorBorder: COLORS.BORDER,
          }
        },
      }}
    >
      <NavigationMiddleWareProvider>
        <ModalContainer>
          <ModalConfirmProvider>
            <Navigation />
          </ModalConfirmProvider>
        </ModalContainer>
      </NavigationMiddleWareProvider>
    </ConfigProvider>
  );
}
