
export const dataFacts = [  
  {  
    id: 1,  
    title: 'Migration Marvels',  
    content: 'Geese are famous for their long migrations, often flying thousands of miles between their breeding and wintering grounds. Some species, like the Snow Goose, can travel up to 5,000 miles during migration.',
  },  
  {  
    id: 2,  
    title: 'V-Formation Flight',  
    content: 'When flying in a "V" formation, geese can conserve energy by taking advantage of the upwash from the bird in front. This allows them to fly longer distances with less fatigue. The lead bird takes on the most challenging role, and they rotate positions to share the workload.',
  },  
  {  
    id: 3,  
    title: 'Monogamous Relationships',  
    content: 'Geese are known for their strong pair bonds, often mating for life. Both parents are involved in raising their young, which contributes to the success of their offspring.',  
  },  
  {  
    id: 4,  
    title: 'Communication Skills',  
    content: 'Geese have a wide range of vocalizations used for different purposes, including warning calls, mating calls, and communication during migration. Their loud honking is often heard during flight.',  
  },  
  {  
    id: 5,  
    title: 'Highly Territorial',  
    content: 'Geese are very protective of their nests and young. During breeding season, they can become quite aggressive, chasing away intruders, including much larger animals.',  
  },  
  {  
    id: 6,  
    title: 'Adaptable Feeders',  
    content: 'Geese are herbivores and eat a variety of plants, including grasses, roots, stems, seeds, and aquatic vegetation. However, they can adapt their diet based on the availability of food in different environments.',  
  },  
  {  
    id: 7,  
    title: 'Longevity',  
    content: 'In the wild, geese can live up to 20 years or more, although this varies by species. In captivity, some geese have been known to live even longer.',  
  },  
  {  
    id: 8,  
    title: 'Cultural and Symbolic Significance',  
    content: 'In various cultures, geese are symbols of loyalty, bravery, and teamwork. They appear in myths, literature, and folklore worldwide. For example, in Chinese culture, geese are seen as symbols of marital fidelity.',  
  },  
  {  
    id: 9,  
    title: 'Flock Memory',  
    content: 'Geese have excellent memories and can remember their migratory routes and stopover sites from previous years. This memory is crucial for their survival as they navigate vast distances.',  
  },  
  {  
    id: 10,  
    title: 'Precocial Chicks',  
    content: 'Goslings (young geese) are precocial, meaning they are relatively mature and mobile from the moment they hatch. They can walk, swim, and feed themselves almost immediately after birth, though they still rely on their parents for protection and guidance.',  
  },  
  {  
    id: 11,  
    title: 'Variety of Species',  
    content: 'There are several species of geese, including the Canada Goose, Snow Goose, Greylag Goose, and the rare Emperor Goose, each with unique traits and habitats.',  
  },  
  {  
    id: 12,  
    title: 'Strong Family Bonds',  
    content: 'Even after the young geese (goslings) are fully grown, they often stay with their family groups for extended periods, sometimes reuniting with them during migration.',  
  },  
  {  
    id: 13,  
    title: 'Global Distribution',  
    content: 'Geese are found on every continent except Antarctica. They are particularly common in temperate and subarctic regions.',  
  },  
  {  
    id: 14,  
    title: 'Unique Nesting Habits',  
    content: 'Some species of geese, like the Egyptian Goose, often nest in tree hollows or on cliffs, unusual behavior for most waterfowl that typically nest on the ground.',  
  },  
  {  
    id: 15,  
    title: 'Geese in War',  
    content: 'Geese have been used historically as "watchdogs" because of their loud calls and aggressive nature. Roman geese famously alerted the city of Rome to a night attack by the Gauls in 390 BC.',  
  },  
  {  
    id: 16,  
    title: 'Molt Migration',  
    content: 'Some species of geese undergo a molt migration, where they migrate to specific areas to shed and regrow their flight feathers. During this time, they are temporarily flightless.',  
  },  
  {  
    id: 17,  
    title: 'Adaptation to Urban Life',  
    content: 'Canada Geese, in particular, have adapted well to urban environments, often seen in city parks and golf courses. Their adaptability has led to their population boom in many areas.',  
  },  
  {  
    id: 18,  
    title: 'Highly Social Animals',  
    content: 'Geese are very social and form large flocks, especially outside of the breeding season. These flocks can include thousands of individuals.',  
  },  
  {  
    id: 19,  
    title: 'Exceptional Navigation',  
    content: 'Geese use the Earth\'s magnetic field, the position of the sun, and landmarks to navigate during migration. This incredible ability helps them find their way over long distances with remarkable accuracy.',  
  },  
  {  
    id: 20,  
    title: 'Role in Ecosystems',  
    content: 'Geese play a significant role in their ecosystems, influencing vegetation and nutrient cycles through their feeding habits and droppings.',  
  },  
  {  
    id: 21,  
    title: 'Molting Behavior',  
    content: 'Geese undergo a process called molting, where they shed and regrow their feathers. During this time, they lose their flight feathers and are temporarily unable to fly, which typically lasts about 4-5 weeks.',  
  },  
  {  
    id: 22,  
    title: 'Diverse Vocal Range',
    content: 'Beyond the well-known honking, geese can produce a wide range of sounds including hisses, grunts, and cackles. These sounds are used for various types of communication, such as signaling distress, asserting dominance, or coordinating group movements.',
  },
  {
    id: 23,
    title: 'Geese and Ice Age Survival',
    content: 'Fossil records suggest that geese have been around since the Pleistocene Epoch, surviving through the last Ice Age. This indicates their ability to adapt to dramatic climate changes over millennia.',
  },
  {
    id: 24,
    title: 'Night Flying',
    content: 'Geese are capable of flying at night during migration. They often use the light from the moon and stars to navigate, which helps them avoid predators and take advantage of cooler temperatures.',
  },
  {
    id: 25,
    title: 'Intelligent Birds',
    content: 'Geese are highly intelligent and have been known to recognize people, places, and even learn from experience. They can solve problems, such as opening gates or finding new food sources, demonstrating remarkable cognitive abilities for birds.',
  },
  {
    id: 26,
    title: 'Historical Domestication',
    content: 'Geese were among the first birds to be domesticated by humans, dating back over 3,000 years. The Egyptians and Romans kept geese for food, feathers, and as guard animals.',
  },
  {
    id: 27,
    title: 'Hybridization',
    content: 'Geese are known to interbreed with other closely related species, producing hybrids. This occurs most commonly between species like the Canada Goose and the Greylag Goose, resulting in a variety of mixed characteristics in the offspring.',
  },
  {
    id: 28,
    title: 'Geese and Weather Prediction',
    content: 'In folklore, the behavior of geese has been used to predict weather changes. For instance, geese flying unusually high or in a more erratic pattern are often said to be signs of an approaching storm.',
  },
  {
    id: 29,
    title: 'Geese in Art and Literature',
    content: 'Geese have appeared in art, literature, and even nursery rhymes for centuries. They are featured in Aesop\'s fables, the tale of "The Golden Goose," and are the subject of the famous nursery rhyme "Goosey Goosey Gander."',
  },
  {
    id: 30,
    title: 'Incredible Altitude',
    content: 'Some species of geese can fly at incredibly high altitudes. For example, Bar-headed Geese have been recorded flying over the Himalayas at altitudes of up to 29,000 feet, a height comparable to Mount Everest.',
  },
];
