import { Col, Row, Text, CMSLayout, useUIState, Button, showPopupMessage, useMobileScreen } from 'components';
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { IScreen, TCMSOrder } from 'type';
import Store, { Api } from 'store';
import { useNavFunc } from 'navigation';
import { LOG_TYPES, SCREEN } from 'const';
import { ListOrderLoadMore } from './ListOrders.Comps';
import OrderTable from './OrderTable2';
import { OrderActionButtons } from './components/OrderActionButtons';
import { useConfirmCancelOrder } from './utils';

const OrderSearchResult: IScreen = ({ }) => {
  const { navigation, route } = useNavFunc();
  // @ts-ignore
  const { query } = route.params || {};
  const sorter = useRef();
  const filter = useRef();
  const isMobile = useMobileScreen();
  const UserStore = Store.useUserStore();
  const { user } = UserStore;

  const resellerId = useMemo(() => {
    return user?.role === 'admin' ? 'all' : user?.role === 'reseller' ? user?.id
      : user?.resellerId;
  }, [user]);

  const OrderStore = Store.useOrderStore();
  const [{ fetching, errorMes, loading: btnLoading }, setUI] = useUIState({ fetching: true });
  const [pagination, setPagination] = useState({ hasNext: false, total: 1 });
  const [jobs, setJobs] = useState<Array<TCMSOrder>>([]);
  const [selectedJobs, setSelectedJobs] = useState<{ [id: string]: boolean }>({});
  const { show: showConfirm } = useConfirmCancelOrder();

  const atLeastOneSelected = useMemo(() => {
    return Object.keys(selectedJobs).filter(id => !!selectedJobs[id]).length > 0;
  }, [selectedJobs]);

  const getData = async () => {
    setUI({ fetching: true });
    try {
      const { list } = await OrderStore.search(1, query, resellerId, {
        filter: filter.current,
        sorter: sorter.current,
      });
      setJobs(list);
    } catch (err) { }
    setUI({ fetching: false });
  }

  useEffect(() => {
    if (!resellerId) return;
    getData();
  }, [query, resellerId]);

  const listSelected = useMemo(() => {
    return Object.keys(selectedJobs).map(stringId => {
      if (!selectedJobs[stringId]) return undefined;
      const findJob = jobs.find(val => `${val.Id}_${val['Order ID']}` === stringId);
      return findJob;
    }).filter(Boolean);
  }, [selectedJobs, jobs]);

  return (
    <CMSLayout
      requireAuthen
      searchPlaceholder='Search order, email, product name..'
      onSearchInput={(q) => {
        if (!q) return showPopupMessage({
          title: '',
          content: 'Please fill in order number, email or product name',
          buttonOkText: 'OK',
          // 
          typeHighlight: 'warning',
          contentHighlight: 'Fill information'

        });
        // alert('Please fill in order number, email or product name');
        navigation.navigate(SCREEN.OrderSearchResult, { query: q })
      }}
      onClearSearchInput={() => {
        if (navigation.canGoBack()) {
          navigation.goBack();
        } else {
          navigation.navigate(user?.role === 'admin' ? SCREEN.ListOrders : SCREEN.ListOrderForReseller);
        }
      }}
      searchInitialValue={query}
      searchAutoClear
    >
      <Row m2 marginBottom={0} justifyContent={'space-between'}>
        <Row>
          <Text h3 >Search results</Text>
        </Row>
        <Button
          height={30}
          borderRadius={15}
          outline
          text="Go back"
          onPress={() => {
            if (navigation.canGoBack()) {
              navigation.goBack();
            } else {
              navigation.navigate(SCREEN.ListOrders);
            }
          }}
        />
      </Row>
      <Col flex1 {...isMobile ? {
        marginHorizontal: 8,
        marginTop: 10,
      } : {
        m2: true,
        mv1: true,
        pt1: true,
      }}>
        {errorMes ? (
          <Col flex1 middle>
            <Text color="red" subtitle1>{errorMes}</Text>
          </Col>
        ) : (
          <Col flex1 bgWhite={!isMobile} round1>
            <Row justifyContent="flex-start" p1>
              {atLeastOneSelected ? (
                <OrderActionButtons
                  stage={'in-production'}
                  rejectJob={async () => {
                    const selectedOrders = Object.keys(selectedJobs)
                      .map(id => !selectedJobs[id] ? undefined : jobs.find(j => `${j.Id}_${j['Order ID']}` === id))
                      .filter(Boolean);
                    const confirmData = await showConfirm({
                      message: `Do you want to reject ${selectedOrders.length === 1 ? 'this order' : 'these orders'}`,
                      orders: selectedOrders
                    });
                    if (!confirmData?.confirmed) return;
                    OrderStore.updateOrderStage(selectedOrders, {
                      Status: "Rejected",
                      Stage: "Cancelled",
                      StageStatus: "Rejected",
                    }, {
                      deleteShopify: confirmData.data?.deleteShopify
                    });
                    selectedOrders.forEach((order: TCMSOrder) => {
                      Api.Log.addLog({
                        type: LOG_TYPES.REJECT_ORDER,
                        data: {
                          orderId: order['Order ID'],
                        },
                        oldData: {
                          status: order.Status,
                          stage: order.Stage,
                          stageStatus: order.StageStatus,
                        },
                        newData: {
                          status: "Rejected",
                          stage: "Cancelled",
                          stageStatus: "Rejected",
                        }
                      })
                    })
                    setTimeout(() => {
                      navigation.navigate(SCREEN.ListOrders, { stage: 'cancelled' });
                    }, 1000);
                  }}
                  listSelected={listSelected}
                  onDeleteSuccess={() => {
                    setJobs(l => l.filter(val => !selectedJobs[`${val.Id}_${val['Order ID']}`]));
                    setSelectedJobs({});
                  }}
                />
              ) : null}
            </Row>
            <OrderTable
              jobs={jobs}
              setJobs={setJobs}
              onRefresh={() => getData()}
              stage={undefined}
              isLoading={fetching}
              selectedJobs={selectedJobs}
              setSelectedJobs={setSelectedJobs}
              onChangeFilter={(_, _filter, _sorter) => {
                sorter.current = _sorter;
                filter.current = _filter;
                getData();
              }}
              isSearch
            />
            <ListOrderLoadMore
              hasNext={pagination.hasNext}
              getData={getData}
              page={1}
              isLoadingMore={false}
            />
          </Col>
        )}
      </Col>
    </CMSLayout>
  )
};

OrderSearchResult.routeInfo = {
  title: 'Order search results - Bottled Goose',
  path: '/search-orders',
};

export default OrderSearchResult;
