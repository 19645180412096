import React, { useState, useEffect, useRef } from 'react';
import { Col, Text, Input, TouchField, Row, modal, useUIState } from 'components';
import { COLOR } from 'const';
import Store from 'store';
import { ActivityIndicator } from 'react-native';
import { TimeHelper } from 'helpers';
import { TGeneralData } from 'type';

const NoteModal = ({ printJobId }: any) => {

  const dataId = useRef(null);
  const [note, setNote] = useState('');
  const [{ loading, errorMes, fetching }, setUI] = useUIState();
  
  const getData = async () => {
    setUI({ fetching: true });
    await TimeHelper.wait(1000);
    const res = await Store.Api.Data.list({
      type: 'production-note',
    }, {
      field1: printJobId,
    });
    if (res.data.data && res.data.data.length > 0) {
      const thisNote : TGeneralData = res.data.data[0];
      dataId.current = thisNote.id;
      setNote(thisNote.data?.note || '');
    } else if (res.data.error) {
      setUI({ fetching: false, errorMes: res.data.error });
    }
    setUI({ fetching: false });
  };

  useEffect(() => {
    getData();
  }, []);

  const save = async () => {
    setUI({ loading: true });
    const args : any = [
      { type: 'production-note' },
      {
        field1: printJobId,
        data: {
          note,
        }
      }
    ]
    if (dataId.current) args[1].id = dataId.current;
    await TimeHelper.wait(300);
    const res = await Store.Api.Data.upsert(args[0], args[1]);
    if (res.data.error) {
      return setUI({ loading: false, errorMes: res.data.error })
    }
    setUI({ loading: false });
    modal.hide();
  }

  return (
    <Col round1 bgWhite shadow p2>
      <Text fontSize={16} mb2>Production Notes</Text>
      {errorMes ? (
        <Col height={200} width={320} middle>
          <Text color="red" subtitle1>{errorMes}</Text>
        </Col>
      ) : (
        <Col>
          <Input
            inputProps={{
              multiline: true,
            }}
            backgroundColor={COLOR.GREY_LIGHT}
            height={200}
            minWidth={320}
            borderColor={'transparent'}
            value={note}
            onChange={setNote}
          />
          {fetching && (
            <Col absoluteFill middle backgroundColor={'rgba(0,0,0,0.2)'}>
              <ActivityIndicator size="large" color="black" />
            </Col>
          )}
        </Col>
      )}

      <Row justifyContent={'flex-end'} mt2>
        <TouchField
          height={30}
          width={70}
          middle
          borderColor={COLOR.GREY}
          borderRadius={15}
          borderWidth={1}
          m0
          ph2
          onPress={() => {
            if (errorMes) return setUI({ errorMes: '' });
            return modal.hide();
          }}
        >
          <Text color={COLOR.GREY}>Cancel</Text>
        </TouchField>
        <TouchField
          height={30}
          ph2
          middle
          borderColor={COLOR.GREY}
          borderRadius={15}
          borderWidth={1}
          backgroundColor={COLOR.GREY}
          m0
          onPress={save}
        >
          <Row>
            {loading && <ActivityIndicator size='small' color={'white'} style={{ marginRight: 5 }} />}
            <Text color={'white'}>Save</Text>
          </Row>
        </TouchField>
      </Row>
    </Col>
  );
};

export default NoteModal;