import {
  Col,
  Text,
  CMSLayout,
  Row,
  Grid,
  TouchField,
  Button,
} from "components";
import React, { useState, useEffect, useMemo } from "react";
import { IScreen, TUser } from "type";
import Store from "store";
import { useNavFunc } from "navigation";
import { COLOR, SCREEN } from "const";
import { ActivityIndicator } from "react-native";
import { Chart } from "react-google-charts";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
const moment = require("moment");
import { FontAwesome, MaterialCommunityIcons } from "@expo/vector-icons";
import TodayData from "./Reports.Today";
import { exportExcel } from "./Reports.exportExcel";
let XLSX;
import "react-datepicker/dist/react-datepicker.css";
import Customer from "./Reports.Customer";

const ReportsOneClient: IScreen = () => {
  const { navigate } = useNavFunc();
  const UserStore = Store.useUserStore();
  const [listReseller, setListReseller] = useState<Array<TUser>>([]);
  const [chartWidth, setChartWidth] = useState();
  const [todayData, setTodayData] = useState([]);

  useEffect(() => {
    if (!XLSX) {
      import("xlsx").then((xlsx) => {
        XLSX = xlsx;
      });
    }
  }, []);

  useEffect(() => {
    (async () => {
      const { list } = await UserStore.getListResellers(1);
      if (list && list.length > 0) {
        setListReseller(list);
      }
    })();
  }, []);

  useEffect(() => {
    Store.Api.PrintJob.listToday().then((res) => {
      setTodayData(res.data.data?.list || []);
    });
  }, []);

  const [data, setData] = useState<{
    jobsOverMonth: Array<{ month: string; count: string }>;
    printedJobsOverMonth: Array<{ month: string; count: string }>;
    numberOfPrintJobs: number;
    numberOfPrintedJobs: number;
    listCountClients: Array<{ clientId: string; count: string }>;
    listPrintedCountClients: Array<{ clientId: string; count: string }>;
    customers: Array<{
      client: string;
      email: string;
      first_name?: string;
      last_name?: string;
      products: Array<{
        name: string;
        product_id: number;
        variant_id: number;
        timestamp: string;
        quantity: number;
        order_number: number;
      }>;
    }>;
  }>();

  const getData = async () => {
    const res = await Store.Api.PrintJob.stats();
    console.log(res.data);
    if (res.data.success) setData(res.data.data);
  };

  useEffect(() => {
    getData();
  }, []);

  const totalPrintJobData = useMemo<
    Array<{ job: number; printed: number; name: string }>
  >(() => {
    if (!data || !data.jobsOverMonth) return [];
    let res = [];

    data.jobsOverMonth.forEach((v) => {
      const month = moment(v.month, "YYYY-MM-DD").format("MM/YYYY");
      const findPrinted = data.printedJobsOverMonth.find(
        (p) => p.month === v.month
      );
      res.push({
        job: Number(v.count),
        printed: !findPrinted ? 0 : Number(findPrinted.count),
        name: month,
      });
    });
    return res;
  }, [data]);

  const listClients = useMemo(() => {
    if (!data || !data.listCountClients) return [];
    const list: Array<{ client: string; jobs: number; printed: number }> = [];

    data.listCountClients.forEach((v) => {
      const findPrinted = data.listPrintedCountClients.find(
        (p) => p.clientId === v.clientId
      );
      let findClient = listReseller.find((r) => r.id === v.clientId);
      list.push({
        client: (() => {
          if (v.clientId === "partnet-in-wine") return "Partner In Wine";
          if (v.clientId === "admin") return "Admin";
          if (!v.clientId) return "Unknown";
          return !findClient
            ? v.clientId
            : `${findClient.firstName} ${findClient.lastName}`;
        })(),
        jobs: Number(v.count),
        printed: !findPrinted ? 0 : Number(findPrinted.count),
      });
    });

    return list.sort((a, b) => (a.jobs > b.jobs ? -1 : 1));
  }, [data, listReseller]);

  const onLayoutSetState = (label, func) => (e) => {
    const value = e.nativeEvent.layout[label];
    func(value);
  };

  return (
    <CMSLayout requireAuthen>
      <Row m2 marginBottom={0} justifyContent={'space-between'}>
        <Text h3 >Summary</Text>
        {!!data && (
          <Button
            text="Export"
            width={120}
            height={30}
            borderRadius={15}
            iconLeft={
              <MaterialCommunityIcons
                name="microsoft-excel"
                size={14}
                color="white"
                style={{ marginRight: 5 }}
              />
            }
            onPress={() =>
              exportExcel(XLSX, data, totalPrintJobData, listClients)
            }
          />
        )}
      </Row>
      <Col flex1 m2 mv1 p2 round1 bgWhite>
        <TodayData data={todayData} listReseller={listReseller} />
        <Grid
          xs="100%"
          md="50%"
          marginHorizontal={-5}
          alignItems={"flex-start"}
        >
          <Col m0 p0 onLayout={onLayoutSetState("width", setChartWidth)}>
            <Text mb2 bold center>
              Total Print Jobs
            </Text>
            <LineChart
              width={chartWidth}
              height={300}
              data={totalPrintJobData}
              margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
            >
              <Line type="monotone" dataKey="job" stroke="#8884d8" />
              <Line type="monotone" dataKey="printed" stroke="green" />
              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
            </LineChart>
          </Col>
          <Col m0 p0>
            {data?.numberOfPrintJobs && (
              <Text>Total Print Jobs: {data?.numberOfPrintJobs}</Text>
            )}
            {data?.numberOfPrintedJobs && (
              <Text>
                Total <Text bold>Printed</Text> Jobs:{" "}
                {data?.numberOfPrintedJobs}
              </Text>
            )}
            <Row p0 m0 mt2>
              <Col flex1>
                <Text bold>Client</Text>
              </Col>
              <Col flex1>
                <Text bold>Number of Jobs</Text>
              </Col>
              <Col flex1>
                <Text bold>Number of Printed</Text>
              </Col>
            </Row>
            {listClients.map((v, vI) => {
              return (
                <Row
                  p0
                  m0
                  borderTopWidth={1}
                  borderTopColor={COLOR.GREY_LIGHT}
                  backgroundColor={
                    vI % 2 === 0 ? COLOR.GREY_LIGHT : "transparent"
                  }
                >
                  <Col flex1>
                    <Text bold>{v.client}</Text>
                  </Col>
                  <Col flex1>
                    <Text>{v.jobs}</Text>
                  </Col>
                  <Col flex1>
                    <Text>{v.printed}</Text>
                  </Col>
                </Row>
              );
            })}
          </Col>
        </Grid>
        <Col height={1} backgroundColor={COLOR.BORDER} mv3 />
        <Customer customers={data?.customers || []} />
      </Col>
    </CMSLayout>
  );
};

ReportsOneClient.routeInfo = {
  title: "Bottled Goose - My Reports",
  path: "/my-report",
};

export default ReportsOneClient;
