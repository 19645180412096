import React, { useEffect, useState } from 'react';
import { IScreen, TProduct } from 'type';
import { CMSLayout, Col, Row, Text, Button, useUIState, Grid, ShimmerLoading, useRefState } from 'components';
import { useNavFunc } from 'navigation';
import { SCREEN } from 'const';
import { TimeHelper } from 'helpers';
import Store from 'store';
import ProductProblems from './ProductProblems';
import ListProductContainer from 'components/widgets/list-product/ListProductContainer';
import ImageBanner from 'components/products/ImageBanner';

const ListProducts: IScreen = () => {
  const { navigation, reset } = useNavFunc();

  const UserStore = Store.useUserStore();
  const { user } = UserStore;
  const ProductStore = Store.useProductStore();
  const [{ fetching, errorMes, loading: btnLoading }, setUI] = useUIState({ fetching: false });
  const [pagination, setPagination] = useState({ hasNext: false, total: 1 });
  const [products, getProducts, setProducts] = useRefState<Array<TProduct>>([]);
  const [listResellers, setListResellers] = useState([]);

  const getData = async (p) => {
    try {
      const u = await UserStore.onReady();
      const resellerId = UserStore.getResellerId(u);
      if (getProducts().length === 0) {
        setUI({ fetching: true, errorMes: '' });
      }
      await TimeHelper.wait(500);
      const { list, hasNext, total, error } = await ProductStore.getList(p, resellerId, { wholeSale: true });
      if (error) return setUI({ fetching: false, errorMes: error });
      setPagination({ hasNext, total });
      setProducts(list);
      setUI({ fetching: false, errorMes: '' });
    } catch (err) {
      setUI({ fetching: false, errorMes: String(err) });
    }
  };

  useEffect(() => {
    if (!user) return;
    if (user.role !== 'admin') return;
    (async () => {
      const { list } = await UserStore.getListResellers(1);
      if (list && list.length > 0) {
        setListResellers([
          { label: "All", value: "all" },
          ...list.map((v) => ({
            label: `${v.firstName} ${v.lastName}`,
            value: v.id,
          })),
        ]);
      }
    })();
  }, [user]);

  const renderListAvailableBrands = (val: TProduct) => {
    if (user?.role !== 'admin') return null;
    const available = Object.keys(val.availableForResellerIds || {})
      // .filter(id => !!val.availableForResellerIds[id])
      .map(id => !val.availableForResellerIds[id] ? undefined : listResellers.find(v => v.value === id)?.label)
      .filter(Boolean);

    return available.length === 0 ? null : (
      <Text caption numberOfLines={2}>
        Brands:
        <Text bold ml0 backgroundColor={'rgba(0,0,0,0.05)'} p0>
          {available.join(', ')}
        </Text>
      </Text>
    );
  }

  useEffect(() => {
    // will getData when screen is focused
    const unsubscribe = navigation.addListener('focus', () => {
      getData(1);
    });
    return unsubscribe;
  }, []);

  // useEffect(() => {
  //   if(products.length === 0) return;
  //   setTimeout(() => {

  //     const el = document.getElementById(id);
  //   if (!el) return;
  //     // @ts-ignore
  //     el.onProductClick = (e) => {
  //       if (!user) return;
  //       if (user.role === 'admin') {
  //         navigation.navigate(SCREEN.UpsertProduct, { id: e });
  //       } else {
  //         navigation.navigate(SCREEN.ProductLibraryResellerDetail, { id: e});
  //       }

  //       }
  //   }, 500);


  // }, [id, products]);

  // useEffect(() => {
  //   if(products.length === 0) return;
  //   setTimeout(() => {

  //     const el = document.getElementById(bannerId);
  //     console.log("el", el)
  //   if (!el) return;
  //     // @ts-ignore
  //     el.onBannerClick = (e) => {


  //       if (!user) return;
  //       if (user.role === 'admin') {
  //         navigation.navigate(SCREEN.UpsertProduct, { id: e });
  //       } else {
  //         navigation.navigate(SCREEN.ProductLibraryResellerDetail, { id: e});
  //       }

  //       }
  //   }, 500);


  // }, [bannerId, products]);

  // useEffect(() => {
  //   if(products.length === 0) return;
  //   setTimeout(() => {

  //     const el = document.getElementById(bannerId);
  //     // console.log("el", el)
  //   if (!el) return;
  //     // @ts-ignore
  //     el.onCloseClick = () => {
  //         el.parentElement.parentElement.parentElement.remove();
  //       }
  //   }, 500);


  // }, [bannerId, products]);

  const renderProducts = () => {
    const productWithBanner = products.filter((val) => val.banner);
    const bannerData = {
      data: productWithBanner.map(p => ({
        id: p.id,
        image: p.banner,
        description: p.description,
        linkdetail: user?.role === 'admin' ? `/product/${p.id}` : `/product-library/${p.id}`,
        price: p.price,
      }))
    }
    return (
      <>
        <ImageBanner
          onBannerClick={(e) => {
            if (!user) return;
            if (user.role === 'admin') {
              navigation.navigate(SCREEN.UpsertProduct, { id: e });
            } else {
              navigation.navigate(SCREEN.ProductLibraryResellerDetail, { id: e });
            }
          }}
          productWithBanner={productWithBanner}
          bannerData={bannerData}
        />

        <ListProductContainer
          products={products}
          onProductClick={(id) => {
            if (!user) return;
            if (user.role === 'admin') {
              navigation.navigate(SCREEN.UpsertProduct, { id });
            } else {
              navigation.navigate(SCREEN.ProductLibraryResellerDetail, { id });
            }
          }}
        />
      </>
    )
  };

  const renderProductsOld = () => {

    return (


      <>
        {/* {UserStore.user?.role !== 'admin' && (
          <Text caption mv1 marginHorizontal={-20}>* Physical products are added by admin only.</Text>
        )} */}
        <Grid xs='50%' md='25%' marginHorizontal={-30}>

          {products.map((val, i) => {
            const preview3dAvailable = (val?.previewData || []).filter(v => v.previewType === '3d-model').length > 0;
            const preview2dAvailable = (val?.previewData || []).filter(v => v.previewType === 'blender-file').length > 0;
            const colorCorrection = !!val.dropletUrl;
            const printerFileName = !!val.printerIdentificatorCode;

            const productProblems = [
              !preview2dAvailable ? {
                problem: 'Preview missing',
                howToFix: 'Upload blend file',
              } : undefined,
              !colorCorrection ? {
                problem: 'Colors inaccurate',
                howToFix: 'Upload droplet file',
              } : undefined,
              val.physicalWidth === 0 || val.physicalHeight === 0 ? {
                problem: 'Artboard size missing',
                howToFix: 'Fill in the width x height fields',
              } : undefined,
              !val.printAreas || val.printAreas.length === 0 ? {
                problem: 'Print area missing',
                howToFix: 'Fill in the print area fields',
              } : undefined,
              !printerFileName ? {
                problem: 'Printer Identificator code missing',
                howToFix: 'Fill in the Printer Identificator code field',
              } : undefined,
            ].filter(Boolean);
            return (
              <Col bgWhite shadow overflow={'hidden'} round1 m1 p1 key={val.id} onPress={() => {
                if (!user) return;
                if (user.role === 'admin') {
                  navigation.navigate(SCREEN.UpsertProduct, { id: val.id });
                } else {
                  navigation.navigate(SCREEN.ProductLibraryResellerDetail, { id: val.id });
                }
              }}>
                <Row mb0 height={25}>
                  {renderListAvailableBrands(val)}
                </Row>
                <Col height={1} backgroundColor={'rgba(0,0,0,0.05)'} />
                {/* <Text>Artboard size: <Text bold>{val.physicalWidth} x {val.physicalHeight}</Text> ({val.unit})</Text> */}
                <Row justifyContent={'space-between'}>
                  {/* <Row>
                    {!preview2dAvailable ? (
                      <Text fontSize={10} textAlign={'right'} color='red' caption>Preview{'\n'}missing</Text>
                    ) : <Text fontSize={10} textAlign={'right'} color='green' caption>Preview{'\n'}available</Text>}
                    {user.role === 'admin' && !colorCorrection && (
                      <Text fontSize={10} ml1 textAlign={'right'} color='red' caption>Colors{'\n'}inaccurate</Text>
                    )}
                  </Row> */}
                  {user?.role === 'admin' && (
                    <ProductProblems problems={productProblems} />
                  )}
                </Row>
              </Col>
            )
          })}
        </Grid>


      </>
    );
  }

  return (
    <CMSLayout
      requireAuthen
      breadcrumbs={[{ title: `Product Catalogue` }]}
    // description={UserStore.user?.role === 'admin' ? '' : `* Physical products are added by admin only`}
    >
      <Row m2 marginBottom={0} justifyContent={'space-between'}>
        {UserStore.user?.role === 'admin' && (
          <Button
            text="Create"
            width={200} height={40} borderRadius={20}
            onPress={async () => {
              // navigation.navigate(SCREEN.UpsertProduct, { id: 'new' });
              navigation.reset({
                index: 0,
                routes: [{ name: SCREEN.UpsertProduct, params: { id: 'new' } }],
              });
            }}
          />
        )}
      </Row>
      <Col flex1 m2 marginTop={-5} p2={fetching}>
        {errorMes ? (
          <Col flex1 middle>
            <Text color="red" subtitle1>{errorMes}</Text>
          </Col>
        ) : (
          fetching ? (
            <Row height={200} stretch marginHorizontal={-30}>
              <Col round1 bgWhite flex={1} m1 p1>
                <ShimmerLoading flex1 />
              </Col>
              <Col round1 bgWhite flex={1} m1 p1>
                <ShimmerLoading flex1 />
              </Col>
              {window.innerWidth > 768 && (
                <>
                  <Col round1 bgWhite flex={1} m1 p1>
                    <ShimmerLoading flex1 />
                  </Col>
                  <Col round1 bgWhite flex={1} m1 p1>
                    <ShimmerLoading flex1 />
                  </Col>
                </>
              )}
            </Row>
          ) : (
            renderProducts()
          )
        )}

      </Col>
    </CMSLayout>
  );
};

ListProducts.routeInfo = {
  title: 'List Products - Bottled Goose',
  path: '/products',
};

export default ListProducts;
