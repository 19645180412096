import React, { useEffect, useState } from 'react';
import { Col } from 'components';
import * as Animatable from 'react-native-animatable';
import { StyleSheet } from 'react-native';
import { swapAnim } from './swapAnim';

interface ISwapIconEffectProps {
  children: any,
  width: number,
  height: number,
  displayIndex: number,
  effect: 'rotate' | 'fade' | 'rotate-backward',
  [other: string]: any,
}
const rotateAndAppear = {
  0: {
    rotate: '0deg',
    opacity: 0,
  },
  0.25: {
    rotate: '90deg',
  },
  0.5: {
    rotate: '180deg',
    opacity: 0.5,
  },
  0.75: {
    rotate: '270deg',
    opacity: 0.75,
  },
  1: {
    rotate: '360deg',
    opacity: 1,
  },
}
const rotateAndDisappear = {
  0: {
    rotate: '0deg',
  },
  0.25: {
    rotate: '90deg',
  },
  0.5: {
    rotate: '180deg',
    opacity: 0.6,
  },
  0.75: {
    rotate: '270deg',
    opacity: 0.4,
  },
  1: {
    rotate: '360deg',
    opacity: 0,
  },
}

const SwapIconEffect = ({ children, width, height, displayIndex = 0, effect, ...props }: ISwapIconEffectProps) => {
  const arrayChildren = React.Children.toArray(children);
  const [currentIndex, setCurrentIndex] = useState(displayIndex);
  const [upcomingIndex, setUpcomingIndex] = useState(0);
  const [anim, setAnim] = useState<any>({
    current: {
      animation: 'fadeIn',
      duration: 300,
    },
    upcoming: {
      animation: 'fadeIn',
      duration: 300,
    }
  })

  useEffect(() => {
    setUpcomingIndex(displayIndex);
    setAnim({
      current: {
        animation: swapAnim[effect].disappear,
        duration: 600,
        delay: 100,
      },
      upcoming: {
        animation: swapAnim[effect].appear,
        duration: 300,
        delay: 100,
        onAnimationEnd: () => {
          setTimeout(() => {
            setCurrentIndex(displayIndex);
            setUpcomingIndex(-1);
            setAnim({
              current: {},
              upcoming: {},
            });
          }, 200);
        }
      }
    })
  }, [displayIndex, effect]);

  if (arrayChildren.length === 1) return children; // no swap needed
  return (
    <Col width={width} height={width} {...props}>
      <Animatable.View style={styles.container}>
        {/* {arrayChildren.map((child, childIndex) => {
          return (
            <Animatable.View
              key={'swap-child-'+childIndex}
              style={StyleSheet.absoluteFill}
            >
              {child}
            </Animatable.View>
          );
        })} */}
        <Animatable.View
          style={StyleSheet.absoluteFill}
          {...anim.current}
        >
          {arrayChildren[currentIndex]}
        </Animatable.View>
        {upcomingIndex !== -1 && (
          <Animatable.View
            style={StyleSheet.absoluteFill}
            {...anim.upcoming}
          >
            {arrayChildren[upcomingIndex]}
          </Animatable.View>
        )}
      </Animatable.View>
    </Col>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    overflow: 'hidden',
  },
  child: {
    position: 'absolute',
  },
})

export default SwapIconEffect;