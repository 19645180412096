import React, { useEffect, useState, useRef } from 'react';
import { IScreen } from 'type';
import { CMSLayout, Col, Row, Text, Button, TouchField, Input02, Select01, RatioCol, RatioCol2, UploadFile, useUIState, useRefState, Grid, Input, showPopupMessage } from 'components';
import { useNavFunc } from 'navigation';
import { useDynamicResponsiveValue } from 'quickly-react';
import { COLOR, SCREEN } from 'const';
import Store from 'store';
import { Image } from 'react-native';
import { Entypo, AntDesign } from '@expo/vector-icons';

const UpsertInstance: IScreen = () => {

  const { navigation, route } = useNavFunc();
  // @ts-ignore
  const { id } = route.params || {};

  const UserStore = Store.useUserStore();
  const InstanceStore = Store.useInstaceStore();

  const { instance, setProductInstance } = InstanceStore.useProductInstance(id);

  const [{ loading: imageLoading }, setImageUI] = useUIState();
  const [{ loading: submitting }, setSubmitUI] = useUIState();
  const [{ loading: removing }, setRemoveUI] = useUIState();

  const onChangeField = (label) => (newValue) => {
    setProductInstance({
      ...instance,
      [label]: newValue,
    })
  }

  const submit = async () => {
    setSubmitUI({ loading: true });
    try {

    } catch (err) {
      showPopupMessage({
        title: '',
        content: String(err),
        buttonOkText: 'OK',
        // 
        typeHighlight: 'danger',
        contentHighlight: 'Error'

      });
      // alert(String(err));
    }
    setSubmitUI({ loading: false });
  }

  return (
    <CMSLayout requireAuthen>
      <Row m2 marginBottom={0} justifyContent={'space-between'}>
        <Text h3>{id === 'new' ? 'Create new product' : `${instance?.name}`}</Text>
        <Row>
          <Button
            isLoading={submitting}
            text={id === 'new' ? "Create" : "Save"}
            width={100} height={40} borderRadius={20}
            onPress={submit}
          />
        </Row>
      </Row>
      <Col flex1 m2 mv1 p1 round1 bgWhite overflow={'scroll'}>
        <Grid xs='100%' md='50%' alignItems={'flex-start'} mb2>
          <Col m1>
            <Text subtitle1 mb1>Product Name</Text>
            <Input02
              height={35}
              value={instance?.name || ''}
              onChange={onChangeField('name')}
              mb2
            />

            <Text subtitle1 mb1>Select design:</Text>
            <Select01
              value={undefined}
              onChange={() => {

              }}
              options={[]}
              mb1
            />

          </Col>
          <Col m1>
          </Col>
        </Grid>

      </Col>
    </CMSLayout>
  );
};

UpsertInstance.routeInfo = {
  title: 'Product Instance - Bottled Goose',
  path: '/product-instances/:id'
};

export default UpsertInstance;
