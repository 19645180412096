import React, { useState, useEffect, useCallback } from 'react';
import {
  Col,
  Row,
  TouchField,
  Text,
} from "components";

import * as Animatable from 'react-native-animatable';
import { HEADER_LOGIN_HEIGHT } from './CMSLayout';
import SideMenu from './SideMenu';
import { useNavFunc } from "navigation";
import { useWindowDimensions } from 'react-native';

const Drawer = ({ show, breadcrumbs, description } : any) => {
  const { height } = useWindowDimensions();
  const [removed, setRemoved] = useState(true);
  useEffect(() => {
    if (show) {
      // disable body scrolling
      document.body.style.overflowY = 'hidden';
      setRemoved(false);
    } else {
      // disable body scrolling
      document.body.style.overflowY = 'auto';
      setTimeout(() => {
        setRemoved(true);
      }, 500);
    }
  }, [show]);

  const onPressItem = useCallback(async () => {
    setRemoved(true);
  }, [])

  return removed ? null : (
    <Animatable.View
      animation={show ? 'fadeInLeft' : 'fadeOutLeft'}
      duration={500}
      // @ts-ignore
      style={{
        // width: width,
        height: height - HEADER_LOGIN_HEIGHT,
        // @ts-ignore
        position: 'fixed',
        left: 0,
        // bottom: 0,
        right: 0,
        top: HEADER_LOGIN_HEIGHT,
        backgroundColor: 'white',
        // @ts-ignore
        overflow: 'auto',
      }}
    >
      <SideMenu onPressItem={onPressItem} breadcrumbs={breadcrumbs} description={description} />
    </Animatable.View>
  )
};

export default Drawer;
