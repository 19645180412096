import { Col, Row, Text, CMSLayout, useUIState, TouchField, Button, Grid, modal, Table, showPopupMessage, Input, BgIcon } from 'components';
import React, { useState, useEffect, useMemo } from 'react';
import { IScreen, TCMSOrder } from 'type';
import Store, { Api } from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN, COLOR } from 'const';
import { Image, useWindowDimensions } from 'react-native';
import moment from 'moment';
import { FontAwesome5, MaterialCommunityIcons, Ionicons } from '@expo/vector-icons';
import { ValHelper } from 'helpers';
import { commonFuncs } from './ListOrders.funcs';
import axios from 'axios';

const OrderDetailAdminOverride = ({ invoice }: any) => {
  const { width, height } = useWindowDimensions();
  const [manualInvoiceData, setManualInvoiceData] = useState(invoice.manualInvoiceData || {});
  const [manualInvoicePdf, setManualInvoicePdf] = useState(invoice.manualInvoicePdf);
  const [isSaving, setIsSaving] = useState(false);

  const setField = (field) => (value) => {
    setManualInvoiceData(v => ({ ...v, [field]: value }))
  }

  const save = async () => {
    setIsSaving(true);
    try {
      const res = await Api.Order.manuallyOverrideInvoice({
        invoiceId: invoice.id,
        productPrice: +manualInvoiceData.productPrice,
        postage: +manualInvoiceData.postage,
        vat: +manualInvoiceData.vat,
        total: +manualInvoiceData.total,
        manualInvoicePdf,
      });
      if (res.data.error) {
        showPopupMessage({ content: res.data.error, typeHighlight: 'error', buttonOkText: 'OK', contentHighlight: 'Error' });
      } else {
        await showPopupMessage({ content: 'Order has been updated successfully', typeHighlight: 'success', buttonOkText: 'OK', contentHighlight: 'Success' });
        modal.hide();
        window.location.reload();
      }
    } catch (err) { }
    setIsSaving(false);
  }

  const handleUpdateManualInvoice = () => {
    return new Promise((resolve, reject) => {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'application/pdf';
      input.onchange = async (e) => {
        // @ts-ignore
        const file = e.target.files[0];
        const [name, ext] = file.name.split(".");
        const id = new Date().getTime();
        const upload = await Api.User.getUploadPresignedUrl({
          contentType: file.type,
          serverSidePath: `${name}-${id}.${ext}`,
        });
        let s3Url = upload.data.data;
        const pdfUpload = await axios.put(s3Url, file, {
          headers: { "Content-Type": file.type },
          onUploadProgress: (progressEvent: any) => {
            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            console.log(progress); // Update your UI or logic based on progress
          },
        });
        if (pdfUpload.status !== 200) {
          showPopupMessage({
            title: '',
            content: 'PDF Upload Failed',
            buttonOkText: 'OK',
            typeHighlight: 'danger',
            contentHighlight: 'Error'
          })
          return;
        }
        s3Url = s3Url.split("?")[0];
        setManualInvoicePdf(s3Url);
      };
      input.click();
    });
  }

  return (
    <Col round1 bgWhite shadow p2 onPress={() => { }} activeOpacity={1} width={width <= 600 ? width * 0.95 : width * 0.5} minHeight={width <= 600 ? height * 0.95 : 300}>
      <Text h3 mb2>Order override</Text>
      <Col flex1>
        <Text caption mb0>Product price</Text>
        <Input mb2 value={manualInvoiceData.productPrice} onChange={setField('productPrice')} />
        <Text caption mb0>Postage</Text>
        <Input mb2 value={manualInvoiceData.postage} onChange={setField('postage')} />
        <Text caption mb0>VAT</Text>
        <Input mb2 value={manualInvoiceData.vat} onChange={setField('vat')} />
        <Text caption mb0>Total</Text>
        <Input mb2 value={manualInvoiceData.total} onChange={setField('total')} />
        <Col mb2>
          <Text>Invoice URL override (Admin only)</Text>
          <Text caption mt0>* in case of legacy invoice needs repairing, admin can manually make invoice pdf and upload here.</Text>
          <Col>
            <Input
              mt1
              borderColor={'rgba(0,0,0,0.05)'}
              value={manualInvoicePdf}
              onChange={(newVal) => {
                setManualInvoicePdf(newVal);
              }}
            />
            <TouchField cirle middle absolute top={12} right={8}
              onPress={handleUpdateManualInvoice}
            >
              <BgIcon name="upload" size={20} color="black" />
            </TouchField>
          </Col>
        </Col>
      </Col>
      <Row justifyContent={'space-between'}>
        <Col>

        </Col>
        <Row>
          <Button
            isLoading={isSaving}
            text="Save"
            width={100}
            height={30}
            mr1
            onPress={save}
          />
          <Button
            outline
            text="Close"
            width={100}
            height={30}
            onPress={() => modal.hide()}
          />
        </Row>
      </Row>
    </Col>
  );
}

export default OrderDetailAdminOverride;