import { Col, Text, Button, Input, commonStyles } from 'components';
import { IScreen } from 'type';
import { Image, View } from 'react-native';
import React, { useEffect, useMemo, useState } from 'react';
import { COLOR, SCREEN } from 'const';
import { ASSETS } from 'assets';
import { useNavFunc } from 'navigation';
import Store from 'store';

const ResetPassword: IScreen = () => {
  const { navigate, route } = useNavFunc();
  const { code }: any = route.params || {};
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMes, setErrorMes] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    if (!code) setShowMessage(true);
    else {
      Store.Api.User.verifyForgotPassword({ code }).then(res => {
        if (!res.data.success) setShowMessage(true);
      }).catch(() => {
        setShowMessage(true);
      })
    }
  }, [code])

  const onSubmit = async () => {
    try {
      if (loading) return;
      setLoading(true);
      const res = await Store.Api.User.resetPassword({
        code,
        newPassword: password,
      });
      if (!res.data.success) {
        setErrorMes(res.data.error);
      } else {
        setShowSuccessMessage(true);
      }
    } catch (error) {
      setErrorMes(error.message || JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  }

  const renderContent = useMemo(() => {
    if (showMessage) {
      return (
        <Col middle padding={20}>
          <Text textAlign={"center"}>The reset password link is expired or invalid</Text>
        </Col>
      )
    }
    if (showSuccessMessage) {
      return (
        <Col middle padding={20}>
          <Text body1 bold mv1>Password reset successfully</Text>
          <Button
            mt2
            solid
            text='Back to login'
            width='100%'
            onPress={() => navigate(SCREEN.Login)}
          />
        </Col>
      )
    }
    return (
      <Col padding={20}>
        <Text>Input your new password</Text>
        <Input
          mt2
          value={password}
          onChange={setPassword}
          placeholder={"Input password"}
          password
          inputProps={{
            onSubmitEditing: onSubmit,
          }}
        />
        {!!errorMes &&
          <Text color="red" body1 bold mv1>{errorMes}</Text>
        }
        <Button
          mt2
          solid
          text='Reset password'
          width='100%'
          isLoading={loading}
          disabled={!password}
          onPress={onSubmit}
        />
      </Col>
    )
  }, [showMessage, showSuccessMessage, password, onSubmit, errorMes, loading])

  return (
    <Col flex1 backgroundColor={COLOR.GREY_BG} middle>
      <Col bgWhite borderRadius={8} width={320} style={{ ...commonStyles.shadow, overflow: "hidden" }}>
        <View
          style={{
            backgroundColor: COLOR.MAIN,
            height: 120,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Image source={ASSETS.LOGO} style={{ width: '65%', height: '80%' }} resizeMode='contain' />
        </View>
        {renderContent}
      </Col>
    </Col>
  )
}

ResetPassword.routeInfo = {
  title: 'Reset Password',
  path: '/reset-password',
}

export default ResetPassword;
