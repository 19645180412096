
export const shippingPrice = {
  RM24: 5.2,
  RM48: 4.18,
  DPD: 10.99,
};

export const EUROPEAN_COUNTRIES = ["Russia", "Germany", "France", "Italy", "Spain", "Poland", "Ukraine", "Romania", "Netherlands", "Belgium", "Sweden", "Czech Republic (Czechia)", "Greece", "Portugal", "Hungary", "Belarus", "Austria", "Switzerland", "Serbia", "Bulgaria", "Denmark", "Slovakia", "Finland", "Norway", "Ireland", "Croatia", "Moldova", "Bosnia and Herzegovina", "Albania", "Lithuania", "Slovenia", "North Macedonia", "Latvia", "Estonia", "Luxembourg", "Montenegro", "Malta", "Iceland", "Andorra", "Liechtenstein", "Monaco", "San Marino", "Holy See"];

export const shippingPriceEurope = {
  RM24: 8.99,
  RM48: 15,
  DPD: 14.99,
};
export const shippingPriceTheRestOfTheWorld = {
  RM24: 8.99,
  RM48: 22,
  DPD: 14.99,
};

export const SAMPLE_DISCOUNT = 0.7;
export const DISCOUNT_RATE = 1 - SAMPLE_DISCOUNT;
export const TAX_ONLY_RATE = 0.2;
