import React, { useState, useRef } from 'react';
import { Col, IColProps, Text, Row, modal, Button, TouchField, Grid } from 'components';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { AntDesign } from '@expo/vector-icons';
import { COLOR } from 'const';
const moment = require('moment');
type TRangeValue = {
  startDate: any,
  endDate: any,
}

interface Props extends IColProps {
  value: any,
  onChange: any,
}

const getInitialDate = () => {
  return {
    startDate: moment().startOf('year').toDate(),
    endDate: moment().endOf('year').toDate(),
  }
}

export const useRangeDate = (initial = getInitialDate()) => {
  return useState<TRangeValue>(initial);
}

const ModalDate = ({ value, onDateUpdate }) => {
  const [range, setRange] = useRangeDate(value);
  const rangeRef = useRef(value);
  const updateRange = (v : any) => {
    rangeRef.current = v;
    setRange(v);
  }

  return (
    <Col p1 bgWhite width={375} activeOpacity={1}>
      <DateRange
        onChange={item => {
          // @ts-ignore
          updateRange(item.selection);

          // if (moment(item.selection.startDate).isValid() && moment(item.selection.endDate).isValid()) {
          //   onDateUpdate(item.selection);
          //   setTimeout(() => {
          //     modal.hide();
          //   }, 500);
          // }
        }}
        moveRangeOnFirstSelection
        ranges={[{
          ...range,
          key: 'selection',
        }]}
        rangeColors={[COLOR.MAIN]}
        showDateDisplay={false}
        color={COLOR.MAIN}
        onShownDateChange={data => {
          console.log('onShownDateChange', data);
        }}
        onRangeFocusChange={data => {
          if (data[0] === 0 && data[1] === 0) {
            setTimeout(() => {
              onDateUpdate(rangeRef.current);
            }, 300)
            setTimeout(() => {
              modal.hide();
            }, 600)
          }
        }}
      />
      <Row mt1>
        <Button
          mr1
          text='Clear all dates'
          outline
          onPress={() => {
            onDateUpdate({ startDate: undefined, endDate: undefined });
            modal.hide();
          }}
          flex1
        />
        <Button
          text='Cancel'
          solid
          onPress={() => {
            modal.hide();
          }}
          flex1
        />
      </Row>

    </Col>
  )
}

const RangeDatePicker = ({ value, onChange, ...props }: Props) => {
  // const [state, setState] = useState([
  //   {
  //     startDate: new Date(),
  //     endDate: null,
  //     key: 'selection'
  //   }
  // ]);



  const show = () => {
    modal.show(
      <ModalDate value={value} onDateUpdate={onChange} />
    )
  }
  return (
    <Col onPress={show}>
      <Grid width={200} xs='100%' md='50%' {...props}>
        <Row margin={2} padding={2} height={30} borderRadius={4} borderThin justifyContent={'space-between'} bgWhite>
          <Text fontSize={10} marginLeft={2} color={!value.startDate ? '#C7C7C7' : undefined}>{!value.startDate ? 'From' : moment(value.startDate).format('DD/MM/YYYY')}</Text>
          <TouchField cirle middle>
            <AntDesign name="calendar" size={18} color="black" />
          </TouchField>
        </Row>
        <Row margin={2} padding={2} height={30} borderRadius={4} borderThin justifyContent={'space-between'} bgWhite>
          <Text fontSize={10} marginLeft={2} color={!value.startDate ? '#C7C7C7' : undefined}>{!value.endDate ? 'To' : moment(value.endDate).format('DD/MM/YYYY')}</Text>
          <TouchField cirle middle>
            <AntDesign name="calendar" size={18} color="black" />
          </TouchField>
        </Row>
      </Grid>
    </Col>
  )
};

RangeDatePicker.useRangeDate = useRangeDate;

export default RangeDatePicker;