
export const initalCheckShopifyEmbedAppFlow = (shopify_embed_app_url, merchant_name) => {
  // const params = new URLSearchParams(document.location.search);
  // const shopify_embed_app_url = params.get('shopify_embed_app_url')
  // const merchant_name = params.get('merchant_name')

  if (!!shopify_embed_app_url && !!merchant_name) {
    // @ts-ignore
    window.isEmbedAppFlow = true;
    // @ts-ignore
    window.shopify_embed_app_url = shopify_embed_app_url;
    // @ts-ignore
    window.merchant_name = merchant_name;
  }
}

export const useShopifyEmbedAppFlow = () => {
  return {
    // @ts-ignore
    isEmbedAppFlow: !!window.isEmbedAppFlow,
    // @ts-ignore
    shopify_embed_app_url: window.shopify_embed_app_url,
    // @ts-ignore
    merchant_name: window.merchant_name,
  };
}