import create from 'zustand';
import { useEffect, useState, Dispatch } from 'react';
import { useUIState } from 'components';
import { TProductInstance } from 'type';
import Client from './api-client';


interface IInstanceStore {
  instances: {
    [instanceId: string]: TProductInstance,
  },
  getList(p: number, resellerId?: string): Promise<{ hasNext: boolean, list: Array<TProductInstance>, total: number, error?: string, }>,
  useProductInstance(id: string, useCache?: boolean) : {
    instance: TProductInstance | undefined,
    uiState: {
      fetching: boolean,
      errorMes: string,
    },
    setProductInstance: Dispatch<TProductInstance>,
  },
  [otherKey: string]: any,
}

export const useInstaceStore = create<IInstanceStore>((set, get) => ({
  instances: {},
  getList: async (page = 1, resellerId) => {
    const res = await Client.Api.Instance.list({ page, resellerId });

    if (!res.data.success) {
      return {
        hasNext: false,
        list: [],
        total: 0,
        error: res.data.error,
      }
    }

    if (res.data.data.list && Array.isArray(res.data.data.list)) {
      const obj : IInstanceStore['products'] = {}
      res.data.data.list.forEach((j : TProductInstance) => {
        obj[j.id] = j;
      });
      set({ products: { ...get().jobs, ...obj } });
    }
    return res.data.data;
  },
  useProductInstance: (id : string, useCache = true) => {
    
    const [{ fetching, errorMes }, setUI]= useUIState();
    const [instance, setProductInstance] = useState<TProductInstance>();

    useEffect(() => {
      if (id === 'new') return;
      if (useCache && !!get().products[id]) {
        setProductInstance(get().products[id]);
        return;
      }
      (async () => {
        setUI({ fetching: true });
        try {
          const res = await Client.Api.Product.detail({ id });
          if (res.data.error) {
            setUI({ fetching: false, errorMes: res.data.error });
            return;
          }
          if (res.data.data) {
            setProductInstance(res.data.data);
            setUI({ fetching: false, errorMes: '' });
          }
        } catch(err) {
          setUI({ fetching: false, errorMes: String(err) });
        }
      })();
    }, [id, useCache]);

    return { instance, setProductInstance, uiState: { fetching, errorMes } };
  }
}));
