import Request from '../Request.utils'
export interface IUpsertRequestBody {
  urls?: string[];
}
export interface IRemoveRequestParams {
  id: string;
}
export interface IListRequestQuery {
  page?: number;
  resellerId?: string;
}


class StickerAPI {
  upsert = async (body: IUpsertRequestBody) => {
    const res = await Request.call('/api/sticker', 'POST', undefined, undefined, body, );
    return res;
  }
  remove = async (params: IRemoveRequestParams) => {
    const res = await Request.call('/api/sticker/:id', 'DELETE', params, undefined, undefined, );
    return res;
  }
  list = async (query: IListRequestQuery) => {
    const res = await Request.call('/api/stickers', 'GET', undefined, query, undefined, );
    return res;
  }
}
export default new StickerAPI()