import { Col, Row, Text, CMSLayout, useUIState, ShimmerLoading, TouchField, Button, PrintJobItem, SwapIconEffect, Grid, showPopupMessage } from 'components';
import React, { useState, useEffect, useMemo } from 'react';
import { IScreen, TPrintJob, TUser } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN, COLOR } from 'const';
import { ActivityIndicator } from 'react-native';
import moment from 'moment';
import { SVG } from 'assets';
import { SimpleLineIcons, Foundation } from '@expo/vector-icons';
import { saveAs } from 'file-saver';
import { TimeHelper } from 'helpers';
import { Entypo, Feather, Ionicons } from '@expo/vector-icons';

const ListPrintJobs: IScreen = () => {

  const { navigation } = useNavFunc();
  const UserStore = Store.useUserStore();
  const PrintJobStore = Store.usePrintJobStore();
  const [{ fetching, errorMes, loading: btnLoading }, setUI] = useUIState({ fetching: false });
  const [pagination, setPagination] = useState({ hasNext: false, total: 1 });
  const [jobs, setJobs] = useState<Array<TPrintJob>>([]);
  const [searchedJobs, setSearchedJobs] = useState<Array<TPrintJob>>([]);
  const [selectedJobs, setSelectedJobs] = useState<{ [id: string]: boolean }>({});

  const [listReseller, setListReseller] = useState<Array<TUser>>([]);

  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [page, setPage] = useState(1);

  const atLeastOneSelected = useMemo(() => {
    return Object.keys(selectedJobs).filter(id => !!selectedJobs[id]).length > 0;
  }, [selectedJobs]);

  const getData = async (p) => {
    try {
      setUI({ fetching: p === 1 ? true : false, errorMes: '' });
      if (p !== 1) setIsLoadingMore(true);
      await TimeHelper.wait(300);
      const { list, hasNext, total, error } = await PrintJobStore.getList(p);
      console.log({ list, hasNext, total, error });
      if (error) return setUI({ fetching: true, errorMes: error });
      setPage(p);
      setPagination({ hasNext, total });
      setJobs(p === 1 ? list : [
        ...jobs,
        ...list,
      ]);
      setUI({ fetching: false, errorMes: '' });
      setIsLoadingMore(false);
    } catch (err) {
      setUI({ fetching: true, errorMes: String(err) });
    }
  };

  const search = async (id) => {
    if (!id) return setSearchedJobs([]);
    const res = await Store.Api.PrintJob.detail({ id });
    if (res.data.data) {
      setSearchedJobs([res.data.data]);
    }
  }

  useEffect(() => {
    // will getData when screen is focused
    const unsubscribe = navigation.addListener('focus', () => {
      getData(1);
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    (async () => {
      const { list } = await UserStore.getListResellers(1);
      if (list && list.length > 0) {
        setListReseller(list);
      }
    })();
  }, []);

  const handlePrintJob = async (val: TPrintJob) => {
    // const { width, height } = val.data?.artworkSize || { width: 300, height: 300 };
    // const res = await Store.Api.Pdf.fromUrl({
    //   url: val.artworkUrl,
    //   printJobId: val.id,
    //   width, height,
    // });
    if (val.clientId === '578953627077') {
      if (!val.data?.skuPDF) return showPopupMessage({
        title: '',
        content: 'Mising SKU PDF',
        buttonOkText: 'OK',
        // 
        typeHighlight: 'danger',
        contentHighlight: 'Error'

      });
      // alert('Mising SKU PDF');
      window.open(val.data.skuPDF, '_blank');
      return;
    }
    // if (!val.isPaid) {
    //   const shouldContinue = window.confirm('This print job has not been paid yet. Are you sure you want to Print this?');
    //   if (!shouldContinue) return;
    // }
    if (val.data?.pdf) {
      saveAs(val.data?.pdf, val.productName + ".pdf");
      return;
    }
    const { physicalHeight, physicalWidth, printAreas } = val.data.product;
    if (physicalWidth === 0 || physicalHeight === 0) {
      return showPopupMessage({
        title: '',
        content: 'This print job physical sizes are not valid',
        buttonOkText: 'OK',
        // 
        typeHighlight: 'danger',
        contentHighlight: 'Error'

      });
      // alert('This print job physical sizes are not valid');
    }
    const res = await Store.Api.Pdf.fromPrintJobPTS({
      id: val.id,
      designId: val.designId,
      clientId: val.clientId,
      images: val.artworkUrls,
      data: {
        product: {
          physicalHeight,
          physicalWidth,
          printAreas,
        }
      },
    })
    if (res.data.error) {
      return showPopupMessage({
        title: '',
        content: String(res.data.error),
        buttonOkText: 'OK',
        // 
        typeHighlight: 'danger',
        contentHighlight: 'Error'

      });
      //  alert(res.data.error);
    }
    await saveAs(res.data, `${val.productName}.pdf`);
  };

  const downloadAll = async () => {
    setUI({ loading: true });
    const arr = Object.keys(selectedJobs).filter(id => !!selectedJobs[id]);
    for (let i = 0; i < arr.length; i++) {
      const printJobId = arr[i];
      const findPrintJob = jobs.find(j => j.id === printJobId);
      if (!findPrintJob) continue;
      await handlePrintJob(findPrintJob);
    }
    setUI({ loading: false });
  };
  const markPrintedAll = async () => {
    const arr = Object.keys(selectedJobs).filter(id => !!selectedJobs[id]);
    const res = await Store.Api.PrintJob.markPrintedMultiple({ ids: arr });
    if (res.data.error) return showPopupMessage({
      title: '',
      content: String(res.data.error),
      buttonOkText: 'OK',
      // 
      typeHighlight: 'danger',
      contentHighlight: 'Error'

    });
    // alert(res.data.error);
    setJobs(j => j.filter(item => !arr.includes(item.id)));
    setSelectedJobs({});
  };

  const renderJobList = () => {
    if (jobs.length === 0) {
      return (
        <Col flex1>
          <Text>No jobs found</Text>
        </Col>
      )
    }
    return (
      <Col flex1>
        <Grid xs='100%' md='100%' lg='1:any'>
          <Row
            height={50} stretch
            borderBottomColor={COLOR.GREY_BG}
            borderBottomWidth={1}
          >
            <Col width={50} m1>

            </Col>
            <Col flex1 m1>
              <Text color={COLOR.GREY}>Client</Text>
            </Col>
            <Col flex1 m1>
              <Text color={COLOR.GREY}>Product</Text>
            </Col>
            {/* <Col flex1 m1>
              <Text color={COLOR.GREY}>Preview</Text>
            </Col> */}
            <Col width={50} m1>
              <Text color={COLOR.GREY}>QTY</Text>
            </Col>
            <Col flex1 m1>
              <Text color={COLOR.GREY}>Date</Text>
            </Col>
            {/* <Col flex1 m1>
              <Text color={COLOR.GREY}>Paid?</Text>
            </Col> */}
            {/* <Col flex1 m1>
              <Text color={COLOR.GREY}>Production Notes</Text>
            </Col> */}
            <Col flex1 m1>
              <Text color={COLOR.GREY}>PDF</Text>
            </Col>
            <Col flex1 m1>
              <Text color={COLOR.GREY}>Packing Slips</Text>
            </Col>
          </Row>
          <Col width={220} m1 />
        </Grid>
        <Col>
          {(searchedJobs.length > 0 ? searchedJobs : jobs).map((val, i) => {
            let findReseller = !val.clientId ? undefined : listReseller.find(u => u.id === val.clientId);
            if (val.clientId === 'partnet-in-wine') {
              findReseller = {
                id: 'partnet-in-wine',
                firstName: 'Partner',
                lastName: 'In Wine',
                role: 'reseller',
                email: '',
                password: '',
              }
            }
            return (
              <PrintJobItem
                key={val.id}
                data={val}
                isSearchJob={searchedJobs.length > 0}
                reseller={findReseller}
                onDownload={() => handlePrintJob(val)}
                isSelected={selectedJobs[val.id]}
                onSelected={() => setSelectedJobs({
                  ...selectedJobs,
                  [val.id]: !selectedJobs[val.id],
                })}
                onDataUpdate={(newData) => {
                  const list = jobs.slice();
                  list[i] = newData;
                  setJobs(list);
                }}
                onDelete={async () => {
                  const res = await Store.Api.PrintJob.remove({
                    id: val.id
                  });
                  if (res.data.error) {
                    showPopupMessage({
                      title: '',
                      content: String(res.data.error),
                      buttonOkText: 'OK',
                      // 
                      typeHighlight: 'danger',
                      contentHighlight: 'Error'
              
                    });
                    // alert(res.data.error)
                  } else {
                    const newJobs = jobs.filter(j => j.id !== val.id);
                    setJobs(newJobs);
                  }
                }}
              />
            )
          })}
        </Col>
        {!!pagination.hasNext && (
          <Col middle mt1>
            <TouchField
              onPress={() => {
                getData(page + 1);
              }}
              borderWidth={1}
              borderColor={COLOR.MAIN}
            >
              <Col width={200} height={40} middle>
                <Text>{isLoadingMore ? 'Loading more...' : 'Load more'}</Text>
              </Col>
            </TouchField>
          </Col>
        )}
      </Col>
    )
  }

  return (
    <CMSLayout
      requireAuthen
      searchPlaceholder="Search Print Job ID"
      onSearchInput={search}
    >
      <Row m2 marginBottom={0} justifyContent={'space-between'}>
        <Text h3 >Jobs</Text>
        {/* <Button
          isLoading={btnLoading}
          text="Add dummy job"
          width={200} height={40} borderRadius={20}
          onPress={async () => {
            setUI({ loading: true });
            await Store.Api.PrintJob.addNewRandom({ number: 5 });
            await getData(1);
            setUI({ loading: false });
          }}
        /> */}
        <Row>
          {atLeastOneSelected ? (
            <>
              <Button
                text="Mark all as printed"
                width={200} height={40} borderRadius={20}
                onPress={markPrintedAll}
                mr1
              />
              <Button
                isLoading={btnLoading}
                text="Download all"
                width={200} height={40} borderRadius={20}
                onPress={downloadAll}
              />
            </>
          ) : null}
        </Row>
      </Row>
      <Col flex1 m2 mv1 p2 round1 bgWhite>
        {errorMes ? (
          <Col flex1 middle>
            <Text color="red" subtitle1>{errorMes}</Text>
          </Col>
        ) : (
          fetching ? (
            <Col>
              <Row height={50} stretch mt2>
                <ShimmerLoading round1 flex={1} m1 />
                <ShimmerLoading round1 flex={1} m1 />
                <ShimmerLoading round1 flex={1} m1 />
                <ShimmerLoading round1 flex={1} m1 />
              </Row>
              <Row height={50} stretch mt2>
                <ShimmerLoading round1 flex={1} m1 />
                <ShimmerLoading round1 flex={1} m1 />
                <ShimmerLoading round1 flex={1} m1 />
                <ShimmerLoading round1 flex={1} m1 />
              </Row>
            </Col>

          ) : (
            renderJobList()
          )
        )}
      </Col>
    </CMSLayout>
  )
};

ListPrintJobs.routeInfo = {
  title: 'List Print Jobs - Bottled Goose',
  path: '/print-jobs',
};

export default ListPrintJobs;
