import React, { useState } from 'react';
import { Col, Text, Row } from 'components';
import { COLORS } from 'const';
import { Pressable } from 'react-native';
import { ASSETS } from 'assets';

const StepContainer = (props) => {
  const [shown, setShow] = useState(true);

  return (
    <Col style={{
      backgroundColor: 'white',
      borderRadius: 4,
      borderWidth: 1,
      borderColor: COLORS.BORDER2,
      padding: 24,
      marginTop: props.marginTop,
      marginBottom: props.marginBottom,
      marginLeft: 10,
    }}
    >
      <Row justifyContent="space-between">
        <Row >
          <Pressable
            //@ts-ignore
            style={{ outline: "none", zIndex: undefined }} onPress={() => setShow(s => !s)}>
            <img
              src={shown ? ASSETS.ARROW_UP : ASSETS.ARROW_DOWN}
              style={{ width: 40, height: 40, }}
            />
          </Pressable>
          <Col >
            <Text selectable fontSize={14} fontWeight={700} color={COLORS.TEXT_GREY} marginLeft={10}>
              {props.step}
            </Text>
            <Text selectable fontSize={18} fontWeight={700} color="black" marginTop={4} marginLeft={10}>
              {props.title}
            </Text>
          </Col>
        </Row>
        {/* <TutorialLink /> */}
      </Row>
      {shown ? props.content : undefined}
    </Col>
  );
};

export default StepContainer;
