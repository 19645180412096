import React, { useState, useEffect, useRef } from 'react';
import { Col, Text, Input, TouchField, Row, modal, useUIState } from 'components';
import { COLOR } from 'const';
import Store from 'store';
import { ActivityIndicator } from 'react-native';
import { TimeHelper } from 'helpers';
import { TGeneralData, TPrintJob } from 'type';

const CustomerAddressModal = ({ printJob, shipping_address, customer }: any) => {

  const [markAsShippedText, setMarkAsShippedText] = useState('Mark as Shipped');

  useEffect(() => {
    if (printJob.data.useBGServiceShipped) {
      setMarkAsShippedText('Shipped');
    }
  }, [printJob]);

  const markAsShipped = async () => {
    if (markAsShippedText === 'Shipped') return;
    setMarkAsShippedText('Marking as shipped...');
    try {
      const res = await Store.Api.BgService.markAsShipped({
        orderId: printJob.data.orderId,
        printJobId: printJob.id,
        resellerId: printJob.clientId,
      });
      if (res.data.success) {
        setMarkAsShippedText('Shipped');
      }
    } catch(err) {
      setMarkAsShippedText('Error');
      setTimeout(() => {
        setMarkAsShippedText('Mark as Shipped');
      }, 3000);
    }
  };

  return (
    <Col round1 bgWhite shadow p2>
      <Text fontSize={16} mb2>Shipping Address</Text>
      <Col backgroundColor={'rgba(0,0,0,0.1)'} p2 round1>
        {!shipping_address ? (
          <Text>Customer info unavailable. Please wait until the order is placed</Text>
        ) : (
          <>
            <Text mb2>Name: {shipping_address.first_name} {shipping_address.last_name}</Text>
            <Text mb1>Address:</Text>
            <Text mb0>{shipping_address.address1}</Text>
            <Text mb0>{shipping_address.address2}</Text>
            <Text mb2>{shipping_address.city} {shipping_address.zip} {shipping_address.country}</Text>
            <Text mb2>{shipping_address.phone}</Text>
            <Text mb2>Email address: {customer.email}</Text>
          </>
        )}
      </Col>

      <Row justifyContent={'space-between'} mt2>
        {printJob.data?.useBGService ? (
          <TouchField
            height={30}
            width={170}
            middle
            borderColor={COLOR.GREY}
            borderRadius={15}
            borderWidth={1}
            m0
            ph2
            onPress={markAsShipped}
          >
            <Text color={COLOR.GREY}>{markAsShippedText}</Text>
          </TouchField>
        ) : <Col />}
        <TouchField
          height={30}
          width={70}
          middle
          borderColor={COLOR.GREY}
          borderRadius={15}
          borderWidth={1}
          m0
          ph2
          onPress={() => {
            return modal.hide();
          }}
        >
          <Text color={COLOR.GREY}>Close</Text>
        </TouchField>
      </Row>
    </Col>
  );
};

export default CustomerAddressModal;