import React, { useState, useEffect, useRef } from 'react';
import { Col, Text, Input, TouchField, Row, modal, useUIState } from 'components';
import { COLOR } from 'const';
import Store from 'store';
import { ActivityIndicator } from 'react-native';
import { TimeHelper } from 'helpers';
import { TCustomer, TGeneralData, TPrintJob, TShopifyListOrder, TShopifyOrder } from 'type';

interface IProps {
  shipping_address: any,
  customer: TCustomer,
}

const CustomerAddressModal = ({ shipping_address, customer }: IProps) => {

  return (
    <Col round1 bgWhite shadow p2>
      <Text fontSize={16} mb2>Shipping Address</Text>
      <Col backgroundColor={'rgba(0,0,0,0.1)'} p2 round1>
        {!shipping_address ? (
          <>
            <Text mb2>Name: {customer.first_name} {customer.last_name}</Text>
            <Text mb2>Email address: {customer.email}</Text>
            <Text>Shipping address not found. Maybe this is a free product that does not require shipping?</Text>
          </>
        ) : (
          <>
            <Text mb2>Name: {shipping_address.first_name} {shipping_address.last_name}</Text>
            <Text mb1>Address:</Text>
            <Text mb0>{shipping_address.address1}</Text>
            <Text mb0>{shipping_address.address2}</Text>
            <Text mb2>{shipping_address.city} {shipping_address.zip} {shipping_address.country}</Text>
            <Text mb2>{shipping_address.phone}</Text>
            <Text mb2>Email address: {customer.email}</Text>
          </>
        )}
      </Col>

      <Row justifyContent={'space-between'} mt2>
        <TouchField
          height={30}
          width={70}
          middle
          borderColor={COLOR.GREY}
          borderRadius={15}
          borderWidth={1}
          m0
          ph2
          onPress={() => {
            return modal.hide();
          }}
        >
          <Text color={COLOR.GREY}>Close</Text>
        </TouchField>
      </Row>
    </Col>
  );
};

export default CustomerAddressModal;