import { Col, Text, Button, Input } from 'components';
import React, { useState, useEffect } from 'react';
import { IScreen } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN } from 'const';
import { ActivityIndicator, Image } from 'react-native';

import sampleFile from './sample-file.json';

function dataURLtoFile(dataurl, filename) {
 
  var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), 
      n = bstr.length, 
      u8arr = new Uint8Array(n);
      
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  
  return new File([u8arr], filename, {type:mime});
}

const SampleUploading : IScreen = () => {

  const [s3DirectUrl, setS3DirectUrl] = useState('');

  const upload = async () => {
    const name = `random-${Math.random()}.jpeg`;
    const res = await Store.Api.User.getUploadPresignedUrl({
      serverSidePath: name,
      contentType: sampleFile.type,
    });

    const uploadUrl = res.data.data
    const res2 = await fetch(res.data.data, {
      method: 'PUT',
      headers: {
        'Content-Type': sampleFile.type,
      },
      body: dataURLtoFile(sampleFile.base64, name),
    })
    if (res2.status === 200) {
      const u = new URL(uploadUrl);
      setS3DirectUrl(`${u.origin}${u.pathname}`);
    }

  }

  return (
    <Col flex1 middle>
      {s3DirectUrl && (
        <Image source={{ uri: s3DirectUrl }} style={{ width: 300, height: 300 }} resizeMode='contain' />
      )}
      <Button mt2 text="Upload Sample" onPress={upload} />
    </Col>
  )
};

SampleUploading.routeInfo = {
  title: 'Sample Uploading',
  path: '/sample-uploading',
};

export default SampleUploading;