import React, { useEffect, useRef, useState } from 'react';
import { Animated } from 'react-native';
import * as Animatable from 'react-native-animatable';

export const HEADER_LOGIN_WIDTH = 269 * 0.7;
export const HEADER_LOGIN_HEIGHT = 79 * 0.7;

const animate = (
  value : Animated.Value | Animated.ValueXY,
  toValue: Animated.TimingAnimationConfig['toValue'],
  duration: Animated.TimingAnimationConfig['duration'],
  delay : Animated.TimingAnimationConfig['delay'] = 0,
) => new Promise((resolve) => {
  Animated.timing(value, {
    toValue,
    duration,
    delay,
    useNativeDriver: false,
  }).start(({ finished }) => {
    resolve(finished);
  });
});

export const useExpansionAnim = (expanded?: boolean) => {

  const shouldDoExpansionAnim = expanded !== undefined;

  const headerLogoXY = useRef(new Animated.ValueXY({ x: 0, y: 0 })).current;
  const headerContainerTranslateY = useRef(new Animated.Value(0)).current;
  const mainContentLeftTop = useRef(new Animated.ValueXY({
    x: HEADER_LOGIN_WIDTH,
    y: 0,
  })).current;

  useEffect(() => {
    if (typeof expanded !== 'boolean') return;
    if (expanded) {
      Promise.all([
        animate(headerLogoXY, { x : -HEADER_LOGIN_WIDTH, y: -HEADER_LOGIN_HEIGHT }, 300),
        animate(headerContainerTranslateY, -HEADER_LOGIN_HEIGHT, 300, 200),
        animate(mainContentLeftTop, { x: 0, y: -HEADER_LOGIN_HEIGHT }, 300, 200),
      ])
    } else {
      Promise.all([
        animate(headerContainerTranslateY, 0, 300),
        animate(headerLogoXY, { x : 0, y: 0 }, 300, 100),
        animate(mainContentLeftTop, {
          x: HEADER_LOGIN_WIDTH,
          y: 0,
        }, 300),
      ])
    }
  }, [expanded])
  
  return {
    shouldDoExpansionAnim,
    headerAnimated: {
      logoXY: headerLogoXY,
      containerY: headerContainerTranslateY,
    },
    mainAnimated: {
      top: mainContentLeftTop.y,
      left: mainContentLeftTop.x,
    }
  }

};

export const animStill = {
  0: {
    translateX: 0,
    opacity: 1,
  },
  0.5: {
    translateX: 0,
    opacity: 1,
  },
  1: {
    translateX: 0,
    opacity: 1,
  },
}

export const AnimatableView = ({ firstRenderAnim, animation, ...props } : any) => {
  const [anim, setAnim] = useState(firstRenderAnim);
  const rerenderCount = useRef(0);
  useEffect(() => {
    rerenderCount.current++;
    if (rerenderCount.current !== 1) {
      setAnim(animation);
    }
  }, [animation]);
  return (
    <Animatable.View
      animation={anim}
      {...props}
    />
  )
}