import React, { useEffect, useState } from 'react';
import { TouchField, Col, Row, Text, SwapIconEffect, modal } from 'components';
import { TPrintJob } from 'type';
import { SVG } from 'assets';
import { SimpleLineIcons, Feather, MaterialIcons, AntDesign } from '@expo/vector-icons';
import { TimeHelper } from 'helpers';
import {
  ActivityIndicator,
  Image,
} from 'react-native';
import { COLOR } from 'const';
import Store from 'store';
import * as Animatable from 'react-native-animatable';
import NoteModal from './NoteModal';

interface IPrintHistoryItemProps {
  data: TPrintJob,
  onDataUpdate(newData: TPrintJob): void,
}

const ICON = {
  REPRINT: 0,
  LOADING: 1,
  DONE: 2
}

const PrintHistoryItem = ({ data, onDataUpdate }: IPrintHistoryItemProps) => {
  const val = data;
  const [actionIcon, setActionIcon] = useState<[number, 'rotate' | 'fade']>([ICON.REPRINT, 'rotate']);
  const [key, setKey] = useState(Math.random());
  const [anim, setAnim] = useState({});
  const [showItem, setShowItem] = useState(true);

  useEffect(() => {
    if (!data.isPrinted) {
      setAnim({
        animation: 'fadeOut',
        duration: 500,
        onAnimationEnd: () => {
          setShowItem(false);
        }
      })
    }
  }, [data.isPrinted]);

  const handleReprint = async () => {
    setActionIcon([ICON.LOADING, 'rotate']);
    await TimeHelper.wait(1000);
    try {
      await Store.Api.PrintJob.upsert({
        id: val.id,
        isPDFDownloaded: false,
        isPrinted: false,
        isRePrinted: true,
      })
    } catch (err) {
      setActionIcon([ICON.REPRINT, 'rotate']);
    }
    setActionIcon([ICON.DONE, 'rotate']);
    await TimeHelper.wait(1000);
    setActionIcon([ICON.REPRINT, 'fade']);
    onDataUpdate({
      ...val,
      isPDFDownloaded: false,
      isPrinted: false,
      isRePrinted: true,
    });
  };

  const showPreview = () => {
    const image = (
      <Image
        style={{
          width: 300,
          height: 300,
        }}
        resizeMode='contain'
        source={{ uri: val.previewUrl }}
      />
    )
    modal.show(image);
  }

  const showNotes = () => {
    modal.show(<NoteModal printJobId={val.id} />);
  }

  return showItem ? (
    <Animatable.View {...anim} style={{ flexDirection: 'row', alignItems: 'center' }} key={val.id}>
      <Col flex1 m1>
        <Text>{val.clientId === '2022' ? '' : val.clientId}</Text>
      </Col>
      <Col flex1 m1>
        <Text>{val.productName}</Text>
      </Col>
      <Col flex1 m1>
        <TouchField cirle middle onPress={showPreview}>
          <SVG.Eye />
        </TouchField>
      </Col>
      <Col flex1 m1>
        <Text>{val.quantity}</Text>
      </Col>
      <Col flex1 m1>
        <Text>{TimeHelper.formatDay(val.createdAt)}</Text>
      </Col>
      <Col flex1 m1>
        <Text></Text>
      </Col>
      <Col flex1 m1>
        <TouchField cirle middle onPress={showNotes}>
          <SimpleLineIcons name="notebook" size={20} color="black" />
        </TouchField>
      </Col>
      <Col flex1 m1>
        <SwapIconEffect
          width={40}
          height={40}
          displayIndex={actionIcon[0]}
          key={key}
          effect={actionIcon[1] || 'rotate'}
        >
          <TouchField cirle middle onPress={handleReprint}>
            <AntDesign name="retweet" size={24} color="black" />
          </TouchField>
          <Col flex1 middle>
            <ActivityIndicator size="small" color="black" />
          </Col>
          <Col flex1 middle>
            <MaterialIcons name="done" size={20} color={COLOR.GREEN} />
          </Col>
        </SwapIconEffect>
      </Col>
    </Animatable.View>
  ) : null;
};

export default PrintHistoryItem;
