import { useState, useEffect } from 'react';
import list_currencies from './list_currencies.json';

import Store from 'store';

export const useListCurrencies = () : Array<{ rate: number; cc: string; symbol: string; name: string; }> => {
  const [list, setList] = useState<Array<{ rate: number; cc: string; symbol: string; name: string; }>>([]);
  useEffect(() => {

    (async() => {
      const res = await Store.Api.Payment.listCurrencies();
      setList(res.data.data);
    })();
    // fetch('https://api.apilayer.com/fixer/latest?base=GBP', {
    //   headers: {
    //     'apiKey': 'gRu8s8SHOLF3iSrp74saZZCS8C4bLHrT'
    //   }
    // })
    // .then(res => res.json())
    // .then(json => {
    //   const newList : Array<{ rate: number; cc: string; symbol: string; name: string; }> = [];
    //   const { rates } = json;

    //   ['GBP', 'USD', 'EUR', 'AUD', 'CNY', 'JPY'].forEach(key => {
    //     const findCurrency = list_currencies.find(val => val.cc === key);
    //     if (!!findCurrency) newList.push({ ...findCurrency, rate: rates[key] });
    //   })
    //   setList(newList);
    // });
  }, []);

  return list;
}