import { useNavigation, useRoute } from '@react-navigation/native';
import { useNavigationMiddleWare } from './NavigationContext';
import { modalConfirm } from 'helpers';

export const reset = (navigation, screenName, params) => {
  navigation.reset({ index: 0, routes: [{ name: screenName, params }] });
};
 
export const goBackOrTo = (navigation, screenName) => {
  const { index } = navigation.dangerouslyGetState();
  index === 0 ? navigation.navigate(screenName) : navigation.goBack();
};

let _idDatas = {};
export const goWithPreData = (navigation, screenName, params, preData) => {
  if (preData.id) _idDatas[preData.id] = preData;
  navigation.navigate(screenName, params);
};
export const getScreenPreData = (id) => _idDatas[id];

export const useNavFunc = () => {
  const navigation = useNavigation();
  const { getEditting, updateEditting } = useNavigationMiddleWare();
  const route = useRoute();

  const showEditWarning = () => new Promise<boolean>(resolve => {
    modalConfirm({
      title: 'Warning',
      content: 'Are you sure you want to exit without saving?',
      onOk: () => resolve(false),
      onCancel: () => resolve(true),
    })
  })

  const navigateWrapper = async (...arg: any) => {
    let shouldSkip = false;
    const editting = getEditting();
    if (editting?.[route.name]) {
      shouldSkip = await showEditWarning();
      if (shouldSkip) return;
      updateEditting(route.name, false);
    }
    navigation.navigate(...arg);
  }

  const resetWrapper = (navigation, screenName, params) => {
    const editting = getEditting();
    if (editting?.[route.name]) return showEditWarning();
    reset(navigation, screenName, params);
  }

  return {
    route,
    navigation: {
      ...navigation,
      navigate: navigateWrapper,
    },
    navigate: navigateWrapper,
    goWithPreData: (screenName, params, preData) => goWithPreData(navigation, screenName, params, preData),
    getScreenPreData,
    reset: (screenName, params = {}) => resetWrapper(navigation, screenName, params),
    goBackOrTo: (screenName) => goBackOrTo(navigation, screenName),
  };
};
