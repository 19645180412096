import { Col, Row, Text } from 'components';
import React from 'react';
import { BgIcon } from 'components';
import { COLOR } from 'const';
import { Image, useWindowDimensions } from 'react-native';
import { useDynamicResponsiveValue } from 'quickly-react';
import { FontAwesome5 } from "@expo/vector-icons";

const AnyTroubleUI = () => {
  const rV = useDynamicResponsiveValue(['xs', 'lg']);
  const isMobile = rV({ xs: true, lg: false })
  return (

    <Col style={{ minWidth: 230, }}>
      <Col
        style={{
          height: 120,
          marginBottom: 10,
        }}>
        <Col style={{
          marginLeft: 10,
        }}>
          <Text h4>Any trouble?</Text>

          <Text paddingVertical={5} body1>
            Don't hesiate to ask for support!</Text>
          <Row paddingVertical={5}>
            <Image
              style={{
                height: 18,
                width: 18,
              }}
              source={require('../../../assets/img/IconPhoneBottledGoose.png')} />
            <Text body1 marginLeft={17}>0208 941 3484
            </Text>
          </Row>
          <Row paddingVertical={5}>
            <Image
              style={{
                height: 15,
                width: 15,
                borderRadius: 3,
              }}
              source={require('../../../assets/img/IconEmailBottledGoose.png')} />
            <Text body1 marginLeft={20}>hello@bottledgoose.com
            </Text>
          </Row>
          {isMobile && (
            <Col
              onPress={() => {
                window.open("https://www.bottledgoose.co.uk/")
              }}
            >
              <Row paddingVertical={5}>
                <FontAwesome5
                  name="youtube"
                  size={18}
                  color={COLOR.MAIN}
                />
                <Text body1 marginLeft={16}>Helpful Guides
                </Text>
              </Row>
            </Col>
          )}
        </Col>
      </Col>
    </Col>
  );
}
export default AnyTroubleUI;