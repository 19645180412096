import { Col, IColProps } from "components/base";
import React, { useState } from "react";
import { ImageStyle } from "react-native";
import { Image, StyleSheet } from "react-native";
import { Image as AntImage } from "antd";
import BgIcon from "../BgIcon";
import TouchField from "../button/TouchField";

interface Props extends IColProps {
  uri: string,
  onPressRemove?: () => void,
  style?: ImageStyle,
  canRemove?: boolean,
}

const ImageItem = ({ uri, onPressRemove, style, canRemove, ...props }: Props) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  return (
    // @ts-ignore
    <Col style={{ outline: "none" }} onPress={() => setPreviewVisible(true)} {...props}>
      <Image
        source={{ uri }}
        style={[styles.imageItem, style]}
      />
      {canRemove &&
        <TouchField onPress={onPressRemove} absolute right={-8} top={-8} bgWhite borderRadius="50%">
          <BgIcon name="cross" size={16} />
        </TouchField>
      }
      <AntImage
        style={{ display: 'none', width: 0, height: 0 }}
        src={uri}
        preview={{
          visible: previewVisible,
          src: uri,
          onVisibleChange: setPreviewVisible,
        }}
      />
    </Col>
  )
}

const styles = StyleSheet.create({
  imageItem: {
    width: 120,
    aspectRatio: 1,
    overflow: "hidden",
    borderWidth: 1,
    borderColor: "#D9D9D9",
  },
})

export default ImageItem;
