import * as React from "react"
import { SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M16 6.463C9.886 6.463 4.342 9.808.25 15.24a1.267 1.267 0 0 0 0 1.512c4.092 5.44 9.636 8.785 15.75 8.785 6.114 0 11.658-3.345 15.75-8.778a1.266 1.266 0 0 0 0-1.512C27.658 9.808 22.114 6.463 16 6.463Zm.439 16.254a6.735 6.735 0 0 1-7.155-7.155c.21-3.352 2.926-6.068 6.277-6.278a6.735 6.735 0 0 1 7.155 7.155c-.216 3.345-2.932 6.061-6.277 6.277Zm-.203-3.103a3.618 3.618 0 0 1-3.85-3.85c.112-1.806 1.578-3.266 3.385-3.383a3.618 3.618 0 0 1 3.849 3.849c-.118 1.813-1.584 3.273-3.384 3.384Z"
        fill={props.color || "#000"}
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default SvgComponent
