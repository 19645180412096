import { TouchField } from './index';
import { FontAwesome5 } from '@expo/vector-icons';
import React from 'react';
import { COLOR } from '../../const';

interface IProps {
  isSelected: boolean;
  toggleSelect: any;
  translateY?: number;
}

const Checkbox = ({ isSelected, toggleSelect, translateY = 0 }: IProps) => {
  return (
    <TouchField cirle middle onPress={toggleSelect} transform={[{ translateY: translateY }]}>
      {isSelected ? (
        <FontAwesome5 name="check-circle" size={18} color={COLOR.MAIN} />
      ) : (
        <FontAwesome5 name="circle" size={18} color={COLOR.MAIN} />
      )}
    </TouchField>
  );
}

export default React.memo(Checkbox);
