import create from 'zustand';
import Client from './api-client'
import { TCard } from 'type/TStripe';
import { loadStripe } from '@stripe/stripe-js';
import { showPopupMessage } from 'components';


interface IPaymentStore {
  loading: boolean,
  stripePromise: any,
  card: any,
  balance: any,
  stripeClientSecret: string,
  stripePublishKey: string,
  removingCard: boolean,
  getStripeConfig: (createdIfNotExisted?: boolean) => void,
  refreshBalance: () => Promise<any>,
  getExistedCard: () => Promise<TCard>,
  removeCard: () => Promise<any>,
  getBalanceTransactions: (startingAfter?: string) => Promise<any>,
  adminGetBalanceTransactions: (resellerId: string, startingAfter?: string) => Promise<any>,
}

export const usePaymentStore = create<IPaymentStore>((set, get) => ({
  loading: true,
  card: null,
  stripePromise: null,
  balance: null,
  removingCard: false,
  stripeClientSecret: '',
  stripePublishKey: '',
  getStripeConfig: async (createdIfNotExisted = true) => {
    if (get().stripePromise && get().stripeClientSecret) return;
    try {
      const res = await Client.Api.Payment.publicKey()
      if (res?.data?.data) set({
        stripePublishKey: res?.data?.data,
        stripePromise: loadStripe(res?.data?.data),
      });

      const res2 = await Client.Api.Payment.createSetupIntent({ createdIfNotExisted });
      if (res2.data?.data?.client_secret) {
        set({ stripeClientSecret: res2.data.data.client_secret });
      }
    } catch (error) {
    } finally {
      set({ loading: false });
    }
  },
  refreshBalance: async () => {
    const res = await Client.Api.Payment.getBalance();
    if (res.data?.data) {
      set({ balance: res.data.data });
    }
  },
  getExistedCard: async () => {
    const res = await Client.Api.Payment.listCard();
    if (res?.data?.data?.data) {
      const card = res.data.data.data.find(i => i.type === "card");
      get().refreshBalance();
      set({ card });
      return card;
    }
  },
  getBalanceTransactions: async (startingAfter) => {
    let obj: any = {};
    if (startingAfter) obj.starting_after = startingAfter;
    const res = await Client.Api.Payment.getBalanceTransactions(obj);
    if (res?.data?.data?.length) {
      return res?.data;
    }
    return {
      data: [],
      hasNext: false,
    };
  },
  adminGetBalanceTransactions: async (resellerId, startingAfter) => {
    let obj: any = {};
    if (startingAfter) obj.starting_after = startingAfter;
    const res = await Client.Api.Payment.adminGetBalanceTransactionsOfAReseller(
      { resellerId },
      obj,
    );
    return res?.data?.data;
  },
  removeCard: async () => {
    if (get().removingCard) return;
    set({ removingCard: true });
    try {
      const res = await Client.Api.Payment.removeCard();
      if (!res.data.success) {
        showPopupMessage({
          title: '',
          content: String(res.data.error),
          buttonOkText: 'OK',
          
          typeHighlight: 'danger',
          contentHighlight: 'Error'
    
        });
        // alert(res.data.error);
        return;
      }
      set({
        card: null,
        removingCard: false,
      });
      return res.data;
    } catch (error) {
      set({ removingCard: false });
    }
  },
}));
