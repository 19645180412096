import { Row, showPopupMessage } from 'components';
import React, { useState, useCallback } from 'react';
import { TCMSOrder } from 'type';
import Store, { Api } from 'store';
import { useNavFunc } from 'navigation';
import { LOG_TYPES, SCREEN } from 'const';
import RequestUtils from 'store/api-client/Request.utils';
import ToastHelper from 'helpers/ToastHelper';
import { Button as AntButton, Tooltip } from 'antd';
import { downloadArtworkAndPackingSlip } from 'helpers/FileHelper';
import { getPDFDataFromPipelines, useConfirmCancelOrder } from '../utils';
import { commonFuncs } from '../ListOrders.funcs';
import GlobalEvent from 'js-events-listener';

export const OrderActionButtons = ({ stage, rejectJob, listSelected, onDeleteSuccess }: any) => {
  const UserStore = Store.useUserStore();
  const OrderStore = Store.useOrderStore();
  const { navigation } = useNavFunc();
  const [loadings, setLoadings] = useState({});
  const isAdmin = UserStore.user?.role === "admin";
  const { show: showConfirm } = useConfirmCancelOrder();

  const onDownload = async () => {
    const candles = [];
    const listOfpdfs = listSelected.map((order: TCMSOrder) => {
      OrderStore.dispatchToRoyalMail(order);
      const { pdfStages, displayValue } = getPDFDataFromPipelines(order.Pipelines);
      displayValue.candleStickers?.forEach((item) => {
        const { lineId, candleSticker, name, orderId } = item;
        candles.push({
          url: candleSticker,
          name: `${order["Order ID"]}_line-${lineId}.pdf`,
          lineId,
          orderId,
          shopifyProductId: item.product_id,
        })
      })
      return displayValue.pdfs.map((item) => {
        const { lineId, pdf, name, orderId } = item;
        console.log('item.product_id', item.product_id);
        return {
          url: pdf,
          name: `${order["Order ID"]}_line-${lineId}.pdf`,
          lineId,
          orderId,
          orderNumber: order["Order Number"],
          shopifyProductId: item.product_id,
          quantity: item.quantity,
          designId: item.designId,
        };
      });
    });
    let artworks;
    if (listOfpdfs.length === 0) {
      artworks = [];
    } else {
      artworks = listOfpdfs.length === 1 ? listOfpdfs[0] : listOfpdfs.reduce((a, b) => [...a, ...b]);
    }
    const res = await Api.Design.getPDFNames({
      items: artworks,
    });
    if (res.data.success && res.data.data.length === artworks.length) {
      artworks = res.data.data;
    }
    const slips = listSelected.map((order: TCMSOrder) => {
      const { displayValue } = getPDFDataFromPipelines(order.Pipelines);
      return {
        url: `${RequestUtils.host}/api/online-stores/order-packing-slips/${order['Order ID']}?clientId=${order['Client ID']}`,
        name: `${order["Order Number"]}.pdf`,
        pdfUrl: displayValue?.packingSlipUrl,
      }
    })
    ToastHelper.showProgress(0);
    await downloadArtworkAndPackingSlip({ artworks, slips, candles }, (progress) => {
      ToastHelper.showProgress(progress);
    });
  }

  const markAsAccepted = async (id?: string) => {
    if (isAdmin) {
      if (loadings[id]) return;
      setLoadings(s => ({ ...s, [id]: true }));
      try {
        await OrderStore.updateOrderStage(listSelected, {
          Status: "Accepted",
          Stage: "In Production",
          StageStatus: "On Time",
        });
        listSelected.forEach((order: TCMSOrder) => {
          Api.Log.addLog({
            type: LOG_TYPES.UPDATE_ORDER,
            data: {
              orderId: order['Order ID'],
            },
            oldData: {
              status: order.Status,
              stage: order.Stage,
              stageStatus: order.StageStatus,
            },
            newData: {
              status: "Accepted",
              stage: "In Production",
              stageStatus: "On Time",
            }
          })
        })
        await onDownload();
      } catch (error) {
        showPopupMessage({
          title: '',
          content: String(error),
          buttonOkText: 'OK',
          typeHighlight: 'danger',
          contentHighlight: 'Error'
        });
      } finally {
        setTimeout(() => {
          setLoadings(s => ({ ...s, [id]: false }));
          navigation.navigate(SCREEN.ListOrders, { stage: 'in-production' });
        }, 500);
      }
    } else {
      commonFuncs.resellerPayPendingOrder(listSelected, navigation);
    }
  }

  const holdJob = async () => {
    const res = await commonFuncs.holdOrders(listSelected, isAdmin);
    if (res) GlobalEvent.emit('REFRESH_LIST_ORDERS', true);
  }

  const resumeJob = async () => {
    const res = await commonFuncs.resumeOrders(listSelected, isAdmin);
    if (res) GlobalEvent.emit('REFRESH_LIST_ORDERS', true);
  }

  const onDelete = useCallback(async () => {
    // const success = await commonFuncs.onDelete(listSelected);
    const confirmData = await showConfirm({
      message: `Do you want to ${isAdmin ? "reject" : "cancel"} ${listSelected.length === 1 ? 'this order' : 'these orders'}`,
      orders: listSelected
    });
    if (!confirmData?.confirmed) return;
    await OrderStore.updateOrderStage(listSelected, {
      Status: "Rejected",
      Stage: "Cancelled",
      StageStatus: isAdmin ? "Rejected" : "Cancelled",
    }, {
      deleteShopify: confirmData?.data?.deleteShopify
    });
    listSelected.forEach((order: TCMSOrder) => {
      Api.Log.addLog({
        type: isAdmin ? LOG_TYPES.REJECT_ORDER : LOG_TYPES.CANCEL_ORDER,
        data: {
          orderId: order['Order ID'],
        },
        oldData: {
          status: order.Status,
          stage: order.Stage,
          stageStatus: order.StageStatus,
        },
        newData: {
          status: "Rejected",
          stage: "Cancelled",
          stageStatus: isAdmin ? "Rejected" : "Cancelled",
        }
      })
    })
    onDeleteSuccess?.();
  }, [listSelected, onDeleteSuccess]);

  const isHoldingJob = listSelected.some(i => i.StageStatus?.includes("Held"));
  const canHoldJob = listSelected.some(i => !i.StageStatus?.includes("Held") && ["Pre Production"].includes(i.Stage)); // ["Pre Production", "In Production"].includes(i.Stage))
  const isPreProductionJob = listSelected.some(i => i.Stage === "Pre Production");
  const isInProductionJob = listSelected.some(i => i.Stage === "In Production");
  const noUnPaidOrder = !listSelected.some(i => !i.Pipelines?.[i.Pipelines?.length - 1]?.SharedData?.isPaid);

  const btns = [
    {
      id: "accept",
      title: isAdmin ? "Mark in Production" : "Pay & Proceed",
      onPress: () => markAsAccepted("accept"),
      visible: isPreProductionJob,
      disabled: !isAdmin && noUnPaidOrder,
      tooltip: !isAdmin && noUnPaidOrder ? "You have already paid these orders. Please wait for admin approval" : undefined,
    },
    {
      title: "Hold",
      onPress: holdJob,
      visible: canHoldJob,
    },
    {
      title: "Resume",
      onPress: resumeJob,
      visible: isHoldingJob,
    },
    {
      title: "Reject job",
      onPress: rejectJob,
      visible: isPreProductionJob && isAdmin,
    },
    {
      title: "Download Selected Jobs",
      onPress: onDownload,
      visible: isInProductionJob && isAdmin,
    },
    {
      title: "Cancel",
      onPress: onDelete,
      danger: true,
      visible: isPreProductionJob || (isInProductionJob && isAdmin), // isInProductionJob // && (stage === "pre-production" && !isAdmin) || (stage !== "fulfillment" && isAdmin)
    }
  ].filter(i => i.visible);

  return (
    <Row>
      {btns.map((btn, idx) => (
        <Tooltip
          key={`${btn.title}${idx}`}
          title={btn.tooltip}
        >
          <AntButton
            // size="large"
            type="link"
            loading={btn.id ? loadings[btn.id] : false}
            danger={btn.danger}
            onClick={btn.onPress}
            disabled={btn.disabled}
          >
            {btn.title}
          </AntButton>
        </Tooltip>
      ))}
    </Row>
  )
}
