import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Col, showPopupMessage, Text, TextArea } from 'components';
import { LOG_TYPES, SCREEN } from 'const';
import { Modal } from 'antd';
import { useOrderStore } from 'store/Order.Store';
import { useNavFunc } from 'navigation';
import { TCMSOrder } from 'type';
import { Api } from 'store';
import { useConfirmCancelOrder } from './utils';
import { useModalConfirm } from 'navigation/ModalConfirmContext';

const RejectOrderModal = ({ selectedOrders }: { selectedOrders: any }, ref) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [reason, setReason] = useState('');
  const OrderStore = useOrderStore();
  const { navigation } = useNavFunc();
  const { show: showConfirm } = useConfirmCancelOrder();

  useImperativeHandle(ref, () => ({
    show: () => {
      setShow(true);
      setReason('');
      setError('');
      setLoading(false);
    },
  }));

  const onSubmit = async () => {
    try {
      if (loading) return;
      setError('');
      setLoading(true);
      // const confirmData = await showConfirm({
      //   message: `Do you want to reject ${selectedOrders.length === 1 ? 'this order' : 'these orders'}`,
      //   orders: selectedOrders
      // });
      // if (!confirmData?.confirmed) return;
      await OrderStore.updateOrderStage(selectedOrders, {
        Status: "Rejected",
        Stage: "Cancelled",
        StageStatus: "Rejected",
      }, {
        reason,
        // deleteShopify: confirmData?.data?.deleteShopify
      });
      selectedOrders.forEach((order: TCMSOrder) => {
        Api.Log.addLog({
          type: LOG_TYPES.REJECT_ORDER,
          data: {
            orderId: order['Order ID'],
          },
          oldData: {
            status: order.Status,
            stage: order.Stage,
            stageStatus: order.StageStatus,
          },
          newData: {
            status: "Rejected",
            stage: "Cancelled",
            stageStatus: "Rejected",
          }
        })
      })
      setTimeout(() => {
        setShow(false);
        navigation.navigate(SCREEN.ListOrders, { stage: 'cancelled' });
      }, 600);
    } catch (error) {
      setError(error.message || JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      open={show}
      title={`Reject order${selectedOrders?.length > 1 ? 's' : ''}`}
      onOk={onSubmit}
      onCancel={() => setShow(false)}
      okText="Send"
      confirmLoading={loading}
    >
      <Col height={12} />
      <TextArea
        height={80}
        placeholder='Enter reason'
        value={reason}
        onChangeText={setReason}
      />
      <Text mt2 color="red">{error}</Text>
    </Modal>
  );
};

export default forwardRef(RejectOrderModal);
