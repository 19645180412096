import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import {
  Col,
  Grid,
  RatioCol,
  Text,
  useRefState,
  useUIState,
} from "components";
import { COLOR } from "const";
import { TSticker } from "type";
import { Store } from "store";
import { TimeHelper } from "helpers";
import { Image } from "react-native";
import { AntDesign } from "@expo/vector-icons";

interface IProps {}

const StickersSettings = (props : IProps, ref) => {
  const [{ fetching, errorMes, loading: btnLoading }, setUI] = useUIState({
    fetching: false,
  });

  useImperativeHandle(ref, () => ({
    getData: () => {
      getData(1);
    },
  }));
  
  const [stickers, getStickers, setStickers] = useRefState<Array<TSticker>>([]);
  const [pagination, setPagination] = useState({ hasNext: false, total: 1 });
  const StickerStore = Store.useStickerStore();

  const getData = async (p) => {
    try {
      if (getStickers().length === 0) {
        setUI({ fetching: true, errorMes: "" });
      }
      await TimeHelper.wait(500);
      const { list, hasNext, total, error } = await StickerStore.getList(p);
      console.log({ list, hasNext, total, error });
      if (error) return setUI({ fetching: false, errorMes: error });
      setPagination({ hasNext, total });
      setStickers(list);
      setUI({ fetching: false, errorMes: "" });
    } catch (err) {
      setUI({ fetching: false, errorMes: String(err) });
    }
  };

  useEffect(() => {
    getData(1);
  }, []);

  const onPressDelete = (val, i) => {
    Store.Api.Sticker.remove({id:val.id}).then(() => {
      getData(1);
    })
  }

  return (
    <Col pl2 pr2>
      <Text subtitle1 mb2>Candle Stickers</Text>
      <Grid xs="50%" md="25%" marginHorizontal={-30}>
        {stickers.map((val, i) => {
          return (
            <>
              <Col bgWhite shadow overflow={"hidden"} round1 m1 p1 key={val.id}>
                <RatioCol width={"100%"} ratio={1}>
                  <Image
                    source={{ uri: val.url }}
                    style={{ width: "100%", height: "100%" }}
                    resizeMode="contain"
                  />
                </RatioCol>
              </Col>
              <Col position="absolute" alignSelf="flex-end" onPress={() => onPressDelete(val, i)}>
                <AntDesign name="closecircle" size={24} color={COLOR.MAIN} />
              </Col>
            </>
          );
        })}
      </Grid>
    </Col>
  );
};
export default forwardRef(StickersSettings);
