import { Col, Text, CMSLayout, useShopifyEmbedAppFlow } from 'components';
import React, { useState, useEffect } from 'react';
import { IScreen } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN } from 'const';
import { ActivityIndicator } from 'react-native';

import Report from '../reports/Reports';
import ReportsOneClient from '../reports/ReportsOneClient';
import { RolesHelper } from 'helpers';
import apiClient from 'store/api-client';
import GetStarted from './components/GetStarted';

const HomeGetStarted : IScreen = () => {
  const { navigate, reset } = useNavFunc();
  const UserStore = Store.useUserStore();
  const { user } = UserStore;

  useEffect(() => {
    if (user?.role === 'admin') {
      reset(SCREEN.Reports);
    }
  }, [user])

  const renderCustomElemetns = () => {
    // @ts-ignore
    return <setup-business
      // linkTutorialStep1="https://www.youtube.com/embed/W7lzTmHK_is?si=hgoGL9teE6W7XAgL"
      durianVideoStep1="1 minute"
      // linkTutorialStep2="https://www.youtube.com/embed/Pzz-LSh0Xf4?si=v0HyxZbYGTNNYT3C"
      durianVideoStep2="33 seconds"
      // linkTutorialStep3="https://www.youtube.com/embed/iE1CeihmtzY?si=Ezszii4_M42V57re"
      durianVideoStep3="38 seconds"
      // linkTutorialStep4="https://www.youtube.com/embed/7QmhDVfwLBQ?si=ppAy8XHKKcXJ-iFS"
      durianVideoStep4="19 seconds"
      productPageLink="/products"
      artworkLink="https://www.bottledgoose.co.uk/pages/artboards"
      shopifyLink="https://apps.shopify.com/bottled-goose-print-on-demand"
      etsyLink="/my-stores/new?storetype=etsy"
      titleEtsy="Coming soon"
      uploadProductLink="/my-designs"
      billingLink="/wallet"
      telephone="020 8941 3481"
      email="hello@bottledgoose.com"
    />
  }
  const userName = [user?.firstName, user?.lastName].filter(Boolean).join(' ')
  return (
    <CMSLayout
      requireAuthen
      breadcrumbs={[{ title: `Welcome, ${userName}` }]}
      description={`Let’s setup your business`}
    >
      <Col flex1>
        {/* {renderCustomElemetns()} */}
        <GetStarted />
      </Col>
    </CMSLayout>
  )
};

HomeGetStarted.routeInfo = {
  title: 'Get started - Bottled Goose',
  path: '/get-started',
};

export default HomeGetStarted;
