import { AntDesign, MaterialIcons } from "@expo/vector-icons";
import { Col, IColProps, Row, Text } from "components";
import { COLOR, SCREEN } from "const";
import React, { useEffect } from "react";
import Store from "store";
import { isEmpty } from "lodash";
import { useNavFunc } from "navigation";

interface Props extends IColProps {

}

const InfoItem = ({ title, checked, onPress }) => {
  return (
    <Row mt1>
      {checked ?
        <AntDesign name="checkcircle" size={18} color={COLOR.MAIN} />
        :
        <MaterialIcons name="radio-button-unchecked" size={19} color={COLOR.MAIN} />
      }
      <Text onPress={checked ? undefined : onPress} marginLeft={8} opacity={checked ? 0.6 : 1} body1>
        {title}
      </Text>
    </Row>
  )
}

const ResellerStatus = ({ ...props }: Props) => {
  const UserStore = Store.useUserStore();
  const { getAll, shops } = Store.useShopStore();
  const { card } = Store.usePaymentStore();
  const { navigate } = useNavFunc();

  const getData = async () => {
    try {
      const u = await UserStore.onReady();
      const resellerId = UserStore.getResellerId(u);
      getAll(resellerId);
    } catch (err) { }
  };

  useEffect(() => {
    if (isEmpty(shops)) getData();
  }, []);

  return (
    <Col {...props}>
      <Text h4>
        Reseller connection
      </Text>
      <InfoItem
        title={"Connect your shopify store"}
        checked={!isEmpty(shops)}
        onPress={() => navigate(SCREEN.UpsertStore, { id: "new", storetype: "shopify" })}
      />
      <InfoItem
        title={"Payment card added"}
        checked={!isEmpty(card)}
        onPress={() => navigate(SCREEN.Settings, { tabId: 'billing' })}
      />
    </Col>
  )
}

export default ResellerStatus;
