import { Col, Text, CMSLayout, initalCheckShopifyEmbedAppFlow } from 'components';
import React, { useState, useEffect } from 'react';
import { IScreen } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN } from 'const';
import { ActivityIndicator } from 'react-native';

import Report from '../reports/Reports';
import ReportsOneClient from '../reports/ReportsOneClient';
import { RolesHelper } from 'helpers';
import apiClient from 'store/api-client';

const Home : IScreen = ( ) => {
  const { navigate, route } = useNavFunc();
  // @ts-ignore
  let { shopify_embed_app_url, merchant_name } = route.params || {};
  merchant_name = decodeURI(merchant_name);
 
  // console.log('route', route);

  // const params = new URLSearchParams(document.location.search);
  // const shopify_embed_app_url = params.get('shopify_embed_app_url')
  // const merchant_name = params.get('merchant_name')
  console.log('shopify_embed_app_url', shopify_embed_app_url);
  console.log('merchant_name', merchant_name);

  const isEmbedAppFlow = !!shopify_embed_app_url && !!merchant_name;
  initalCheckShopifyEmbedAppFlow(shopify_embed_app_url, merchant_name);

  const UserStore = Store.useUserStore();
  const ShopStore = Store.useShopStore();
  const { user } = UserStore;
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    
    UserStore.onReady().then((u) => {
      if (!u) {
        navigate(SCREEN.Login);
      } else if(isEmbedAppFlow && u.role === 'admin') {
        Store.Api.User.logout();
        UserStore.set({ user: null, token: "" });
        navigate(SCREEN.Login);
      } else if (isEmbedAppFlow) {
        console.log('HERE isEmbedAppFlow', isEmbedAppFlow);
        apiClient.Api.Shop.createNotExistShopifyEmbedApp({
          name: merchant_name,
          url: shopify_embed_app_url,
        });
      }
    });
  }, []);

  useEffect(() => {
    if (!isLoggedIn && !!user) {
      setIsLoggedIn(true);
    }
    if (!user && isLoggedIn) {
      setIsLoggedIn(false);
    }
    if (!!user && isLoggedIn && user.role === 'reseller') {
      ShopStore.resellerHealthCheck();
    }
  }, [user, isLoggedIn]);

  const userName = !user ? '' : `${user.firstName} ${user.lastName}`;
  const resellerId = user?.role === 'reseller' ? user?.id : user?.role === 'admin' ? 'all'
    : user?.resellerId;

  return !isLoggedIn ? (
    <Col flex1 middle>
      <ActivityIndicator color="black" size="large" />
      <Text mt2>Please wait...</Text>
    </Col>
  ) : <Report resellerId={resellerId} />
};

Home.routeInfo = {
  title: 'Bottled Goose - Print Manager',
  path: '/embed-app/:merchant_name/:shopify_embed_app_url',
};

export default Home;
