import { Col, Text } from 'components';
import React, { useState, useEffect } from 'react';
import { IScreen } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN } from 'const';
import { ActivityIndicator } from 'react-native';

const SampleEditor : IScreen = ( ) => {
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const isInIframe = window.location !== window.parent.location;
    if (!isInIframe) return;
    Store.Client.editor.authorizeParent('*', async function onReceiveToken() {
      console.log('onReceiveToken');
      const res = await Store.Api.User.me();
      if (res.data.success && res.data.data?.id) {
        const { firstName, lastName } = res.data.data;
        setMessage(`Hello ${firstName} ${lastName}, This is an example of the embeded iframe authenticate without re-login using Iframe comunication`);
        setIsLoading(false);
      }
    });
  }, []);

  return isLoading ? (
    <Col flex1 middle>
      <ActivityIndicator color="black" size="large" />
      <Text mt2>Please wait...</Text>
    </Col>
  ) : (
    <Col flex1 middle p2>
      <Text mt2 center>{message}</Text>
    </Col>
  )
};

SampleEditor.routeInfo = {
  title: 'Sample Editor',
  path: '/sample-editor-embed',
};

export default SampleEditor;