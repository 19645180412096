import React, { useState } from 'react';
import { BgIcon, Text } from 'components';
import { Carousel } from 'antd';
import { View } from 'react-native-animatable';
import { Dimensions, Image, LayoutChangeEvent, Pressable, StyleSheet } from 'react-native';
import { ValHelper } from 'helpers';
interface ImageBannerProps {
  productWithBanner: any[];
  bannerData: {
    data?: { id: string; image: string; description: string; linkdetail: string; price: number }[]
  };
  onBannerClick?: (id: string) => void;
}

const ImageBanner: React.FC<ImageBannerProps> = ({ bannerData, onBannerClick }) => {
  const [width, setWidth] = useState(400)
  const [visible, setVisible] = useState(true);

  const onLayout = (e: LayoutChangeEvent) => {
    setWidth(e.nativeEvent.layout.width)
  }

  if (!visible) return null;
  return (
    <View onLayout={onLayout} style={{ marginTop: 10, marginBottom: 20 }}>
      <Carousel autoplay dots={false} fade>
        {bannerData?.data?.map(a => (
          <Pressable
            key={a.id}
            style={styles.bannerContainer}
            onPress={() => onBannerClick(a.id)}
          >
            <Image source={{ uri: a.image }}
              style={{ width, height: width * 0.2, borderRadius: 8 }} />
            <View style={styles.textContainer}>
              <Text style={styles.priceText}>
                £{ValHelper.formatMoney(a.price)} for this item</Text>
            </View>
            <Pressable
              style={styles.closeButton}
              onPress={() => { setVisible(false) }}>
              <BgIcon name="cross" color='white' size={16} />
            </Pressable>
          </Pressable>
        ))}
      </Carousel>
    </View>
  );
}
const styles = StyleSheet.create({
  bannerContainer: {
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height,
  },
  textContainer: {
    position: 'absolute',
    bottom: 10,
    left: 10,
    backgroundColor: 'rgba(216, 218, 229, 1)',
    borderRadius: 5,
  },
  priceText: {
    color: 'rgba(16, 24, 64, 1)',
    fontSize: 16,
    margin: 10,
    fontWeight: 'bold'
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    borderRadius: 15,
    padding: 5
  },
});


export default ImageBanner;
