import { Checkbox, Modal } from "antd";
import { Col, Text, useRefState } from "components";
import { COLORS } from "const";
import React, { ReactElement, useCallback, useContext, useRef, useState } from "react";
import { createContext, memo } from "react";

export interface IModalData {
  title?: string;
  content?: string;
  buttonOkText?: string;
  buttonCancelText?: string;
  onButtonOkClick?: (data?: any) => void;
  onClose?: () => void;
  typeHighlight?: string;
  contentHighlight?: string;
  type?: "normal" | "cancel-order";
  extra?: ReactElement;
}

interface ModalConfirmValues {
  show: (data: IModalData) => void;
}

export const ModalConfirmContext = createContext<ModalConfirmValues>({
  show: () => ({}),
});

export const ModalConfirmProvider = memo((props: any) => {
  const { children } = props;
  const [visible, getVisible, setVisible] = useRefState(false);
  const callbackRef = useRef<Function>();
  const onCloseCallbackRef = useRef<Function>();
  const [data, setData] = useState({
    title: "",
    content: "",
    buttonOkText: "",
    buttonCancelText: "",
    typeHighlight: "success",
    contentHighlight: "",
    type: "normal",
  });
  const [deleteShopify, setDeleteShopify] = useState(false);
  const [extraComp, setExtraComp] = useState<ReactElement>();

  const show = useCallback((data: IModalData) => {
    setVisible(true);
    setData({
      title: data.title,
      content: data.content,
      buttonOkText: data.buttonOkText,
      buttonCancelText: data.buttonCancelText,
      typeHighlight: data.typeHighlight,
      contentHighlight: data.contentHighlight,
      type: data.type,
    });
    if (data.onButtonOkClick) {
      callbackRef.current = data.onButtonOkClick;
    } else {
      callbackRef.current = undefined;
    }
    setExtraComp(data.extra);
    if (data.onClose) {
      onCloseCallbackRef.current = data.onClose;
    } else {
      onCloseCallbackRef.current = undefined;
    }
  }, [])

  const handleOk = useCallback(() => {
    setVisible(false);
    if (typeof callbackRef.current === "function") {
      callbackRef.current?.({
        deleteShopify
      });
    }
  }, [deleteShopify])

  const handleCancel = useCallback(() => {
    setVisible(false)
    if (typeof onCloseCallbackRef.current === "function") onCloseCallbackRef.current?.();
  }, [])

  return (
    <ModalConfirmContext.Provider value={{ show }}>
      {children}
      <Modal
        title={data?.title}
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={data?.buttonOkText || "OK"}
        cancelText={data?.buttonCancelText || "Cancel"}
        okButtonProps={{
          size: "large",
          style: { backgroundColor: COLORS.BLUE, fontWeight: 500 }
        }}
        cancelButtonProps={{
          size: "large",
          type: "text",
          style: { color: COLORS.HEADLINES, backgroundColor: COLORS.BG_DARKER, fontWeight: 500 }
        }}
      >
        <Col marginTop={20} marginBottom={16}>
          {data.typeHighlight === "success" ? (
            <div className="popup-highlight success">
              <img src="/img/tick-circle.svg" />
              <p className="popup-highlight-text">{data.contentHighlight}</p>
            </div>
          ) : data.typeHighlight === "warning" ? (
            <div className="popup-highlight warning">
              <img src="/img/warning.svg" />
              <p className="popup-highlight-text">{data.contentHighlight}</p>
            </div>
          ) : (
            <div className="popup-highlight danger">
              <img src="/img/danger.svg" />
              <p className="popup-highlight-text">{data.contentHighlight}</p>
            </div>
          )}
        </Col>
        <Text lineHeight={20} color={"rgba(34, 51, 99, 1)"}>
          {data?.content}
        </Text>
        {data?.type === "cancel-order" && (
          <Col marginTop={16}>
            <Checkbox checked={deleteShopify} onChange={(e) => setDeleteShopify(e.target.checked)}>
              <Text color={"rgba(34, 51, 99, 1)"}>Also cancel the order on Shopify</Text>
            </Checkbox>
          </Col>
        )}
        {!!extraComp && (
          <Col marginTop={16}>
            {extraComp}
          </Col>
        )}
        <Col height={20} />
      </Modal>
    </ModalConfirmContext.Provider>
  );
});

export const useModalConfirm = () => {
  return useContext(ModalConfirmContext);
}
