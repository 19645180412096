import React from "react";
import { Entypo, Ionicons } from "@expo/vector-icons";
import { Col, IColProps, Row, Text } from "components/base";
import { COLOR } from "const";
import { ValHelper } from "helpers";
import { usePaymentStore } from "store/Payment.Store";

const WalletBalance = (props: IColProps) => {
  const { balance, loading } = usePaymentStore();
  if (!balance) return null;
  const _balance = (balance?.balance * -1) || 0;
  return (
    <Col
      backgroundColor={_balance < 0 ? '#BC0100' : COLOR.MAIN}
      paddingTop={10} paddingBottom={20} round0 {...props}
    >
      <Text marginLeft={14} subtitle1 mb2 color={COLOR.WHITE}>
        Current Balance
      </Text>
      <Row marginLeft={14} minWidth={200}>
        <Ionicons name="wallet-outline" size={44} color={COLOR.WHITE} />
        <Text marginLeft={16} h4 color={COLOR.WHITE}>
          {String(balance?.currency || "GBP").toUpperCase()}
        </Text>
        <Text marginLeft={6} h4 color={COLOR.WHITE}>
          {ValHelper.formatBalance((_balance) / 100)}
        </Text>
      </Row>
    </Col>
  )
}

export default React.memo(WalletBalance);
