import React, { useEffect, useState } from 'react';
import { IScreen, TDesign, TOnlineStore, TProduct, TProductInstance } from 'type';
import { CMSLayout, Col, Row, Text, Button, useUIState, Grid, TouchField, ShimmerLoading, useRefState } from 'components';
import { useNavFunc } from 'navigation';
import { COLOR, SCREEN } from 'const';
import { ASSETS, SVG } from 'assets';
import { TimeHelper } from 'helpers';
import Store from 'store';
import { Image } from 'react-native';
import { AntDesign } from '@expo/vector-icons';

import BrandsSettings from 'screens/resellers/Settings/Settings.Brands';

const ListStore: IScreen = () => {
  const { navigation, navigate } = useNavFunc();

  const UserStore = Store.useUserStore();
  const ShopStore = Store.useShopStore();

  const onPressConnectMore = () => {
    navigation.reset({
      index: 0,
      routes: [
        {
          name: SCREEN.UpsertStore,
          params: { id: "new", storetype: "shopify" },
        },
      ],
    });
  }

  return (
    <CMSLayout requireAuthen>
      <Row m2 marginBottom={0} justifyContent={'space-between'}>
        <Text h3 >My Stores</Text>
        <Button
          iconLeft={<AntDesign name="link" size={15} color={COLOR.WHITE} />}
          text="Connect more stores"
          borderRadius={20}
          paddingVertical={6}
          paddingHorizontal={10}
          onPress={onPressConnectMore}
          touchWidth={190}
          numberOfLineTitle={1}
        />
      </Row>
      <Col bgWhite flex1 m2 p2>
        <BrandsSettings
        
        />
      </Col>
    </CMSLayout>
  );
};

ListStore.routeInfo = {
  title: 'My Stores - Bottled Goose',
  path: '/my-stores',
};

export default ListStore;