import React, { useEffect, useState, useRef, useMemo } from "react";
import { IScreen, TProduct } from "type";
import {
  CMSLayout,
  Col,
  Row,
  Text,
  Button,
  ShimmerLoading,
  TouchField,
  Input02,
  Select01,
  RatioCol,
  RatioCol2,
  UploadFile,
  useUIState,
  useRefState,
  Grid,
  UseMemo,
  modal,
  showPopupMessage,
} from "components";
import { useNavFunc } from "navigation";
import { useDynamicResponsiveValue } from "quickly-react";
import { COLOR, SCREEN } from "const";
import Store from "store";
import { Animated, Image, LayoutAnimation, View } from "react-native";
import {
  Entypo,
  AntDesign,
  FontAwesome5,
  Feather,
  EvilIcons,
} from "@expo/vector-icons";
import { AnimHelper, ValHelper } from "helpers";
import PublishProductModal from "./PublishProductModal";

const TAB = {
  INFO: "INFO",
  EDITOR: "EDITOR",
  items: [
    { title: "Editor", key: "EDITOR" },
    { title: "Design Info", key: "INFO" },
  ],
};

type TSelectResellerOption = {
  label: string;
  value: string;
  data?: any;
};

let loadingCandleTemplate = false;

const UpsertDesign: IScreen = () => {
  const UserStore = Store.useUserStore();
  const { user } = UserStore;
  const DesignStore = Store.useDesignStore();
  const ProductStore = Store.useProductStore();
  const { navigation, route } = useNavFunc();
  // @ts-ignore
  const { designId: id, onDemand, t, productId } = route.params || {};
  const { design, setDesign, uiState } = DesignStore.useDesign(id, false);
  const canEdit =
    user?.role === "admin" ||
    (!!design?.createdByUserId &&
      (design?.createdByUserId === user?.id ||
        design?.createdByUserId === user?.resellerId));
  const [listProducts, setListProducts] = useState([]);

  const [listResellers, setListResellers] = useState<
    Array<TSelectResellerOption>
  >([]);
  const [selectedResellers, setSelectedResellers] = useState<
    Array<TSelectResellerOption>
  >([]);

  const [expand, setExpand] = useState(false);
  const [expandUI, setExpandUI] = useState(false);

  const [curTab, setCurTab] = useState(t === "info" ? TAB.INFO : TAB.EDITOR);
  console.log('curTab', t, curTab);
  useEffect(() => {
    navigation.setParams({
      t: curTab.toLowerCase(),
    });
  }, [curTab]);

  const didRemindToSave = useRef(false);

  // const [{ loading: removing }, setRemoveUI] = useUIState();
  // const [{ loading: submitting }, setSubmitUI] = useUIState();

  const [selectedProduct, setSelectedProduct] = useState<any>();

  const titleXY = useRef(new Animated.ValueXY({ x: 0, y: 0 })).current;
  const titleScale = useRef(new Animated.Value(1)).current;

  const rV = useDynamicResponsiveValue(["xs", "md"]);
  const breakpoint = rV({ xs: "xs", md: "md" });

  const previewCandle = async (candle, candleTemplate) => {
    if (loadingCandleTemplate) return;
    try {
      loadingCandleTemplate = true;
      await DesignStore.previewCandleTemplate(candle, candleTemplate);
    } catch (error) {
      alert(error?.message || JSON.stringify(error))
    } finally {
      loadingCandleTemplate = false;
    }
  }

  const onEditorMessage = async (
    data: { event: string; payload: any } | undefined
  ) => {
    console.log("onEditorMessage", data);
    if (!!data && data.event === "DESIGN_SAVE") {
      // save & exit
      navigation.reset({
        index: 0,
        routes: [
          {
            name: SCREEN.ResellerCreateMyOwnProduct, params: {
              productId: design.productId,
              designId: design.id,
            }
          },
        ],
      });
    }
    if (data?.event === "PREVIEW_CANDLE_TEMPLATE") {
      const { candle, candleTemplate } = data.payload || {};
      if (!candle || !candleTemplate) return;
      previewCandle(candle, candleTemplate);
    }
  };

  useEffect(() => {
    if (!user) return;
    (async () => {
      const resellerId = UserStore.getResellerId(user);
      const { list, error } = await ProductStore.getList(1, resellerId);
      if (!error && !!list) {
        console.log("list", list);
        setListProducts(list);
      }
    })();
  }, [user]);

  // useEffect(() => {
  //   if (
  //     (user?.role === "reseller" || user?.role === "user") &&
  //     !design?.isCustomizable &&
  //     curTab === TAB.EDITOR
  //   ) {
  //     setCurTab(TAB.INFO);
  //   }
  // }, [user, design, curTab]);

  useEffect(() => {
    console.log("onDemand", onDemand);
    setDesign({
      ...design,
      isCustomizable: !!onDemand ? false : true,
    });
  }, [onDemand]);

  useEffect(() => {
    if (user?.role !== "admin") return;
    (async () => {
      const { list } = await UserStore.getListResellers(1);
      if (list && list.length > 0) {
        setListResellers([
          { label: "All resellers", value: "all" },
          ...list.map((v) => ({
            label: `${v.firstName} ${v.lastName}`,
            value: v.id,
          })),
        ]);
      }
    })();
  }, [user]);

  useEffect(() => {
    console.log(
      "design?.availableForResellerIds",
      design?.availableForResellerIds
    );
    if (design?.availableForResellerIds) {
      const resellerIds = Object.keys(design?.availableForResellerIds).filter(
        (id) => !!design?.availableForResellerIds[id]
      );
      const selected = [];
      resellerIds.forEach((id) => {
        const findReseller = listResellers.find((v) => v.value === id);
        if (findReseller) selected.push(findReseller);
      });
      setSelectedResellers(selected);
    }
  }, [design?.availableForResellerIds, listResellers]);

  useEffect(() => {
    console.log("should run here", selectedProduct);
    if (!design?.productId) return;
    if (listProducts.length === 0) return;
    if (!!selectedProduct && selectedProduct.value === design?.productId)
      return;
    const find = listProducts.find((val) => val.id === design?.productId);
    if (!find) return;
    console.log(find, selectedProduct);
    if (!!selectedProduct && find.name === selectedProduct.name) return;
    setSelectedProduct({
      value: design?.productId,
      label: find.name,
      data: find,
    });
  }, [design?.productId, listProducts, selectedProduct]);

  const animExpand = (bool) => {
    setExpand(bool);
    if (bool) {
      Promise.all([
        AnimHelper.animate(
          titleXY,
          {
            x: -15,
            y: 0,
          },
          300
        ),
        AnimHelper.animate(titleScale, 0.7, 300),
      ]);
    } else {
      Promise.all([
        AnimHelper.animate(
          titleXY,
          {
            x: 0,
            y: 0,
          },
          300
        ),
        AnimHelper.animate(titleScale, 1, 300),
      ]);
    }
    setTimeout(() => {
      setExpandUI(bool);
    }, 400);
  };

  const addToPrintJobs = async () => {
    const res = await Store.Api.PrintJob.upsert({
      clientId: "admin",
      artworkUrls: design?.data?.artworkUrls || [],
      previewUrl: design?.image || undefined,
      isPDFDownloaded: false,
      isPrinted: false,
      isRePrinted: false,
      productId: design?.productId,
      productName: "Design: " + design?.name,
      designId: design?.id,
      quantity: 1,
      data: {
        product: {
          physicalWidth: design?.width,
          physicalHeight: design?.height,
          printAreas: design?.printAreas,
        },
      },
    });
    if (res.data.error) {
      return showPopupMessage({
        title: '',
        content: String(res.data.error),
        buttonOkText: 'OK',
        // 
        typeHighlight: 'danger',
        contentHighlight: 'Error'

      });
      // alert(res.data.error);
    }
    showPopupMessage({
      title: '',
      content: 'Successfully added a print job.',
      buttonOkText: 'OK',
      // 
      typeHighlight: 'success',
      contentHighlight: 'Success'

    });
    // alert("Successfully added a print job");
  };

  const handleDelete = async () => {
    showPopupMessage({
      title: "",
      content: "Are you sure you want to delete?",
      buttonOkText: "OK",
      onButtonOkClick: async () => {
        const res = await Store.Api.Design.remove({
          id: design.id,
        });
        if (res.data.error) {
          return showPopupMessage({
            title: '',
            content: String(res.data.error),
            buttonOkText: 'OK',
            // onButtonOkClick: () => {},
            typeHighlight: 'danger',
            contentHighlight: 'Error'
    
          });
          // alert(res.data.error);
        } else if (res.data.success) {
          navigation.reset({
            index: 0,
            routes: [{ name: SCREEN.ListDesigns, params: {} }],
          });
        }
      },
      typeHighlight: "danger",
      contentHighlight: "This action is not reversible.",
    });
    // const shouldDelete = confirm("Are you sure you want to delete?");
    // if (!shouldDelete) return;
    
  };

  const handleSave = async () => {
    const availableForResellerIds = {};
    selectedResellers.forEach(({ value }) => {
      availableForResellerIds[value] = true;
    });

    const res = await Store.Api.Design.upsert({
      id: id === "new" ? undefined : id,
      name: design.name,
      availableForResellerIds:
        user.role === "admin" ? availableForResellerIds : undefined,
      isCustomizable: design.isCustomizable,
    });
    if (res.data.error) {
      showPopupMessage({
        title: '',
        content: String(res.data.error),
        buttonOkText: 'OK',
        // 
        typeHighlight: 'danger',
        contentHighlight: 'Error'

      });
      // alert(res.data.error);
      return;
    }
    if (id === "new") {
      navigation.reset({
        index: 0,
        routes: [
          { name: SCREEN.UpsertDesign, params: { id: res.data.data.id } },
        ],
      });
    } else {
      showPopupMessage({
        title: '',
        content: 'Save design successfully',
        buttonOkText: 'OK',
        // 
        typeHighlight: 'success',
        contentHighlight: 'Success'

      });
      // alert("Save design successfully");
    }
  };

  const isCustomizableOptions = [
    { label: "YES", value: true },
    { label: "NO", value: false },
  ];

  const publishProduct = () => {
    modal.show(
      <PublishProductModal
        design={design}
        onPublishDone={(newDesign) => {
          setDesign({
            ...design,
            ...newDesign,
          });
        }}
        navigation={navigation}
      />
    );
  };

  const renderReady = () => {
    if (breakpoint === "xs")
      return (
        <Col flex1 middle>
          <Text>Please use bigger screen to see this page.</Text>
        </Col>
      );

    return curTab === TAB.INFO ? (
      <Col flex1>
        <Grid xs="100%" md="50%" p1>
          <Col m1>
            <Text subtitle1 mb1>
              Design Name
            </Text>
            <Input02
              height={35}
              value={design?.name || ""}
              onChange={(v) => setDesign({ ...design, name: v })}
              mb2
            />

            {user?.role === "admin" ? (
              <>
                <Text subtitle1 mb1>
                  Available for Resellers:
                </Text>
                <Text caption mb1>
                  * If blank, it will be hidden from all resellers
                </Text>
                <Select01
                  value={selectedResellers}
                  isMulti
                  onChange={setSelectedResellers}
                  options={listResellers}
                  mb1
                />

                <Text subtitle1 mb1>
                  Is Customizable:
                </Text>
                <Text caption mb1>
                  * If NO, it will be a preset (
                  <Text bold>Print on Demand design</Text>) that resellers can
                  only print
                </Text>
                <Select01
                  value={
                    design?.isCustomizable
                      ? {
                        label: "YES",
                        value: true,
                      }
                      : {
                        label: "NO",
                        value: false,
                      }
                  }
                  isClearable={false}
                  onChange={(newVal) => {
                    console.log("newVal", newVal);
                    setDesign({
                      ...design,
                      isCustomizable: Boolean(newVal?.value),
                    });
                  }}
                  options={isCustomizableOptions}
                  mb1
                />
              </>
            ) : !design?.isCustomizable ? (
              <Text caption mb1>
                This Design is not Customizable.
              </Text>
            ) : null}

            <Row>
              {canEdit && (
                <Button
                  onPress={handleSave}
                  height={30}
                  width={75}
                  borderRadius={15}
                  text="Save"
                />
              )}
              {canEdit && (
                <Button
                  ml1
                  onPress={handleDelete}
                  height={30}
                  width={75}
                  backgroundColor="red"
                  bgHovered="rgba(255,255,255,0.2)"
                  borderRadius={15}
                  text="Delete"
                />
              )}
              {Boolean(
                !!design?.data && Object.keys(design?.data).length > 0
              ) && (
                  <Button
                    outline
                    onPress={addToPrintJobs}
                    height={30}
                    width={150}
                    ml1
                    borderRadius={15}
                    text="Add to print jobs"
                  />
                )}
              {Boolean(user?.role === "reseller" || user?.role === "user") && (
                <Button
                  outline
                  ml1
                  onPress={publishProduct}
                  height={30}
                  width={150}
                  borderRadius={15}
                  text="Publish to my stores"
                />
              )}
            </Row>
          </Col>
          <Col m1></Col>
        </Grid>

        {!!design?.products && design?.products?.length > 0 && (
          <Col mt2 p2>
            <Text bold>List of products on my stores</Text>
            <Text caption mt0>
              * Note that if the product is in draft state in the stores, the
              urls wont be accessible. Try using product admin url instead
            </Text>
            <Row
              mt2
              height={50}
              stretch
              borderBottomColor={COLOR.GREY_BG}
              borderBottomWidth={1}
            >
              <Col flex1 m1>
                <Text color={COLOR.GREY}>Store ID</Text>
              </Col>
              <Col flex1 m1>
                <Text color={COLOR.GREY}>Product URL</Text>
              </Col>
              <Col flex1 m1>
                <Text color={COLOR.GREY}>Product Admin URL</Text>
              </Col>
              <Col flex1 m1>
                <Text color={COLOR.GREY}></Text>
              </Col>
            </Row>
            <Col>
              {design?.products.map((p, i) => {
                return (
                  <Row height={50} stretch key={p.productId}>
                    <Col flex1 m1>
                      <Text>{p.storeId}</Text>
                    </Col>
                    <Col flex1 m1>
                      <a href={p.url} target="_blank">
                        <Text>{p.url}</Text>
                      </a>
                    </Col>
                    <Col flex1 m1>
                      <a href={p.productAdminUrl} target="_blank">
                        <Text>{p.productAdminUrl}</Text>
                      </a>
                    </Col>
                    <Col flex1 m1>
                      <TouchField
                        width={30}
                        height={30}
                        borderRadius={15}
                        middle
                        onPress={async () => {
                          // const shouldDelete = confirm(
                          //   "Are you sure you want to remove this product. Please note that it will just remove the reference of the product to the design. If you wish to remove the products from the store, open the Product Admin Url and continue there"
                          // );
                          // if (!shouldDelete) return;
                          showPopupMessage({
                            title: "",
                            content: "Are you sure you want to remove this product. Please note that it will just remove the reference of the product to the design. If you wish to remove the products from the store, open the Product Admin Url and continue there",
                            buttonOkText: "OK",
                            onButtonOkClick: async () => {
                              const newProducts = design.products.filter(
                                (item) => item.productId !== p.productId
                              );
                              setDesign({
                                ...design,
                                products: newProducts,
                              });
                              await Store.Api.Design.upsert({
                                id: design.id,
                                products: newProducts,
                              });
                            },
                            typeHighlight: "danger",
                            contentHighlight: "This action is not reversible.",
                          });
                          
                        }}
                      >
                        <EvilIcons name="trash" size={24} color={COLOR.FONT} />
                      </TouchField>
                    </Col>
                  </Row>
                );
              })}
            </Col>
          </Col>
        )}
      </Col>
    ) : (
      <View
        style={{
          width: "100%",
          height: "100%",
          margin: 0,
        }}
      >
        <iframe
          src={
            window.location.href.includes("iframe_dev=1")
              ? `http://localhost:3009/design/${id}?admin=1&userId=${user?.id}`
              : `${ValHelper.editorHost}/design/${id}?admin=1&dev=${ValHelper.isDev ? 1 : 0}&userId=${user?.id}`
          }
          style={{
            width: "100%",
            height: "100%",
            border: "none",
            backgroundColor: COLOR.MAIN,
          }}
          onLoad={Store.Client.editor.initOnLoadAndOpenCommunication(
            onEditorMessage
          )}
        />
      </View>
    );
  };

  return (
    <CMSLayout requireAuthen expanded={expand}>
      <Row
        zIndex={2}
        m2
        marginBottom={0}
        justifyContent={"space-between"}
      >
        <Text h3 ml0 color={COLOR.FONT}>
          {id === "new" ? "Create new" : design?.name}
        </Text>
        <Row>
          
        </Row>
      </Row>
      <Col
        flex1
        zIndex={1}
        style={
          !expandUI
            ? {
              margin: 20,
              marginVertical: 10,
              borderRadius: 10,
              backgroundColor: "white",
              borderColor: "white",
              borderWidth: 1,
            }
            : {}
        }
      >
        {useMemo(() => {
          return uiState.errorMes ? (
            <Col flex1 middle>
              <Text color="red" subtitle1>
                {uiState.errorMes}
              </Text>
            </Col>
          ) : uiState.fetching ? (
            <Row height={50} stretch>
              <ShimmerLoading round1 flex={1} m1 />
              <ShimmerLoading round1 flex={1} m1 />
              <ShimmerLoading round1 flex={1} m1 />
              <ShimmerLoading round1 flex={1} m1 />
            </Row>
          ) : (
            renderReady()
          );
        }, [
          uiState,
          breakpoint,
          curTab,
          listProducts,
          design,
          listResellers,
          selectedResellers,
          selectedProduct,
        ])}
      </Col>
    </CMSLayout>
  );
};

UpsertDesign.routeInfo = {
  title: "Bottled Goose",
  path: "/product-library/:productId/create-my-own/:designId/design",
};

export default UpsertDesign;
