import React, { useImperativeHandle, forwardRef, useRef } from 'react';

import Store from 'store';

interface IProps {
  isMulti?: boolean,
  onUploaded(urls: Array<string>): void,
}

const UploadFile = forwardRef((props : IProps, ref) => {

  const inputRef = useRef(null);
  const namePrefixRef = useRef('');

  const clearFiles = () => {
    if (!inputRef.current) return;
    inputRef.current.value = '';
    inputRef.current.type = '';
    inputRef.current.type = 'file';
  }

  const onChange : React.InputHTMLAttributes<HTMLInputElement>['onChange'] = async e => {
    const files = Array.from(e.target.files);
    console.log('onChange files', files);
    if (files.length === 0) return props.onUploaded([]);
    
    const toBeProcessedFiles = props.isMulti ? files : [files[0]];

    const promiseArr = toBeProcessedFiles.map(async (val : File) => {
      // console.log('toBeProcessedFiles', val);
      // const uploadName = (namePrefixRef.current || new Date().getTime() + '-') + val.name;
      const uploadName = `${namePrefixRef.current || (new Date().getTime() + '-')}${val.name}`;
      const serverSidePath = `files/${uploadName}`;
      try {
        const res1 = await Store.Api.User.getUploadPresignedUrl({
          serverSidePath,
          contentType: val.type || 'application/octet-stream',
        });
        const awsUrl = res1.data.data
        if (!awsUrl) return undefined;

        const res2 = await fetch(awsUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': val.type || 'application/octet-stream',
          },
          body: val,
        });

        if (res2.status !== 200) return undefined;
        const u = new URL(awsUrl);
        return `${u.origin}${u.pathname}`;
      } catch(err){
        return undefined;
      }
    });

    const urls = await Promise.all(promiseArr);
    props.onUploaded(urls.filter(val => !!val));
    clearFiles();
  }

  useImperativeHandle(ref, () => ({

    showDialog: () => {
      console.log('showDialog', inputRef.current);
      inputRef.current?.click();
    },
    setPrefix: (str) => {
      namePrefixRef.current = str;
    }

  }));

  return (
    <input multiple ref={inputRef} type="file" style={{ display: 'none' }} onChange={onChange} />
  );
});

export default UploadFile;