import React from 'react';
import { Col, IColProps } from 'components';

import './ShimmerLoading.css';

const ShimmerLoading = (props : IColProps) => {
  return (
    <Col overflow={'hidden'} {...props}>
      <div className='shimmer' />
    </Col>
  )
};

export default ShimmerLoading;
