import React, { useState, useRef, useMemo, useEffect } from 'react';
import { Row, Col, Text, Button, useUIState, modal, Input02, UploadFile } from 'components';
import { TProduct } from 'type';
import { COLOR } from 'const';
import { FontAwesome5 } from '@expo/vector-icons';

interface IProps {
  product: TProduct
  setProduct: any,
}

export const readBlendDataFromProduct = (product) => {
  const blendFiles = (product?.previewData || []).find(v => v.previewType === 'blender-file');
    const blendFilesIndex = (product?.previewData || []).findIndex(v => v.previewType === 'blender-file');
    const blender = (blendFiles?.previewItems || []).find(v => v.title === 'blend');
    const support = (blendFiles?.previewItems || []).find(v => v.title === 'support');

    return {
      raw: blendFiles,
      rawIndex: blendFilesIndex,
      blender: blender,
      support: support,
    }
}

const BlendModal = ({ product: p, setProduct: setP }: IProps) => {
  const [product, updateProduct] = useState(p);
  const setProduct = (v) => { updateProduct(v); setP(v) };
  const [isLoading, setIsLoading] = useState(false);

  const blendData = useMemo(() => {
    return readBlendDataFromProduct(product);
  }, [product]);

  const uploadRef = useRef<{ showDialog: Function, setPrefix: Function }>(null);
  const fileTypeRef = useRef<'blend' | 'support'>('blend');
  const onImageUploaded = (urls) => {
    setIsLoading(false);
    const url = urls[0];
    let raw = blendData.raw;
    if (!blendData.raw) {
      raw = {
        groupTitle: 'Blender 2D render',
        previewType: 'blender-file',
        previewItems: []
      };
    }
    if (fileTypeRef.current === 'blend') {
      raw.previewItems[0] = {
        title: 'blend',
        image: url,
        data: '',
      }
    } else if (fileTypeRef.current === 'support') {
      raw.previewItems[1] = {
        title: 'support',
        image: url,
        data: '',
      } 
    }
    console.log('raw', raw);
    const previewData = (product?.previewData || []).slice();
    if (blendData.rawIndex === -1) previewData.push(raw);
    else previewData[blendData.rawIndex] = raw;
    setProduct({
      ...product, 
      previewData,
    });
  }
  console.log('product', product);
  console.log('blendData', blendData);

  useEffect(() => {
    uploadRef.current.setPrefix(`bg/2d-render/blend-files/${new Date().getTime()}/`);
  }, []);

  return (
    <Col round1 bgWhite shadow p2>
      <Text>Blend file is used for <Text bold>2D preview</Text> rendering</Text>
      <Col height={1} backgroundColor={COLOR.GREY} mv2 />

      <Text mb0 subtitle1>Blend file</Text>
      <Text caption mb0>Please upload a <Text bold>.blend</Text> file with <Text bold>default camera</Text> setup</Text>
      <Text caption mb0>Try using sample number <Text bold>no more than 200</Text> to render faster</Text>
      <Text mb0></Text>
      <Row
        mb2
      >
        <Button
          isLoading={isLoading}
          opacity={!isLoading ? 1 : fileTypeRef.current === 'blend' ? 1 : 0.5}
          outline
          bgHovered={COLOR.GREY_BG}
          text={'Upload'}
          height={30}
          borderRadius={15}
          width={120}
          iconLeft={
            <FontAwesome5 name={"cloud-upload-alt"} size={20} color="black" />
          }
          mh0
          onPress={() => {
            fileTypeRef.current = 'blend';
            setIsLoading(true);
            uploadRef.current.showDialog();
          }}
        />
        <Col flex1 ml0>
          <Input02
            value={blendData?.blender?.image}
            onChange={v => {
              fileTypeRef.current = 'blend';
              onImageUploaded([v]);
            }}
          />
        </Col>
      </Row>

      <Text mb0 subtitle1>Support file</Text>
      <Text caption mb0>In case there is other file (like HDRI image) that needs to be put in same folder as the blend file, please upload here</Text>
      <Text caption mb0>Do not upload Artwork.png file</Text>
      <Text mb0></Text>
      <Row
        mb2
      >
        <Button
          isLoading={isLoading}
          opacity={!isLoading ? 1 : fileTypeRef.current === 'support' ? 1 : 0.5}
          outline
          bgHovered={COLOR.GREY_BG}
          text={'Upload'}
          height={30}
          borderRadius={15}
          width={120}
          iconLeft={
            <FontAwesome5 name={"cloud-upload-alt"} size={20} color="black" />
          }
          mh0
          onPress={() => {
            fileTypeRef.current = 'support';
            setIsLoading(true);
            uploadRef.current.showDialog();
          }}
        />
        <Col flex1 ml0>
          <Input02
            value={blendData?.support?.image}
            onChange={v => {
              fileTypeRef.current = 'support';
              console.log('onchange', v, fileTypeRef.current);
              onImageUploaded([v]);
            }}
          />
        </Col>
      </Row>
      <UploadFile
        ref={uploadRef}
        onUploaded={onImageUploaded}
      />
      <Col height={1} backgroundColor={COLOR.GREY} mb2 />
      <Col>
        {!!blendData.blender && (
          <>
            <Text mb0>Once you have done preparing your preview files (blend (and support) and 3d preview), go back click <Text bold>Test Preview</Text> to see how it turns out</Text>
            <Text mb2 caption>Remember to press Save to save everything</Text>
          </>
        )}
        <Button
          solid
          text={'Go back'}
          height={30}
          borderRadius={15}
          width={120}
          onPress={() => {
            modal.hide();
          }}
        />
      </Col>
    </Col>
  )
}

const UploadBlendFile = ({ product, setProduct, ...props }: IProps) => {
  const blendFiles = (product?.previewData || []).find(v => v.previewType === 'blender-file');
  const blender = (blendFiles?.previewItems || []).find(v => v.title === 'blend');

  const openBlenModal = () => {
    modal.show(<BlendModal product={product} setProduct={setProduct} />)
  };
 
  return (
    <Button
      text={!!blender ? 'Replace blend file' : 'Upload blend file'}
      {...props}
      iconLeft={
        <FontAwesome5 name={"cloud-upload-alt"} size={10} color="black" />
      }
      width={140}
      onPress={() => {
        openBlenModal();
      }}
    />
  )
};

export default UploadBlendFile