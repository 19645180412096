import { useState } from 'react';

export interface IStateUI {
  errorMes?: string,
  loading?: boolean,
  fetching?: boolean,
  btnText?: string,
}

export const useUIState = (initialState : IStateUI = undefined) : [IStateUI, (newValue: IStateUI) => void] => {

  const [UIState, setUIState] = useState<IStateUI>(initialState || {
    errorMes: '',
    loading: false,
    btnText: '',
    fetching: false,
  });

  const setValue = (obj) => setUIState(v => ({ ...v, ...obj }));

  return [
    UIState,
    setValue,
  ];
}