import create from 'zustand';
import { useEffect, useState, Dispatch } from 'react';
import { useUIState, IStateUI, showPopupMessage } from 'components';
import { TDesign } from 'type';
import Client from './api-client';
import { useZustandUserStore } from './User.Store';

interface IDesignStore {
  designs: {
    [designId: string]: TDesign,
  },
  getList(p: number, status: string, resellerId?: string, excludeAll?: number): Promise<{ hasNext: boolean, list: Array<TDesign>, total: number, error?: string, }>,
  useDesign(id: string, useCache?: boolean, excludeData?: boolean): {
    design: TDesign | undefined,
    uiState: IStateUI,
    setDesign: Dispatch<TDesign | any>,
  },
  deleteDesign(id: string): Promise<any>,
  previewCandleTemplate(
    candle: {
      scentName?: string, defaultScentName?: string, companyName?: string, address?: string
    },
    candleTemplate?: string
  ): Promise<any>,
  [otherKey: string]: any,
}

export const useDesignStore = create<IDesignStore>((set, get) => ({
  designs: {},
  getList: async (page = 1, status, createdByUserId, excludeAll = 0) => {

    const productQuery = status === 'custom-product' ? { customProduct: 1 } :
      status === 'print-on-demand' ? { printOnDemand: 1 } :
      status === 'wholesale' ? { wholeSale: 1 } : {};

    const res = await Client.Api.Design.list({ page, createdByUserId, excludeAll, ...productQuery });

    if (!res.data.success) {
      return {
        hasNext: false,
        list: [],
        total: 0,
        error: res.data.error,
      }
    }

    if (res.data.data.list && Array.isArray(res.data.data.list)) {
      const obj: IDesignStore['designs'] = {}
      res.data.data.list.forEach((j: TDesign) => {
        obj[j.id] = j;
      });
      set({ Designs: { ...get().jobs, ...obj } });
    }
    return res.data.data;
  },
  useDesign: (id: string, useCache = true, excludeData = true) => {
    
    const [uiState, setUI] = useUIState();
    const [design, setDesign] = useState<TDesign>();

    useEffect(() => {
      if (id === 'new') return;
      if (useCache && !!get().designs[id]) {
        setDesign(get().Designs[id]);
        return;
      }
      (async () => {
        setUI({ fetching: true });
        try {
          const res = excludeData ? await Client.Api.Design.detailExcludeData({ id })
            : await Client.Api.Design.detail({ id });
          if (res.data.error) {
            setUI({ fetching: false, errorMes: res.data.error });
            return;
          }
          if (res.data.data) {
            setDesign(res.data.data);
            setUI({ fetching: false, errorMes: '' });
          }
        } catch (err) {
          setUI({ fetching: false, errorMes: String(err) });
        }
      })();
    }, [id, useCache]);

    return { design, setDesign, uiState };
  },
  deleteDesign: async (id: string) => {
    try {
      await Client.Api.Design.archive({ id });
      return true;
    } catch (error) {
      showPopupMessage({
        title: '',
        content: String(error?.message || JSON.stringify(error)),
        buttonOkText: 'OK',

        typeHighlight: 'danger',
        contentHighlight: 'Error'

      });
      // alert(error?.message || JSON.stringify(error));      
    }
  },
  previewCandleTemplate: async (candle, candleTemplate) => {
    const user = useZustandUserStore.getState()?.user;
    if (!user) return;
    const resellerId = user?.role === 'reseller' ? user?.id : user?.resellerId;
    const res = await Client.Api.PackingSlip.getPackingSlip({ resellerId });
    const { scentName, defaultScentName, companyName, address } = candle || {};
    const res2 = await Client.Api.Pdf.generateCandleTemplate({
      address,
      scentName: scentName || defaultScentName,
      templateUrl: candleTemplate,
      companyName,
      companyLogo: res?.data?.data?.companyLogo,
    });
    if (res2.data.data) {
      window.open(res2.data.data, "_blank");
    }
  }
}));
