import Request from '../Request.utils'
export interface IListRequestQuery {
  page?: number;
  resellerId?: string;
}
export interface IUpsertRequestBody {
  designId?: string;
  id?: string;
  image?: string;
  isCustomizable?: boolean;
  name?: string;
  price?: string;
  productId?: string;
  skuNumber?: string;
}
export interface IRemoveRequestParams {
  id: string;
}
export interface IDetailRequestParams {
  id: string;
}


class InstanceAPI {
  list = async (query: IListRequestQuery) => {
    const res = await Request.call('/api/product-instances', 'GET', undefined, query, undefined, );
    return res;
  }
  upsert = async (body: IUpsertRequestBody) => {
    const res = await Request.call('/api/product-instances', 'POST', undefined, undefined, body, );
    return res;
  }
  remove = async (params: IRemoveRequestParams) => {
    const res = await Request.call('/api/product-instances/:id', 'DELETE', params, undefined, undefined, );
    return res;
  }
  detail = async (params: IDetailRequestParams) => {
    const res = await Request.call('/api/product-instances/:id', 'GET', params, undefined, undefined, );
    return res;
  }
}
export default new InstanceAPI()