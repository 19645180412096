import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState, useRef, Fragment } from 'react';
import { Button, Col, Grid, Input, Row, Text, Select, useListCurrencies, MoneyValue, modal, DepositFundPopup, InputField, SelectField, showPopupMessage } from 'components';
import { COLOR, COUNTRIES } from 'const';
import { AntDesign, Entypo } from '@expo/vector-icons';
import Store from 'store';
import { useStripe, CardElement, useElements } from '@stripe/react-stripe-js';
import { usePaymentStore } from 'store/Payment.Store';
import { useSettingsForm } from './FormContext';
import { TimeHelper, modalConfirm } from 'helpers';
import TransactionHistory from '../components/TransactionHistory';
import { useDynamicResponsiveValue } from 'quickly-react';

const FIELDS = [
  [
    { id: "firstName", label: "First name", require: true },
    { id: "lastName", label: "Last name", require: true },
  ],
  { id: "addressLine1", label: "Street address", placeholder: "House number and street name", require: true },
  { id: "addressLine2", placeholder: "Apartment, suite, unit etc. (optional)", label: "" },
  [
    { id: "city", label: "Town / City", require: true },
    { id: "postcode", label: "Post Code / ZIP", require: true },
  ],
  // { id: "country", label: "Country", require: true },
];

const BillingSettings = (props, ref) => {
  const { billingInformation, updateBillingInformation, checkIsChanged } = useSettingsForm();
  // const listCurencies = useListCurrencies();
  // const currencyOptions = useMemo(() => {
  //   return listCurencies.map(v => ({ label: `${v.cc} (${v.symbol})`, value: v.cc }));
  // }, [listCurencies])
  const UserStore = Store.useUserStore();
  const { getStripeConfig, getExistedCard, balance, stripeClientSecret, refreshBalance } = usePaymentStore();

  const stripe = useStripe();
  const elements = useElements();

  const { user } = UserStore;

  const [openAddForm, setOpenAddForm] = useState<boolean>(false);
  const [state, setState] = useState({
    depositBalance: '',
    withdrawAmount: '',
  })
  const resellerId = UserStore.getResellerId(user);
  const rV = useDynamicResponsiveValue();
  const isMobile = rV({ xs: true, lg: false });

  const listCountries = COUNTRIES.map(v => ({ label: v.name, value: v.name }));

  useEffect(() => {
    getStripeConfig();
    getExistedCard();
    Store.Api.User.detail({ id: resellerId }).then(res => {
      updateBillingInformation({
        ...billingInformation,
        addressLine1: res.data.data?.addressLine1,
        addressLine2: res.data.data?.addressLine2,
        city: res.data.data?.town,
        postcode: res.data.data?.postCode,
        country: res.data.data?.country,
        currency: res.data.data?.currency,
        accountName: res.data.data?.accountName,
        firstName: res.data.data?.firstName,
        lastName: res.data.data?.lastName,
      }, true)
    })
  }, []);



  const onSubmitAddcard = async () => {
    if (!openAddForm) return;
    const res = await stripe.confirmCardSetup(stripeClientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          address: {
            city: billingInformation.city,
            line1: billingInformation.addressLine1,
            line2: billingInformation.addressLine2,
            postal_code: billingInformation.postcode,
          }
        }
      }
    })
    if (res.error?.message) {
      throw new Error(res.error?.message);
    }
    getExistedCard();
  }

  useImperativeHandle(ref, () => ({
    submit: onSubmitAddcard,
  }));

  const onPressAddCard = () => {
    setOpenAddForm(true);
  }

  const onChangeField = (key) => (value) => {
    updateBillingInformation(s => ({
      ...s,
      [key]: value,
    }))
  }

  const onChangeState = (key) => (value) => {
    setState(s => ({
      ...s,
      [key]: value,
    }))
  }

  const onPressAddBalance = () => {
    if (!state.depositBalance) return;
    if (checkIsChanged('billing')) {
      modalConfirm({
        title: 'Warning',
        content: 'Please save your changes before adding funds',
        onOk: () => {},
      })
      return;
    }
    if (
      !billingInformation.firstName ||
      !billingInformation.lastName ||
      !billingInformation.city ||
      !billingInformation.postcode ||
      !billingInformation.country ||
      !billingInformation.addressLine1
    ) {
      return showPopupMessage({
        title: '',
        content: 'Please fill in your billing information before adding funds',
        buttonOkText: 'OK',
        typeHighlight: 'danger',
        contentHighlight: 'Error'
      });
    }

    modal.show(
      <DepositFundPopup
        resellerId={resellerId}
        amount={+state.depositBalance}
        currency={billingInformation.currency || 'GBP'}
      />
    )
  }

  useEffect(() => {
    onChangeState("depositBalance")('');
  }, [balance]);

  const totalBalanceRef = useRef(0);

  const withdrawFunds = async () => {
    TimeHelper.runOnceIn(500)(() => {
      modalConfirm({
        title: 'Withdraw',
        content: 'Requesting a refund will refund all the money within your wallet - are you sure you wish to continue?',
        onOk: async () => {
          const res = await Store.Api.Payment.requestWithdrawFund({
            amount: Number(balance?.balance * -1) / 100,
            currency: balance?.currency ? balance?.currency?.toUpperCase() : 'GBP',
          });
          if (res.data.success) {
            setTimeout(() => {
              refreshBalance();
              modalConfirm({
                type: 'success',
                title: '',
                cancelButtonProps: {
                  style: {
                    visibility: 'hidden',
                  }
                },
                content: 'We have sent through your request to withdraw your funds. Your refund could take up to 5-10 business days to process.',
              });
            }, 500);
          }
        },
      })
    })
  }

  return (
    <>
      <Col flex1 overflow={"scroll"} {...props}>
        <Grid xs="100%" md="50%" alignItems={"flex-start"} mb2>
          <Col m1>
            <Text subtitle1 mb2>
              Billing address
            </Text>
            {FIELDS.map((field: any, idx: number) => {
              if (!field["id"]) {
                return (
                  <Row marginBottom={12} marginRight={-12} key={`${field.id}${idx}`}>
                    {field.map((item, jdx) => (
                      <Fragment key={`${item.id}${jdx}`}>
                        <Col flex1>
                          <InputField
                            title={item.label}
                            value={billingInformation[item.id]}
                            onChangeText={(val) => {
                              onChangeField(item.id)(val);
                            }}
                            isRequired={item.require}
                            // error={formErrors[field.id]}
                            inputProps={{
                              placeholder: item.placeholder || item.label,
                            }}
                          />
                        </Col>
                        <Col width={12} />
                      </Fragment>
                    ))}
                  </Row>
                )
              }
              return (
                <Col marginBottom={12} key={`${field.id}${idx}`}>
                  <InputField
                    title={field.label}
                    value={billingInformation[field.id]}
                    onChangeText={(val) => {
                      onChangeField(field.id)(val);
                    }}
                    isRequired={field.require}
                    // error={formErrors[field.id]}
                    inputProps={{
                      placeholder: field.placeholder || field.label,
                    }}
                  />
                </Col>
              )
            })}
            <SelectField
              title="Country"
              value={billingInformation.country}
              options={listCountries}
              isRequired
              selectProps={{ showSearch: true }}
              onChange={(value: string) => {
                onChangeField("country")(value);
              }}
            />
          </Col>
          <Col m1>
            <Col width={isMobile ? '100%' : '70%'}>
              <Text subtitle1 mb2>
                Bottled Goose balance
              </Text>
              <Row>
                <AntDesign name="checkcircle" size={18} color={COLOR.MAIN} />
                <Text marginLeft={8}>
                  Easily cover order and production costs
                </Text>
              </Row>
              <Row marginTop={4}>
                <AntDesign name="checkcircle" size={18} color={COLOR.MAIN} />
                <Text marginLeft={8}>
                  Order processing without the need for card payment
                </Text>
              </Row>
              <MoneyValue
                baseAmountGBP={(balance?.balance * -1 || 0) / 100}
                toCurrency={billingInformation.currency}
                renderingProp
              >
                {({ amount }) => {
                  totalBalanceRef.current = amount;
                  return (
                    <Col backgroundColor={amount < 0 ? '#BC0100' : COLOR.MAIN} paddingTop={10} paddingBottom={20} borderRadius={8} marginTop={20}>
                      <Text marginLeft={14} subtitle1 mb2 color={COLOR.WHITE}>
                        Current Balance
                      </Text>
                      <Row marginLeft={14}>
                        <Entypo name="wallet" size={44} color={COLOR.WHITE} />
                        <Text marginLeft={24} h2 color={COLOR.WHITE}>
                          {String(billingInformation.currency || 'GBP').toUpperCase()}
                        </Text>
                        <Text marginLeft={6} h2 color={COLOR.WHITE}>{amount}</Text>
                      </Row>
                    </Col>
                  )
                }}
              </MoneyValue>

              <Row mt1 marginLeft={-5} marginRight={-5}>
                <Input
                  placeholder="+ Enter amount"
                  value={state.depositBalance}
                  onChange={onChangeState("depositBalance")}
                  flex1
                  m0
                />
                <Button
                  text='Add funds'
                  solid
                  opacity={Number(state.depositBalance) ? 1 : 0.7}
                  disabled={!Number(state.depositBalance)}
                  onPress={onPressAddBalance}
                  alignSelf='center'
                  width={120}
                  height={45}
                  nativeID='add-fund'
                  m0
                />
              </Row>
              <Row mt1>
                <Button
                  text='Withdraw funds'
                  solid
                  onPress={withdrawFunds}
                  width="100%"
                  height={45}
                  opacity={balance?.balance >= 0 ? 0.7 : 1}
                  disabled={balance?.balance >= 0}
                />
              </Row>
            </Col>
          </Col>
        </Grid>
        <TransactionHistory />
      </Col>
      {/* POWERED BY STRIPE */}
      <Col position={'fixed'} backgroundColor={COLOR.MAIN} bottom={20} right={30} paddingVertical={10} paddingHorizontal={20} round1>
        <Text colorWhite>Powered by <Text bold colorWhite fontSize={20}>stripe</Text></Text>
      </Col>
    </>
  );
};

export default forwardRef(BillingSettings);
