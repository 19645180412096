import * as React from "react";
import Svg, { Path } from "react-native-svg";
const SVGComponent = (props) => (
  <Svg
    width={12}
    height={12}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 5.9775C12 5.97 12 5.97 12 5.9625V5.955C12 5.9475 12 5.9475 12 5.94C12 5.9325 12 5.9325 12 5.925C11.985 5.82 11.94 5.73 11.8725 5.655C11.505 5.1825 11.07 4.755 10.635 4.365L8.265 6.06C8.235 7.2675 7.245 8.235 6.03 8.235C5.7975 8.235 5.58 8.19 5.37 8.1225L3.8175 9.225C4.1475 9.3675 4.4925 9.495 4.845 9.5775C5.5725 9.75 6.3 9.7575 7.035 9.615C7.695 9.4875 8.34 9.21 8.9325 8.88C9.87 8.355 10.725 7.6275 11.4675 6.8175C11.6025 6.6675 11.745 6.51 11.865 6.3525C11.9325 6.2775 11.9775 6.1875 11.9925 6.0825C11.9925 6.075 11.9925 6.075 11.9925 6.0675C11.9925 6.06 11.9925 6.06 11.9925 6.0525V6.045C11.9925 6.0375 11.9925 6.0375 11.9925 6.03C11.9925 6.0225 11.9925 6.015 11.9925 6.0075C12 5.9925 12 5.985 12 5.9775ZM11.6775 2.85C11.865 2.715 12 2.505 12 2.25C12 1.8375 11.6625 1.5 11.25 1.5C11.085 1.5 10.9425 1.56 10.8225 1.65L10.815 1.6425L8.8125 3.075C8.295 2.79 7.755 2.5575 7.185 2.4225C6.465 2.25 5.73 2.2425 5.0025 2.385C4.3425 2.52 3.6975 2.79 3.105 3.1275C2.1675 3.6525 1.3125 4.38 0.57 5.19C0.435 5.34 0.2925 5.4975 0.1725 5.655C0 5.8725 0 6.1275 0.165 6.345C0.5475 6.84 0.9975 7.2825 1.4625 7.6875C1.5975 7.8075 1.7475 7.905 1.8825 8.0175L0.315 9.1425L0.3225 9.15C0.135 9.285 0 9.495 0 9.75C0 10.1625 0.3375 10.5 0.75 10.5C0.915 10.5 1.0575 10.44 1.1775 10.35L1.185 10.3575L11.685 2.8575L11.6775 2.85ZM3.87 6.6C3.8175 6.405 3.7875 6.21 3.7875 6C3.7875 4.77 4.7925 3.765 6.03 3.765C6.495 3.765 6.9225 3.9225 7.275 4.1625L3.87 6.6Z"
      fill="#8F95B2"
    />
  </Svg>
);
export default SVGComponent;
