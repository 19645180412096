import { Col, Row, Text, CMSLayout, useUIState, RangeDatePicker, Select01, useRefState, modal, Button, showPopupMessage, useMobileScreen } from 'components';
import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { IScreen, TCMSOrder } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN } from 'const';
import { DatePicker, Select } from 'antd';
import { TimeHelper, ValHelper } from 'helpers';
import { ListOrdersTabs } from './ListOrders.Tabs';
import { commonFuncs } from './ListOrders.funcs';
import { ListOrderLoadMore, ListOrderLoading } from './ListOrders.Comps';
import { downloadArtworkAndPackingSlip } from 'helpers/FileHelper';
import ToastHelper from 'helpers/ToastHelper';
import RejectOrderModal from './RejectOrderModal';
import { useEvent } from 'js-events-listener/react';
import OrderTable from './OrderTable2';
import { Tooltip } from 'react-tippy';
import dayjs, { Dayjs } from 'dayjs';
import { ActivityIndicator, ScrollView, useWindowDimensions } from 'react-native';
import { kebabCase, uniqBy } from 'lodash';
import { OrderActionButtons } from './components/OrderActionButtons';
import { ORDER_STATUS, STATUS_BY_STAGE } from 'store/Order.Store';
const { RangePicker } = DatePicker;

const defaultRange = [dayjs().startOf("year"), dayjs().endOf("year")];

const isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }: any) => {
  return layoutMeasurement.height + contentOffset.y >= contentSize.height - 100
    && contentSize.height > 100
    && contentOffset.y > 10;
};

let lastQuery = {}
const ListOrders: IScreen = ({ resellerId = 'all', breadcrumbs }) => {
  const { navigation, route } = useNavFunc();
  // @ts-ignore
  const stage = route.params?.stage;
  const rejectModalRef = useRef(null);
  const UserStore = Store.useUserStore();
  const ShopStore = Store.useShopStore();
  const { user } = UserStore;
  // const resellerId = user?.role === 'admin' ? 'all' : user?.role === 'reseller' ? user?.id
  //   : user.resellerId;

  const OrderStore = Store.useOrderStore();
  // @ts-ignore
  window.OrderStore = OrderStore;
  const [{ fetching, errorMes, loading: btnLoading }, setUI] = useUIState({ fetching: false });
  const [pagination, setPagination] = useState({ hasNext: false, total: 1 });
  const [jobsByStage, setJobsByStage] = useState<{ [key: string]: Array<TCMSOrder> }>({});
  const [selectedJobs, setSelectedJobs] = useState<{ [id: string]: boolean }>({});
  const { height: screenHeight } = useWindowDimensions();
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [page, setPage] = useState(1);
  const sorter = useRef<{ [key: string]: any }>();
  const filter = useRef<{ [key: string]: any }>();
  const [selectedStageStatus, setSelectedStageStatus] = useState<string>();
  const layoutTable = useRef();
  const layoutContainer = useRef();
  const [containerHeight, setContainerHeight] = useState(0);
  const [filterTimeRange, setFilterTimeRange] = useState<Dayjs[]>(defaultRange);
  const isMobile = useMobileScreen();

  const atLeastOneSelected = useMemo(() => {
    return Object.keys(selectedJobs).filter(id => !!selectedJobs[id]).length > 0;
  }, [selectedJobs]);

  const getData = useCallback(async (p, stageStatus = stage) => {
    try {
      // prevent duplicate request
      if (lastQuery[`${stageStatus}_${p}`] && Date.now() - lastQuery[`${stageStatus}_${p}`] < 500) return;
      lastQuery[`${stageStatus}_${p}`] = Date.now();

      if (p <= 1) {
        setUI({ fetching: true, errorMes: '' })
        setJobsByStage(s => ({
          ...s,
          [stageStatus]: [],
        }))
      };
      if (p !== 1) setIsLoadingMore(true);
      // await TimeHelper.wait(300);
      const Stage = ValHelper.toDisplayStage(stageStatus);
      // fetch pipelines first to match later
      const filterRange = { startDate: filterTimeRange?.[0]?.toISOString(), endDate: filterTimeRange?.[1]?.toISOString() };
      // await OrderStore.getListPipeline(p, filterRange);
      const { list, hasNext, total, error } = await OrderStore.getListOrder(p, Stage, filterRange, resellerId, {
        filter: filter.current,
        sorter: sorter.current,
      });
      if (error) return setUI({ fetching: false, errorMes: error });
      setPage(p);
      setPagination({ hasNext, total });
      setJobsByStage(s => ({
        ...s,
        [stageStatus]: p === 1 ? list : uniqBy([...s[stageStatus], ...list], 'Id'),
      }))
      setUI({ fetching: false, errorMes: '' });
      setIsLoadingMore(false);
      if (hasNext) {
        setTimeout(() => {
          // @ts-ignore
          if (layoutContainer.current?.height > layoutTable.current?.height) {
            getData(p + 1, stageStatus);
          }
        }, 300);
      }
    } catch (err) {
      setUI({ fetching: true, errorMes: String(err) });
    }
  }, [filterTimeRange, resellerId]);

  const updateReportData = async (change = 0) => {
    if (change) {
      OrderStore.updateReportDataManually({
        unpaidOrderThisYear: Math.max(0, OrderStore.reportData.unpaidOrderThisYear + change),
      });
    }
  }

  useEvent('REFRESH_LIST_ORDERS', () => {
    getData(1, stage);
  }, [getData, stage]);

  useEvent('ORDER_UPDATE_TO_FULFILLED', ({ orderId, orderData }: any) => {
    if (stage === 'in-production') {
      // remove order from list
      setJobsByStage(s => {
        const newArr = s[stage].filter(j => j['Order ID'] !== orderId);
        const processedUnPaidOrder = newArr.filter(j =>
          j['Order ID'] !== orderId && j.StageStatus === 'Awaiting Payment' && !j["Other Data"]?.isTestOrder
        );
        updateReportData(-processedUnPaidOrder?.length);
        return {
          ...s,
          [stage]: newArr
        }
      });
      return;
    }
    if (stage === 'fulfillment') {
      // add order to list
      setJobsByStage(s => {
        const oldArr = s[stage] || [];
        const findIndex = oldArr.findIndex(j => j['Order ID'] === orderId);
        return {
          ...s,
          [stage]: findIndex === -1 ? [orderData, ...oldArr] : oldArr,
        }
      });
      return;
    }
  }, [stage]);

  useEffect(() => {
    if (!stage || stage === 'undefined') {
      navigation.navigate(resellerId !== 'all' ? SCREEN.ListOrderForReseller : SCREEN.ListOrders, { stage: "pre-production" });
      return;
    }
    if (stage === 'recycling-bin') {
      navigation.navigate(SCREEN.DeletedOrders);
      return;
    }
    // will getData when screen is focused
    const unsubscribe = navigation.addListener('focus', () => {
      getData(1, stage);
    });
    getData(1, stage);
    return unsubscribe;
  }, [stage, resellerId, filterTimeRange]);

  const listSelected = useMemo(() => {
    return Object.keys(selectedJobs).map(stringId => {
      if (!selectedJobs[stringId]) return undefined;
      const findJob = jobsByStage[stage].find(val => `${val.Id}_${val['Order ID']}` === stringId);
      return findJob;
    }).filter(Boolean);
  }, [selectedJobs, jobsByStage, stage]);

  useEffect(() => {
    UserStore.getListResellers(1);
    ShopStore.getList(1);
  }, [])

  const inProductionOptions = [
    { label: 'All', value: undefined },
    { label: 'In production', value: true },
    { label: 'Accepted', value: false },
  ]

  const displayJobs = useMemo(() => (
    jobsByStage?.[stage]?.filter(j => {
      if (user?.role !== 'admin' && j['Client ID'] !== UserStore.getResellerId(user)) {
        console.log('client id not match', j['Client ID'], UserStore.getResellerId(user));
        return false;
      }
      if (j.Pipelines.length === 0) {
        console.log('EMPTY PIPELINE', j.Pipelines.length, j['Order ID']);
        return false;
      }
      const sharedData = j.Pipelines?.[j.Pipelines.length - 1]?.SharedData;
      if (sharedData?.skipped) return false;
      // if (status !== j.Status.toLowerCase()) {
      //   console.log('wrong status', status, j.Status.toLowerCase());
      //   return false;
      // }
      // if (status !== 'accepted') return true;
      if (sharedData?.isSampleRequest && j.StageStatus === 'Awaiting Payment') { // !sharedData?.isPaid - assuming every Await Payment order is unpaid
        return false;
      }
      return true
    })
  ), [jobsByStage, stage, user]);
  // console.log('displayJobs', displayJobs);

  const onScroll = (e) => {
    if (isCloseToBottom(e.nativeEvent)) {
      if (pagination.hasNext && !isLoadingMore) {
        console.log("load more");
        TimeHelper.throttle(() => getData(page + 1, stage), 500)();
      }
    }
  };

  const onLayoutTable = (e) => layoutTable.current = e.nativeEvent?.layout;
  const onLayoutContainer = (e) => layoutContainer.current = e.nativeEvent?.layout;

  const stageStatusOpts = useMemo(() => {
    let opts = [];
    if (stage && stage !== "all") {
      opts = STATUS_BY_STAGE[ValHelper.toDisplayStage(stage)] || [];
    }
    if (!opts.length) opts = ORDER_STATUS;
    return opts.map(i => ({ text: i, value: i }))
  }, [stage]);

  return (
    <CMSLayout
      requireAuthen
      breadcrumbs={breadcrumbs || [
        { title: 'Orders' },
      ]}
    >
      <Row marginBottom={0} ph2 bgWhite alignItems={'flex-start'}
        onLayout={(e) => {
          const height = e.nativeEvent.layout.height;
          setContainerHeight(screenHeight - height - 55);
        }}
      >
        <ListOrdersTabs
          activeTab={stage}
          flex1
          searchPlaceholder='Search order, email, product name..'
          onSearchInput={(query) => {
            if (!query) return showPopupMessage({
              title: '',
              content: 'Please fill in order number, email or product name',
              buttonOkText: 'OK',
              // 
              typeHighlight: 'warning',
              contentHighlight: 'Fill information'
            });
            // alert('Please fill in order number, email or product name');
            navigation.navigate(SCREEN.OrderSearchResult, { query })
          }}
          onChangeTab={(newKey) => {
            setSelectedJobs({});
            if (kebabCase(newKey) === 'recycling-bin') {
              navigation.navigate(SCREEN.DeletedOrders);
              return;
            }
            navigation.navigate(resellerId !== 'all' ? SCREEN.ListOrderForReseller : SCREEN.ListOrders, { stage: kebabCase(newKey) });
          }}
          renderAction={(
            atLeastOneSelected ? (
              <Col marginLeft={isMobile ? -12 : 24} marginTop={isMobile ? 10 : -14}>
                <OrderActionButtons
                  stage={stage}
                  rejectJob={() => {
                    rejectModalRef.current?.show();
                  }}
                  listSelected={listSelected}
                  onDeleteSuccess={() => {
                    setJobsByStage(s => {
                      const newArr = s[stage]?.filter(val => !selectedJobs[`${val.Id}_${val['Order ID']}`]);
                      const deletedUnPaidOrder = newArr.filter(val =>
                        selectedJobs[`${val.Id}_${val['Order ID']}`] && val.StageStatus === 'Awaiting Payment' && !val["Other Data"]?.isTestOrder
                      );
                      updateReportData(-deletedUnPaidOrder?.length);
                      return {
                        ...s,
                        [stage]: newArr,
                      }
                    })
                    setSelectedJobs({});
                  }}
                />
              </Col>
            ) : <Col></Col>
          )}
        />
      </Row>
      {isMobile && (
        <Col padding={10} borderRadius={8} bgWhite marginHorizontal={6} marginTop={4}>
          <Select
            size="large"
            value={selectedStageStatus}
            onChange={(val) => {
              filter.current = {
                ...(filter.current || {}),
                stageStatus: [val],
              };
              setSelectedStageStatus(val);
              getData(1, stage);
            }}
            placeholder="Job Status"
            options={stageStatusOpts}
            allowClear
          />
        </Col>
      )}
      <ScrollView style={{ height: containerHeight }} onScroll={onScroll} onLayout={onLayoutContainer} scrollEventThrottle={30}>
        <Col flex1 round1 {...isMobile ? {
          marginHorizontal: 8,
          marginTop: 10,
        } : {
          m2: true,
          bgWhite: true,
          mv1: true,
          pt1: true,
        }}>
          {errorMes ? (
            <Col flex1 middle>
              <Text color="red" subtitle1>{errorMes}</Text>
            </Col>
          ) : (
            <Col>
              {!isMobile && (
                <Row justifyContent="flex-end" p1>
                  <Row>
                    {/* @ts-ignore */}
                    <RangePicker
                      onChange={setFilterTimeRange}
                      allowClear={false}
                      allowEmpty={false}
                      value={filterTimeRange?.length ? filterTimeRange : defaultRange}
                      size="large"
                    />
                  </Row>
                </Row>
              )}
              <OrderTable
                isLoading={fetching}
                jobs={displayJobs}
                onRefresh={() => getData(1, stage)}
                stage={stage}
                onChangeFilter={(_, _filter, _sorter) => {
                  sorter.current = _sorter;
                  filter.current = _filter;
                  getData(1, stage);
                }}
                selectedJobs={selectedJobs}
                setSelectedJobs={setSelectedJobs}
                onLayout={onLayoutTable}
              />
              {isLoadingMore && (
                <Col middle padding={10}>
                  <ActivityIndicator />
                </Col>
              )}
              {/* <ListOrderLoadMore
                hasNext={pagination.hasNext}
                getData={getData}
                page={page}
                isLoadingMore={isLoadingMore}
              /> */}
            </Col>
          )}
        </Col>
      </ScrollView>
      <RejectOrderModal
        selectedOrders={listSelected}
        ref={rejectModalRef}
      />
    </CMSLayout>
  )
};

ListOrders.routeInfo = {
  title: 'Orders - Bottled Goose',
  path: '/list-orders/:stage',
};

export default ListOrders;
