import Request from '../Request.utils'
export interface INewOrderRequestBody {
  artworkUrls?: string[];
  customer: {
    email?: string;
    first_name?: string;
    last_name?: string;
    phone?: string;
  };
  height: number;
  orderId: string;
  productName: string;
  quantity: number;
  shipping_address: {
    address1?: string;
    address2?: string;
    city?: string;
    country_code?: string;
    first_name?: string;
    last_name?: string;
    phone?: string;
    zip?: string;
  };
  width: number;
}
export interface IMarkAsShippedRequestBody {
  orderId: string;
  printJobId: string;
  resellerId: string;
}


class BgServiceAPI {
  newOrder = async (body: INewOrderRequestBody) => {
    const res = await Request.call('/api/bg-services/:id/new-order', 'POST', undefined, undefined, body, );
    return res;
  }
  markAsShipped = async (body: IMarkAsShippedRequestBody) => {
    const res = await Request.call('/api/bg-services/:id/mark-as-shipped', 'POST', undefined, undefined, body, );
    return res;
  }
}
export default new BgServiceAPI()