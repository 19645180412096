
import Store from 'store';
import { COLOR, SCREEN } from 'const';
////////////////////////////////////////////////
import React, { useState, useRef, useEffect } from 'react';
import { BgIcon, useUIState, Col, Row, Text, showPopupMessage } from 'components';
import {
  ScrollView, View, TouchableOpacity, StyleSheet,
  useWindowDimensions, TextInput, Image,
} from 'react-native';
import { useNavFunc } from 'navigation';
import { ValHelper } from 'helpers';
import { dataFacts } from 'const/facts';
import { SVG, ASSETS } from 'assets';
import GeeseFactsRandom from 'components/widgets/facts/GeeseFactsRandom';
import { IScreen } from 'type';
////////////////////////////////////////////////

const ResellerRegister: IScreen = () => {
  const { navigate, reset, route } = useNavFunc();
  const { redirect }: any = route.params || {};
  const UserStore = Store.useUserStore();
  const [{ loading }, setUI] = useUIState();

  const { width } = useWindowDimensions();
  const minWidth = 900;
  const isResizeLayout = width > minWidth;
  const isMobile = width <= minWidth;
  const paddingRightPassword = (width: number, minWidth: number) => {
    if (width / 2 >= 560) return 16;
    if (width / 2 < 560 && width > minWidth) return 0;
    if (width > 560 && width <= minWidth) return 16;
    return 0;
  }
  const resizeFont = (width: number, minWidth: number) => {
    if (width > 1000) return 0;
    if (900 <= width) return 2;
    if (width < 900 && width >= 550) return 0;
    if (width < 550) return 2;
  }
  /* Logic */
  const [isChecked, setIsChecked] = useState(false);
  const [fullname, setFullname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const isRegisterEnabled =
    fullname.length > 0
    && email.length > 0
    && password.length > 0
    && confirmPassword.length > 0
    && isChecked
    ;
  const [openEyePassWord, setOpenEyePassword] = useState(true);
  const [openEyeConfirmPassWord, setOpenEyeConfirmPassword] = useState(true);

  /* Validate */

  const [errorMessageEmail, setErrorMessageEmail] = useState('');
  const validateEmail = () => {
    const emailChecker = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailChecker.test(email)) {
      setErrorMessageEmail('Please provide a valid email.');
      return false;
    }
    setErrorMessageEmail('');
    return true;
  }
  const [errorMessageConfirmPassword, setErroMessageConfirmPassword] = useState('');
  const validateConfirmPassword = () => {
    if (password !== confirmPassword) {
      setErroMessageConfirmPassword('Not the same as your password.');
      return false;
    }
    setErroMessageConfirmPassword('');
    return true;
  }

  const handleRegister = async () => {
    if (!validateEmail() || !validateConfirmPassword()) {
      return;
    }
    setUI({ loading: true });
    const names = fullname.split(' ');
    const firstName = names[0];
    const lastName = names.slice(1).join(' ');
    try {
      const res = await Store.Api.User.register({
        firstName,
        lastName,
        email,
        password,
        role: 'reseller',
        otherData: {
          registeredUserAgent: navigator.userAgent,
        },
      });
      if (res.data.error) {
        setUI({ loading: false });
        // return alert(String(res.data.error));
        return showPopupMessage({
          title: '',
          content: String(res.data.error),
          buttonOkText: 'OK',
          // 
          typeHighlight: 'danger',
          contentHighlight: 'Error'

        });
      }
      showPopupMessage({
        title: '',
        content: 'Register successfully.',
        buttonOkText: 'OK',
        // 
        typeHighlight: 'success',
        contentHighlight: 'Success'

      });
      // @ts-ignore
      if (window.dataLayer) {
        // @ts-ignore
        window.dataLayer.push({
          event: 'registration',
          eventCategory: 'User',
          eventAction: 'Register',
          eventLabel: 'Successful Registration'
        })
      }
      // alert('Register successfully.');
      const token = res.data.data?.token;
      Store.Client.setToken(token);
      UserStore.set({
        token,
        user: res.data.data?.publicInfo,
      });
      if (redirect && redirect !== 'undefined') {
        reset(redirect);
      } else {
        reset(SCREEN.Home);
      }
      // reset(SCREEN.Login, { redirect: redirect === 'undefined' ? '' : redirect  });
      // alert('Register successfully. Please login now');
      // reset(SCREEN.Login, { redirect: redirect === 'undefined' ? '' : redirect  });
    } catch (err) {
      setUI({ loading: false, errorMes: '' });
      // alert(String(err));
      showPopupMessage({
        title: '',
        content: String(err),
        buttonOkText: 'OK',
        // 
        typeHighlight: 'danger',
        contentHighlight: 'Error'

      });
    }
  }

  const renderFact = () => {
    return (
      <Col
        width={isResizeLayout ? '50%' : '100%'}
        justifyContent={'center'}
        alignItems={isResizeLayout ? 'flex-end' : 'center'}
        paddingLeft={isResizeLayout ? 30 : 30}
        paddingRight={isResizeLayout ? 0 : 30}
        minWidth={300}
        height={isResizeLayout ? '100%' : ''}
      >
        <Col
          width={'100%'}
          height={'100%'}
          backgroundColor={'#FFFFFFE5'}
          opacity={0.9}
          middle
          borderRadius={16}
          padding={isResizeLayout ? 16 : 5}
        >
          <GeeseFactsRandom />
        </Col>
      </Col>
    )
  };

  const renderForm = () => {
    return (
      <Col
        width={isResizeLayout ? '50%' : '100%'}
        minWidth={isResizeLayout ? 450 : ''}
        middle
        height={isResizeLayout ? '100%' : ''}
      >
        <Image
          source={ASSETS.BG_SMALL_BLUE}
          style={{
            width: 100, display: isResizeLayout ? '' : 'none',
            height: 120, borderRadius: 10, alignSelf: 'center', marginRight: 10
          }}
          resizeMode="contain"
        />

        <Col
          maxWidth={750}
          justifyContent={'center'}
          paddingHorizontal={30}
        >
          <Col
            marginTop={20}
            marginBottom={isResizeLayout ? 50 : 16}
          >
            <Row
              flexWrap={'wrap'}
              justifyContent={'center'}
            >
              <Text
                fontFamily={'Inter, sans-serif'}
                fontWeight={600}
                fontSize={32 - resizeFont(width, minWidth)}
                lineHeight={40}
                marginBottom={16}
                color={'#101840'}
                textAlign={'center'}
              >Welcome to </Text>
              <Image
                source={ASSETS.BG_SMALL_BLUE}
                style={{
                  width: 40, height: 52, borderRadius: 10,
                  display: isResizeLayout ? 'none' : '',
                  alignSelf: 'center', marginRight: 10,
                }}
                resizeMode="contain"
              />
              <Text
                fontFamily={'Inter, sans-serif'}
                fontWeight={600}
                fontSize={32 - resizeFont(width, minWidth)}
                lineHeight={40}
                marginBottom={16}
                color={'#101840'}
                textAlign={'center'}
              >Bottled Goose</Text>
            </Row>
            <Text
              fontFamily={'Inter, sans-serif'}
              fontWeight={600}
              fontSize={16 - resizeFont(width, minWidth)}
              lineHeight={24}
              color={'#8692A6'}
              textAlign={'center'}
            >Kindly fill in your details below to create an account</Text>

          </Col>
          <Col
          >
            <Col
              marginBottom={16}
            >
              <Text
                fontFamily={'Inter, sans-serif'}
                fontWeight={600}
                fontSize={14 - resizeFont(width, minWidth)}
                lineHeight={21}
                color={'#101840'}
                marginBottom={8}
              >Full name</Text>
              <TextInput
                style={{
                  fontFamily: 'Inter, sans-serif',
                  fontWeight: 400,
                  fontSize: 16 - resizeFont(width, minWidth),
                  lineHeight: 24,
                  height: 52,
                  borderWidth: 1,
                  borderRadius: 5,
                  borderColor: '#696F8C',
                  paddingVertical: 14,
                  paddingHorizontal: 16,
                  outline: 'none',
                }}
                placeholder='Enter your full name'
                placeholderTextColor={'#696F8C'}
                onChangeText={(text) => setFullname(text)}
              ></TextInput>
            </Col>
            <Col
              marginBottom={16}
            >
              <Text
                fontFamily={'Inter, sans-serif'}
                fontWeight={600}
                fontSize={14 - resizeFont(width, minWidth)}
                lineHeight={21}
                color={'#101840'}
                marginBottom={8}
              >Email</Text>
              <TextInput
                style={{
                  fontFamily: 'Inter, sans-serif',
                  fontWeight: 400,
                  fontSize: 16 - resizeFont(width, minWidth),
                  lineHeight: 24,
                  height: 52,
                  borderWidth: 1,
                  borderRadius: 5,
                  borderColor: '#696F8C',
                  paddingVertical: 14,
                  paddingHorizontal: 16,
                  outline: 'none',
                }}
                placeholder='Enter your email'
                placeholderTextColor={'#696F8C'}
                onChangeText={(text) => setEmail(text)}
              ></TextInput>
              <Text
                color={'red'}
                marginTop={5}
              >
                {errorMessageEmail}
              </Text>
            </Col>
            <Col flex1
              marginBottom={16}
            >
              <Row
                flexWrap={'wrap'}
              >
                <Col
                  flex1
                  paddingRight={paddingRightPassword(width, minWidth)}
                  minWidth={250}
                >
                  <Text
                    fontFamily={'Inter, sans-serif'}
                    fontWeight={600}
                    fontSize={14 - resizeFont(width, minWidth)}
                    lineHeight={21}
                    color={'#101840'}
                    marginBottom={8}
                  >Password</Text>
                  <Row>
                    <TextInput
                      style={{
                        fontFamily: 'Inter, sans-serif',
                        fontWeight: 400,
                        fontSize: 16 - resizeFont(width, minWidth),
                        lineHeight: 24,
                        height: 52,
                        borderWidth: 1,
                        borderRadius: 5,
                        borderColor: '#696F8C',
                        paddingVertical: 14,
                        paddingHorizontal: 16,
                        outline: 'none',
                        flex: 1,
                      }}
                      placeholder='Enter your password'
                      placeholderTextColor={'#696F8C'}
                      secureTextEntry={openEyePassWord}
                      onChangeText={(text) => setPassword(text)}
                    ></TextInput>
                    <Col
                      absolute
                      right={20}
                      style={{ outline: 'none', }}
                      onPress={() => { setOpenEyePassword(!openEyePassWord) }}
                    >{openEyePassWord ? <SVG.EyeOpen /> : <SVG.EyeClose />}</Col>
                  </Row>
                </Col>
                <Col
                  minWidth={250}
                  flex1
                >
                  <Text
                    fontFamily={'Inter, sans-serif'}
                    fontWeight={600}
                    fontSize={14 - resizeFont(width, minWidth)}
                    lineHeight={21}
                    color={'#101840'}
                    marginTop={width >= 460 ? 0 : 8}
                    marginBottom={8}
                  >Confirm Password</Text>
                  <Row>
                    <TextInput
                      style={{
                        fontFamily: 'Inter, sans-serif',
                        fontWeight: 400,
                        fontSize: 16 - resizeFont(width, minWidth),
                        lineHeight: 24,
                        height: 52,
                        borderWidth: 1,
                        borderRadius: 5,
                        borderColor: '#696F8C',
                        paddingVertical: 14,
                        paddingHorizontal: 16,
                        outline: 'none',
                        flex: 1,
                      }}
                      placeholder='Enter your password'
                      placeholderTextColor={'#696F8C'}
                      secureTextEntry={openEyeConfirmPassWord}
                      onChangeText={(text) => setConfirmPassword(text)}

                    ></TextInput>
                    <Col
                      absolute
                      right={20}
                      style={{ outline: 'none', }}
                      onPress={() => { setOpenEyeConfirmPassword(!openEyeConfirmPassWord) }}
                    >{openEyeConfirmPassWord ? <SVG.EyeOpen /> : <SVG.EyeClose />}</Col>
                  </Row>
                </Col>
              </Row>
              <Text
                color={'red'}
                marginTop={5}
              >
                {errorMessageConfirmPassword}
              </Text>
            </Col>
            <Col
              marginBottom={16}
            >
              <Row
                justifyContent={'flex-start'}
                alignItems={'flex-start'}
              >
                <Col
                  width={20}
                  height={20}
                  borderWidth={2}
                  borderColor={'#5984FF'}
                  borderRadius={3}
                  backgroundColor={isChecked ? '#5984FF' : ''}
                  marginRight={14}
                  middle
                  style={{ outline: 'none', }}
                  onPress={() => { setIsChecked(!isChecked); }}
                >
                  {isChecked ? <SVG.CheckIcon /> : ''}
                </Col>
                <Col
                  flex1
                >
                  <Text
                    fontFamily={'Inter, sans-serif'}
                    fontWeight={400}
                    fontSize={14 - resizeFont(width, minWidth)}
                    lineHeight={20}
                    color={'#101840'}
                    textAlign={'justify'}
                  >
                    By ticking, you are confirming that you have read, understood and agree to
                    <Text
                      fontFamily={'Inter, sans-serif'}
                      fontWeight={500}
                      fontSize={14 - resizeFont(width, minWidth)}
                      lineHeight={24}
                      color={'#3366FF'}
                      marginLeft={3}
                      onPress={() => { navigate(window.location.href = 'https://www.bottledgoose.co.uk/pages/policies') }}
                    >
                      our terms and conditions
                    </Text>
                  </Text>
                </Col>

              </Row>
            </Col>
            <Col marginBottom={16}
            >
              <Col
                middle
                paddingVertical={8}
                paddingHorizontal={16}
                marginBottom={20}
                borderRadius={4}
                backgroundColor={'#223363'}
                opacity={isRegisterEnabled ? 1 : 0.7}
                style={{ outline: 'none' }}
                onPress={() => {
                  // validateEmail();
                  // validateConfirmPassword();
                  handleRegister();
                }}
                disabled={!isRegisterEnabled || !isChecked}
              >
                <Text
                  fontFamily={'Inter, sans-serif'}
                  fontWeight={600}
                  fontSize={16 - resizeFont(width, minWidth)}
                  lineHeight={16}
                  color={'#FFF'}
                >
                  Register Account
                </Text>
              </Col>
              <Row
                marginBottom={20}
              >
                <Col
                  flex6
                  height={1}
                  backgroundColor={'#686677'}
                ></Col>
                <Text
                  fontFamily={'Inter, sans-serif'}
                  fontWeight={600}
                  fontSize={12 - resizeFont(width, minWidth)}
                  lineHeight={16}
                  color={'#101840'}
                  flex={1}
                  textAlign={'center'}
                >
                  Or
                </Text>
                <Col
                  flex6
                  height={1}
                  backgroundColor={'#686677'}
                ></Col>
              </Row>
              <Col
              >
                <Text
                  fontFamily={'Inter, sans-serif'}
                  fontWeight={400}
                  fontSize={16 - resizeFont(width, minWidth)}
                  lineHeight={24}
                  color={'#101840'}
                >
                  Already have an account?
                  <Text
                    fontFamily={'Inter, sans-serif'}
                    fontWeight={500}
                    fontSize={14 - resizeFont(width, minWidth)}
                    lineHeight={24}
                    color={'#3366FF'}
                    marginLeft={8}
                    onPress={() => navigate('Login')}
                  >
                    Log in
                  </Text>
                </Text>
              </Col>
            </Col>
          </Col>
        </Col>
      </Col>
    )
  }

  const renderMobile = () => {
    return (
      <ScrollView style={{ height: 500, backgroundColor: COLOR.EXTENDED_N300 }}>
        {renderForm()}
        {renderFact()}
        <Col height={50} />
      </ScrollView>
    )
  };

  return isMobile ? renderMobile() : (
    <Col
      flex1
      backgroundColor={COLOR.EXTENDED_N300}
    >
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{
          flex: 1,
        }}
      >
        <Col
          flex1
          middle
          paddingBottom={isResizeLayout ? 0 : 50}

        >
          <Row
            flexWrap={'wrap-reverse'}
            flex1
            marginVertical={isResizeLayout ? 30 : 10}
            width={'100%'}
          >
            {renderFact()}
            {renderForm()}
          </Row>
        </Col>
      </ScrollView>
    </Col>
  )
}

ResellerRegister.routeInfo = {
  title: 'Reseller register - Bottled Goose - Print Manager',
  path: '/reseller-register',
};

export default ResellerRegister;