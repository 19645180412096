import { Col, IColProps, Row, Text } from "components/base";
import React from "react";
import BgIcon from "../BgIcon";
import { COLORS } from "const";
import PhoneInput from "antd-phone-input";

interface Props extends IColProps {
  title?: string,
  icon?: string,
  value?: string,
  error?: string,
  isRequired?: boolean,
  onValidPhone?: (valid: boolean) => void,
  onChangeText?: (txt: string) => void,
}

const PhoneField = (props: Props) => {
  const { title, icon, value, onChangeText, error, isRequired, onValidPhone, ...restProps } = props;
  return (
    <Col {...restProps}>
      {!!title && (
        <Row marginBottom={8} alignItems="center">
          <Text fontWeight="500" fontSize={14}>{title}</Text>
          {!!icon && (
            <Col marginLeft={8}>
              <BgIcon name={icon} size={14} />
            </Col>
          )}
          {isRequired && (
            <Text color={COLORS.RED} fontSize={18} marginLeft={2}>*</Text>
          )}
        </Row>
      )}
      <PhoneInput
        size="large"
        value={value}
        onChange={(e) => {
          if (typeof e.valid === "function") onValidPhone?.(e.valid());
          onChangeText(`${e.countryCode || ""}${e.areaCode || ""}${e.phoneNumber || ""}`);
        }}
        status={!!error ? "error" : undefined}
        enableSearch
      />
      {!!error && (
        <Text fontSize={12} marginTop={2} color={COLORS.RED}>{error}</Text>
      )}
    </Col>
  )
}

export default PhoneField;
