import { Col, Row, Text } from "components/base";
import { COLORS, COLOR } from "const";
import { adminNav, resellerNav } from "./nav";
import { useNavFunc } from "navigation";
import React, { useMemo } from "react";
import Store from "store";
import { BgIcon, TouchField } from "components/elements";
import { Badge } from "antd";
import { MaterialCommunityIcons, Feather, Ionicons, AntDesign } from "@expo/vector-icons";

const SideMenu = ({ onPressItem, breadcrumbs, description }: any) => {
  const { navigate, route } = useNavFunc();
  const { user, isResellerCompleteSetup } = Store.useUserStore();
  const OrderStore = Store.useOrderStore();
  const unpaidOrder = OrderStore.reportData?.unpaidOrderThisYear;
  const role = user?.role;

  const onPress = async (screen) => {
    if (onPressItem) await onPressItem?.();
    navigate(screen);
  }

  const menuItems = useMemo(() => {
    let arr = []
    if (role !== 'admin') arr = resellerNav;
    else arr = adminNav;
    if (isResellerCompleteSetup) {
      arr = arr.filter((val) => val.title !== 'Get Started');
    }
    return arr;
  }, [role, isResellerCompleteSetup]);

  const renderBreadcrumb = () => {
    if (!breadcrumbs || breadcrumbs.length === 0) return null;
    return (
      <Col p1 borderBottomColor={COLOR.GREY_BG} borderBottomWidth={1}>
        <Text caption color='rgba(0,0,0,0.3)' mb0>You're viewing:</Text>
        {breadcrumbs && breadcrumbs.length > 0 && (
          <Row>
            {breadcrumbs.map(({ title, screen, params }, index) => {
              const isLast = index === breadcrumbs.length - 1;
              const isFirst = index === 0;
              const color = isLast ? COLOR.MAIN : '#667085';
              return (
                <Row key={index} mr1>
                  {!!screen ? (
                    <TouchField
                      onPress={() => navigate(screen, params)}
                      marginLeft={isFirst ? -5 : 0}
                    >
                      <Text p0 fontSize={14} color={color} semiBold>{title}</Text>
                    </TouchField>
                  ) : (
                    <Text p0 marginLeft={isFirst ? -5 : 0} fontSize={14} color={color} semiBold>{title}</Text>
                  )}
                  {!isLast && (
                    <AntDesign name="right" size={12} color="#667085" style={{ marginLeft: 5, marginRight: 0 }} />
                  )}
                </Row>
              )
            })}
          </Row>
        )}
        {description && (
          <Text caption color='#667085'>{description}</Text>
        )}
      </Col>
    )
  }

  return (
    <Col paddingHorizontal={12} paddingVertical={16} bgWhite flex1>
      {renderBreadcrumb()}
      <Text ml1 caption color='rgba(0,0,0,0.3)' mv1>Navigation menu:</Text>
      {menuItems.map((i, ii) => {
        const isActive = route.name === i.screen || i.childrens?.includes(route.name);
        const badge = ['Job Bag', 'My Orders'].includes(i.title) ? unpaidOrder : undefined
        return (
          <Col
            key={'side-menu-' + ii + i.title}
            paddingHorizontal={16} paddingVertical={16}
            marginBottom={10}
            onPress={() => onPress(i.screen)}
            backgroundColor={isActive ? COLORS.BG_DARKER : undefined}
          >
            <Row>
              <BgIcon name={i.icon} />
              <Col flex1>
                <Text marginLeft={16} numberOfLines={1}>
                  {i.title}
                </Text>
              </Col>
              {Boolean(badge) && (
                <Badge count={badge} />
              )}
            </Row>
          </Col>
        )
      })}
    </Col>
  );
}

export default SideMenu;
