import React from 'react';
import { Col, IColProps } from 'components';

interface IRatioColProps extends IColProps {
  ratio: number, // width / height
  children: any,
  width: number | string,
}

const RatioCol = ({ ratio, children, width, ...props} : IRatioColProps) => {
  return (
    <Col width={width} {...props}>
      <Col paddingBottom={100 / ratio + '%'}>
        <Col absoluteFill>
          {children}
        </Col>
      </Col>
    </Col>
  );
};

export default RatioCol;