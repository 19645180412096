import React, { useEffect, useState } from 'react';
import { Col, modal, Text } from 'components';
import { COLOR, LOG_TYPES } from 'const';
import Store, { Api } from 'store';
import { ActivityIndicator, useWindowDimensions } from 'react-native';
import { ValHelper } from 'helpers';
import { TDesign } from 'type';
import apiClient from 'store/api-client';
import { isNil, omitBy } from 'lodash';
import { TCMSOrder } from 'type';
import { requestMicroApi } from 'helpers/RequestHelper';
import { useOrderStore } from 'store/Order.Store';

const EditDesignModal = ({ designId, onDesignSave, printJobId, order, record }: {
  designId: string;
  onDesignSave: () => void;
  printJobId?: string;
  order?: TCMSOrder;
  record?: any;
}) => {
  const [design, setDesign] = useState<TDesign>();
  const [isLoading, setIsLoading] = useState(false);
  const { width, height } = useWindowDimensions();
  const [isGenerating, setIsGenerating] = useState(false);
  const OrderStore = useOrderStore();

  const getDesign = async (designId: string) => {
    try {
      setIsLoading(true);
      const res = await apiClient.Api.Design.detail({ id: designId });
      setDesign(res?.data?.data || {});
    } catch (error) {
      console.log('getDesign', error);
      alert(String(error?.message || JSON.stringify(error)));
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (designId) {
      getDesign(designId);
    }
  }, [designId])

  const updateOrderData = async (payload: any) => {
    const { thumbnail, printJobId: newPrintJobId } = payload;
    setIsGenerating(true);
    try {
      const lineItems = order["Raw Data"]?.line_items || []
      const targetIdx = lineItems.findIndex(i => i.id === record.id);
      const oldLine = lineItems[targetIdx];
      const newLine = {
        ...oldLine,
        properties: oldLine.properties.map(i => {
          if (i.name === "Print Job") return null;
          return i;
        }).filter(Boolean).concat([{
          name: "Print Job",
          value: newPrintJobId,
        }])
      }
      lineItems[targetIdx] = newLine;
      Api.Log.addLog({
        type: LOG_TYPES.UPDATE_ORDER_ARTWORK,
        data: {
          orderId: order['Order ID'],
        },
      })
      await requestMicroApi(`/api/bg/updateOrderData?id=${order.Id}`, {
        method: 'post',
        body: JSON.stringify({
          data: {
            ...order["Raw Data"],
            line_items: lineItems,
          }
        }),
      })
      const last = order?.Pipelines[order?.Pipelines.length - 1];
      const res = await Api.Pdf.sendRequestGeneratePdf({
        returnUrl: true,
        callbackUrl: '',
        items: last?.SharedData?.canBeProcessedItems.map(v => ({
          id: v.id,
          printJobId: v.id === record.id ? newPrintJobId : v.printJobId,
          customArtworkData: v.customArtworkData,
          designRenderId: v.designRenderId,
        })),
      })
      const pdfs = res?.data?.data?.pdfs;
      const canBeProcessedItems = last?.SharedData?.canBeProcessedItems?.map(v => {
        const findPdf = pdfs.find(p => p.lineId === v.id);
        const newItem = findPdf ? {
          ...v,
          pdf: findPdf.pdfUrl,
          printJobId: findPdf.printJobId,
          previewUrl: v.id === record.id ? thumbnail : v.previewUrl,
        } : v;
        if (findPdf.imageQuality) newItem.imageQuality = findPdf.imageQuality;
        return newItem;
      }) || [];
      await OrderStore.updateShareData(order, { canBeProcessedItems });
      setTimeout(() => {
        onDesignSave?.();
      }, 100);
    } catch (error) {
      alert(String(error?.message || JSON.stringify(error)));
    } finally {
      setIsGenerating(false);
    }
  }

  const onEditorMessage = async (
    data: { event: string; payload: any } | undefined
  ) => {
    if (!!data && (data.event === "CLOSE_EDITOR" || data.event === "DESIGN_SAVE")) {
      // close modal
      modal.hide();
    } else if (!!data && data.event === "CUSTOMIZATION_DONE") {
      // close modal
      await updateOrderData(data.payload);
      modal.hide();
    }
  };

  const iframeParams = omitBy({
    dev: ValHelper.isDev ? 1 : 0,
    print_job: printJobId,
    store_product_id: design?.productId,
    is_edit: 1,
  }, isNil)

  return (
    <Col width={width * 0.95} height={height * 0.95} round1 bgWhite shadow overflow={'hidden'}>
      <Col flex1>
        {isLoading || isGenerating ? (
          <Col flex={1} middle>
            <ActivityIndicator size={'large'} color={COLOR.MAIN} />
            {isGenerating && <Text marginTop={12}>Generating new artwork PDF</Text>}
          </Col>
        ) : (
          <iframe
            src={
              window.location.href.includes("iframe_dev=1")
                ? `http://localhost:3009/embed/${designId}?${ValHelper.objectToQueryString(iframeParams)}`
                : `${ValHelper.editorHost}/embed/${designId}?${ValHelper.objectToQueryString(iframeParams)}`
            }
            style={{
              width: "100%",
              height: "100%",
              border: "none",
              backgroundColor: COLOR.MAIN,
            }}
            onLoad={Store.Client.editor.initOnLoadAndOpenCommunication(
              onEditorMessage
            )}
          />
        )}
      </Col>
    </Col>
  );
}

export default EditDesignModal;
