import React, { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import { Col, Text, Row, showPopupMessage, InputField, PhoneField, SelectField } from 'components';
import { Modal } from 'antd';
import { COLOR, COUNTRIES, SCREEN } from 'const';
import Store, { Api } from 'store';
import PaySampleOrder from './OrderSampleModal.Pay';
import { TCMSOrder } from 'type';
import { usePaymentStore } from 'store/Payment.Store';
import { ValHelper, modalConfirm } from 'helpers';
import { useNavFunc } from 'navigation';
import './ordermodal.css'


const FIELDS = [
  { id: "first_name", label: "First name", require: true },
  { id: "last_name", label: "Last name", require: true },
  { id: "address1", label: "Street address", placeholder: "House number and street name", require: true },
  { id: "address2", placeholder: "Apartment, suite, unit etc. (optional)", label: "" },
  { id: "town", label: "Town / City", require: true },
  { id: "zip", label: "Post Code / ZIP", require: true },
  { id: "country", label: "Country", require: true },
  { id: "email", label: "Email", require: true },
  // { id: "phone", label: "Phone number" },
  // { id: "county", label: "County", require: true },
];

const OrderSampleModal = (props, ref) => {
  const { navigation } = useNavFunc();
  const { balance, refreshBalance } = usePaymentStore();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isOrdering, setIsOrdering] = useState(false);
  const [sampleToken, setSampleToken] = useState(0);
  const UserStore = Store.useUserStore();
  const { user } = UserStore;
  const [createdOrder, setCreatedOrder] = useState<TCMSOrder>();
  const [formErrors, setFormErrors] = useState({});
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [customAddress, setCustomAddress] = useState({
    first_name: user?.firstName,
    address1: user?.addressLine1,
    county: "",
    country: user?.country,
    town: user?.town,
    zip: user?.postCode,
    last_name: user?.lastName,
    address2: user?.addressLine2,
    email: user?.email,
    phone: '',
  })

  const getOrderSampleToken = async () => {
    // const res = await apiClient.Api.Shop.getAdminShopifyDiscountCodes();
    // setCode(res.data?.data?.code);
    setLoading(true);
    try {
      const res = await Api.User.getResellerSampleToken();
      setSampleToken(res.data?.data);
    } catch (err) {

    }
    setLoading(false);
  }

  useImperativeHandle(ref, () => ({
    show: () => {
      refreshBalance();
      setCreatedOrder(undefined);
      setShow(true);
      getOrderSampleToken();
    },
  }));

  const preCheckBalance = async () => {
    const res = await Api.Payment.getChargeAmountWithoutInvoice({
      address: customAddress,
      discounted: sampleToken > 0,
      products: [{
        productId: props.productId,
        designId: props.sampleDesignData?.designId,
      }]
    })
    if (res.data.data) {
      const { amount } = res.data.data;
      if (amount * 100 <= (balance?.balance * -1)) {
        return true;
      }
      modalConfirm({
        title: "Insufficient balance",
        content: `Please add more funds to request this order (£${ValHelper.formatMoney(amount)})`,
        onOk: () => {
          setShow(false);
          navigation.navigate(SCREEN.Settings, { tabId: 'billing' });
        },
        onCancel: () => { },
      })
    }
  }

  const validateFields = () => {
    const requireFields = FIELDS.filter(i => i.require).map(i => i.id);
    const errors = {};
    requireFields.map(key => {
      if (!customAddress[key]) {
        errors[key] = "This field is required";
      }
    })
    if (customAddress.phone) {
      if (!isValidPhone) errors["phone"] = "Invalid phone number";
    }
    if (customAddress.email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(customAddress.email)) errors["email"] = "Invalid email";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  }

  const onSubmit = async () => {
    const isValid = validateFields();
    if (!isValid) return;
    if (loading) return;
    setIsOrdering(true);
    try {
      const enoughBalance = await preCheckBalance();
      if (!enoughBalance) return;

      const res = await Api.Product.orderASample({
        productId: props.productId,
        productName: props.productName,
        customAddress,
        designId: props.sampleDesignData.designId,
        style: props.sampleDesignData.style,
      })
      if (res.data.success) {
        await new Promise(resolve => setTimeout(resolve, 3000));
        setCreatedOrder(res?.data?.data as any);
      } else {
        showPopupMessage({
          title: '',
          content: String(res.data.error),
          buttonOkText: 'OK',
          typeHighlight: 'danger',
          contentHighlight: 'Error'
        });
        // alert(res.data.error);
      }
    } catch (err) {
      showPopupMessage({
        title: '',
        content: String(err),
        buttonOkText: 'OK',
        typeHighlight: 'danger',
        contentHighlight: 'Error'

      });
      // alert(String(err));
    } finally {
      setIsOrdering(false);
    }
  }

  const okText = sampleToken > 0 ? "Order" : "Order without discount";

  const countryOpts = useMemo(() => {
    return COUNTRIES.map(i => ({
      label: i.name,
      value: i.name,
    }))
  }, []);

  return (
    <Modal
      open={show}
      title={""}
      onOk={onSubmit}
      onCancel={() => setShow(false)}
      okText={loading ? 'Loading...' : (isOrdering ? "Ordering..." : okText)}
      closeIcon={false}
      footer={createdOrder?.["Order ID"] ? null : undefined}
      confirmLoading={loading}
      className={createdOrder?.["Order ID"] ? "modalStyle" : ""}

    >
      {createdOrder?.["Order ID"] ?
        <PaySampleOrder
          
          sampleToken={sampleToken}
          order={createdOrder}
          onClose={() => setShow(false)}
        />
        :
        <>
          <Text bold h5>Ordering a sample? We`re offering 10 tokens that you can use to have 75% discount on sample products:</Text>
          <Col mv2>
            {loading ? (
              <Text caption mb0>Loading sample token...</Text>
            ) : (
              <Text caption mb0>You have {sampleToken}/10 tokens left</Text>
            )}
            <Row marginHorizontal={-5} mb1>
              {new Array(10).fill(0).map((_, i) => (
                <Col key={'token-' + i}
                  borderRadius={3}
                  height={4}
                  backgroundColor={sampleToken >= (i + 1) ? COLOR.MAIN : COLOR.GREY_BG}
                  flex1
                  m0
                />
              ))}
            </Row>
            {!loading && sampleToken === 0 && (
              <Text caption mb0>Please contact us if you wish to have more token</Text>
            )}
            {props.sampleDesignData?.image && (
              <Row>
                <Col>
                  <img src={props.sampleDesignData.image} alt="Sample Design" style={{ width: 200, height: 200 }} />
                </Col>
                <Col flex1 ml1>
                  <Text bold mb1 fontSize={24}>{props.productName}</Text>
                  <Text caption>{props.sampleDesignData.style}</Text>
                </Col>
              </Row>
            )}
            {!loading && (
              <Col>
                <Text mb2 subtitle1>Please enter Shipping address</Text>
                {FIELDS.map(field => {
                  if (field.id === "country") {
                    return (
                      <Col marginBottom={8}>
                        <SelectField
                          title="Country"
                          value={customAddress.country}
                          options={countryOpts}
                          selectProps={{ showSearch: true }}
                          onChange={(value: string) => {
                            setCustomAddress(s => ({
                              ...s,
                              [field.id]: value
                            }));
                            setFormErrors(s => ({
                              ...s,
                              [field.id]: null,
                            }))
                          }}
                        />
                      </Col>
                    )
                  }
                  return (
                    <Col key={field.id} marginBottom={8}>
                      <InputField
                        title={field.label}
                        value={customAddress[field.id] || ""}
                        onChangeText={(val) => {
                          setCustomAddress(s => ({
                            ...s,
                            [field.id]: val
                          }));
                          setFormErrors(s => ({
                            ...s,
                            [field.id]: null,
                          }))
                        }}
                        isRequired={field.require}
                        error={formErrors[field.id]}
                        inputProps={{
                          placeholder: field.placeholder || field.label,
                        }}
                      />
                    </Col>
                  )
                })}
                <PhoneField
                  size="large"
                  title="Phone number"
                  value={customAddress.phone}
                  error={formErrors["phone"]}
                  onValidPhone={setIsValidPhone}
                  onChangeText={(val) => {
                    setCustomAddress(s => ({
                      ...s,
                      phone: val
                    }));
                    setFormErrors(s => ({
                      ...s,
                      phone: null,
                    }))
                  }}
                />
              </Col>
            )}
          </Col>
        </>
      }
    </Modal>
  );
};

export default forwardRef(OrderSampleModal);
