import Request from '../Request.utils'
export interface IUpsertPackingSlipRequestBody {
  address?: string;
  companyLogo?: string;
  companyName?: string;
  email?: string;
  id?: string;
  phone?: string;
  storeId?: string;
}
export interface IGetPackingSlipRequestBody {
  id?: string;
  resellerId?: string;
  storeId?: string;
}


class PackingSlipAPI {
  upsertPackingSlip = async (body: IUpsertPackingSlipRequestBody) => {
    const res = await Request.call('/api/packing-slips', 'POST', undefined, undefined, body, );
    return res;
  }
  getPackingSlip = async (body: IGetPackingSlipRequestBody) => {
    const res = await Request.call('/api/get-packing-slips', 'POST', undefined, undefined, body, );
    return res;
  }
}
export default new PackingSlipAPI()