import React, { useCallback, useState, useRef } from 'react';
import {
  Image,
  ActivityIndicator,
} from 'react-native';
import { Col, Text, Row, RatioCol, Grid, TouchField } from 'components';
import { useDropzone } from 'react-dropzone';
import { Entypo, Feather, EvilIcons } from '@expo/vector-icons';
import Store from 'store';
import { set } from 'react-native-reanimated';
import { COLOR } from 'const';

interface IProps {
  ratio: number,
  width: any,
  value: string,
  shortText?: boolean,
  onChange: (value: string) => void,
  [otherProps: string]: any,
}

const ImageField = ({ ratio, width, value, onChange, shortText, ...props }: IProps) => {

  const [isUploading, setIsUploading] = useState(false);
  const namePrefixRef = useRef('');

  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    if (acceptedFiles.length === 0) return;
    setIsUploading(true);
    uploadFile(acceptedFiles[0])
    .catch(err => console.log(err))
    .finally(() => setIsUploading(false));
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const uploadFile = async file => {
    if (!file) return;
    const val = file;
    const uploadName = `${namePrefixRef.current || (new Date().getTime() + '-')}${val.name}`;
    const serverSidePath = `files/${uploadName}`;
    try {
      const res1 = await Store.Api.User.getUploadPresignedUrl({
        serverSidePath,
        contentType: val.type || 'application/octet-stream',
      });
      const awsUrl = res1.data.data
      if (!awsUrl) return undefined;

      const res2 = await fetch(awsUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': val.type || 'application/octet-stream',
        },
        body: file,
      });

      if (res2.status !== 200) return undefined;
      const u = new URL(awsUrl);
      const uploadedUrl = `${u.origin}${u.pathname}`;
      onChange(uploadedUrl);
    } catch (err) {
      return undefined;
    }
  }
  return (
    <RatioCol ratio={ratio} width={width} {...props}>
      {
        !value ? (
          <div
            style={{
              width: '100%',
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              backgroundColor: '#f0f0f0',
              flexDirection: 'column'
            }}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            {
              isDragActive || isUploading ? (
                <ActivityIndicator color="black" />
              ) : (
                <Entypo name="upload" size={24} color="black" />
              )
            }
            <Col mt1 p1>
              {
                isDragActive ?
                <Text>{shortText ? `Drop here` : `Drop the image here ...`}</Text> :
                isUploading ? <Text>Uploading...</Text> :
                  <>
                    <Text fontSize={shortText ? 12: undefined}>{shortText ? `Drag 'n' drop` : `Drag 'n' drop the image here, or click to select`}</Text>
                  </>
              }
            </Col>
          </div>
        ) : (
          <>
            <Image source={{ uri: value }} style={{ width: '100%', height: '100%' }} />
            <TouchField cirle middle backgroundColor={'white'}
              absolute top={20} right={20}
              shadow onPress={() => onChange('')}
            >
              <EvilIcons name="trash" size={24} color={COLOR.FONT} />
            </TouchField>
          </>
        )
      }
    </RatioCol>
  );
}

export default ImageField;