import React, { useEffect } from 'react';
import './Settings.Emails.css';
import { Col, Row, Text, TouchField } from 'components';
import { COLOR } from 'const';
import { useState } from 'react';
import Store, { Api } from 'store';

interface IProps {
}

const EmailsSettings = () => {
  const [toggledStep1, settoggledStep1] = useState(false);
  const [toggledStep2, settoggledStep2] = useState(false);
  const [toggledStep3, settoggledStep3] = useState(false);

  const [listSettings, setListSettings] = useState<Array<any>>([]);

  const UserStore = Store.useUserStore();
  const { user } = UserStore;

  const getSettings = async () => {
    const res = await Api.Data.list({
      type: 'reseller-settings-emails',
    }, {
      field1: UserStore.getResellerId(user),
    });
    console.log('res', res);
    const list = res.data.data || [];
    const findStep1 = list.find((item: any) => item.name === 'Awaiting accept');
    const findStep2 = list.find((item: any) => item.name === 'Dispatch Notification');
    const findStep3 = list.find((item: any) => item.name === 'Order Rejected');
    settoggledStep1(findStep1?.field2 !== 'false');
    settoggledStep2(findStep2?.field2 !== 'false');
    settoggledStep3(findStep3?.field2 !== 'false');
    setListSettings(list);
  }

  useEffect(() => {
    getSettings();
  }, [user]);

  const updateSettings = async (name: string, value: boolean) => {
    const find = listSettings.find((item: any) => item.name === name);
    if (find) {
      // if it's already exists, update it
      await Api.Data.upsert({
        type: 'reseller-settings-emails',
      }, {
        id: find.id,
        field2: value.toString(),
      });
    } else {
      // create new settings
      await Api.Data.upsert({
        type: 'reseller-settings-emails',
      }, {
        field1: UserStore.getResellerId(user),
        name,
        field2: value.toString(),
      });
    }
  }


  return (
    <div className='container'>
      <p className='title-setting-email'><strong>Set up email notifications</strong></p>
      <div className='email-setting-container'>
        <div className='setting-item' >
          <p>Order has been placed via BG and needs you to accept (payment required)</p>
          <button
            className={`toggle-btn ${toggledStep1 ? "toggledStep1" : ""}`}
            onClick={() => {
              settoggledStep1(!toggledStep1);
              updateSettings('Awaiting accept', !toggledStep1);
            }}
          >
            <div className='email-setting-thumb'></div>
          </button>
        </div>
        <div className='setting-item'>
          <p>Dispatch Notification</p>
          <button
            className={`toggle-btn ${toggledStep2 ? "toggledStep2" : ""}`}
            onClick={() => {
              settoggledStep2(!toggledStep2);
              updateSettings('Dispatch Notification', !toggledStep2);
            }}
          >
            <div className='email-setting-thumb'></div>
          </button>
        </div>
        <div className='setting-item'>
          <p>Order Rejected</p>
          <button
            className={`toggle-btn ${toggledStep3 ? "toggledStep3" : ""}`}
            onClick={() => {
              settoggledStep3(!toggledStep3);
              updateSettings('Order Rejected', !toggledStep3);
            }}
          >
            <div className='email-setting-thumb'></div>
          </button>
        </div>


      </div>
    </div>
  );
};

export default EmailsSettings;
