
import { saveAs } from 'file-saver';
const moment = require('moment');

export const exportExcel = (XLSX, data, totalPrintJobData, listClients) => {
  var wb = XLSX.utils.book_new();

  // add Number of Print Jobs
  XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet([
    {
      'Total Print Jobs': data?.numberOfPrintJobs,
      'Total Printed Jobs': data?.numberOfPrintedJobs,
    },
    ...totalPrintJobData.map(val => ({
      'Month': val.name,
      'Number of Jobs': val.job,
      'Number of Printed Jobs': val.printed,
    }))
  ]), "Number of Print Jobs");

  // add Client Data - Number of Print Jobs
  XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet([
    ...listClients.map(val => ({
      'Client': val.client,
      'Number of Jobs': val.jobs,
      'Number of Printed Jobs': val.printed,
    }))
  ]), "Print Jobs each Client");


  const listOfListProduct = data?.customers.map(val => {
    return val.products.map((v) => ({
      'Client': val.client,
      'Email': val.email,
      'First Name': val.first_name,
      'Last Name': val.last_name,
      'Product Count': val.products.length,
      'One Product': `${v.name} | ${v.product_id} | ${v.variant_id} | ${v.quantity} | ${moment(v.timestamp).format('DD-MM-YYYY')}`,
    }))
  });
  const list = listOfListProduct.length === 0 ? [] : listOfListProduct.reduce((a, b) => [...a, ...b]);

  // add Client Data - Number of Print Jobs
  XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(list), "Customer");


  const listProduct = [];
  data?.customers.forEach(v => {
    v.products.forEach(p => {
      const findIndex = listProduct.findIndex(li => li.product_id === p.product_id && li.variant_id === p.variant_id);
      if (findIndex === -1) {
        listProduct.push({
          client: v.client,
          product_id: p.product_id,
          variant_id: p.variant_id,
          product_name: p.name,
          quantity: p.quantity,
          customers: [
            {
              email: v.email,
              first_name: v.first_name,
              last_name: v.last_name,
            }
          ]
        })
      } else {
        listProduct[findIndex].quantity += p.quantity;
        listProduct[findIndex].customers.push({
          email: v.email,
          first_name: v.first_name,
          last_name: v.last_name,
        })
      }
    });
  });
  // add Client Data - Number of Print Jobs
  XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(listProduct.map(v => ({
    'Client': v.client,
    'Product Name': v.product_name,
    'Product ID': String(v.product_id),
    'Variant ID': String(v.variant_id),
    'Total Quantity': v.quantity,
    'Total Customer': v.customers.length,
  }))), "Product");

  var wbout = XLSX.write(wb, {bookType:'xlsx', type:'array'});
  saveAs(new Blob([wbout],{type:"application/octet-stream"}), `export-${new Date().getTime()}.xlsx`);
};