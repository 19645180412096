import { Button, Popover } from "antd";
import { BgIcon, Col, Row, Text, modal, showPopupMessage, useMobileScreen } from "components";
import React, { useMemo } from "react";
import { PreviewIcon } from "./ListOrders.Comps";
import { useNavFunc } from "navigation";
import Store from "store";
import { commonFuncs } from "./ListOrders.funcs";
import RequestUtils from "store/api-client/Request.utils";
import { COLORS, SCREEN } from "const";
import DownloadPDFsModal from "./DownloadPDFsModal";
import { getPDFDataFromPipelines } from "./utils";

const OrderActions = ({ order, stage, isSearch }) => {
  const { navigation } = useNavFunc();
  const { user } = Store.useUserStore();
  const orderStatus = String(order.Status).toLowerCase();
  const isMobile = useMobileScreen();
  const pipelines = order.Pipelines;
  const { pdfStages, displayValue } = useMemo(() => {
    return getPDFDataFromPipelines(pipelines);
  }, [pipelines]);

  const royalMailOrder = useMemo(() => {
    if (!order.Pipelines) return;
    const last = order.Pipelines[order.Pipelines.length - 1];
    return {
      orderIdentifier: last?.SharedData?.royalMailOrderIdentifier,
      trackingNumber: last?.SharedData?.trackingNumber,
    };
  }, [order]);

  const reprintOrder = async () => {
    if (user?.role !== 'admin') {
      commonFuncs.resellerReprintOrder([order], navigation);
      return;
    }
    await commonFuncs.reprint(order);
    navigation.setParams({
      stage: 'in-production',
    });
  };

  const handlePressDownload = () => {
    modal.show(<DownloadPDFsModal pdfs={displayValue.pdfs} order={order} />)
  };

  const handleCheckRoyalMailStatus = (e: any) => {
    e?.preventDefault?.();
    showPopupMessage({
      title: '',
      content: 'Checking Royal Mail status, please wait for a moment...',
      buttonOkText: 'OK',
      // 
      typeHighlight: 'success',
      contentHighlight: 'Success'
    });
    commonFuncs.checkRoyalMailStatus({
      orderIdentifier: royalMailOrder?.orderIdentifier,
      trackingNumber: royalMailOrder?.trackingNumber,
      order,
    })
  }

  const btns = [
    { title: "Shipping address", icon: "send-to-map", onPress: () => commonFuncs.showCustomerInfo(order['Raw Data']) },
    user?.role === 'admin' && {
      title: "Order override", icon: "manually-entered-data", onPress: () => commonFuncs.adminShowOverrideOrderInfoModal(order)
    },
    orderStatus === 'fulfilled' && {
      title: "Reprint", icon: "refresh", onPress: reprintOrder
    },
    (stage === 'in-production' || isSearch) && {
      title: "Packing slips", icon: "chat", onPress: () => window.open(`${RequestUtils.host}/api/online-stores/order-packing-slips/${order['Order ID']}?clientId=${order['Client ID']}`, "_blank")
    },
    (stage === 'in-production' || isSearch) && {
      title: "Download artwork", icon: "download", onPress: handlePressDownload
    },
    Boolean(royalMailOrder?.trackingNumber || royalMailOrder?.orderIdentifier) && {
      title: "Check Royal Mail status", icon: "refresh", onPress: handleCheckRoyalMailStatus
    },
  ].filter(Boolean);

  const actionButtons = (
    <Col>
      {btns.map(btn => (
        <Button
          type="text" style={{ borderRadius: 3, justifyContent: "flex-start", display: "flex", flexDirection: "row" }}
          key={btn.title}
          onClick={btn.onPress}
          icon={<BgIcon name={btn.icon} size={14} />}
        >
          <Text color="#333333">{btn.title}</Text>
        </Button>
      ))}
    </Col>
  )

  if (isMobile) {
    return (
      <Popover
        content={actionButtons}
        trigger="click"
      >
        <Col width={32} height={32}>
          <BgIcon name="more" size={12} color={COLORS.BLUE} />
        </Col>
      </Popover>
    )
  }
  return (
    <Row width={120}>
      {orderStatus !== 'fulfilled' && (
        <Popover
          content={(
            <Text>Job info</Text>
          )}
          trigger="hover"
        >
          <Button
            type="text" style={{ borderRadius: 3 }}
            onClick={() => navigation.navigate(SCREEN.OrderDetail, { orderId: order['Order ID'] })}
          >
            <BgIcon name="info-sign" size={14} />
          </Button>
        </Popover>
      )}
      <PreviewIcon order={order} />
      <Popover
        content={actionButtons}
        trigger="hover"
      >
        <Button onClick={(e) => e.stopPropagation()} type="text" style={{ borderRadius: 3 }}>
          <BgIcon name="more" size={14} />
        </Button>
      </Popover>
    </Row>
  );
}

export default OrderActions;
