import React, { useEffect, useState, useRef } from 'react';
import { IScreen } from 'type';
import { CMSLayout, Col, Row, Text, Button, TouchField, Input02, Select01, RatioCol, RatioCol2, UploadFile, useUIState, useRefState, Grid, Input, showPopupMessage } from 'components';
import { useNavFunc } from 'navigation';
import { useDynamicResponsiveValue } from 'quickly-react';
import { COLOR, SCREEN } from 'const';
import Store from 'store';
import { Image } from 'react-native';
import { Entypo, AntDesign } from '@expo/vector-icons';

const BGService: IScreen = () => {

  const { navigation, route } = useNavFunc();

  const UserStore = Store.useUserStore();
  const { user, setUser, uiState } = UserStore.useUser(UserStore.user?.id, false);

  const [headerKey, setHeaderKey] = useState('');
  const [headerValue, setHeaderValue] = useState('');

  const [{ loading: imageLoading }, setImageUI] = useUIState();
  const [{ loading: submitting }, setSubmitUI] = useUIState();
  const [{ loading: removing }, setRemoveUI] = useUIState();

  const onChangeField = (label) => (newValue) => {
    setUser({
      ...user,
      [label]: newValue,
    })
  }

  const submit = async () => {
    setSubmitUI({ loading: true });
    try {
      console.log(user);
      const res = await Store.Api.User.upsertReseller({
        id: user.id,
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
        password: user?.password,
        addressLine1: user?.addressLine1 || '',
        addressLine2: user?.addressLine2 || '',
        town: user?.town || '',
        country: user?.country || '',
        postCode: user?.postCode || '',
        otherData: !!headerKey ? {
          ...user?.otherData,
          bgServices: {
            ...user?.otherData?.bgServices,
            authenHeaders: {
              [headerKey]: headerValue,
            }
          }
        } : user?.otherData,
      });
      if (res.data.error) {
        showPopupMessage({
          title: '',
          content: String(res.data.error),
          buttonOkText: 'OK',
          // 
          typeHighlight: 'danger',
          contentHighlight: 'Error'
    
        });
        // alert(res.data.error);
      } else if (res.data.data) {
        showPopupMessage({
          title: '',
          content: 'Reseller information successfully submitted.',
          buttonOkText: 'OK',
          // 
          typeHighlight: 'success',
          contentHighlight: 'Success'
    
        });
        // alert('Reseller information successfully submitted');
        // navigation.reset(SCREEN.UpsertReseller, { id: res.data.data.id });
        navigation.reset({
          index: 0,
          routes: [{ name: SCREEN.UpsertReseller, params: { id: res.data.data.id } }],
        });
      }
    } catch (err) {
      showPopupMessage({
        title: '',
        content: String(err),
        buttonOkText: 'OK',
        // 
        typeHighlight: 'danger',
        contentHighlight: 'Error'
  
      });
      // alert(String(err));
    }
    setSubmitUI({ loading: false });
  }


  useEffect(() => {
    if (!user) return;
    if (!user.otherData?.bgServices?.authenHeaders) return;
    const keys = Object.keys(user.otherData?.bgServices?.authenHeaders);
    setHeaderKey(keys[0]);
    setHeaderValue(user.otherData?.bgServices?.authenHeaders[keys[0]]);
  }, [user]);

  return (
    <CMSLayout requireAuthen>
      <Row m2 marginBottom={0} justifyContent={'space-between'}>
        <Text h3>{`${user?.firstName} ${user?.lastName}`}</Text>
        <Row>
          <Button
            isLoading={submitting}
            text={"Save"}
            width={100} height={40} borderRadius={20}
            onPress={submit}
          />
        </Row>
      </Row>
      <Col flex1 m2 mv1 p1 round1 bgWhite overflow={'scroll'}>
        <Grid xs='100%' md='50%' alignItems={'flex-start'} mb2>
          <Col m1>
            <Text subtitle1 mb2>My information</Text>
            <Input
              placeholder='Email'
              value={user?.email}
              mb1
              onChange={onChangeField('email')}
            />
            <Input
              placeholder='Password'
              value={user?.password}
              mb1
              password
              onChange={onChangeField('password')}
            />
            <Input
              placeholder='First Name'
              value={user?.firstName}
              mb1
              onChange={onChangeField('firstName')}
            />
            <Input
              placeholder='Last Name'
              value={user?.lastName}
              mb1
              onChange={onChangeField('lastName')}
            />
            <Input
              placeholder='Address Line 1'
              value={user?.addressLine1}
              mb1
              onChange={onChangeField('addressLine1')}
            />
            <Input
              placeholder='Address Line 2'
              value={user?.addressLine2}
              mb1
              onChange={onChangeField('addressLine2')}
            />
            <Input
              placeholder='Town'
              value={user?.town}
              mb1
              onChange={onChangeField('town')}
            />
            <Input
              placeholder='Country'
              value={user?.country}
              mb1
              onChange={onChangeField('country')}
            />
            <Input
              placeholder='Post Code'
              value={user?.postCode}
              mb1
              onChange={onChangeField('postCode')}
            />
          </Col>
          <Col m1>
            {!!user && (
              <>
                <Text subtitle1 mb1>BG Print & Ship service API:</Text>
                <Col p2 round1 mb2 backgroundColor={COLOR.GREY_LIGHT}>
                  <Text>https://bg-production.personify.tech/api/bg-services/{user?.id}/new-order</Text>
                </Col>
                <Text subtitle1 mb1>Webhook for receive events:</Text>
                <Input
                  placeholder='Webhook URL'
                  value={user?.otherData?.bgServices?.webhookUrl || ''}
                  mb2
                  onChange={v => {
                    setUser({
                      ...user,
                      otherData: {
                        ...user?.otherData,
                        bgServices: {
                          ...user?.otherData?.bgServices,
                          webhookUrl: v,
                        }
                      }
                    })
                  }}
                />
                <Text subtitle1 mb1>Webhook authentication headers:</Text>
                {(() => {
                  return (
                    <Grid xs='100%' md='50%' marginHorizontal={-10}>
                      <Col p0 m0>
                        <Input
                          placeholder='Header key'
                          value={headerKey}
                          mb2
                          onChange={setHeaderKey}
                        />
                      </Col>
                      <Col p0 m0>
                        <Input
                          placeholder='Header value'
                          value={headerValue}
                          mb2
                          onChange={setHeaderValue}
                        />
                      </Col>
                    </Grid>
                  )
                })()}

              </>
            )}
          </Col>
        </Grid>
      </Col>
    </CMSLayout>
  );
};

BGService.routeInfo = {
  title: 'BG Service - Bottled Goose',
  path: '/bg-service'
};

export default BGService;
