import React from "react";
import { Table as AntTable, TableProps } from "antd";
import "./ordertable.css";

export interface ITableProps extends TableProps<any> {
  data: any[],
  minWidth?: number,
}

const OrderTable = (props: ITableProps) => {
  const { data, columns, expandable, rowClassName, minWidth, ...restProps } = props;
  
  return (
    <AntTable
      
      dataSource={data}
      columns={columns}
      pagination={false}
      expandable={expandable}
      rowClassName={rowClassName}
      
      {...restProps}
    />
  );
};

export default OrderTable;
