import {
  Col,
  Row,
  Text,
  CMSLayout,
  useUIState,
  ShimmerLoading,
  TouchField,
  Button,
  PrintJobItem,
  SwapIconEffect,
} from "components";
import React, { useState, useEffect, useMemo } from "react";
import { IScreen, TPrintJob, TUser } from "type";
import Store from "store";
import { useNavFunc } from "navigation";
import { SCREEN, COLOR } from "const";
import { ActivityIndicator } from "react-native";
import moment from "moment";
import { SVG } from "assets";
import { SimpleLineIcons, Foundation } from "@expo/vector-icons";
import { saveAs } from "file-saver";
import { TimeHelper } from "helpers";
import { Entypo, Feather } from "@expo/vector-icons";

const ListOrderSampleRequest: IScreen = () => {
  const { navigate, navigation } = useNavFunc();
  const UserStore = Store.useUserStore();
  const [list, setList] = useState([]);
  const [{ fetching, errorMes, loading: btnLoading }, setUI] = useUIState({
    fetching: false,
  });
  const [listReseller, setListReseller] = useState<Array<TUser>>([]);
  useEffect(() => {
    (async () => {
      const { list } = await UserStore.getListResellers(1);
      if (list && list.length > 0) {
        setListReseller(list);
      }
    })();
  }, []);
  
  const getData = async (p) => {
    try {
      setUI({ fetching: true, errorMes: "" });
      await TimeHelper.wait(300);
      const res = await Store.Api.Data.list({
        type: 'order-sample-request',
      }, {
        userId: 'all',
      });
      if (res.data.success) {
        setList(res.data.data);
      }
      setUI({ fetching: false, errorMes: "" });
    } catch (err) {
      setUI({ fetching: true, errorMes: String(err) });
    }
  };

  useEffect(() => {
    getData(1);
  }, []);

  const renderList = () => {
    if (list.length === 0) {
      return (
        <Col flex1>
          <Text>No request found</Text>
        </Col>
      );
    }
    return (
      <Col flex1>
        <Row
          height={50}
          stretch
          borderBottomColor={COLOR.GREY_BG}
          borderBottomWidth={1}
        >
          <Col flex1 m1>
            <Text color={COLOR.GREY}>Client</Text>
          </Col>
          <Col flex1 m1>
            <Text color={COLOR.GREY}>Product Name</Text>
          </Col>
          <Col flex1 m1>
            <Text color={COLOR.GREY}>Created At</Text>
          </Col>
          <Col flex1 m1></Col>
        </Row>
        <Col>
          {list.map((val, i) => {
            const findReseller = listReseller.find(v => v.id === val.userId);
            return (
              <Row
                height={50}
                stretch
                borderBottomColor={COLOR.GREY_BG}
                borderBottomWidth={1}
                key={val.id}
              >
                <Col flex1 m1 justifyContent={"center"}>
                  {findReseller ? (
                    <Text>
                      {findReseller.firstName} {findReseller.lastName}
                    </Text>
                  ) : (
                    <Text>{val.userId}</Text>
                  )}
                  
                </Col>
                <Col flex1 m1 justifyContent={"center"}>
                  <Text>{val.name}</Text>
                </Col>
                <Col flex1 m1 justifyContent={"center"}>
                  <Text>{TimeHelper.formatDay(val.createdAt)}</Text>
                </Col>
                <Col flex1 m1 justifyContent={"center"}>
                  <TouchField
                    onPress={() =>
                      navigate(SCREEN.UpsertProduct, { id: val.field1 })
                    }
                    width={30}
                    height={30}
                    borderRadius={15}
                    middle
                  >
                    <SVG.Eye width={17} height={17} />
                  </TouchField>
                </Col>
              </Row>
            );
          })}
        </Col>
      </Col>
    );
  };

  return (
    <CMSLayout requireAuthen>
      <Row m2 marginBottom={0} justifyContent={"space-between"}>
        <Text h3>List Order sample request</Text>
      </Row>
      <Col flex1 m2 mv1 p2 round1 bgWhite>
        {errorMes ? (
          <Col flex1 middle>
            <Text color="red" subtitle1>
              {errorMes}
            </Text>
          </Col>
        ) : fetching ? (
          <Col>
            <Row height={50} stretch mt2>
              <ShimmerLoading round1 flex={1} m1 />
              <ShimmerLoading round1 flex={1} m1 />
              <ShimmerLoading round1 flex={1} m1 />
              <ShimmerLoading round1 flex={1} m1 />
            </Row>
            <Row height={50} stretch mt2>
              <ShimmerLoading round1 flex={1} m1 />
              <ShimmerLoading round1 flex={1} m1 />
              <ShimmerLoading round1 flex={1} m1 />
              <ShimmerLoading round1 flex={1} m1 />
            </Row>
          </Col>
        ) : (
          renderList()
        )}
      </Col>
    </CMSLayout>
  );
};

ListOrderSampleRequest.routeInfo = {
  title: "List Order sample request - Bottled Goose",
  path: "/products/list-order-sample-request",
};

export default ListOrderSampleRequest;
