import Request from '../Request.utils'
export interface IMergeImageRequestBody {
  data: {
    product: {
      physicalHeight: number;
      physicalWidth: number;
      printAreas?: {
        height: number;
        left: number;
        top: number;
        width: number;
      }[];
    };
  };
  images: string[];
}


class TestAPI {
  mergeImage = async (body: IMergeImageRequestBody) => {
    const res = await Request.call('/b/api/test/merge-image', 'POST', undefined, undefined, body, );
    return res;
  }
  testXero = async () => {
    const res = await Request.call('/b/api/test/xero', 'POST', undefined, undefined, undefined, );
    return res;
  }
}
export default new TestAPI()