import * as React from "react";
import Svg, { Path } from "react-native-svg";
const SVGComponent = (props) => (
  <Svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.666664 3.16667C0.666664 1.78596 1.78595 0.666672 3.16666 0.666672H14.8333C16.214 0.666672 17.3333 1.78596 17.3333 3.16667V14.8333C17.3333 16.2141 16.214 17.3333 14.8333 17.3333H3.16666C1.78595 17.3333 0.666664 16.2141 0.666664 14.8333V3.16667Z"
      fill="#5984FF"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0952 5.91081C13.4206 6.23625 13.4206 6.76389 13.0952 7.08932L7.92259 12.506C7.59715 12.8314 7.06951 12.8314 6.74408 12.506L4.24408 10.006C3.91864 9.68055 3.91864 9.15292 4.24408 8.82748C4.56951 8.50204 5.09715 8.50204 5.42259 8.82748L7.33333 10.7382L11.9167 5.91081C12.2421 5.58538 12.7697 5.58538 13.0952 5.91081Z"
      fill="white"
    />
  </Svg>
);
export default SVGComponent;
