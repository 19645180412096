import React from 'react';

interface Props {
  name: string;
  size?: number;
  color?: string;
  [other: string]: any;
}
// https://dev.bg-production.personify.tech/list-icon.html

const BgIcon = ({ name, size, color, ...other }: Props) => {
  // @ts-ignore
  return (<bg-icon
      name={name}
      size={size}
      color={color}
      {...other}
    />
  );
}


export default BgIcon;