import Request from '../Request.utils'
export interface IFromPolotnoRequestBody {
  json?: any;
}
export interface IFromPolotnoToS3RequestBody {
  json?: any;
  name?: string;
}
export interface IBlender2DRenderRequestBody {
  additionRotationLayer?: string;
  angle?: string;
  artworkBase64?: string;
  artworkUrl?: string;
  blend?: string;
  debug?: boolean;
  mainArtworkRotationLayer?: string;
  resolution_x?: number;
  resolution_y?: number;
  support?: string;
  type?: string;
}


class ImageAPI {
  fromPolotno = async (body: IFromPolotnoRequestBody) => {
    const res = await Request.call('/api/images/from-polotno', 'POST', undefined, undefined, body, );
    return res;
  }
  fromPolotnoToS3 = async (body: IFromPolotnoToS3RequestBody) => {
    const res = await Request.call('/api/images/from-polotno-to-s3', 'POST', undefined, undefined, body, );
    return res;
  }
  blender2DRender = async (body: IBlender2DRenderRequestBody) => {
    const res = await Request.call('/api/images/blender-2d-render', 'POST', undefined, undefined, body, );
    return res;
  }
}
export default new ImageAPI()