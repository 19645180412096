import { useRefState } from "components";
import React, { useCallback, useContext, useState } from "react";
import { createContext, memo, useMemo } from "react";

interface NavigationValues {
  editting: {
    [key: string]: boolean,
  },
  updateEditting: (screen: string, value: boolean) => void,
  getEditting: () => void,
}

export const NavigationContext = createContext<NavigationValues>({
  editting: {
  },
  updateEditting: () => { },
  getEditting: () => ({}),
});

export const NavigationMiddleWareProvider = memo((props: any) => {
  const { children } = props;

  const [editting, getEditting, setEditting] = useRefState({});

  const updateEditting = useCallback((screen: string, value: boolean) => {
    setEditting(s => {
      const obj = { ...s };
      if (!value) delete obj[screen];
      else obj[screen] = true;
      return obj;
    })
  }, []);

  const contextValue = useMemo(() => ({
    editting,
    updateEditting,
    getEditting,
  }), [
    editting,
    updateEditting,
    getEditting,
  ]);

  return (
    <NavigationContext.Provider value={contextValue}>
      {children}
    </NavigationContext.Provider>
  );
});

export const useNavigationMiddleWare = () => {
  return useContext(NavigationContext);
}
