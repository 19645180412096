import { Col, Text, CMSLayout, useShopifyEmbedAppFlow, Button } from 'components';
import React, { useState, useEffect } from 'react';
import { IScreen } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN } from 'const';
import { ActivityIndicator } from 'react-native';

import Report from '../reports/Reports';
import ReportsOneClient from '../reports/ReportsOneClient';
import { RolesHelper } from 'helpers';
import apiClient from 'store/api-client';
import { MICRO_SERVICES_HOST } from 'store/Order.Store';

const Home: IScreen = () => {
  const { navigate } = useNavFunc();
  const UserStore = Store.useUserStore();
  const ShopStore = Store.useShopStore();
  const { user } = UserStore;
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    UserStore.onReady().then((u) => {
      if (!u) {
        navigate(SCREEN.Login);
      }
    });
  }, []);

  useEffect(() => {
    if (!isLoggedIn && !!user) {
      setIsLoggedIn(true);
    }
    if (!user && isLoggedIn) {
      setIsLoggedIn(false);
    }
  }, [user, isLoggedIn]);

  return !isLoggedIn ? (
    <Col flex1 middle>
      <ActivityIndicator color="black" size="large" />
      <Text mt2>Please wait...</Text>
    </Col>
  ) : user?.role !== 'admin' ? (
    <CMSLayout>
      <Col flex1 m2 mt1 p2 round1 bgWhite>
        <Col flex1 middle>
          <Text>Permission denied</Text>
        </Col>
      </Col>
    </CMSLayout>
  ) : (
    <CMSLayout>
      <Col flex1 m2 mt1 p2 round1 bgWhite>
        <Col flex1>
          <Button
            solid
            text="Open Logs"
            onPress={async () => {
              const getText = async () => {
                const res = await fetch(`${MICRO_SERVICES_HOST}/api/@common-utils/get-server-log`, {
                  method: 'GET',
                  headers: {
                    'Authorization': 'kgmdqtveva'
                  }
                });
                const text = await res.text();
                return text;
              };
              // console.log(text);
              // open a popup with text
              const popup = window.open('', '_blank');
              popup.document.write(`<pre>${await getText()}</pre>`);
              // refresh log each 1s
              let _interval = setInterval(async () => {
                // stop intervel if popup is closed
                if (popup.closed) {
                  clearInterval(_interval);
                  return;
                }
                const text = await getText();
                popup.document.body.innerHTML = `<pre>${text}</pre>`;
                // scroll popup to bottom
                popup.scrollTo(0, popup.document.body.scrollHeight);
              }, 1000);
            }}
          />
        </Col>
      </Col>
    </CMSLayout>
  )
};

Home.routeInfo = {
  title: 'Bottled Goose - Print Manager',
  path: '/system/services-log',
};

export default Home;
