import { Col, IColProps, Row, Text } from "components/base";
import React from "react";
import BgIcon from "../BgIcon";
import { Input } from "antd";
import { TextAreaProps } from "antd/es/input";

interface Props extends IColProps {
  title?: string,
  icon?: string,
  value?: string,
  onChangeText?: (txt: string) => void,
  inputProps?: TextAreaProps,
}

const TextAreaField = (props: Props) => {
  const { title, icon, value, onChangeText, inputProps, ...restProps } = props;
  return (
    <Col {...restProps}>
      <Row marginBottom={8} alignItems="center">
        <Text fontWeight="500" fontSize={14} marginRight={8}>{title}</Text>
        {!!icon && (
          <BgIcon name={icon} size={14} />
        )}
      </Row>
      <Input.TextArea
        size="large"
        value={value}
        onChange={(e) => onChangeText(e.target.value)}
        autoSize={{ minRows: 3, maxRows: 6 }}
        {...inputProps}
      />
    </Col>
  )
}

export default TextAreaField;
